package com.sludg.vue.rendering

import com.sludg.vue.{EventBindings, ScopedSlots, TemplatingHelpers, VueProps}

/**
  * @author Samir :3
  *         Date: 5/3/2019
  *         Time: 18:43
  *
  */
trait Strong {
  this: TemplatingHelpers =>
  val strong = namedTag[VueProps, EventBindings, ScopedSlots]("strong")
}
