package com.sludg.vuetify.components.helpers

import scala.reflect.ClassTag
import scala.scalajs.js
import scala.scalajs.js.|

/**
  * @author dpoliakas
  *         Date: 2019-02-24
  *         Time: 13:46
  *
  *
  */
object InputRuleHelpers {

  import scala.scalajs.js.JSConverters._

  def convertRuleFunction[A, B](
      f: Option[A] => Option[String],
      g: B => A
  ): js.Function1[js.UndefOr[B], Boolean | String] = s => {
    val r = f(if (s == null) None else s.toOption.map(g))
    if (r.isEmpty)
      true
    else
      r.get
  }

  def ruleListToVueRules[A, B](
      l: List[Option[A] => Option[String]],
      g: B => A
  ): js.UndefOr[js.Array[js.Function1[js.UndefOr[B], Boolean | String]]] = {
    val rulesConverted: js.UndefOr[js.Array[js.Function1[js.UndefOr[B], Boolean | String]]] =
      if (l == Nil)
        js.undefined
      else
        js.defined(l.map(f => convertRuleFunction(f, g)).toJSArray)

    rulesConverted
  }

  def unionToEither[A: ClassTag, B: ClassTag](i: A | B): Either[A, B] = {
    (i: Any) match {
      case a: A => Left(a)
      case b: B => Right(b)
    }
  }

}
