package com.sludg.vuetify.components

import com.sludg.scalajs.DynamicHelper
import com.sludg.vue.{EventBindings, ScopedSlots, VueProps}

import scala.scalajs.js
import scala.scalajs.js.|

/**
  * @author skahvedic
  *         Date: 08/03/2020
  *         Time: 12:10
  *
  *
  */
trait VToolTip {

  import com.sludg.vue.RenderHelpers._

  val vToolTip = namedTag[VTooltipProps, EventBindings, ScopedSlots]("v-tooltip")
}

trait VTooltipProps extends VueProps {
  val color: js.UndefOr[String]                 = js.undefined
  val activator: js.UndefOr[js.Any]             = js.undefined
  val absolute: js.UndefOr[Boolean]             = js.undefined
  val bottom: js.UndefOr[Boolean]               = js.undefined
  val `allow-overflow`: js.UndefOr[Boolean]     = js.undefined
  val `close-delay`: js.UndefOr[Int | String]   = js.undefined
  val `content-class`: js.UndefOr[String]       = js.undefined
  val dark: js.UndefOr[Boolean]                 = js.undefined
  val disabled: js.UndefOr[Boolean]             = js.undefined
  val eager: js.UndefOr[Boolean]                = js.undefined
  val fixed: js.UndefOr[Boolean]                = js.undefined
  val `internal-activator`: js.UndefOr[Boolean] = js.undefined
  val left: js.UndefOr[Boolean]                 = js.undefined
  val light: js.UndefOr[Boolean]                = js.undefined
  val `max-width`: js.UndefOr[Int | String]     = js.undefined
  val `min-width`: js.UndefOr[Int | String]     = js.undefined
  val `nudge-bottom`: js.UndefOr[Int | String]  = js.undefined
  val `nudge-left`: js.UndefOr[Int | String]    = js.undefined
  val `nudge-right`: js.UndefOr[Int | String]   = js.undefined
  val `nudge-top`: js.UndefOr[Int | String]     = js.undefined
  val `nudge-width`: js.UndefOr[Int | String]   = js.undefined
  val `offset-overflow`: js.UndefOr[Boolean]    = js.undefined
  val `open-delay`: js.UndefOr[Int | String]    = js.undefined
  val `open-on-click`: js.UndefOr[Boolean]      = js.undefined
  val `open-on-hover`: js.UndefOr[Boolean]      = js.undefined
  val `position-x`: js.UndefOr[Int]             = js.undefined
  val `position-y`: js.UndefOr[Int]             = js.undefined
  val right: js.UndefOr[Boolean]                = js.undefined
  val top: js.UndefOr[Boolean]                  = js.undefined
  val tag: js.UndefOr[String]                   = js.undefined
  val transition: js.UndefOr[String]            = js.undefined

}

object VTooltipProps {

  import scala.scalajs.js.JSConverters._

  def apply(
      color: Option[String] = None,
      absolute: Option[Boolean] = None,
      activator: Option[js.Any] = None,
      bottom: Option[Boolean] = None,
      `allow-overflow`: Option[Boolean] = None,
      `close-delay`: Option[Either[Int, String]] = None,
      `content-class`: Option[String] = None,
      dark: Option[Boolean] = None,
      disabled: Option[Boolean] = None,
      eager: Option[Boolean] = None,
      fixed: Option[Boolean] = None,
      `internal-activator`: Option[Boolean] = None,
      left: Option[Boolean] = None,
      light: Option[Boolean] = None,
      `max-width`: Option[Either[Int, String]] = None,
      `min-width`: Option[Either[Int, String]] = None,
      `nudge-bottom`: Option[Either[Int, String]] = None,
      `nudge-left`: Option[Either[Int, String]] = None,
      `nudge-right`: Option[Either[Int, String]] = None,
      `nudge-top`: Option[Either[Int, String]] = None,
      `nudge-width`: Option[Either[Int, String]] = None,
      `offset-overflow`: Option[Boolean] = None,
      `open-delay`: Option[Either[Int, String]] = None,
      `open-on-click`: Option[Boolean] = None,
      `open-on-hover`: Option[Boolean] = None,
      `position-x`: Option[Int] = None,
      `position-y`: Option[Int] = None,
      right: Option[Boolean] = None,
      top: Option[Boolean] = None,
      tag: Option[String] = None,
      transition: Option[String] = None
  ): VTooltipProps = {

    DynamicHelper.buildViaDynamic(
      "color"              -> color.map(js.Any.fromString).orUndefined,
      "absolute"           -> absolute.map(js.Any.fromBoolean).orUndefined,
      "activator"          -> activator.orUndefined,
      "bottom"             -> bottom.map(js.Any.fromBoolean).orUndefined,
      "allow-overflow"     -> `allow-overflow`.map(js.Any.fromBoolean).orUndefined,
      "close-delay"        -> `close-delay`.map(_.fold(js.Any.fromInt, js.Any.fromString)).orUndefined,
      "content-class"      -> `content-class`.map(js.Any.fromString).orUndefined,
      "dark"               -> dark.map(js.Any.fromBoolean).orUndefined,
      "disabled"           -> disabled.map(js.Any.fromBoolean).orUndefined,
      "eager"              -> eager.map(js.Any.fromBoolean).orUndefined,
      "fixed"              -> fixed.map(js.Any.fromBoolean).orUndefined,
      "internal-activator" -> `internal-activator`.map(js.Any.fromBoolean).orUndefined,
      "left"               -> left.map(js.Any.fromBoolean).orUndefined,
      "light"              -> light.map(js.Any.fromBoolean).orUndefined,
      "max-width"          -> `max-width`.map(_.fold(js.Any.fromInt, js.Any.fromString)).orUndefined,
      "min-width"          -> `min-width`.map(_.fold(js.Any.fromInt, js.Any.fromString)).orUndefined,
      "nudge-bottom"       -> `nudge-bottom`.map(_.fold(js.Any.fromInt, js.Any.fromString)).orUndefined,
      "nudge-left"         -> `nudge-left`.map(_.fold(js.Any.fromInt, js.Any.fromString)).orUndefined,
      "nudge-right"        -> `nudge-right`.map(_.fold(js.Any.fromInt, js.Any.fromString)).orUndefined,
      "nudge-top"          -> `nudge-top`.map(_.fold(js.Any.fromInt, js.Any.fromString)).orUndefined,
      "nudge-width"        -> `nudge-width`.map(_.fold(js.Any.fromInt, js.Any.fromString)).orUndefined,
      "offset-overflow"    -> `offset-overflow`.map(js.Any.fromBoolean).orUndefined,
      "open-delay"         -> `open-delay`.map(_.fold(js.Any.fromInt, js.Any.fromString)).orUndefined,
      "open-on-click"      -> `open-on-click`.map(js.Any.fromBoolean).orUndefined,
      "open-on-hover"      -> `open-on-hover`.map(js.Any.fromBoolean).orUndefined,
      "position-x"         -> `position-x`.map(js.Any.fromInt).orUndefined,
      "position-y"         -> `position-y`.map(js.Any.fromInt).orUndefined,
      "right"              -> right.map(js.Any.fromBoolean).orUndefined,
      "top"                -> top.map(js.Any.fromBoolean).orUndefined,
      "tag"                -> tag.map(js.Any.fromString).orUndefined,
      "transition"         -> transition.map(js.Any.fromString).orUndefined
    )
  }
}
