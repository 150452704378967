package com.sludg.vue.rendering

import com.sludg.vue.RenderHelpers.namedTag
import com.sludg.vue.{EventBindings, ScopedSlots, TemplatingHelpers, VueProps}

/**
  * @author Samir :3
  *         Date: 25/2/2019
  *         Time: 10:05
  *
  */
trait Small {
  this: TemplatingHelpers =>
  val small = namedTag[VueProps, EventBindings, ScopedSlots]("small")
}
