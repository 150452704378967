package com.sludg.scalajs

import scala.scalajs.js

/**
  * @author dpoliakas
  *         Date: 26/11/2018
  *         Time: 10:09
  *
  *         A helper class to help with some common code
  *
  */
object DynamicHelper {

  /**
    * Helps one build a class of type A using a js.Dynamic.
    *
    * In effect is very similar to using js.Dynamic; the only difference is that fields supplied with
    * undefined value will not be written into the object at all. That is:
    * {{{
    *
    *   // The below would result in a JS object which has a single field "a", the value of which is undefined
    *   val one = js.Dynamic.literal("a" -> js.undefined)
    *
    *   // The below would result in a JS object which would have no fields
    *   val two = DynamicHelper.buildViaDynamic("a" -> js.undefined)
    *
    * }}}
    *
    * This behavior is useful when defining props; an undefined prop has a certain meaning in such contexts
    * and may as a result be undesirable.
    *
    * @param fields the fields of this object
    * @tparam A a type to return. This can be used to bypass the mandatory asInstanceOf[A] call once this method returns
    * @return a dynamic built to include all fields whose value is defined in the parameters and then it is cast to type A
    */
  def buildViaDynamic[A <: js.Any](fields: (String, js.UndefOr[js.Any])*): A =
    addToDynamicIfDefined(js.Dynamic.literal(), fields: _*).asInstanceOf[A]

  /**
    * Adds a field to the dynamic if that field value is defined, otherwise just returns the dynamic
    */
  def addToDynamicIfDefined[A <: js.Dynamic](
      dynamic: A,
      fields: (String, js.UndefOr[js.Any])*
  ): A = {
    fields.foreach { field =>
      if (field._2.isDefined) {
        dynamic.updateDynamic(field._1)(field._2)
      }
    }
    dynamic
  }

}
