package com.sludg.auth0

import scala.scalajs.js
import scala.scalajs.js.annotation.JSImport
import com.sludg

@js.native
@JSImport("auth0-js", "WebAuth")
class WebAuth(val props: WebAuthProps) extends js.Object {

  def authorize(authOptions: Option[AuthorizeOptions]): Unit = js.native

  def checkSession(
      checkSessionProps: js.UndefOr[CheckSessionProps],
      cb: js.Function2[js.UndefOr[String], js.UndefOr[ResponseToken], Unit]
  ): Unit = js.native

  def parseHash(
      props: HashProps,
      f: js.Function2[js.UndefOr[Error], js.UndefOr[ResponseToken], Unit]
  ): Unit = js.native

  def logout(props: LogoutProps): Unit = js.native
}

@js.native
trait LogoutProps extends js.Object {
  val returnTo: js.UndefOr[String]  = js.native
  val clientID: js.UndefOr[String]  = js.native
  val federated: js.UndefOr[String] = js.native
}

object LogoutProps {

  import js.JSConverters._

  def apply(
      returnTo: Option[String] = None,
      clientID: Option[String] = None,
      federated: Option[String] = None
  ): LogoutProps = {
    js.Dynamic
      .literal(
        returnTo = returnTo.orUndefined,
        clientID = clientID.orUndefined,
        federated = federated.orUndefined
      )
      .asInstanceOf[LogoutProps]
  }
}

@js.native
trait CheckSessionProps extends js.Object {
  val clientID: js.UndefOr[String]     = js.native
  val responseType: js.UndefOr[String] = js.native
  val state: js.UndefOr[String]        = js.native
  val nonce: js.UndefOr[String]        = js.native
  val scope: js.UndefOr[String]        = js.native
  val audience: js.UndefOr[String]     = js.native
  val timeout: js.UndefOr[String]      = js.native
}

object CheckSessionProps {
  import js.JSConverters._
  def apply(
      clientID: Option[String] = None,
      responseType: Option[String] = None,
      state: Option[String] = None,
      nonce: Option[String] = None,
      scope: Option[String] = None,
      audience: Option[String] = None,
      timeout: Option[String] = None
  ): CheckSessionProps = {
    js.Dynamic
      .literal(
        "clientID"     -> clientID.orUndefined,
        "responseType" -> responseType.orUndefined,
        "state"        -> state.orUndefined,
        "nonce"        -> nonce.orUndefined,
        "scope"        -> scope.orUndefined,
        "audience"     -> audience.orUndefined,
        "timeout"      -> timeout.orUndefined
      )
      .asInstanceOf[CheckSessionProps]
  }
}

@js.native
trait HashProps extends js.Object {
  val hash: js.UndefOr[String] = js.native
}

object HashProps {

  import js.JSConverters._

  def apply(hash: Option[String]): HashProps =
    js.Dynamic.literal("hash" -> hash.orUndefined).asInstanceOf[HashProps]
}

@js.native
trait Error extends js.Object {
  val error: String            = js.native
  val errorDescription: String = js.native
}

@js.native
trait AuthorizeOptions extends js.Object {
  val audience: Option[String]     = js.native
  val connection: Option[String]   = js.native
  val scope: Option[String]        = js.native
  val responseType: Option[String] = js.native
  val clientID: Option[String]     = js.native
  val redirectUri: Option[String]  = js.native
  val state: Option[String]        = js.native
  val prompt: Option[String]       = js.native
}

object AuthorizeOptions {

  import js.JSConverters._

  def apply(
      audience: Option[String] = None,
      connection: Option[String] = None,
      scope: Option[String] = None,
      responseType: Option[String] = None,
      clientID: Option[String] = None,
      redirectUri: Option[String] = None,
      state: Option[String] = None,
      prompt: Option[String] = None
  ): AuthorizeOptions = {
    js.Dynamic
      .literal(
        "audience"     -> audience.orUndefined,
        "connection"   -> connection.orUndefined,
        "scope"        -> scope.orUndefined,
        "responseType" -> responseType.orUndefined,
        "clientID"     -> clientID.orUndefined,
        "redirectUri"  -> redirectUri.orUndefined,
        "state"        -> state.orUndefined,
        "prompt"       -> prompt.orUndefined
      )
      .asInstanceOf[AuthorizeOptions]
  }
}

@js.native
trait WebAuthProps extends js.Object {
  val domain: String                               = js.native
  val clientID: String                             = js.native
  val redirectUri: Option[String]                  = js.native
  val scope: Option[String]                        = js.native
  val audience: Option[String]                     = js.native
  val responseType: Option[String]                 = js.native
  val responseMode: Option[String]                 = js.native
  val _disableDeprecationWarnings: Option[Boolean] = js.native
}

object WebAuthProps {

  import js.JSConverters._

  def apply(
      domain: String,
      clientID: String,
      redirectUri: Option[String] = None,
      scope: Option[String] = None,
      audience: Option[String] = None,
      responseType: Option[String] = None,
      responseMode: Option[String] = None,
      _disableDeprecationWarnings: Option[Boolean] = None
  ): WebAuthProps =
    js.Dynamic
      .literal(
        "domain"                      -> domain,
        "clientID"                    -> clientID,
        "redirectUri"                 -> redirectUri.orUndefined,
        "scope"                       -> scope.orUndefined,
        "audience"                    -> audience.orUndefined,
        "responseType"                -> responseType.orUndefined,
        "responseMode"                -> responseMode.orUndefined,
        "_disableDeprecationWarnings" -> _disableDeprecationWarnings.orUndefined
      )
      .asInstanceOf[WebAuthProps]
}

import scala.scalajs.js.JSConverters._
@js.native
trait ResponseToken extends js.Object {
  val accessToken: String            = js.native
  val appState: String               = js.native
  val expiresIn: Int                 = js.native
  val idToken: String                = js.native
  val idTokenPayload: IdTokenPayload = js.native
  val refreshToken: Option[String]   = js.native
  val scope: Option[String]          = js.native
  val state: Option[String]          = js.native
  val tokenType: String              = js.native
}

object ResponseToken {
  def apply(
      accessToken: String,
      appState: String,
      expiresIn: Long,
      idToken: String,
      idTokenPayload: IdTokenPayload,
      refreshToken: Option[String] = None,
      scope: Option[String] = None,
      state: Option[String] = None,
      tokenType: String
  ): ResponseToken =
    js.Dynamic
      .literal(
        "accessToken"    -> accessToken,
        "appState"       -> appState,
        "expiresIn"      -> expiresIn.toDouble, // Javascript doesn't have longs; so to keep the precision we convert to double, which in JS is just the number primitive
        "idToken"        -> idToken,
        "idTokenPayload" -> idTokenPayload,
        "refreshToken"   -> refreshToken.orUndefined,
        "scope"          -> scope.orUndefined,
        "state"          -> state.orUndefined,
        "tokenType"      -> tokenType
      )
      .asInstanceOf[ResponseToken]
}

@js.native
trait IdTokenPayload extends js.Object {
  val at_hash: String = js.native
  val aud: String     = js.native
  val exp: Long       = js.native
  val iat: Long       = js.native
  val iss: String     = js.native
  val nonce: String   = js.native
  val sub: String     = js.native
}

object IdTokenPayload {
  def apply(
      at_hash: String,
      aud: String,
      exp: Long,
      iat: Long,
      iss: String,
      nonce: String,
      sub: String
  ): IdTokenPayload =
    js.Dynamic
      .literal(
        "at_hash" -> at_hash,
        "aud"     -> aud,
        "exp"     -> exp.toDouble, // Javascript doesn't have longs; so to keep the precision we convert to double, which in JS is just the number primitive,
        "iat"     -> iat.toDouble, // Javascript doesn't have longs; so to keep the precision we convert to double, which in JS is just the number primitive,
        "iss"     -> iss,
        "nonce"   -> nonce,
        "sub"     -> sub
      )
      .asInstanceOf[IdTokenPayload]
}
