package com.sludg.vuetify.components

import com.sludg.vue.{EventBindings, ScopedSlots, VueProps}

import scala.scalajs.js

trait VListTileTitle {

  import com.sludg.vue.RenderHelpers._

  val vListTileTitle = namedTag[VueProps, EventBindings, ScopedSlots]("v-list-tile-title")

}
