package com.sludg.vuetify.components

import com.sludg.vue.{EventBindings, ScopedSlots, VueProps}

import scala.scalajs.js

trait VListTileAction {

  import com.sludg.vue.RenderHelpers._

  val vListTileAction = namedTag[VueProps, EventBindings, ScopedSlots]("v-list-tile-action")

}

object VListTileAction extends VListTileAction
