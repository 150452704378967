package com.sludg.vuetify.components

import com.sludg.scalajs.DynamicHelper
import com.sludg.vue.{EventBindings, ScopedSlots, VueProps}

import scala.scalajs.js

/**
  * @author pbarman
  *         Date: 29/01/2019
  *         Time: 11:11
  *
  *
  */
trait VSnackbar {

  import com.sludg.vue.RenderHelpers._

  val vSnackbar = namedTag[VSnackbarProps, EventBindings, ScopedSlots]("v-snackbar")

}

object VSnackbar extends VSnackbar

trait VSnackbarProps extends VueProps {

  val `absolute`: js.UndefOr[Boolean]    = js.undefined
  val `auto-height`: js.UndefOr[Boolean] = js.undefined
  val bottom: js.UndefOr[Boolean]        = js.undefined
  val color: js.UndefOr[String]          = js.undefined
  val left: js.UndefOr[Boolean]          = js.undefined
  val `multi-line`: js.UndefOr[Boolean]  = js.undefined
  val right: js.UndefOr[Boolean]         = js.undefined
  val timeout: js.UndefOr[Int]           = js.undefined
  val top: js.UndefOr[Boolean]           = js.undefined
  val value: js.UndefOr[js.Any]          = js.undefined
  val vertical: js.UndefOr[Boolean]      = js.undefined

}

object VSnackbarProps {

  import scala.scalajs.js.JSConverters._

  def apply(
      `absolute`: Option[Boolean] = None,
      `auto-height`: Option[Boolean] = None,
      bottom: Option[Boolean] = None,
      color: Option[String] = None,
      left: Option[Boolean] = None,
      `multi-line`: Option[Boolean] = None,
      right: Option[Boolean] = None,
      timeout: Option[Int] = None,
      top: Option[Boolean] = None,
      value: Option[js.Any] = None,
      vertical: Option[Boolean] = None
  ): VSnackbarProps = {

    DynamicHelper.buildViaDynamic(
      "absolute"    -> `absolute`.map(js.Any.fromBoolean).orUndefined,
      "auto-height" -> `auto-height`.map(js.Any.fromBoolean).orUndefined,
      "bottom"      -> bottom.map(js.Any.fromBoolean).orUndefined,
      "color"       -> color.map(js.Any.fromString).orUndefined,
      "left"        -> left.map(js.Any.fromBoolean).orUndefined,
      "multi-line"  -> `multi-line`.map(js.Any.fromBoolean).orUndefined,
      "right"       -> right.map(js.Any.fromBoolean).orUndefined,
      "timeout"     -> timeout.map(js.Any.fromInt).orUndefined,
      "top"         -> top.map(js.Any.fromBoolean).orUndefined,
      "value"       -> value.orUndefined,
      "vertical"    -> vertical.map(js.Any.fromBoolean).orUndefined
    )
  }
}
