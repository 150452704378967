package com.sludg.vuetify.components

import scala.scalajs.js.JSConverters._
import com.sludg.scalajs.DynamicHelper
import com.sludg.vue.{EventBindings, ScopedSlots, VueProps}

import scala.scalajs.js

trait VRadioGroup {
  import com.sludg.vue.RenderHelpers._
  val vRadioGroup = namedTag[VRadioGroupProps, EventBindings, ScopedSlots]("v-radio-group")
}

trait VRadioGroupProps extends VueProps {
  val value: js.UndefOr[js.Any] = js.undefined

  val `append-icon`: js.UndefOr[String]      = js.undefined
  val name: js.UndefOr[String]               = js.undefined
  val label: js.UndefOr[String]              = js.undefined
  val color: js.UndefOr[String]              = js.undefined
  val `background-color`: js.UndefOr[String] = js.undefined

  val column: js.UndefOr[Boolean]         = js.undefined
  val dark: js.UndefOr[Boolean]           = js.undefined
  val error: js.UndefOr[Boolean]          = js.undefined
  val `hide-details`: js.UndefOr[Boolean] = js.undefined
  val hint: js.UndefOr[Boolean]           = js.undefined
  val light: js.UndefOr[Boolean]          = js.undefined
  val loading: js.UndefOr[Boolean]        = js.undefined
  val mandatory: js.UndefOr[Boolean]      = js.undefined
}

object VRadioGroupProps {

  def apply(
      value: Option[js.Any] = None,
      `append-icon`: Option[String] = None,
      name: Option[String] = None,
      label: Option[String] = None,
      color: Option[String] = None,
      `background-color`: Option[String] = None,
      column: Option[Boolean] = None,
      dark: Option[Boolean] = None,
      error: Option[Boolean] = None,
      `hide-details`: Option[Boolean] = None,
      hint: Option[Boolean] = None,
      light: Option[Boolean] = None,
      loading: Option[Boolean] = None,
      mandatory: Option[Boolean] = None
  ): VRadioGroupProps = {

    DynamicHelper.buildViaDynamic(
      "value"            -> value.orUndefined,
      "append-icon"      -> `append-icon`.map(js.Any.fromString).orUndefined,
      "name"             -> name.map(js.Any.fromString).orUndefined,
      "label"            -> label.map(js.Any.fromString).orUndefined,
      "color"            -> color.map(js.Any.fromString).orUndefined,
      "background-color" -> `background-color`.map(js.Any.fromString).orUndefined,
      "column"           -> column.map(js.Any.fromBoolean).orUndefined,
      "dark"             -> dark.map(js.Any.fromBoolean).orUndefined,
      "error"            -> error.map(js.Any.fromBoolean).orUndefined,
      "hide-details"     -> `hide-details`.map(js.Any.fromBoolean).orUndefined,
      "hint"             -> hint.map(js.Any.fromBoolean).orUndefined,
      "light"            -> light.map(js.Any.fromBoolean).orUndefined,
      "loading"          -> loading.map(js.Any.fromBoolean).orUndefined,
      "mandatory"        -> mandatory.map(js.Any.fromBoolean).orUndefined
    )
  }
}
