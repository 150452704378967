package com.sludg.vuetify.components

import com.sludg.vue.{EventBindings, ScopedSlots, VueProps}

import scala.scalajs.js

import scala.scalajs.js.|

/**
  * @author dpoliakas
  *         Date: 22/11/2018
  *         Time: 17:56
  *
  *
  */
trait VProgressLinear {

  import com.sludg.vue.RenderHelpers._

  val vProgressLinear =
    namedTag[VProgressLinearProps, EventBindings, ScopedSlots]("v-progress-linear")

}

object VProgressLinear extends VProgressLinear

trait VProgressLinearProps extends VueProps {

  val active: js.UndefOr[Boolean]                    = js.undefined
  val `background-color`: js.UndefOr[String]         = js.undefined
  val `background-opacity`: js.UndefOr[Int | String] = js.undefined
  val `buffer-value`: js.UndefOr[Int | String]       = js.undefined
  val color: js.UndefOr[String]                      = js.undefined
  val height: js.UndefOr[Int | String]               = js.undefined
  val indeterminate: js.UndefOr[Boolean]             = js.undefined
  val query: js.UndefOr[Boolean]                     = js.undefined
  val value: js.UndefOr[Int | String]                = js.undefined

}

object VProgressLinearProps {

  import scala.scalajs.js.JSConverters._

  def apply(
      active: Option[Boolean] = None,
      `background-color`: Option[String] = None,
      `background-opacity`: Option[Either[Int, String]] = None,
      `buffer-value`: Option[Either[Int, String]] = None,
      color: Option[String] = None,
      height: Option[Either[Int, String]] = None,
      indeterminate: Option[Boolean] = None,
      query: Option[Boolean] = None,
      value: Option[Either[Int, String]] = None
  ): VProgressLinearProps = {

    js.Dynamic
      .literal(
        "active"           -> active.map(js.Any.fromBoolean).orUndefined,
        "background-color" -> `background-color`.map(js.Any.fromString).orUndefined,
        "background-opacity" -> `background-opacity`
          .map(_.fold(js.Any.fromInt, js.Any.fromString))
          .orUndefined,
        "buffer-value"  -> `buffer-value`.map(_.fold(js.Any.fromInt, js.Any.fromString)).orUndefined,
        "color"         -> color.map(js.Any.fromString).orUndefined,
        "height"        -> height.map(_.fold(js.Any.fromInt, js.Any.fromString)).orUndefined,
        "indeterminate" -> indeterminate.map(js.Any.fromBoolean).orUndefined,
        "query"         -> query.map(js.Any.fromBoolean).orUndefined,
        "value"         -> value.map(_.fold(js.Any.fromInt, js.Any.fromString)).orUndefined
      )
      .asInstanceOf[VProgressLinearProps]
  }
}
