package com.sludg.vuetify.components

import com.sludg.scalajs.DynamicHelper
import com.sludg.vue.{EventBindings, ScopedSlots, VueProps}

import scala.scalajs.js

trait VCardActions {

  import com.sludg.vue.RenderHelpers._
  val vCardActions = namedTag[VCardActionsProps, EventBindings, ScopedSlots]("v-card-actions")

}

trait VCardActionsProps extends VueProps {}

object VCardActionsProps {
  import scala.scalajs.js.JSConverters._
  def apply(): VCardActionsProps = {
    DynamicHelper.buildViaDynamic(
      )
  }
}
