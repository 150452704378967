package com.sludg.client.pages.components.diverts

import com.sludg.FieldExtractor
import com.sludg.Security
import com.sludg.auth0.SludgToken
import com.sludg.client.UserPortalApp
import com.sludg.helpers.AppSetup.{VtslAppProps, VtslAppScopedSlots}
import com.sludg.models.Config.AppLink
import com.sludg.services.ApiCalls
import com.sludg.util.models.SilhouetteModels.{DIDNumber, HourlyExtensions, SilhouetteLocation}
import com.sludg.vue.RenderHelpers._
import com.sludg.vuetify.VuetifyComponents._
import com.sludg.vuetify.components._
import com.sludg.FieldExtractor
import com.sludg.util.models.SilhouetteModels
import com.sludg.vue.{EventBindings, _}
import org.scalajs.dom.Event

import scala.scalajs.js.JSConverters._
import scala.scalajs.js

/**
  * MobileList is the mobile view for did displaying and interaction housed within `DDIDisplayer`.
  * It is only rendered if the screen viewport is `xs` or `sm`, and shows less information in a condensed format when
  * compared with the data table view.
  */
object MobileList {

  def MobileListRenderer(registrationName: String) = namedTag[VueProps, MobileListEvents, ScopedSlots]("MobileList")

  def MobileListComponent(apiCalls: ApiCalls, security: Security, apiCallBus: Vue)(implicit token: SludgToken) = {
    VueComponent.builder
      .withData(new MobileListData())
      .withPropsAs[MobileListProps]
      .build(
        templateOrRender = Right((component, renderer) => {
          /* Each DID number list tile (outerGroupTile) houses dropdown tiles, hourlyLocationsTile, and innerActionTile.
           * The inner tile contains actions that the user can perform to modify that number. */
          vListGroup(
            outerGroupTile(component.didFromTable, component),
            vDivider,
            hourlyLocationsTile(component.didFromTable),
            vDivider,
            innerActionTile(apiCalls, apiCallBus, component, component.didFromTable)
          ).render(renderer)
        })
      )
  }

  private def outerGroupTile(did: DIDNumber, component: MobileListData with MobileListProps with VueComponent[_, _, _])(implicit token: SludgToken) = {
    vListTile(
      RenderOptions(
        slot = Some("activator"),
        //The disabled prop messes with click events, so we use css
        style = Some(js.Dynamic.literal("color" -> DDIUtil.didStatus[String](did, "grey !important", "black", "black"))),
        on = Some(
          EventBindings(
            click = js.defined(_ => {
              component.$emit("currentDid", did)
            })
          )
        )
      ),
      /* Very similar in layout and content to the DDIDisplayer table view, but with certain mobile centric modifications
       * to formatting and content. */
      vListTileAction(DDIUtil.RenderFunctions.whichIcon(did)),
      vListTileContent(
        vListTileTitle(did.incomingName.filter(_.trim.length > 0).map(nem => did.phoneNumber + " - " + nem).getOrElse(did.phoneNumber).toString),
        vListTileSubTitle(mobileDivertText(did, component)),
        vListTileSubTitle(if (!did.openHoursExtension.exists(_.trim.nonEmpty)) "Open hours not set" else "Open - " + did.openHoursExtension.getOrElse(""))
      ),
      vSpacer
    )
  }

  private def mobileDivertText(did: DIDNumber, component: MobileListData with MobileListProps) = {
    if (DDIUtil.isDivertEnablable(did)) {
      (DDIUtil.getExtensionSil(
        DDIUtil.removeNineIfExternal(did.emergencyOverride.number.getOrElse("")),
        component.tenantSubscribers,
        component.tenantAutoAttendants,
        component.tenantCallGroups
      )
        + did.emergencyOverride.name.map(nem => if (nem.trim.length > 0) " - " + nem else "").getOrElse("") + " - " + DDIUtil.booleanToText(did.emergencyOverride.active, "Active", "Inactive"))
    } else {
      "Divert not set"
    }
  }

  private def hourlyLocationsTile(did: DIDNumber) = {
    vListTile(
      vListTileAction(),
      vListTileContent(
        RenderOptions(`class` = DDIUtil.didStatus(did, List(), List(Left("text--primary")), List(Left("text--primary")))),
        vListTileSubTitle(if (!did.closedHoursExtension.exists(_.trim.nonEmpty)) "Closed hours not set" else "Closed - " + did.closedHoursExtension.getOrElse("")),
        vListTileSubTitle(if (!did.lunchHoursExtension.exists(_.trim.nonEmpty)) "Lunch hours not set" else "Lunch - " + did.lunchHoursExtension.getOrElse("")),
        vListTileSubTitle(if (!did.specialHoursExtension.exists(_.trim.nonEmpty)) "Special hours not set" else "Special - " + did.specialHoursExtension.getOrElse(""))
      )
    )
  }

  private def innerActionTile(apiCalls: ApiCalls, vueBus: Vue, component: MobileListData with MobileListProps with VueComponent[_, _, _], did: DIDNumber)(implicit token: SludgToken) = {
    //Events emitted up to the DDIDisplayer so it can call the relevant input screens or api calls
    vListTile(
      makeButton(
        "Routing",
        EventBindings(
          click = js.defined(e => {
            component.$emit("openRouting", e)
          })
        )
      ),
      vDivider(
        RenderOptions(
          props = Some(js.Dynamic.literal("vertical" -> true, "inset" -> true).asInstanceOf[VueProps])
        )
      ),
      makeButton(
        "Diverts",
        EventBindings(
          click = js.defined(e => {
            component.$emit("openDivert", e)
          })
        )
      ),
      vDivider(
        RenderOptions(
          props = Some(js.Dynamic.literal("vertical" -> true, "inset" -> true).asInstanceOf[VueProps])
        )
      ),
      makeButton(
        "Toggle Divert",
        EventBindings(
          click = js.defined(e => {
            component.$emit("toggleDivert", e)
          })
        )
      )
    )
  }

  def makeButton(label: String, eventBindings: EventBindings) = {
    vButton(
      RenderOptions(
        style = Some(js.Dynamic.literal("color" -> "grey")),
        `class` = List(Left("font-weight-regular")),
        props = Some(
          VButtonProps(
            flat = Some(true),
            block = Some(true)
          )
        ),
        on = Some(
          eventBindings
        )
      ),
      label
    )
  }

}

class MobileListData extends js.Object {
  var tenant: Option[Int] = None
}

class MobileListProps(
    val token: js.UndefOr[SludgToken] = js.undefined,
    val didFromTable: DIDNumber,
    val tenantSubscribers: List[SilhouetteLocation],
    val tenantAutoAttendants: List[SilhouetteLocation],
    val tenantCallGroups: List[SilhouetteLocation]
) extends VueProps

trait MobileListEvents extends EventBindings {
  def currentDid(did: DIDNumber): Unit

  def openRouting(e: Event): Unit

  def openDivert(e: Event): Unit

  def toggleDivert(e: Event): Unit
}

object MobileListEvents {
  def apply(
      bindings: EventBindings = EventBindings(),
      currentDid: js.UndefOr[js.Function1[DIDNumber, Unit]] = js.undefined,
      openRouting: js.UndefOr[js.Function1[Event, Unit]] = js.undefined,
      openDivert: js.UndefOr[js.Function1[Event, Unit]] = js.undefined,
      toggleDivert: js.UndefOr[js.Function1[Event, Unit]] = js.undefined
  ): MobileListEvents = {
    bindings.asInstanceOf[js.Dynamic].updateDynamic("currentDid")(currentDid)
    bindings.asInstanceOf[js.Dynamic].updateDynamic("openRouting")(openRouting)
    bindings.asInstanceOf[js.Dynamic].updateDynamic("openDivert")(openDivert)
    bindings.asInstanceOf[js.Dynamic].updateDynamic("toggleDivert")(toggleDivert)
    val binding = bindings.asInstanceOf[MobileListEvents]
    binding
  }
}
