package com.sludg.vuetify

import com.sludg.scalajs.DynamicHelper
import com.sludg.vue.{EventBindings, RenderHelpers, ScopedSlots, VueProps}
import com.sludg.vuetify.components.helpers.InputRuleHelpers

import scala.scalajs.js
import scala.scalajs.js.|

/**
  * @author dpoliakas
  *         Date: 2019-02-25
  *         Time: 09:03
  *
  *
  */
trait VSlider {
  val vSlider = RenderHelpers.namedTag[VSliderProps, EventBindings, ScopedSlots]("v-slider")
}

trait VSliderProps extends VueProps {

  val `always-dirty`: js.UndefOr[Boolean]                       = js.undefined
  val `append-icon`: js.UndefOr[String]                         = js.undefined
  val `background-color`: js.UndefOr[String]                    = js.undefined
  val color: js.UndefOr[String]                                 = js.undefined
  val dark: js.UndefOr[Boolean]                                 = js.undefined
  val disabled: js.UndefOr[Boolean]                             = js.undefined
  val error: js.UndefOr[Boolean]                                = js.undefined
  val `error-count`: js.UndefOr[Int | String]                   = js.undefined
  val `error-messages`: js.UndefOr[String | js.Array[js.Any]]   = js.undefined
  val height: js.UndefOr[Int | String]                          = js.undefined
  val `hide-details`: js.UndefOr[Boolean]                       = js.undefined
  val hint: js.UndefOr[String]                                  = js.undefined
  val `inverse-label`: js.UndefOr[Boolean]                      = js.undefined
  val label: js.UndefOr[String]                                 = js.undefined
  val light: js.UndefOr[Boolean]                                = js.undefined
  val loading: js.UndefOr[Boolean | String]                     = js.undefined
  val max: js.UndefOr[Int | String]                             = js.undefined
  val messages: js.UndefOr[String | js.Array[js.Any]]           = js.undefined
  val min: js.UndefOr[Int | String]                             = js.undefined
  val `persistent-hint`: js.UndefOr[Boolean]                    = js.undefined
  val `prepend-icon`: js.UndefOr[String]                        = js.undefined
  val readonly: js.UndefOr[Boolean]                             = js.undefined
  val rules: js.UndefOr[js.Array[js.Any]]                       = js.undefined
  val step: js.UndefOr[Int | String]                            = js.undefined
  val success: js.UndefOr[Boolean]                              = js.undefined
  val `success-messages`: js.UndefOr[String | js.Array[js.Any]] = js.undefined
  val `thumb-color`: js.UndefOr[String]                         = js.undefined
  val `thumb-label`: js.UndefOr[Boolean | String]               = js.undefined
  val `thumb-size`: js.UndefOr[Int | String]                    = js.undefined
  val `tick-labels`: js.UndefOr[js.Array[js.Any]]               = js.undefined
  val `tick-size`: js.UndefOr[Int | String]                     = js.undefined
  val ticks: js.UndefOr[Boolean | String]                       = js.undefined
  val `track-color`: js.UndefOr[String]                         = js.undefined
  val `validate-on-blur`: js.UndefOr[Boolean]                   = js.undefined
  val value: js.UndefOr[Int | String]                           = js.undefined

}

object VSliderProps {

  import scala.scalajs.js.JSConverters._

  def apply(
      `always-dirty`: Option[Boolean] = None,
      `append-icon`: Option[String] = None,
      `background-color`: Option[String] = None,
      color: Option[String] = None,
      dark: Option[Boolean] = None,
      disabled: Option[Boolean] = None,
      error: Option[Boolean] = None,
      `error-count`: Option[Either[Int, String]] = None,
      `error-messages`: Option[Either[String, js.Array[js.Any]]] = None,
      height: Option[Either[Int, String]] = None,
      `hide-details`: Option[Boolean] = None,
      hint: Option[String] = None,
      `inverse-label`: Option[Boolean] = None,
      label: Option[String] = None,
      light: Option[Boolean] = None,
      loading: Option[Either[Boolean, String]] = None,
      max: Option[Either[Int, String]] = None,
      messages: Option[Either[String, js.Array[js.Any]]] = None,
      min: Option[Either[Int, String]] = None,
      `persistent-hint`: Option[Boolean] = None,
      `prepend-icon`: Option[String] = None,
      readonly: Option[Boolean] = None,
      rules: List[Option[Either[Int, String]] => Option[String]] = Nil,
      step: Option[Either[Int, String]] = None,
      success: Option[Boolean] = None,
      `success-messages`: Option[Either[String, js.Array[js.Any]]] = None,
      `thumb-color`: Option[String] = None,
      `thumb-label`: Option[Either[Boolean, String]] = None,
      `thumb-size`: Option[Either[Int, String]] = None,
      `tick-labels`: Option[js.Array[js.Any]] = None,
      `tick-size`: Option[Either[Int, String]] = None,
      ticks: Option[Either[Boolean, String]] = None,
      `track-color`: Option[String] = None,
      `validate-on-blur`: Option[Boolean] = None,
      value: Option[Either[Int, String]] = None
  ): VSliderProps = {

    val rulesConverted = InputRuleHelpers
      .ruleListToVueRules[Either[Int, String], Int | String](rules, InputRuleHelpers.unionToEither)

    DynamicHelper.buildViaDynamic(
      "always-dirty"     -> `always-dirty`.map(js.Any.fromBoolean).orUndefined,
      "append-icon"      -> `append-icon`.map(js.Any.fromString).orUndefined,
      "background-color" -> `background-color`.map(js.Any.fromString).orUndefined,
      "color"            -> color.map(js.Any.fromString).orUndefined,
      "dark"             -> dark.map(js.Any.fromBoolean).orUndefined,
      "disabled"         -> disabled.map(js.Any.fromBoolean).orUndefined,
      "error"            -> error.map(js.Any.fromBoolean).orUndefined,
      "error-count"      -> `error-count`.map(_.fold(js.Any.fromInt, js.Any.fromString)).orUndefined,
      "error-messages"   -> `error-messages`.map(_.fold(js.Any.fromString, identity)).orUndefined,
      "height"           -> height.map(_.fold(js.Any.fromInt, js.Any.fromString)).orUndefined,
      "hide-details"     -> `hide-details`.map(js.Any.fromBoolean).orUndefined,
      "hint"             -> hint.map(js.Any.fromString).orUndefined,
      "inverse-label"    -> `inverse-label`.map(js.Any.fromBoolean).orUndefined,
      "label"            -> label.map(js.Any.fromString).orUndefined,
      "light"            -> light.map(js.Any.fromBoolean).orUndefined,
      "loading"          -> loading.map(_.fold(js.Any.fromBoolean, js.Any.fromString)).orUndefined,
      "max"              -> max.map(_.fold(js.Any.fromInt, js.Any.fromString)).orUndefined,
      "messages"         -> messages.map(_.fold(js.Any.fromString, identity)).orUndefined,
      "min"              -> min.map(_.fold(js.Any.fromInt, js.Any.fromString)).orUndefined,
      "persistent-hint"  -> `persistent-hint`.map(js.Any.fromBoolean).orUndefined,
      "prepend-icon"     -> `prepend-icon`.map(js.Any.fromString).orUndefined,
      "readonly"         -> readonly.map(js.Any.fromBoolean).orUndefined,
      "rules"            -> rulesConverted,
      "step"             -> step.map(_.fold(js.Any.fromInt, js.Any.fromString)).orUndefined,
      "success"          -> success.map(js.Any.fromBoolean).orUndefined,
      "success-messages" -> `success-messages`.map(_.fold(js.Any.fromString, identity)).orUndefined,
      "thumb-color"      -> `thumb-color`.map(js.Any.fromString).orUndefined,
      "thumb-label"      -> `thumb-label`.map(_.fold(js.Any.fromBoolean, js.Any.fromString)).orUndefined,
      "thumb-size"       -> `thumb-size`.map(_.fold(js.Any.fromInt, js.Any.fromString)).orUndefined,
      "tick-labels"      -> `tick-labels`.orUndefined,
      "tick-size"        -> `tick-size`.map(_.fold(js.Any.fromInt, js.Any.fromString)).orUndefined,
      "ticks"            -> ticks.map(_.fold(js.Any.fromBoolean, js.Any.fromString)).orUndefined,
      "track-color"      -> `track-color`.map(js.Any.fromString).orUndefined,
      "validate-on-blur" -> `validate-on-blur`.map(js.Any.fromBoolean).orUndefined,
      "value"            -> value.map(_.fold(js.Any.fromInt, js.Any.fromString)).orUndefined
    )
  }

}
