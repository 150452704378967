package com.sludg.vue.rendering

import com.sludg.vue.{EventBindings, ScopedSlots, TemplatingHelpers, VueProps}

import scala.scalajs.js

/**
  * @author dpoliakas
  *         Date: 22/11/2018
  *         Time: 18:50
  *
  *
  */
trait Div { this: TemplatingHelpers =>
  val div = namedTag[VueProps, EventBindings, ScopedSlots]("div")
}
