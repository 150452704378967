package com.sludg.client.pages.components.diverts

import com.sludg.FieldExtractor
import com.sludg.Security
import com.sludg.auth0.SludgToken
import com.sludg.client.UserPortalApp
import com.sludg.client.UserPortalApp.UserPortalAppProps
import com.sludg.helpers.AppSetup.{VtslAppProps, VtslAppScopedSlots}
import com.sludg.models.Config.AppLink
import com.sludg.services.ApiCalls
import com.sludg.vue.RenderHelpers._
import com.sludg.vue.{EventBindings, _}

import scala.scalajs.js

object DivertsPage {

  def DivertsPageRenderer(registrationName: String) =
    namedTag[UserPortalAppProps, EventBindings, ScopedSlots]("DivertsPage")

  val userPortalApp = UserPortalApp.userPortalAppRenderer("UserPortal")
  val ddiDisplayer = DDIDisplayer.DDIDisplayerRenderer("DDIDisplayer")

  def DivertsPageComponent(
      apiCalls: ApiCalls,
      security: Security,
      apiCallBus: Vue,
      otherApps: List[AppLink]
  )(implicit token: SludgToken) = {
    VueComponent.builder
      .withData(new DivertsPageData())
      .withPropsAs[DivertsPageProps]
      .build(
        created = js.defined(c => {}),
        components = js.defined(
          js.Dynamic.literal(
            "UserPortal" -> UserPortalApp
              .userPortalAppComponent(apiCalls, security, apiCallBus, otherApps, token.isSuperUser),
            "DDIDisplayer" -> DDIDisplayer
              .DDIDisplayerComponent(apiCalls, security, apiCallBus, otherApps)
          )
        ),
        templateOrRender = Right((component, renderer) => {
          userPortalApp(
            RenderOptions(
              on = Some(js.Dynamic.literal().asInstanceOf[EventBindings]),
              props = Some(
                new UserPortalAppProps(
                  title = js.defined("Call Routing"),
                  displayTenantSelector = token.isSuperUser,
                  spacedToolbar = true,
                  isStandardUser =
                    !token.isSuperUser && !token.subscribers.map(_.admin).headOption.contains(true),
                  token = Some(token)
                )
              ),
              scopedSlots = Some(
                new VtslAppScopedSlots {
                  override val default = js.defined {
                    case Some(tenant) =>
                      div(
                        RenderOptions(
                          style = Some(
                            js.Dynamic.literal(
                              "margin-top" -> "-25px",
                              "margin-left" -> "-25px",
                              "margin-right" -> "-25px"
                            )
                          )
                        ),
                        ddiDisplayer(RenderOptions(props = Some(DDIDisplayerProps(token, tenant))))
                      ).render(renderer)
                    case None => div(nothing).render(renderer)
                  }
                }
              )
            )
          ).render(renderer)
        })
      )
  }
}

class DivertsPageData extends js.Object {
  var tenant: Option[Int] = None
}

class DivertsPageProps(val token: js.UndefOr[SludgToken]) extends VueProps
