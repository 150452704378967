package com.sludg.vuetify.components

import com.sludg.scalajs.DynamicHelper
import com.sludg.vue.{EventBindings, ScopedSlots, VueProps}

import scala.scalajs.js

import scala.scalajs.js.|

/**
  * @author dpoliakas
  *         Date: 27/11/2018
  *         Time: 08:51
  *
  *
  */
trait VIcon {

  import com.sludg.vue.RenderHelpers._

  val vIcon = namedTag[VIconProps, EventBindings, ScopedSlots]("v-icon")
}

object VIcon extends VIcon

trait VIconProps extends VueProps {
  val color: js.UndefOr[String]      = js.undefined
  val dark: js.UndefOr[Boolean]      = js.undefined
  val disabled: js.UndefOr[Boolean]  = js.undefined
  val large: js.UndefOr[Boolean]     = js.undefined
  val left: js.UndefOr[Boolean]      = js.undefined
  val light: js.UndefOr[Boolean]     = js.undefined
  val medium: js.UndefOr[Boolean]    = js.undefined
  val right: js.UndefOr[Boolean]     = js.undefined
  val size: js.UndefOr[Int | String] = js.undefined
  val small: js.UndefOr[Boolean]     = js.undefined
  val `x-large`: js.UndefOr[Boolean] = js.undefined
}

object VIconProps {
  import scala.scalajs.js.JSConverters._

  def apply(
      color: Option[String] = None,
      dark: Option[Boolean] = None,
      disabled: Option[Boolean] = None,
      large: Option[Boolean] = None,
      left: Option[Boolean] = None,
      light: Option[Boolean] = None,
      medium: Option[Boolean] = None,
      right: Option[Boolean] = None,
      size: Option[Either[Int, String]] = None,
      small: Option[Boolean] = None,
      xLarge: Option[Boolean] = None
  ): VIconProps = {
    DynamicHelper.buildViaDynamic(
      "color"    -> color.map(js.Any.fromString).orUndefined,
      "dark"     -> dark.map(js.Any.fromBoolean).orUndefined,
      "disabled" -> disabled.map(js.Any.fromBoolean).orUndefined,
      "large"    -> large.map(js.Any.fromBoolean).orUndefined,
      "left"     -> left.map(js.Any.fromBoolean).orUndefined,
      "light"    -> light.map(js.Any.fromBoolean).orUndefined,
      "medium"   -> medium.map(js.Any.fromBoolean).orUndefined,
      "right"    -> right.map(js.Any.fromBoolean).orUndefined,
      "size"     -> size.map(_.fold(js.Any.fromInt, js.Any.fromString)).orUndefined,
      "small"    -> small.map(js.Any.fromBoolean).orUndefined,
      "x-large"  -> xLarge.map(js.Any.fromBoolean).orUndefined
    )
  }

}
