package com.sludg.vue

import com.sludg.scalajs.DynamicHelper

import scala.scalajs.js
import scala.scalajs.js.annotation.JSImport
import scala.scalajs.js.|

/**
  * @author dpoliakas
  *         Date: 27/11/2018
  *         Time: 10:24
  *
  *
  */
@JSImport("vue-router", JSImport.Default)
@js.native
class VueRouter(routes: VueRouterOptions) extends js.Object {

  def go(i: Int): Unit = js.native

  def beforeEach(
      to: VueRoute,
      from: VueRoute,
      next: js.Function1[VueRoute, Unit]
  ): js.Function1[VueRoute, Unit] = js.native

  def push(n: String | VueRoute): js.Dynamic = js.native
}

class RouteParam(name: String) extends js.Object

@JSImport("vue-router", JSImport.Default)
@js.native
object VueRouter extends js.Object

@js.native
trait VueRouterOptions extends js.Object {
  val routes: js.Array[VueRoute] = js.native
  val mode: js.UndefOr[String]   = js.native
}

object VueRouterOptions {

  import scala.scalajs.js.JSConverters._

  def apply(routes: List[VueRoute], mode: Option[String] = None): VueRouterOptions = {
    DynamicHelper.buildViaDynamic(
      "routes" -> routes.toJSArray,
      "mode"   -> mode.map(js.Any.fromString).orUndefined
    )
  }
}

trait VueRoute extends js.Object {
  val name: js.UndefOr[String]
  val path: String
  val component: VueComponent[_, _, _]
  val redirect: js.UndefOr[String] = js.undefined
  val props: js.UndefOr[js.Object] = js.undefined
}

object VueRoute {

  import scala.scalajs.js.JSConverters._

  def apply(
      path: String,
      name: Option[String] = None,
      component: Option[VueComponent[_, _, _]] = None,
      props: Option[js.Object] = None,
      redirect: Option[String] = None
  ): VueRoute = {
    DynamicHelper.buildViaDynamic(
      "path"      -> js.Any.fromString(path),
      "name"      -> name.map(js.Any.fromString).orUndefined,
      "props"     -> props.map(_.asInstanceOf[js.Any]).orUndefined,
      "component" -> component.orUndefined,
      "redirect"  -> redirect.map(js.Any.fromString).orUndefined
    )
  }
}
