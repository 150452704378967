package com.sludg.components

import com.sludg.vue.VueInstanceProperties.CreateElement
import com.sludg.vue.{RenderHelpers, _}
import com.sludg.vuetify.components.{VButtonProps, VSnackbarProps}
import org.scalajs.dom.raw.Event
import com.sludg.vue.RenderHelpers.{br, div, nothing, span}
import com.sludg.vuetify.components._
import com.sludg.vuetify.VuetifyComponents._
import scala.scalajs.js.timers.SetTimeoutHandle
import scala.concurrent.{ExecutionContext, Future}
import scala.scalajs.js
import scala.scalajs.js.{JSON, UndefOr}

/**
  * User notification snackbar related Models and methods
  */
object Snackbar {

  val snackBarEvent = "snackbar-message-event"

  trait DisplayMessageType {
    val color: String
  }

  // These types could be better
  object DisplayMessageType {

    case object Error extends DisplayMessageType {
      val color = "red"
    }

    case object Success extends DisplayMessageType {
      val color = "green"
    }

    case object Info extends DisplayMessageType {
      val color = "primary"
    }

    case object Forbidden extends DisplayMessageType {
      val color = "orange"
    }

  }

  case class SnackBarMessage(
      message: String,
      messageType: DisplayMessageType,
      timer: Option[SetTimeoutHandle] = None
  )

  /**
    * Used to display snackbar Messages with some colour coding.
    *
    */
  def snackBarRenderer(appData: SnackbarData, closeHandler: Event => Unit) = {
    appData.snackBarQueue.zipWithIndex.map({
      case (sbm, i) => {
        val queueSize = appData.snackBarQueue.size - 1
        vSnackbar(
          RenderOptions(
            key = Some(i.toString),
            props = Some(
              VSnackbarProps(
                timeout = Some(0),
                `multi-line` = Some(true),
                value = Some(i == 0),
                color = Some(sbm.messageType.color)
              )
            )
          ),
          sbm.message,
          vButton(
            RenderOptions(
              props = Some(
                VButtonProps(
                  color = Some("white"),
                  flat = Some(true)
                )
              ),
              on = Some(
                EventBindings(
                  click = js.defined(e => closeHandler(e))
                )
              )
            ),
            s"Close${if (queueSize > 0) s" (${queueSize})" else ""}"
          )
        )
      }
    })
  }

  /**
    * Adds snack bar message to the queue
    * @param snackBarMessage
    * @param c
    */
  def addSnackBarToQueue(snackBarMessage: SnackBarMessage, c: SnackbarData) = {
    c.snackBarQueue = c.snackBarQueue ++ List(snackBarMessage)
  }

  /**
    * Closes snackbar by removing it from queue and clears the timer
    *
    * @param s
    */
  def closeSnackBar(c: SnackbarData) = {
    c.currentSnackBarMessageTimer foreach { t =>
      js.timers.clearTimeout(t)
      c.currentSnackBarMessageTimer = None
      c.snackBarQueue = if (c.snackBarQueue.headOption.isDefined) c.snackBarQueue.tail else Nil
    }
  }

  /**
    * Method used by all apps to queue up messages and display them
    * @param snackBarMessage
    * @param loader
    */
  def displaySnackBar(snackBarMessage: SnackBarMessage, loader: Vue) = {
    loader.$emit(snackBarEvent, snackBarMessage)
  }
}

trait SnackbarData extends js.Any {
  import Snackbar._
  var snackBarQueue: List[SnackBarMessage]
  var currentSnackBarMessageTimer: Option[SetTimeoutHandle]
}
