package com.sludg.models

import com.sludg.vuetify.VuetifyTheme
import org.log4s
import play.api.libs.json.{JsObject, JsString}
import org.log4s._
import org.log4s.Debug
import com.sludg.Auth0Config

object Config {

  trait DevState
  object DevState {
    case object Beta        extends DevState
    case object Alpha       extends DevState
    case object Prod        extends DevState
    case object Unavailable extends DevState
  }

  case class AppLink(
      app: String,
      title: String,
      subtitle: String,
      icon: String,
      domain: Option[String],
      userLinkApp: Option[Boolean],
      devState: Option[DevState] = None
  ) {
    val userLinkDomain = for {
      d <- domain
      u <- userLinkApp if u
    } yield d
  }

  case class VueColors(primary: String, secondary: String) {
    def toVuetifyColors: VuetifyTheme =
      VuetifyTheme(primary = Some(primary), secondary = Some(secondary))
  }

  trait Config {
    val vuetify: VueColors
  }

  case class ScheduledReportConfig(maxColumns: String)
  case class ApiConfig(host: String, protocol: String)

  case class AlmostAuth0Config(domain: String, clientID: String, audience: String) {
    def toFull(redirectUri: String): Auth0Config =
      Auth0Config(domain, clientID, audience, redirectUri)
  }

}

sealed trait LogLevel

object LogLevel {
  val strToObj: Map[String, log4s.LogLevel] = Map(
    "TRACE" -> Trace,
    "INFO"  -> Info,
    "DEBUG" -> Debug,
    "WARN"  -> Warn
  )
}
