package com.sludg.vuetify.components

import com.sludg.vue.{EventBindings, ScopedSlots, VueProps}

trait VListTileSubTitle {

  import com.sludg.vue.RenderHelpers._

  val vListTileSubTitle = namedTag[VueProps, EventBindings, ScopedSlots]("v-list-tile-sub-title")

}
