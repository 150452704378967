package com.sludg.vuetify

import com.sludg.vuetify.components._
import com.sludg.vuetify.components.grid._
import com.sludg.vuetify.components.gridSystem.{VueGridItem, VueGridLayout}
import com.sludg.vuetify.components.transitions.VTransitions

/**
  * @author dpoliakas
  *         Date: 23/11/2018
  *         Time: 09:32
  *
  *         Importing members of this object allows you to use all Vuetify components
  *
  *         {{{
  *                                      @ScalaJSDefined
  *                                      class MyVueComponent extends VueComponent[_] {
  *                                        import com.sludg.vue.RenderHelpers._
  *                                        import com.sludg.vuetify.VuetifyComponents._
  *                                        import com.sludg.vuetify.components._
  *
  *                                        override val render = withoutData(
  *                                          vApp(
  *                                            vToolbar(
  *                                                vToolbarTitle("My application")
  *                                            )(VToolbarProps(app = true)
  *                                          )
  *                                        )
  *                                      }
  * }}}
  *
  *         If you want you can also import individual components, e.g.
  *
  *         {{{
  *                                      import com.sludg.vuetify.components.VApp
  *                                      vApp("Hello")
  * }}}
  *
  */
object VuetifyComponents
    extends VApp
    with VAlert
    with VAvatar
    with VButton
    with VButtonToggle
    with VCheckBox
    with VChip
    with VCard
    with VCardText
    with VueGridItem
    with VueGridLayout
    with VCardTitle
    with VCardActions
    with VContent
    with VContainer
    with VCombobox
    with VDialog
    with VExpansionPanel
    with VExpansionPanelContent
    with VFooter
    with VForm
    with VImage
    with VIcon
    with VNavigationDrawer
    with VSpacer
    with VToolbar
    with VToolbarTitle
    with VToolbarSideIcon
    with VAutocomplete
    with VMenu
    with VTextField
    with VTextArea
    with VDatePicker
    with VTimePicker
    with VListItem
    with VListItemTitle
    with VList
    with VListGroup
    with VListTile
    with VListTileAction
    with VListTileAvatar
    with VListTileContent
    with VListTileTitle
    with VListTileSubTitle
    with VDivider
    with VPagination
    with VProgressLinear
    with VProgressCircular
    with VSlider
    with VSnackbar
    with VStepper
    with VStepperHeader
    with VStepperStep
    with VStepperContent
    with VStepperItems
    with VSubheader
    with VSwitch
    with VRadio
    with VRadioGroup
    with VTab
    with VTabItem
    with VTabs
    with VTabsItems
    with VToolTip
    with VTransitions
    with VSpeedDial
    with VFlex
    with VBreadcrumbs
    with VDataTable
    with VLayout
    with VGridPropValues
    with VFlexPropValues
    with VBadge
