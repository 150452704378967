package com.sludg.vuetify.components

import com.sludg.scalajs.DynamicHelper
import com.sludg.vue.{EventBindings, ScopedSlots, VueProps}

import scala.scalajs.js
import scala.scalajs.js.|

trait VChip {

  import com.sludg.vue.RenderHelpers._

  val vChip = namedTag[VChipProps, EventBindings, ScopedSlots]("v-chip")

}

object VChip extends VChip

trait VChipProps extends VueProps {
  val close: js.UndefOr[Boolean]    = js.undefined
  val small: js.UndefOr[Boolean]    = js.undefined
  val value: js.UndefOr[Boolean]    = js.undefined
  val disabled: js.UndefOr[Boolean] = js.undefined
  val label: js.UndefOr[Boolean]    = js.undefined
  val color: js.UndefOr[String]     = js.undefined
  val textColor: js.UndefOr[String] = js.undefined

}

object VChipProps {

  import scala.scalajs.js.JSConverters._

  def apply[A <: js.Any](
      close: Option[Boolean] = None,
      small: Option[Boolean] = None,
      value: Option[Boolean] = None,
      disabled: Option[Boolean] = None,
      label: Option[Boolean] = None,
      color: Option[String] = None,
      textColor: Option[String] = None
  ): VChipProps = {

    DynamicHelper.buildViaDynamic(
      "close"      -> close.map(js.Any.fromBoolean).orUndefined,
      "small"      -> small.map(js.Any.fromBoolean).orUndefined,
      "value"      -> value.map(js.Any.fromBoolean).orUndefined,
      "disabled"   -> disabled.map(js.Any.fromBoolean).orUndefined,
      "label"      -> label.map(js.Any.fromBoolean).orUndefined,
      "color"      -> color.map(js.Any.fromString).orUndefined,
      "text-color" -> textColor.map(js.Any.fromString).orUndefined
    )
  }
}
