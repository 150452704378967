package com.sludg.vuetify.components

import com.sludg.vue.{EventBindings, ScopedSlots}

/**
  * @author dpoliakas
  *         Date: 22/11/2018
  *         Time: 17:20
  *
  *
  */
trait VToolbar {

  import com.sludg.vue.RenderHelpers._

  val vToolbar = namedTag[VToolbarProps, EventBindings, ScopedSlots]("v-toolbar")

}

object VToolbar extends VToolbar

trait VToolbarProps extends VuetifyComponentProps

object VToolbarProps {
  def apply(app: Boolean = false): VToolbarProps =
    VuetifyComponentProps(app = app).asInstanceOf[VToolbarProps]
}
