package com.sludg.vuetify.components
import com.sludg.scalajs.DynamicHelper
import com.sludg.vue.{EventBindings, ScopedSlots, VueProps}
import scala.scalajs.js
import scala.scalajs.js.|

trait VCard {

  import com.sludg.vue.RenderHelpers._
  val vCard = namedTag[VCardProps, EventBindings, ScopedSlots]("v-card")

}

object VCard extends VCard

trait VCardProps extends VueProps {
  val `active-class`: js.UndefOr[String]       = js.undefined
  val append: js.UndefOr[Boolean]              = js.undefined
  val color: js.UndefOr[String]                = js.undefined
  val dark: js.UndefOr[Boolean]                = js.undefined
  val disabled: js.UndefOr[Boolean]            = js.undefined
  val elevation: js.UndefOr[Int | String]      = js.undefined
  val exact: js.UndefOr[Boolean]               = js.undefined
  val `exact-active-class`: js.UndefOr[String] = js.undefined
  val flat: js.UndefOr[Boolean]                = js.undefined
  val height: js.UndefOr[Int | String]         = js.undefined
  val hover: js.UndefOr[Boolean]               = js.undefined
  val href: js.UndefOr[String]                 = js.undefined
  val img: js.UndefOr[String]                  = js.undefined
  val light: js.UndefOr[Boolean]               = js.undefined
  val `max-height`: js.UndefOr[Int | String]   = js.undefined
  val `max-width`: js.UndefOr[Int | String]    = js.undefined
  val `min-height`: js.UndefOr[Int | String]   = js.undefined
  val `min-width`: js.UndefOr[Int | String]    = js.undefined
  val nuxt: js.UndefOr[Boolean]                = js.undefined
  val raised: js.UndefOr[Boolean]              = js.undefined
  val replace: js.UndefOr[Boolean]             = js.undefined
  val ripple: js.UndefOr[Boolean]              = js.undefined
  val tag: js.UndefOr[String]                  = js.undefined
  val target: js.UndefOr[String]               = js.undefined
  val tile: js.UndefOr[Boolean]                = js.undefined
  val to: js.UndefOr[String]                   = js.undefined
  val `width`: js.UndefOr[Int | String]        = js.undefined
}

object VCardProps {

  import scala.scalajs.js.JSConverters._

  def apply(
      `active-class`: Option[String] = None,
      append: Option[Boolean] = None,
      color: Option[String] = None,
      dark: Option[Boolean] = None,
      disabled: Option[Boolean] = None,
      elevation: Option[Either[Int, String]] = None,
      exact: Option[Boolean] = None,
      `exact-active-class`: Option[String] = None,
      flat: Option[Boolean] = None,
      height: Option[Either[Int, String]] = None,
      hover: Option[Boolean] = None,
      href: Option[String] = None,
      img: Option[String] = None,
      light: Option[Boolean] = None,
      `max-height`: Option[Either[Int, String]] = None,
      `max-width`: Option[Either[Int, String]] = None,
      `min-height`: Option[Either[Int, String]] = None,
      `min-width`: Option[Either[Int, String]] = None,
      nuxt: Option[Boolean] = None,
      raised: Option[Boolean] = None,
      replace: Option[Boolean] = None,
      ripple: Option[Boolean] = None,
      tag: Option[String] = None,
      target: Option[String] = None,
      tile: Option[Boolean] = None,
      to: Option[String] = None,
      `width`: Option[Either[Int, String]] = None
  ): VCardProps = {

    DynamicHelper.buildViaDynamic(
      "active-class"       -> `active-class`.map(js.Any.fromString).orUndefined,
      "append"             -> append.map(js.Any.fromBoolean).orUndefined,
      "color"              -> color.map(js.Any.fromString).orUndefined,
      "dark"               -> dark.map(js.Any.fromBoolean).orUndefined,
      "disabled"           -> disabled.map(js.Any.fromBoolean).orUndefined,
      "elevation"          -> elevation.map(_.fold(js.Any.fromInt, js.Any.fromString)).orUndefined,
      "exact"              -> exact.map(js.Any.fromBoolean).orUndefined,
      "exact-active-class" -> `exact-active-class`.map(js.Any.fromString).orUndefined,
      "flat"               -> flat.map(js.Any.fromBoolean).orUndefined,
      "height"             -> height.map(_.fold(js.Any.fromInt, js.Any.fromString)).orUndefined,
      "hover"              -> hover.map(js.Any.fromBoolean).orUndefined,
      "href"               -> href.map(js.Any.fromString).orUndefined,
      "img"                -> img.map(js.Any.fromString).orUndefined,
      "light"              -> light.map(js.Any.fromBoolean).orUndefined,
      "max-height"         -> `max-height`.map(_.fold(js.Any.fromInt, js.Any.fromString)).orUndefined,
      "max-width"          -> `max-width`.map(_.fold(js.Any.fromInt, js.Any.fromString)).orUndefined,
      "min-height"         -> `min-height`.map(_.fold(js.Any.fromInt, js.Any.fromString)).orUndefined,
      "min-width"          -> `min-width`.map(_.fold(js.Any.fromInt, js.Any.fromString)).orUndefined,
      "nuxt"               -> nuxt.map(js.Any.fromBoolean).orUndefined,
      "raised"             -> raised.map(js.Any.fromBoolean).orUndefined,
      "replace"            -> replace.map(js.Any.fromBoolean).orUndefined,
      "ripple"             -> ripple.map(js.Any.fromBoolean).orUndefined,
      "tag"                -> tag.map(js.Any.fromString).orUndefined,
      "target"             -> target.map(js.Any.fromString).orUndefined,
      "tile"               -> tile.map(js.Any.fromBoolean).orUndefined,
      "to"                 -> to.map(js.Any.fromString).orUndefined,
      "width"              -> `width`.map(_.fold(js.Any.fromInt, js.Any.fromString)).orUndefined
    )
  }
}
