package com.sludg.vuetify.components.grid

import com.sludg.scalajs.DynamicHelper
import com.sludg.vue.{EventBindings, ScopedSlots, VueProps}

import scala.scalajs.js

/**
  * @author dpoliakas
  *         Date: 22/11/2018
  *         Time: 17:20
  *
  *
  */
trait VContainer {

  import com.sludg.vue.RenderHelpers._

  val vContainer = namedTag[VGridProps, EventBindings, ScopedSlots]("v-container")

}

object VContainer extends VContainer
