package com.sludg.vuetify.components

import com.sludg.vue.{EventBindings, ScopedSlots, VueProps}

import scala.scalajs.js

trait VListTileContent {

  import com.sludg.vue.RenderHelpers._

  val vListTileContent = namedTag[VueProps, EventBindings, ScopedSlots]("v-list-tile-content")

}

object VListTileContent extends VListTileContent
