package com.sludg.vuetify.components

import com.sludg.vue.{EventBindings, ScopedSlots, VueProps}

import scala.scalajs.js

import scala.scalajs.js.|

/**
  * @author dpoliakas
  *         Date: 22/11/2018
  *         Time: 17:56
  *
  *
  */
trait VProgressCircular {

  import com.sludg.vue.RenderHelpers._

  val vProgressCircular =
    namedTag[VProgressCircularProps, EventBindings, ScopedSlots]("v-progress-circular")

}

object VProgressCircular extends VProgressCircular

trait VProgressCircularProps extends VueProps {

  val color: js.UndefOr[String]          = js.undefined
  val indeterminate: js.UndefOr[Boolean] = js.undefined
  val rotate: js.UndefOr[Int | String]   = js.undefined
  val size: js.UndefOr[Int | String]     = js.undefined
  val value: js.UndefOr[Int | String]    = js.undefined
  val width: js.UndefOr[Int | String]    = js.undefined

}

object VProgressCircularProps {

  import scala.scalajs.js.JSConverters._

  def apply(
      color: Option[String] = None,
      indeterminate: Option[Boolean] = None,
      rotate: Option[Either[Int, String]] = None,
      size: Option[Either[Int, String]] = None,
      value: Option[Either[Int, String]] = None,
      width: Option[Either[Int, String]] = None
  ): VProgressCircularProps = {

    js.Dynamic
      .literal(
        "color"         -> color.map(js.Any.fromString).orUndefined,
        "indeterminate" -> indeterminate.map(js.Any.fromBoolean).orUndefined,
        "rotate"        -> rotate.map(_.fold(js.Any.fromInt, js.Any.fromString)).orUndefined,
        "size"          -> size.map(_.fold(js.Any.fromInt, js.Any.fromString)).orUndefined,
        "value"         -> value.map(_.fold(js.Any.fromInt, js.Any.fromString)).orUndefined,
        "width"         -> width.map(_.fold(js.Any.fromInt, js.Any.fromString)).orUndefined
      )
      .asInstanceOf[VProgressCircularProps]
  }
}
