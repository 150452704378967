package com.sludg.vuetify.components

import com.sludg.scalajs.DynamicHelper
import com.sludg.vue.{EventBindings, ScopedSlots, VueProps}

import scala.scalajs.js

import scala.scalajs.js.|

trait VTextField {

  import com.sludg.vue.RenderHelpers._

  val vTextField = namedTag[VTextFieldProps, EventBindings, ScopedSlots]("v-text-field")

}

object VTextField extends VTextField

trait VTextFieldProps extends VueProps {

  val `append-icon`: js.UndefOr[String]                                   = js.undefined
  val `append-outer-icon`: js.UndefOr[String]                             = js.undefined
  val autofocus: js.UndefOr[Boolean]                                      = js.undefined
  val `background-color`: js.UndefOr[String]                              = js.undefined
  val box: js.UndefOr[Boolean]                                            = js.undefined
  val `browser-autocomplete`: js.UndefOr[String]                          = js.undefined
  val `clear-icon`: js.UndefOr[String]                                    = js.undefined
  val clearable: js.UndefOr[Boolean]                                      = js.undefined
  val color: js.UndefOr[String]                                           = js.undefined
  val counter: js.UndefOr[Boolean | Int | String]                         = js.undefined
  val dark: js.UndefOr[Boolean]                                           = js.undefined
  val disabled: js.UndefOr[Boolean]                                       = js.undefined
  val `dont-fill-mask-blanks`: js.UndefOr[Boolean]                        = js.undefined
  val error: js.UndefOr[Boolean]                                          = js.undefined
  val `error-count`: js.UndefOr[Int | String]                             = js.undefined
  val `error-messages`: js.UndefOr[String | js.Array[js.Any]]             = js.undefined
  val flat: js.UndefOr[Boolean]                                           = js.undefined
  val `full-width`: js.UndefOr[Boolean]                                   = js.undefined
  val height: js.UndefOr[Int | String]                                    = js.undefined
  val `hide-details`: js.UndefOr[Boolean]                                 = js.undefined
  val hint: js.UndefOr[String]                                            = js.undefined
  val label: js.UndefOr[String]                                           = js.undefined
  val light: js.UndefOr[Boolean]                                          = js.undefined
  val loading: js.UndefOr[Boolean | String]                               = js.undefined
  val mask: js.UndefOr[js.Object | String]                                = js.undefined
  val messages: js.UndefOr[String | js.Array[js.Any]]                     = js.undefined
  val outline: js.UndefOr[Boolean]                                        = js.undefined
  val `persistent-hint`: js.UndefOr[Boolean]                              = js.undefined
  val placeholder: js.UndefOr[String]                                     = js.undefined
  val prefix: js.UndefOr[String]                                          = js.undefined
  val `prepend-icon`: js.UndefOr[String]                                  = js.undefined
  val `prepend-inner-icon`: js.UndefOr[String]                            = js.undefined
  val readonly: js.UndefOr[Boolean]                                       = js.undefined
  val `return-masked-value`: js.UndefOr[Boolean]                          = js.undefined
  val reverse: js.UndefOr[Boolean]                                        = js.undefined
  val rules: js.UndefOr[js.Array[js.Function1[String, Boolean | String]]] = js.undefined
  val `single-line`: js.UndefOr[Boolean]                                  = js.undefined
  val solo: js.UndefOr[Boolean]                                           = js.undefined
  val `solo-inverted`: js.UndefOr[Boolean]                                = js.undefined
  val success: js.UndefOr[Boolean]                                        = js.undefined
  val `success-messages`: js.UndefOr[String | js.Array[js.Any]]           = js.undefined
  val suffix: js.UndefOr[String]                                          = js.undefined
  val `type`: js.UndefOr[String]                                          = js.undefined
  val `validate-on-blur`: js.UndefOr[Boolean]                             = js.undefined
  val `value`: js.UndefOr[Any]                                            = js.undefined
  val landscape: js.UndefOr[Boolean]                                      = js.undefined

}

object VTextFieldProps {

  import scala.scalajs.js.JSConverters._

  import helpers.InputRuleHelpers._

  def apply(
      `append-icon`: Option[String] = None,
      `append-outer-icon`: Option[String] = None,
      autofocus: Option[Boolean] = None,
      `background-color`: Option[String] = None,
      box: Option[Boolean] = None,
      `browser-autocomplete`: Option[String] = None,
      `clear-icon`: Option[String] = None,
      clearable: Option[Boolean] = None,
      color: Option[String] = None,
      counter: Option[Either[Int, Either[Boolean, String]]] = None,
      dark: Option[Boolean] = None,
      disabled: Option[Boolean] = None,
      `dont-fill-mask-blanks`: Option[Boolean] = None,
      error: Option[Boolean] = None,
      `error-count`: Option[Either[Int, String]] = None,
      `error-messages`: Option[Either[String, js.Array[js.Any]]] = None,
      flat: Option[Boolean] = None,
      `full-width`: Option[Boolean] = None,
      height: Option[Either[Int, String]] = None,
      `hide-details`: Option[Boolean] = None,
      hint: Option[String] = None,
      label: Option[String] = None,
      light: Option[Boolean] = None,
      loading: Option[Either[Boolean, String]] = None,
      mask: Option[Either[js.Object, String]] = None,
      messages: Option[Either[String, js.Array[js.Any]]] = None,
      outline: Option[Boolean] = None,
      `persistent-hint`: Option[Boolean] = None,
      placeholder: Option[String] = None,
      prefix: Option[String] = None,
      `prepend-icon`: Option[String] = None,
      `prepend-inner-icon`: Option[String] = None,
      readonly: Option[Boolean] = None,
      `return-masked-value`: Option[Boolean] = None,
      reverse: Option[Boolean] = None,
      rules: List[Option[String] => Option[String]] = Nil, // each rule can either succeed by returning None or fail by returning a Some with the error message to display
      `single-line`: Option[Boolean] = None,
      solo: Option[Boolean] = None,
      `solo-inverted`: Option[Boolean] = None,
      success: Option[Boolean] = None,
      `success-messages`: Option[Either[String, js.Array[js.Any]]] = None,
      suffix: Option[String] = None,
      `type`: Option[String] = None,
      `validate-on-blur`: Option[Boolean] = None,
      `value`: Option[js.Any] = None,
      landscape: Option[Boolean] = None
  ): VTextFieldProps = {

    DynamicHelper.buildViaDynamic(
      "append-icon"          -> `append-icon`.map(js.Any.fromString).orUndefined,
      "append-outer-icon"    -> `append-outer-icon`.map(js.Any.fromString).orUndefined,
      "autofocus"            -> autofocus.map(js.Any.fromBoolean).orUndefined,
      "background-color"     -> `background-color`.map(js.Any.fromString).orUndefined,
      "box"                  -> box.map(js.Any.fromBoolean).orUndefined,
      "browser-autocomplete" -> `browser-autocomplete`.map(js.Any.fromString).orUndefined,
      "clear-icon"           -> `clear-icon`.map(js.Any.fromString).orUndefined,
      "clearable"            -> clearable.map(js.Any.fromBoolean).orUndefined,
      "color"                -> color.map(js.Any.fromString).orUndefined,
      "counter" -> counter
        .map(_.fold(js.Any.fromInt, _.fold(js.Any.fromBoolean, js.Any.fromString)))
        .orUndefined,
      "dark"                  -> dark.map(js.Any.fromBoolean).orUndefined,
      "disabled"              -> disabled.map(js.Any.fromBoolean).orUndefined,
      "dont-fill-mask-blanks" -> `dont-fill-mask-blanks`.map(js.Any.fromBoolean).orUndefined,
      "error"                 -> error.map(js.Any.fromBoolean).orUndefined,
      "error-count"           -> `error-count`.map(_.fold(js.Any.fromInt, js.Any.fromString)).orUndefined,
      "error-messages"        -> `error-messages`.map(_.fold(js.Any.fromString, identity)).orUndefined,
      "flat"                  -> flat.map(js.Any.fromBoolean).orUndefined,
      "full-width"            -> `full-width`.map(js.Any.fromBoolean).orUndefined,
      "height"                -> height.map(_.fold(js.Any.fromInt, js.Any.fromString)).orUndefined,
      "hide-details"          -> `hide-details`.map(js.Any.fromBoolean).orUndefined,
      "hint"                  -> hint.map(js.Any.fromString).orUndefined,
      "label"                 -> label.map(js.Any.fromString).orUndefined,
      "light"                 -> light.map(js.Any.fromBoolean).orUndefined,
      "loading"               -> loading.map(_.fold(js.Any.fromBoolean, js.Any.fromString)).orUndefined,
      "mask"                  -> mask.map(_.fold(identity, js.Any.fromString)).orUndefined,
      "messages"              -> messages.map(_.fold(js.Any.fromString, identity)).orUndefined,
      "outline"               -> outline.map(js.Any.fromBoolean).orUndefined,
      "persistent-hint"       -> `persistent-hint`.map(js.Any.fromBoolean).orUndefined,
      "placeholder"           -> placeholder.map(js.Any.fromString).orUndefined,
      "prefix"                -> prefix.map(js.Any.fromString).orUndefined,
      "prepend-icon"          -> `prepend-icon`.map(js.Any.fromString).orUndefined,
      "prepend-inner-icon"    -> `prepend-inner-icon`.map(js.Any.fromString).orUndefined,
      "readonly"              -> readonly.map(js.Any.fromBoolean).orUndefined,
      "return-masked-value"   -> `return-masked-value`.map(js.Any.fromBoolean).orUndefined,
      "reverse"               -> reverse.map(js.Any.fromBoolean).orUndefined,
      "rules"                 -> ruleListToVueRules[String, String](rules, identity),
      "single-line"           -> `single-line`.map(js.Any.fromBoolean).orUndefined,
      "solo"                  -> solo.map(js.Any.fromBoolean).orUndefined,
      "solo-inverted"         -> `solo-inverted`.map(js.Any.fromBoolean).orUndefined,
      "success"               -> success.map(js.Any.fromBoolean).orUndefined,
      "success-messages"      -> `success-messages`.map(_.fold(js.Any.fromString, identity)).orUndefined,
      "suffix"                -> suffix.map(js.Any.fromString).orUndefined,
      "type"                  -> `type`.map(js.Any.fromString).orUndefined,
      "validate-on-blur"      -> `validate-on-blur`.map(js.Any.fromBoolean).orUndefined,
      "value"                 -> `value`.orUndefined,
      "landscape"             -> landscape.map(js.Any.fromBoolean).orUndefined
    )
  }
}
