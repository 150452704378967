package com.sludg.helpers

import com.sludg.models.Config.{AppLink, Config}
import com.sludg.vue._
import com.sludg.vuetify.{Vuetify, VuetifyOptions}

import scala.scalajs.js
import com.sludg.models.Config.VueColors

object Client {
  private val vuetifyAssets = com.sludg.vuetify.VuetifyAssets

  def initialize(
      colors: VueColors,
      appContainer: String,
      routes: List[VueRoute],
      vuePlugins: List[js.Any] = Nil
  ) = {

    Vue.use(Vuetify, new VuetifyOptions {
      override val theme = js.defined(colors.toVuetifyColors)
    })
    Vue.use(VueRouter)
    vuePlugins.foreach(p => Vue.use(p))

    val router: VueRouter = new VueRouter(
      VueRouterOptions(
        routes,
        Some("history")
      )
    )

    new Vue(App.props(appContainer, router))
  }
}
