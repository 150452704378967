package com.sludg.vuetify.components

import com.sludg.vue.RenderHelpers.namedTag
import com.sludg.vue.{EventBindings, ScopedSlots, VueProps}

trait VAvatar {
  val vAvatar = namedTag[VueProps, EventBindings, ScopedSlots]("v-avatar")
}

object VAvatar extends VAvatar
