package com.sludg.vuetify.components

import com.sludg.vue.{EventBindings, ScopedSlots, VueProps}

import scala.scalajs.js

/**
  * @author dpoliakas
  *         Date: 22/11/2018
  *         Time: 17:56
  *
  *
  */
trait VApp {

  import com.sludg.vue.RenderHelpers._

  val vApp = namedTag[VAppProps, EventBindings, ScopedSlots]("v-app")

}

object VApp extends VApp

trait VAppProps extends VueProps {
  val dark: js.UndefOr[Boolean] = js.undefined
}

object VAppProps {
  def apply(dark: Boolean = false): VAppProps = {
    js.Dynamic
      .literal(
        "dark" -> dark
      )
      .asInstanceOf[VAppProps]
  }
}
