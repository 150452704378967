package com.sludg.helpers

import com.sludg.vue.Vue

import scala.concurrent.{ExecutionContext, Future}

/**
  * @author dpoliakas
  *         Date: 2019-01-30
  *         Time: 15:51
  *
  *
  */
object LoadingFuture {

  def withLoading[A](eventBus: Vue, f: => Future[A])(
      implicit executionContext: ExecutionContext
  ) = {
    val future = (for {
      _      <- Future.successful(eventBus.$emit("api-call:started", 0))
      result <- f
    } yield {
      result
    })

    future.onComplete { e =>
      e.failed.foreach(ee => eventBus.$emit("api-call:error", ee))
      e.foreach(_ => eventBus.$emit("api-call:complete", 0))
    }

    future
  }

  case class AccessForbiddenFailure(message: String) extends Exception

}
