package com.sludg.vuetify.components

import com.sludg.scalajs.DynamicHelper
import com.sludg.vue.{EventBindings, ScopedSlots, VueProps}
import scala.scalajs.js
import scala.scalajs.js.|

trait VCardText {

  import com.sludg.vue.RenderHelpers._
  val vCardText = namedTag[VueProps, EventBindings, ScopedSlots]("v-card-text")

}

object VCardText extends VCardText
