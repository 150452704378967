package com.sludg.client.pages.components.diverts

import com.sludg.FieldExtractor
import com.sludg.Security
import com.sludg.auth0.SludgToken
import com.sludg.helpers.LoadingFuture
import com.sludg.services.ApiCalls
import com.sludg.util.PresenterSyntax._
import com.sludg.util.SilhouettePresenters._
import com.sludg.util.Validators
import com.sludg.util.models.SilhouetteModels
import com.sludg.util.models.SilhouetteModels.{DIDNumber, EmergencyOverride}
import com.sludg.vue.{EventBindings, RenderOptions, _}
import com.sludg.vuetify.VuetifyComponents._
import com.sludg.vuetify.components._
import monix.execution.Scheduler.Implicits.global
import org.log4s.getLogger
import org.scalajs.dom.Event

import scala.scalajs.js
import scala.scalajs.js.JSConverters._

/** The divert input screen allows the user to select the location for an emergency override (or divert).
  * They must first select the location type (AA, CG, User, External)[OverrideInputType], and this field
  * determines the next input the user can select from.
  * The [OverrideInputType] is the primary way of managing the state of the divert screen.
  * Api calls (which in turn, modify sil) are made when the user saves the information they have selected.
  */

object DivertInputScreen {

  import com.sludg.vue.RenderHelpers._

  private[this] val logger = getLogger

  def DivertInputScreenRenderer(registrationName: String) =
    namedTag[DivertInputScreenProps, DivertInputScreenEvents, ScopedSlots]("DivertInputScreen")

  def DivertInputScreenComponent(apiCalls: ApiCalls, security: Security, apiCallBus: Vue)(implicit
      token: SludgToken
  ) = {
    VueComponent.builder
      .withData(new DivertInputScreenData())
      .withPropsAs[DivertInputScreenProps]
      .build(
        created = js.defined(c => {
          //On creation, the component attempts to set values already saved in sil to the front end ui
          c.emergencyOverrideActive = c.didFromTable.emergencyOverride.active
          c.emergencyOverrideType =
            c.didFromTable.emergencyOverride.number.flatMap { divertNumber =>
              c.emergencyOverrideNumber = Some(divertNumber)
              c.saveDisabled = areNumberRulesTriggered(
                c,
                if (divertNumber.startsWith("9")) divertNumber.tail else divertNumber,
                ""
              )
              val listToUseAndType
                  : (List[SilhouetteModels.SilhouetteLocation], Option[OverrideInputType]) =
                (for {
                  subs <- c.tenantSubscribers
                  aas <- c.tenantAutoAttendants
                  cgs <- c.tenantCallGroups
                } yield DDIUtil.findNumberFromSilhouette(subs, aas, cgs, divertNumber))
                  .getOrElse((List(), None))
              c.extensionInput = DDIUtil.silhouetteUserToExtensionInput(listToUseAndType._1)
              listToUseAndType._2
            }
          c.emergencyOverrideName = c.didFromTable.emergencyOverride.name

        }),
        components = js.defined(
          js.Dynamic.literal(
          )
        ),
        templateOrRender = Right((component, renderer) => {
          vCard(
            DDIUtil.RenderFunctions.cardTitleHeadline(
              "Edit Divert",
              component.didFromTable,
              EventBindings(
                click = js.defined(e => {
                  component.$emit("closeEvent", e)
                })
              )
            ),
            vCardText(
              vContainer(
                vLayout(
                  vFlex(
                    p("Enable a divert to configure its properties"),
                    vSwitch(
                      RenderOptions(
                        props = Some(
                          VSwitchProps(
                            label = Some(
                              s"Divert ${DDIUtil.booleanToText(component.emergencyOverrideActive, "Enabled", "Disabled")}"
                            ),
                            `input-value` = Some((component.emergencyOverrideActive))
                          )
                        ),
                        on = Some(
                          EventBindings(
                            change = js.defined(e => {
                              component.emergencyOverrideActive = e.asInstanceOf[Boolean]
                            })
                          )
                        )
                      )
                    )
                  )
                ),
                vDivider(),
                vLayout(
                  extensionTypeInputSelector(component)
                ),
                vLayout(
                  numberInputSelector(component, "External Number")
                ),
                vLayout(
                  textFieldNameInput(component)
                ),
                vLayout(
                  vButton(
                    "Save",
                    RenderOptions(
                      props = Some(
                        VButtonProps(
                          disabled = Some(component.saveDisabled)
                        )
                      ),
                      on = Some(
                        EventBindings(
                          click = js.defined(e => {
                            val eo = EmergencyOverride(
                              component.emergencyOverrideActive,
                              component.emergencyOverrideName,
                              component.emergencyOverrideNumber
                            )
                            LoadingFuture.withLoading(
                              apiCallBus,
                              apiCalls
                                .setEmergencyOverride(
                                  component.tenant.get.id,
                                  component.didFromTable.didId,
                                  eo
                                )
                                .map {
                                  case Right(value) =>
                                    if (value) {
                                      component.$emit("updateSuccessful", value)
                                    } else {
                                      logger.error("Override not set, apicall failed ")
                                    }
                                  case Left(error) =>
                                    logger.error("Override Future failed: " + error)
                                }
                            )
                          })
                        )
                      )
                    )
                  ),
                  vSpacer,
                  vButton(
                    "Clear Divert",
                    RenderOptions(
                      on = Some(
                        EventBindings(
                          click = js.defined(e => {
                            component.emergencyOverrideNumber = Some("")
                            component.emergencyOverrideType = None
                            component.emergencyOverrideName = Some("")
                            component.emergencyOverrideActive = false
                            component.saveDisabled = false
                          })
                        )
                      )
                    )
                  )
                )
              )
            )
          ).render(renderer)

        })
      )
  }

  private def extensionTypeInputSelector(
      component: DivertInputScreenData with DivertInputScreenProps
  ) = {
    vFlex(
      p("The type of number calls will be directed to"),
      DDIUtil.RenderFunctions.extensionTypeInputSelector(
        VAutocompleteEventBindings(
          input = js.defined(e => {
            component.emergencyOverrideType = (e: Any) match {
              case a: OverrideInputType => Some(a)
              case _ => None
            }
            component.extensionInput = (e: Any) match {
              case AutoAttendant =>
                DDIUtil.silhouetteUserToExtensionInput(
                  component.tenantAutoAttendants.getOrElse(Nil)
                )
              case CallGroup =>
                DDIUtil.silhouetteUserToExtensionInput(component.tenantCallGroups.getOrElse(Nil))
              case Extension =>
                DDIUtil.silhouetteUserToExtensionInput(component.tenantSubscribers.getOrElse(Nil))
              case External => Nil
              case _ => Nil
            }
          })
        ),
        "Divert Destination",
        !(component.emergencyOverrideActive),
        component.emergencyOverrideType,
        false
      )
    )
  }

  def areNumberRulesTriggered(component: DivertInputScreenData, s: String, fieldName: String) = if (
    Validators.startsWithNineRules(fieldName).apply(Some(s)).isDefined ||
    Validators.textNumberRules(fieldName).apply(Some(s)).isDefined
  ) true
  else false

  private def numberInputSelector(
      component: DivertInputScreenData with DivertInputScreenProps,
      fieldName: String
  ) = {
    //This field must strip 9s from the start of external numbers coming from sil, whilst adding them to input that a user gives
    vFlex(
      p("Numbers on your tenant, or an external number"),
      if (component.emergencyOverrideType.contains(External)) {
        vTextField(
          RenderOptions(
            props = Some(
              VTextFieldProps(
                disabled = Some(!(component.emergencyOverrideActive)),
                label = Some(fieldName),
                value =
                  component.emergencyOverrideNumber.map(x => if (x.startsWith("9")) x.tail else x),
                clearable = Some(true),
                prefix = Some("9"),
                rules = List(
                  Validators.nonEmpty(fieldName),
                  Validators.startsWithNineRules(fieldName),
                  Validators.textNumberRules(fieldName)
                )
              )
            ),
            on = Some(
              EventBindings(
                /* The default vue textField behaviour prevents the rules from being a complete
                 * solution for error handling, so some additional manual checks are performed with said rules. */
                input = js.defined(e => {
                  component.emergencyOverrideNumber = if (js.isUndefined(e)) {
                    component.saveDisabled = true
                    None
                  } else {
                    Option(e: Any) match {
                      case Some(s: String) if s.nonEmpty =>
                        component.saveDisabled = areNumberRulesTriggered(component, s, fieldName)
                        Some("9" + s)
                      case _ =>
                        component.saveDisabled = true
                        Some("")
                    }
                  }
                })
              )
            )
          )
        )
      } else {
        vFlex(
          DDIUtil.RenderFunctions.numberInputSelector(
            VAutocompleteEventBindings(
              input = js.defined(e => {
                component.emergencyOverrideNumber = if (js.isUndefined(e)) {
                  component.saveDisabled = true
                  None
                } else {
                  Option(e: Any).collect { case ext: ExtensionInput =>
                    component.saveDisabled =
                      areNumberRulesTriggered(component, ext.value.getOrElse(""), fieldName)
                    ext.value.getOrElse("")
                  }
                }
              })
            ),
            "Divert Number",
            if ((component.emergencyOverrideActive)) component.emergencyOverrideType.isEmpty
            else true,
            component.emergencyOverrideType,
            component.extensionInput,
            component.emergencyOverrideNumber
          )
        )
      }
    )
  }

  private def textFieldNameInput(component: DivertInputScreenData with DivertInputScreenProps) = {
    vFlex(
      p("Give a name to this divert"),
      vTextField(
        RenderOptions(
          props = Some(
            VTextFieldProps(
              label = Some("Note"),
              disabled = Some(
                if ((component.emergencyOverrideActive)) component.emergencyOverrideType.isEmpty
                else true
              ),
              value = Some((component.emergencyOverrideName.getOrElse(""): String)),
              clearable = Some(true)
            )
          ),
          on = Some(
            EventBindings(
              input = js.defined(e => {
                component.emergencyOverrideName = Option(e: Any).collect {
                  case s: String if s.nonEmpty => s
                }
              })
            )
          )
        )
      )
    )
  }

}

class DivertInputScreenData extends js.Object {
  var emergencyOverrideName: Option[String] = None
  var emergencyOverrideType: Option[OverrideInputType] = None
  var emergencyOverrideNumber: Option[String] = None
  var emergencyOverrideActive: Boolean = false
  var extensionInput: List[ExtensionInput] = Nil

  var saveDisabled: Boolean = true

  var apiCalls: Option[ApiCalls] = None
}

class DivertInputScreenProps(
    val token: js.UndefOr[SludgToken] = js.undefined,
    val tenant: js.UndefOr[SilhouetteModels.Tenant] = js.undefined,
    val didFromTable: DIDNumber,
    val tenantSubscribers: js.UndefOr[List[SilhouetteModels.SilhouetteLocation]] = js.undefined,
    val tenantAutoAttendants: js.UndefOr[List[SilhouetteModels.SilhouetteLocation]] = js.undefined,
    val tenantCallGroups: js.UndefOr[List[SilhouetteModels.SilhouetteLocation]] = js.undefined
) extends VueProps

trait DivertInputScreenEvents extends EventBindings {
  def updateSuccessful(e: Boolean): Unit
  def closeEvent(e: Event): Unit
}

object DivertInputScreenEvents {
  def apply(
      bindings: EventBindings = EventBindings(),
      updateSuccessful: js.UndefOr[js.Function1[Boolean, Unit]] = js.undefined,
      closeEvent: js.UndefOr[js.Function1[Event, Unit]] = js.undefined
  ): DivertInputScreenEvents = {
    bindings.asInstanceOf[js.Dynamic].updateDynamic("updateSuccessful")(updateSuccessful)
    bindings.asInstanceOf[js.Dynamic].updateDynamic("closeEvent")(closeEvent)
    val binding = bindings.asInstanceOf[DivertInputScreenEvents]
    binding
  }
}

