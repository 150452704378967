package com.sludg.vuetify.components.gridSystem

import com.sludg.scalajs.DynamicHelper
import com.sludg.vue.{EventBindings, ScopedSlots, VueProps}

import scala.scalajs.js
import scala.scalajs.js.Dynamic

trait VueGridLayout {
  import com.sludg.vue.RenderHelpers._
  val vueGridLayout = namedTag[VueGridLayoutProps, EventBindings, ScopedSlots]("grid-layout")
}

trait VueGridLayoutProps extends VueProps {
  val responsive: js.UndefOr[Boolean]       = js.undefined
  val useCssTransforms: js.UndefOr[Boolean] = js.undefined
  val verticalCompact: js.UndefOr[Boolean]  = js.undefined
  val autoSize: js.UndefOr[Boolean]         = js.undefined
  val isMirrored: js.UndefOr[Boolean]       = js.undefined
  val isResizable: js.UndefOr[Boolean]      = js.undefined
  val isDraggable: js.UndefOr[Boolean]      = js.undefined

  val maxRows: js.UndefOr[Int]              = js.undefined
  val colWidth: js.UndefOr[Int]             = js.undefined
  val rowHeight: js.UndefOr[Int]            = js.undefined
  val colNum: js.UndefOr[Int]               = js.undefined
  val preventCollision: js.UndefOr[Boolean] = js.undefined

  val layout: js.UndefOr[js.Array[js.Object]] = js.undefined
  val margin: js.UndefOr[js.Array[Int]]       = js.undefined
}

object VueGridLayoutProps {

  import scala.scalajs.js.JSConverters._

  def apply(
      responsive: Option[Boolean] = None,
      useCssTransforms: Option[Boolean] = None,
      verticalCompact: Option[Boolean] = None,
      autoSize: Option[Boolean] = None,
      isMirrored: Option[Boolean] = None,
      isResizable: Option[Boolean] = None,
      isDraggable: Option[Boolean] = None,
      colsNum: Option[Int] = None,
      maxRows: Option[Int] = None,
      colWidth: Option[Int] = None,
      rowHeight: Option[Int] = None,
      cols: Option[js.Object] = None,
      breakpoints: Option[js.Object] = None,
      preventCollision: Option[Boolean] = None,
      layout: Option[js.Array[js.Object]] = None,
      margin: Option[js.Array[Int]] = None
  ): VueGridLayoutProps = {

    DynamicHelper.buildViaDynamic(
      "responsive"       -> responsive.map(js.Any.fromBoolean).orUndefined,
      "useCssTransforms" -> useCssTransforms.map(js.Any.fromBoolean).orUndefined,
      "verticalCompact"  -> verticalCompact.map(js.Any.fromBoolean).orUndefined,
      "autoSize"         -> autoSize.map(js.Any.fromBoolean).orUndefined,
      "isMirrored"       -> isMirrored.map(js.Any.fromBoolean).orUndefined,
      "isResizable"      -> isResizable.map(js.Any.fromBoolean).orUndefined,
      "isDraggable"      -> isDraggable.map(js.Any.fromBoolean).orUndefined,
      "colNum"           -> colsNum.map(js.Any.fromInt).orUndefined,
      "breakpoints"      -> breakpoints.orUndefined,
      "layout"           -> layout.orUndefined,
      "preventCollision" -> preventCollision.map(js.Any.fromBoolean).orUndefined,
      "maxRows"          -> maxRows.map(js.Any.fromInt).orUndefined,
      "colWidth"         -> colWidth.map(js.Any.fromInt).orUndefined,
      "rowHeight"        -> rowHeight.map(js.Any.fromInt).orUndefined,
      "margin"           -> margin.map(_.asInstanceOf[js.Any]).orUndefined
    )
  }
}
