package com.sludg.vuetify.components.transitions

import com.sludg.scalajs.DynamicHelper
import com.sludg.vue.{EventBindings, ScopedSlots, VueProps}
import scala.scalajs.js
import scala.scalajs.js.|

trait VFadeTransition {

  import com.sludg.vue.RenderHelpers._

  val vFadeTransition = namedTag[VTransitionProps, EventBindings, ScopedSlots]("v-fade-transition")

}
