package com.sludg.vuetify.components

import com.sludg.vue.{EventBindings, ScopedSlots}

import scala.scalajs.js

/**
  * @author dpoliakas
  *         Date: 26/11/2018
  *         Time: 09:16
  *
  *
  */
trait VNavigationDrawer {

  import com.sludg.vue.RenderHelpers._

  val vNavigationDrawer =
    namedTag[VNavigationDrawerProps, EventBindings, ScopedSlots]("v-navigation-drawer")

}

object VNavigationDrawer extends VNavigationDrawer

trait VNavigationDrawerProps extends VuetifyComponentProps {
  val value: js.UndefOr[Boolean]     = js.undefined
  val temporary: js.UndefOr[Boolean] = js.undefined
}

object VNavigationDrawerProps {
  def apply(
      app: Boolean = true,
      value: Option[Boolean] = None,
      temporary: Option[Boolean] = None
  ): VNavigationDrawerProps =
    VuetifyComponentProps(app = app).withData(
      "value"     -> value.map(js.Any.fromBoolean).getOrElse(null),
      "temporary" -> temporary.map(js.Any.fromBoolean).getOrElse(null)
    )
}
