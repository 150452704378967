package com.sludg.vuetify.components

import com.sludg.scalajs.DynamicHelper
import com.sludg.vue.{EventBindings, ScopedSlots, VueProps}

import scala.scalajs.js
import scala.scalajs.js.|

trait VTabsItems {

  import com.sludg.vue.RenderHelpers._

  val vTabsItems = namedTag[VTabsItemsProps, EventBindings, ScopedSlots]("v-tabs-items")

}

object VTabsItems extends VTabsItems

trait VTabsItemsProps extends VueProps {

  val dark: js.UndefOr[Boolean]     = js.undefined
  val light: js.UndefOr[Boolean]    = js.undefined
  val max: js.UndefOr[Int | String] = js.undefined
  val multiple: js.UndefOr[Boolean] = js.undefined
  val value: js.UndefOr[js.Any]     = js.undefined

}

object VTabsItemsProps {
  import scala.scalajs.js.JSConverters._

  def apply(
      dark: Option[Boolean] = None,
      light: Option[Boolean] = None,
      max: Option[Either[Int, String]] = None,
      multiple: Option[Boolean] = None,
      value: Option[js.Any] = None
  ): VTabsItemsProps = {
    DynamicHelper.buildViaDynamic(
      "dark"       -> dark.map(js.Any.fromBoolean).orUndefined,
      "light"      -> light.map(js.Any.fromBoolean).orUndefined,
      "max"        -> max.map(_.fold(js.Any.fromInt, js.Any.fromString)).orUndefined,
      "three-line" -> multiple.map(js.Any.fromBoolean).orUndefined,
      "value"      -> value.orUndefined
    )
  }
}
