package com.sludg.vuetify.components

import com.sludg.scalajs.DynamicHelper
import com.sludg.vue.{EventBindings, ScopedSlots, VueProps}

import scala.scalajs.js

import scala.scalajs.js.|

/**
  * @author pbarman
  *         Date: 02/01/2019
  *         Time: 15:16
  *
  *
  */
trait VTimePicker {

  import com.sludg.vue.RenderHelpers._

  val vTimePicker = namedTag[VTimePickerProps, EventBindings, ScopedSlots]("v-time-picker")

}

object VTimePicker extends VTimePicker

trait VTimePickerProps extends VueProps {
  val value: js.UndefOr[js.Any]       = js.undefined
  val format: js.UndefOr[String]      = js.undefined
  val width: js.UndefOr[Int | String] = js.undefined
}

object VTimePickerProps {
  import scala.scalajs.js.JSConverters._

  def apply[A <: js.Any](
      value: Option[js.Any] = None,
      format: Option[String] = None,
      width: Option[Either[Int, String]] = None
  ): VTimePickerProps = {

    DynamicHelper.buildViaDynamic(
      "value"  -> value.orUndefined,
      "format" -> format.map(js.Any.fromString).orUndefined,
      "width"  -> width.map(_.fold(js.Any.fromInt, js.Any.fromString)).orUndefined
    )
  }
}
