package com.sludg.client.pages

import com.sludg.Security
import com.sludg.auth0.SludgToken
import com.sludg.client.UserPortalApp
import com.sludg.client.UserPortalApp.UserPortalAppProps
import com.sludg.client.pages.components.mainpage.UserPortal.{LinkerEvents, LinkerProps}
import com.sludg.client.pages.components.mainpage.UserPortal
import com.sludg.helpers.AppSetup._
import com.sludg.models.Config.AppLink
import com.sludg.services.ApiCalls
import com.sludg.vue.{RenderHelpers, _}
import org.log4s.{Logger, getLogger}
import RenderHelpers._

import scala.scalajs.js

object MainPage {

  val logger: Logger = getLogger

  def initialComponentRenderer(
      registrationName: String
  ): RenderHelpers.NodeRenderer[UserPortalAppProps, EventBindings, ScopedSlots] =
    namedTag[UserPortalAppProps, EventBindings, ScopedSlots](registrationName)

  val userPortalApp
      : RenderHelpers.NodeRenderer[UserPortalAppProps, EventBindings, VtslAppScopedSlots] =
    UserPortalApp.userPortalAppRenderer("UserPortal")
  val linker: RenderHelpers.NodeRenderer[LinkerProps, LinkerEvents, ScopedSlots] =
    UserPortal.userPortalRenderer("Linker")

  def initialComponent(
      apiCalls: ApiCalls,
      security: Security,
      apiCallBus: Vue,
      otherApps: List[AppLink]
  )(implicit token: SludgToken): VueComponent[_ <: VueProps, _ <: Slots, _ <: ScopedSlots] = {
    VueComponent.builder
      .withData(new MainPageData())
      .build(
        created = js.defined(c => c.token = js.defined(new SludgToken(token.tokenString))),
        components = js.Dynamic.literal(
          "UserPortal" -> UserPortalApp
            .userPortalAppComponent(apiCalls, security, apiCallBus, otherApps, false),
          "Linker" -> UserPortal.userPortalComponent(apiCalls, security, apiCallBus)
        ),
        templateOrRender = Right((component, renderer) => {
          userPortalApp(
            RenderOptions(
              on = Some(js.Dynamic.literal().asInstanceOf[EventBindings]),
              props = Some(
                new UserPortalAppProps(
                  title = js.defined("User Portal"),
                  spacedToolbar = true,
                  displayTenantSelector = token.isSuperUser,
                  isStandardUser =
                    !token.isSuperUser && !token.subscribers.map(_.admin).headOption.contains(true),
                  token = Some(token)
                )
              ),
              scopedSlots = Some(
                new VtslAppScopedSlots {
                  override val default = js.defined(_ => {
                    div(
                      RenderOptions(
                        style = Some(
                          js.Dynamic.literal(
                            "margin-top" -> "-25px",
                            "margin-left" -> "-25px",
                            "margin-right" -> "-25px"
                          )
                        )
                      ),
                      linker(
                        RenderOptions(
                          props = Some(new LinkerProps(component.token)),
                          on = Some(
                            LinkerEvents(refreshedToken = js.defined(e => component.token = e))
                          )
                        )
                      )
                    ).render(renderer)
                  })
                }
              )
            )
          ).render(renderer)
        })
      )
  }
}

class MainPageData extends js.Object {
  var token: js.UndefOr[SludgToken] = js.undefined
}
