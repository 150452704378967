package com.sludg.vuetify.components

import com.sludg.scalajs.DynamicHelper
import com.sludg.vue.{EventBindings, ScopedSlots, VueProps}

import scala.scalajs.js

trait VListGroup {

  import com.sludg.vue.RenderHelpers._

  val vListGroup = namedTag[VListGroupProps, EventBindings, ScopedSlots]("v-list-group")

}

object VListGroup extends VListGroup

trait VListGroupProps extends VueProps {

  val `active-class`: js.UndefOr[String] = js.undefined
  val `append-icon`: js.UndefOr[String]  = js.undefined
  val disabled: js.UndefOr[Boolean]      = js.undefined
  val group: js.UndefOr[String]          = js.undefined
  val `lazy`: js.UndefOr[Boolean]        = js.undefined
  val `no-action`: js.UndefOr[Boolean]   = js.undefined
  val `prepend-icon`: js.UndefOr[String] = js.undefined
  val `sub-group`: js.UndefOr[Boolean]   = js.undefined
  val value: js.UndefOr[js.Any]          = js.undefined

}

object VListGroupProps {

  import scala.scalajs.js.JSConverters._

  def apply(
      `active-class`: Option[String] = None,
      `append-icon`: Option[String] = None,
      disabled: Option[Boolean] = None,
      group: Option[String] = None,
      `lazy`: Option[Boolean] = None,
      `no-action`: Option[Boolean] = None,
      `prepend-icon`: Option[String] = None,
      `sub-group`: Option[Boolean] = None,
      value: Option[js.Any] = None
  ): VListGroupProps = {
    DynamicHelper.buildViaDynamic(
      "active-class" -> `active-class`.map(js.Any.fromString).orUndefined,
      "append-icon"  -> `append-icon`.map(js.Any.fromString).orUndefined,
      "disabled"     -> disabled.map(js.Any.fromBoolean).orUndefined,
      "group"        -> group.map(js.Any.fromString).orUndefined,
      "lazy"         -> `lazy`.map(js.Any.fromBoolean).orUndefined,
      "no-action"    -> `no-action`.map(js.Any.fromBoolean).orUndefined,
      "prepend-icon" -> `prepend-icon`.map(js.Any.fromString).orUndefined,
      "sub-group"    -> `sub-group`.map(js.Any.fromBoolean).orUndefined,
      "value"        -> value.orUndefined
    )
  }
}
