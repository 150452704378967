package com.sludg.json

import play.api.libs.json.{JsPath, Reads}
import com.sludg.models.Models.{Report, SubscriberWithAdminStatus}
import com.sludg._
import com.sludg.models.Config.DevState.{Alpha, Beta, Prod, Unavailable}
import com.sludg.models.Config._
import com.sludg.models.LogLevel
import com.sludg.util.json.FiltersJsonDeserializers.{
  answerFilterReads,
  autoAttendantFilterReads,
  callFilterReads,
  callGroupFilterReads,
  callingNumberFilterReads,
  dateFilterReads,
  directionFilterReads,
  extensionFilterReads,
  numberCallFilterReads,
  numberCallFilterWrites,
  relativeDateFilterReads,
  subscriberFilterReads,
  terminationFilterReads,
  timeFilterReads
}
import com.sludg.util.models.ReportModels.Filter
import com.sludg.util.models.ReportModels.Filter.DialedNumberFilter
import play.api.libs.functional.syntax._
import play.api.libs.json.Reads._
import play.api.libs.json._
import org.log4s

object Deserializer {
  private[this] def reverseMap[A, B](m: Map[A, B]): Map[B, A] = m.map { case (a, b) => b -> a }

  def formatViaMap[A](read: Map[String, A]): Format[A] = {
    val write = reverseMap(read)
    Format(
      Reads {
        case JsString(x) => read.get(x).map(x => JsSuccess(x)).getOrElse(JsError())
        case _           => JsError()
      },
      Writes((write.apply _).andThen(JsString))
    )
  }

  implicit val devStateReads = Reads[DevState] {
    case JsString("Alpha")       => JsSuccess(Alpha)
    case JsString("Beta")        => JsSuccess(Beta)
    case JsString("Prod")        => JsSuccess(Prod)
    case JsString("Unavailable") => JsSuccess(Unavailable)
    case _                       => JsError("Incorrect Json value for AppState in config")
  }

  implicit val devStateWrites = Writes[DevState] {
    case Alpha       => JsString("Alpha")
    case Beta        => JsString("Beta")
    case Prod        => JsString("Prod")
    case Unavailable => JsString("Unavailable")
    case _           => JsNull
  }

  implicit val apiConfigFormat: Format[ApiConfig]     = Json.format[ApiConfig]
  implicit val logLevelFormat: Format[log4s.LogLevel] = formatViaMap(LogLevel.strToObj)
  implicit val appLinkFormat: Format[AppLink]         = Json.format[AppLink]
  implicit val sceduledReportFormats: Format[ScheduledReportConfig] =
    Json.format[ScheduledReportConfig]
  implicit val alamostAuth0ConfigFormat: Format[AlmostAuth0Config] = Json.format[AlmostAuth0Config]
  implicit val vueColorFormat: Format[VueColors]                   = Json.format[VueColors]
  implicit val auth0ConfigFormat: Format[Auth0Config]              = Json.format[Auth0Config]
  implicit val reportFormat: Format[Report]                        = Json.format[Report]

  implicit val subscriberWithAdminStatusReads: Reads[SubscriberWithAdminStatus] = (
    (JsPath \ "sid").read[Int] and
      (JsPath \ "tid").read[String] and
      (JsPath \ "ext").read[String] and
      (JsPath \ "uuid").readNullable[String] and
      (JsPath \ "admin").read[Boolean]
  )(SubscriberWithAdminStatus.apply _)
}
