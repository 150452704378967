package com.sludg.vuetify.components

import com.sludg.scalajs.DynamicHelper
import com.sludg.vue.{EventBindings, ScopedSlots, VueProps}

import scala.scalajs.js

/**
  * @author pbarman
  *         Date: 11/06/2019
  *         Time: 16:44
  *
  *
  */
trait VAlert {

  import com.sludg.vue.RenderHelpers._

  val vAlert = namedTag[VAlertProps, EventBindings, ScopedSlots]("v-alert")

}

object VAlert extends VAlert

trait VAlertProps extends VueProps {
  val dismissible: js.UndefOr[Boolean] = js.undefined
  val icon: js.UndefOr[String]         = js.undefined
  val outline: js.UndefOr[Boolean]     = js.undefined
  val `type`: js.UndefOr[String]       = js.undefined
  val value: js.UndefOr[js.Any]        = js.undefined

}

object VAlertProps {
  import scala.scalajs.js.JSConverters._

  def apply(
      dismissible: Option[Boolean] = None,
      icon: Option[String] = None,
      outline: Option[Boolean] = None,
      `type`: Option[String] = None,
      value: Option[js.Any] = None
  ): VAlertProps = {

    DynamicHelper.buildViaDynamic(
      "dismissible" -> dismissible.map(js.Any.fromBoolean).orUndefined,
      "icon"        -> icon.map(js.Any.fromString).orUndefined,
      "outline"     -> outline.map(js.Any.fromBoolean).orUndefined,
      "type"        -> `type`.map(js.Any.fromString).orUndefined,
      "value"       -> value.orUndefined
    )
  }
}
