package com.sludg.client.pages

import com.sludg.FieldExtractor
import com.sludg.Security
import com.sludg.auth0.SludgToken
import com.sludg.client.pages.components.roles.{RolesTable, RolesTableProps}
import com.sludg.client.UserPortalApp
import com.sludg.client.UserPortalApp.UserPortalAppProps
import com.sludg.helpers.AppSetup.{VtslAppProps, VtslAppScopedSlots}
import com.sludg.models.Config.AppLink
import com.sludg.services.ApiCalls
import com.sludg.vue.RenderHelpers._
import com.sludg.vue.{EventBindings, RenderHelpers, _}

import scala.collection.immutable.List
import scala.scalajs.js
import play.api.libs.json.{JsValue => _, Reads => _, _}
import com.sludg.util.models.SilhouetteModels
import org.log4s.{Logger, getLogger}

import scala.scalajs.js.UndefOr

object RolesPage {
  type RolePageType = VueComponent[_ <: UserPortalAppProps, _ <: Slots, _ <: ScopedSlots]
    with RolePageData
    with js.Object
    with UserPortalAppProps
  val logger: Logger = getLogger

  def rolePageRenderer(
      registrationName: String
  ): RenderHelpers.NodeRenderer[VueProps, EventBindings, ScopedSlots] =
    namedTag[VueProps, EventBindings, ScopedSlots](registrationName)

  val userPortalApp = UserPortalApp.userPortalAppRenderer(registrationName = "UserPortal")
  val rolesComponent: RenderHelpers.NodeRenderer[VueProps, EventBindings, ScopedSlots] =
    RolesPage.rolePageRenderer(registrationName = "RolesTable")

  def rolePageComponent(
      apiCalls: ApiCalls,
      security: Security,
      loader: Vue,
      otherApps: List[AppLink]
  )(implicit
      token: SludgToken
  ): VueComponent[_ <: UserPortalAppProps, _ <: Slots, _ <: ScopedSlots] = {
    VueComponent.builder
      .withData(new RolePageData())
      .withPropsAs[UserPortalAppProps]
      .build(
        created = js.defined(c => c.tenantID = token.tenants.headOption),
        components = js.defined(
          js.Dynamic.literal(
            "UserPortal" -> UserPortalApp
              .userPortalAppComponent(apiCalls, security, loader, otherApps, token.isSuperUser),
            "RolesTable" -> RolesTable.rolesTableComponent(apiCalls, security, loader, otherApps)(
              token
            )
          )
        ),
        templateOrRender = Right((_, renderer) => {
          userPortalApp(
            RenderOptions(
              on = Some(js.Dynamic.literal().asInstanceOf[EventBindings]),
              props = Some(
                new UserPortalAppProps(
                  title = js.defined("App Access"),
                  spacedToolbar = true,
                  displayTenantSelector = token.isSuperUser,
                  isStandardUser =
                    !token.isSuperUser && !token.subscribers.map(_.admin).headOption.contains(true),
                  token = Some(token)
                )
              ),
              scopedSlots = Some(
                new VtslAppScopedSlots {
                  override val default
                      : UndefOr[js.Function1[Option[SilhouetteModels.Tenant], VNode]] =
                    js.defined { tenant =>
                      div(
                        rolesComponent(
                          RenderOptions(
                            props = Some(
                              /** The tenant id from the tenant selector takes priority.
                                * If nothing is present, the tenant from your token is used.
                                * If you log in as a super user, no tenant will be selected until you make a selection.
                                */
                              new RolesTableProps(tenant.map(_.id).orElse(token.tenants.headOption))
                            )
                          )
                        )
                      ).render(renderer)
                    }
                }
              )
            )
          ).render(renderer)
        })
      )
  }
}

class RolePageData extends js.Object {
  var tenantID: Option[Int] = None
}
