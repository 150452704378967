package com.sludg.vuetify.components

import com.sludg.scalajs.DynamicHelper
import com.sludg.vue.{EventBindings, ScopedSlots, VueProps}

import scala.scalajs.js

/**
  * @author dpoliakas
  *         Date: 26/11/2018
  *         Time: 12:43
  *
  *
  */
trait VDivider {

  import com.sludg.vue.RenderHelpers._

  val vDivider = namedTag[VueProps, EventBindings, ScopedSlots]("v-divider")

}

trait VDividerProps extends VueProps {

  val dark: js.UndefOr[Boolean]     = js.undefined
  val inset: js.UndefOr[Boolean]    = js.undefined
  val light: js.UndefOr[Boolean]    = js.undefined
  val vertical: js.UndefOr[Boolean] = js.undefined
}

object VDividerProps {

  import scala.scalajs.js.JSConverters._

  def apply(
      dark: Option[Boolean] = None,
      inset: Option[Boolean] = None,
      light: Option[Boolean] = None,
      vertical: Option[Boolean] = None
  ): VDividerProps = {

    DynamicHelper.buildViaDynamic(
      "dark"     -> dark.map(js.Any.fromBoolean).orUndefined,
      "inset"    -> inset.map(js.Any.fromBoolean).orUndefined,
      "light"    -> light.map(js.Any.fromBoolean).orUndefined,
      "vertical" -> vertical.map(js.Any.fromBoolean).orUndefined
    )
  }
}
