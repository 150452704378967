package com.sludg.vuetify.components

import com.sludg.scalajs.DynamicHelper
import com.sludg.vue.{EventBindings, ScopedSlots, VueProps}

import scala.scalajs.js

import scala.scalajs.js.|

/**
  * @author Samir :3
  *         Date: 1/03/2019
  *         Time: 10:50
  *
  *
  */
trait VExpansionPanel {

  import com.sludg.vue.RenderHelpers._

  val vExpansionPanel =
    namedTag[VExpansionPanelProps, EventBindings, ScopedSlots]("v-expansion-panel")
}

trait VExpansionPanelProps extends VueProps {

  val dark: js.UndefOr[Boolean]                 = js.undefined
  val disabled: js.UndefOr[Boolean]             = js.undefined
  val expand: js.UndefOr[Boolean]               = js.undefined
  val focusable: js.UndefOr[Boolean]            = js.undefined
  val inset: js.UndefOr[Boolean]                = js.undefined
  val light: js.UndefOr[Boolean]                = js.undefined
  val popout: js.UndefOr[Boolean]               = js.undefined
  val readonly: js.UndefOr[Boolean]             = js.undefined
  val value: js.UndefOr[Int | js.Array[js.Any]] = js.undefined
}

object VExpansionPanelProps {

  import scala.scalajs.js.JSConverters._

  def apply(
      dark: Option[Boolean] = None,
      disabled: Option[Boolean] = None,
      expand: Option[Boolean] = None,
      focusable: Option[Boolean] = None,
      inset: Option[Boolean] = None,
      light: Option[Boolean] = None,
      popout: Option[Boolean] = None,
      readonly: Option[Boolean] = None,
      value: Option[Either[Int, js.Array[js.Any]]] = None
  ): VExpansionPanelProps = {

    DynamicHelper.buildViaDynamic(
      "dark"      -> dark.map(js.Any.fromBoolean).orUndefined,
      "disabled"  -> disabled.map(js.Any.fromBoolean).orUndefined,
      "expand"    -> expand.map(js.Any.fromBoolean).orUndefined,
      "focusable" -> focusable.map(js.Any.fromBoolean).orUndefined,
      "inset"     -> inset.map(js.Any.fromBoolean).orUndefined,
      "light"     -> light.map(js.Any.fromBoolean).orUndefined,
      "popout"    -> popout.map(js.Any.fromBoolean).orUndefined,
      "readonly"  -> readonly.map(js.Any.fromBoolean).orUndefined,
      "value"     -> value.map(_.fold(js.Any.fromInt, identity)).orUndefined
    )
  }
}
