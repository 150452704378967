package com.sludg.model

import com.sludg.models.Config.{AlmostAuth0Config, ApiConfig, AppLink, Config, ScheduledReportConfig, VueColors}
import cron4s.expr.CronExpr
import play.api.libs.json.{Format, Json}

import scala.scalajs.js.annotation.JSExportAll

object Models {

  import com.sludg.json.Deserializer._

  implicit val userPortalConfigFormat: Format[UserPortalConfig] = Json.format[UserPortalConfig]

  case class UserPortalConfig(
                               auth0: AlmostAuth0Config,
                               vuetify: VueColors,
                               apiConfig: ApiConfig,
                               otherApps: List[AppLink],
                               logLevel: Option[org.log4s.LogLevel],
                             ) extends Config

}