package com.sludg.vuetify

import scala.scalajs.js

/**
  * @author dpoliakas
  *         Date: 22/11/2018
  *         Time: 15:00
  *
  *         An instance of this can be passed into the second argument of [[com.sludg.vue.Vue.use]] when installing Vuetify, i.e.
  *         {{{
  *                              import com.sludg.vuetify._
  *                              Vue.use(Vuetify, new VuetifyOptions{... overwritten properties ..})
  *         }}}
  *
  */
trait VuetifyOptions extends js.Object {
  val theme: js.UndefOr[VuetifyTheme] = js.undefined
}

trait VuetifyTheme extends js.Object {
  val primary: js.UndefOr[String]   = js.undefined
  val secondary: js.UndefOr[String] = js.undefined
  val accent: js.UndefOr[String]    = js.undefined
  val error: js.UndefOr[String]     = js.undefined
  val info: js.UndefOr[String]      = js.undefined
  val success: js.UndefOr[String]   = js.undefined
  val warning: js.UndefOr[String]   = js.undefined
}

object VuetifyTheme {

  def apply(
      primary: Option[String] = None,
      secondary: Option[String] = None,
      accent: Option[String] = None,
      error: Option[String] = None,
      info: Option[String] = None,
      success: Option[String] = None,
      warning: Option[String] = None
  ): VuetifyTheme = {

    List(
      primary.map("primary"     -> _),
      secondary.map("secondary" -> _),
      accent.map("accent"       -> _),
      error.map("error"         -> _),
      info.map("info"           -> _),
      success.map("success"     -> _),
      warning.map("warning"     -> _)
    ).flatten
      .foldRight(js.Dynamic.literal()) {
        case ((key, value), obj) =>
          obj.updateDynamic(key)(value)
          obj
      }
      .asInstanceOf[VuetifyTheme]
  }

}
