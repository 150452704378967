package com.sludg.vuetify.components

import com.sludg.vue.{EventBindings, ScopedSlots, VueProps}

import scala.scalajs.js

trait VListTileAvatar {

  import com.sludg.vue.RenderHelpers._

  val vListTileAvatar = namedTag[VueProps, EventBindings, ScopedSlots]("v-list-tile-avatar")

}

object VListTileAvatar extends VListTileAvatar
