package com.sludg.vuetify.components

import com.sludg.scalajs.DynamicHelper
import com.sludg.vue.{EventBindings, ScopedSlots, VueProps}

import scala.scalajs.js

import scala.scalajs.js.|
import com.sludg.vue.VNode
import com.sludg.vue.RenderOptions

/**
  * @author Samir :3
  *         Date: 1/03/2019
  *         Time: 10:50
  *
  *
  */
trait VDialog {

  import com.sludg.vue.RenderHelpers._

  val vDialog = namedTag[VDialogProps, EventBindings, ScopedSlots]("v-dialog")

  /**
    * A renderer for common cases of vDialog.
    *
    * Note with regards to dialog closure: when the user closes the dialog,
    * vuetify responds by closing the dialog as well regardless of what the
    * current value of dialogCurrentlyOpen is. To that end
    * make sure that your onDialogClose function updates your state to indicate
    * that the dialog should be closed.
    *
    * @param dialogCurrentlyOpen the flag that is used to determine whether the dialog is to be
    *   open when rendering.
    * @param onDialogClose function that is invoked when the user closes the dialog.
    * @param dialogProps the props to be passed through to the dialog. NOTE THAT THE value PROPERTY ON THE OBJECT WILL BE OVERRIDDEN.
    *   this means that you should not reuse the value passed into this parameter elsewhere, as the value of `value` may not be what you expect.
    * @param innards the markup that will be rendered inside the vDialog.
    * @return a v-dialog renderer rendering a vDialog according to the props passed
    */
  def vDialogSimple(
      dialogCurrentlyOpen: Boolean,
      onDialogClose: () => Unit,
      dialogProps: VDialogProps = VDialogProps()
  )(innards: ChildAppender[VDialogProps, EventBindings, ScopedSlots]): RenderFunction[VNode] = {

    // This is a side-effecting operation that modifies the object behind the dialogProps reference
    // Unfortunately I'm not sure if there's a better way to do it. Javascript has no `copy` method
    // and the recommended way is to stringify the object and then parse it, however that would not
    // preserve functions inside the object.
    dialogProps.asInstanceOf[js.Dynamic].value = dialogCurrentlyOpen

    vDialog(
      RenderOptions(
        props = Some(dialogProps),
        on = Some(
          EventBindings(
            input = js.defined(_ => onDialogClose())
          )
        )
      ),
      innards
    )
  }
}

trait VDialogProps extends VueProps {

  val `content-class`: js.UndefOr[js.Any]       = js.undefined
  val dark: js.UndefOr[Boolean]                 = js.undefined
  val disabled: js.UndefOr[Boolean]             = js.undefined
  val `full-width`: js.UndefOr[Boolean]         = js.undefined
  val fullscreen: js.UndefOr[Boolean]           = js.undefined
  val `hide-overlay`: js.UndefOr[Boolean]       = js.undefined
  val `lazy`: js.UndefOr[Boolean]               = js.undefined
  val `no-click-animation`: js.UndefOr[Boolean] = js.undefined
  val `max-width`: js.UndefOr[String | Number]  = js.undefined
  val origin: js.UndefOr[String]                = js.undefined
  val persistent: js.UndefOr[Boolean]           = js.undefined
  val scrollable: js.UndefOr[Boolean]           = js.undefined
  val transition: js.UndefOr[String | Number]   = js.undefined
  val value: js.UndefOr[js.Any]                 = js.undefined
  val width: js.UndefOr[String | Number]        = js.undefined
}

object VDialogProps {

  import scala.scalajs.js.JSConverters._

  def apply(
      `content-class`: Option[js.Any] = None,
      dark: Option[Boolean] = None,
      disabled: Option[Boolean] = None,
      fullWidth: Option[Boolean] = None,
      fullscreen: Option[Boolean] = None,
      hideOverlay: Option[Boolean] = None,
      `lazy`: Option[Boolean] = None,
      noClickAnimation: Option[Boolean] = None,
      `max-width`: Option[Either[String, Int]] = None,
      origin: Option[String] = None,
      persistent: Option[Boolean] = None,
      scrollable: Option[Boolean] = None,
      transition: Option[Either[String, Int]] = None,
      value: Option[js.Any] = None,
      width: Option[Either[String, Int]] = None
  ): VDialogProps = {

    DynamicHelper.buildViaDynamic(
      "content-class"      -> `content-class`.orUndefined,
      "dark"               -> dark.map(js.Any.fromBoolean).orUndefined,
      "disabled"           -> disabled.map(js.Any.fromBoolean).orUndefined,
      "full-width"         -> fullWidth.map(js.Any.fromBoolean).orUndefined,
      "hide-overlay"       -> hideOverlay.map(js.Any.fromBoolean).orUndefined,
      "lazy"               -> `lazy`.map(js.Any.fromBoolean).orUndefined,
      "no-click-animation" -> noClickAnimation.map(js.Any.fromBoolean).orUndefined,
      "max-width"          -> `max-width`.map(_.fold(js.Any.fromString, js.Any.fromInt)).orUndefined,
      "origin"             -> origin.map(js.Any.fromString).orUndefined,
      "persistent"         -> persistent.map(js.Any.fromBoolean).orUndefined,
      "scrollable"         -> scrollable.map(js.Any.fromBoolean).orUndefined,
      "fullscreen"         -> fullscreen.map(js.Any.fromBoolean).orUndefined,
      "transition"         -> transition.map(_.fold(js.Any.fromString, js.Any.fromInt)).orUndefined,
      "value"              -> value.orUndefined,
      "width"              -> transition.map(_.fold(js.Any.fromString, js.Any.fromInt)).orUndefined
    )
  }
}
