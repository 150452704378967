package com.sludg.vuetify.components

import com.sludg.vue.{EventBindings, ScopedSlots, VueProps}

import scala.scalajs.js

/**
  * @author dpoliakas
  *         Date: 22/11/2018
  *         Time: 17:20
  *
  *
  */
trait VBreadcrumbs {

  import com.sludg.vue.RenderHelpers._

  val vBreadcrumbs = namedTag[VBreadcrumbsProps, EventBindings, ScopedSlots]("v-breadcrumbs")

}

object VBreadcrumbs extends VBreadcrumbs

trait VBreadcrumbsProps extends VueProps {}
