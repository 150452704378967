package com.sludg.client

import com.sludg.FieldExtractor
import buildinfo.sbtinfo
import com.sludg.components.VTSLApp
import com.sludg.models.Config.AppLink
import com.sludg.services.ApiCalls
import com.sludg.util.models.SilhouetteModels.Tenant
import com.sludg.vue._
import com.sludg.vuetify.VuetifyComponents._
import com.sludg.vuetify.components.VListTileProps
import com.sludg.Security
import org.log4s.getLogger
import scala.scalajs.js
import com.sludg.helpers.AppSetup._
import com.sludg.auth0.SludgToken

object UserPortalApp {

  private[this] val logger = getLogger

  import RenderHelpers._

  def userPortalAppRenderer(registrationName: String) =
    namedTag[UserPortalAppProps, EventBindings, VtslAppScopedSlots](registrationName)

  val appRenderer = VTSLApp.appRenderer("VtslApp")

  def userPortalAppComponent(
      apiCalls: ApiCalls,
      security: Security,
      apiCallBus: Vue,
      otherApps: List[AppLink],
      displayTenantSelector: Boolean
  ) = VueComponent.builder
    .withScopedSlots[VtslAppScopedSlots]
    .withPropsAs[UserPortalAppProps]
    .withData(new VtslAppData())
    .build(
      components = js.defined(
        js.Dynamic.literal(
          "VtslApp" -> VTSLApp.createApp(
            "UserPortal",
            apiCalls,
            apiCallBus,
            otherApps,
            Some(sbtinfo.version),
            None,
            _ => security.logout(),
            showLogo = true,
            showFooter = true
          )
        )
      ),
      updated = js.defined(_ => logger.debug("UserPortal app updated")),
      templateOrRender = Right((component, renderer) => {
        div(
          appRenderer(
            RenderOptions(
              props = Some(
                new VtslAppProps(
                  title = component.title,
                  spacedToolbar = component.spacedToolbar,
                  displayTenantSelector = displayTenantSelector,
                  temporaryDrawer = true,
                  token = component.token
                )
              ),
              scopedSlots = Some(
                new VtslAppScopedSlots {
                  override val toolbar: js.UndefOr[js.Function1[Option[Tenant], VNode]] =
                    component.$scopedSlots.toolbar
                  override val toolbarExtension: js.UndefOr[js.Function1[Option[Tenant], VNode]] =
                    component.$scopedSlots.toolbarExtension
                  override val drawer: js.UndefOr[js.Function1[Option[Tenant], VNode]] =
                    js.defined(tenant => {
                      vList(
                        vListTile(
                          VListTileProps(
                            to = Some(Left("/"))
                          ),
                          vListTileAction(vIcon("account_box")),
                          vListTileAction(
                            "User Portal"
                          )
                        ),
                        vListTile(
                          VListTileProps(
                            disabled = Some(component.isStandardUser),
                            to = Some(Left("/call_routing"))
                          ),
                          vListTileAction(vIcon("phone_forwarded")),
                          vListTileAction(
                            "Call Routing"
                          )
                        ),
                        vListTile(
                          VListTileProps(
                            disabled = Some(component.isStandardUser),
                            to = Some(Left("/user_roles"))
                          ),
                          vListTileAction(vIcon("apps")),
                          vListTileAction(
                            "App Access"
                          )
                        )
                      ).render(renderer)
                    })
                  override val default: js.UndefOr[js.Function1[Option[Tenant], VNode]] =
                    component.$scopedSlots.default
                }
              )
            )
          )
        ).render(renderer)
      })
    )

  class UserPortalAppProps(
      val isStandardUser: Boolean = true,
      val title: js.UndefOr[String] = js.undefined,
      val spacedToolbar: Boolean = false,
      val buttonName: js.UndefOr[String] = js.undefined,
      val displayTenantSelector: Boolean = false,
      val vContentIsFluid: Boolean = false,
      val token: Option[SludgToken]
  ) extends VueProps
}
