package com.sludg.vue.rendering

import com.sludg.vue.TemplatingHelpers
import com.sludg.vue.VueProps
import com.sludg.vue.EventBindings
import com.sludg.vue.ScopedSlots

trait Source { this: TemplatingHelpers =>
  val source = namedTag[VueProps, EventBindings, ScopedSlots]("source")
}
