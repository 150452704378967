package com.sludg.vuetify.components.transitions

import com.sludg.vue.{EventBindings, ScopedSlots}

trait VScrollYTransition {

  import com.sludg.vue.RenderHelpers._

  val vScrollYTransition =
    namedTag[VTransitionProps, EventBindings, ScopedSlots]("v-scroll-y-transition")

}
