package com.sludg.vuetify.components

import com.sludg.vue.{EventBindings, ScopedSlots, VueProps}

trait VStepperItems {

  import com.sludg.vue.RenderHelpers._

  val vStepperItems = namedTag[VueProps, EventBindings, ScopedSlots]("v-stepper-items")

}

object VStepperItems extends VStepperItems
