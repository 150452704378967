package com.sludg.vuetify.components

import com.sludg.scalajs.DynamicHelper
import com.sludg.vue.{EventBindings, ScopedSlots, VueProps}
import com.sludg.vue.RenderHelpers.namedTag

import scala.scalajs.js

trait VBadge {
  val vBadge = namedTag[VBadgeProps, EventBindings, ScopedSlots]("v-badge")
}

object VBadge extends VBadge

trait VBadgeProps extends VueProps {
  val color: js.UndefOr[String]    = js.undefined
  val overlap: js.UndefOr[Boolean] = js.undefined
  val value: js.UndefOr[Boolean]   = js.undefined
}

object VBadgeProps {
  import scala.scalajs.js.JSConverters._

  def apply(
      color: Option[String] = None,
      overlap: Option[Boolean] = None,
      value: Option[Boolean] = None
  ): VBadgeProps = {

    DynamicHelper.buildViaDynamic(
      "color"   -> color.map(js.Any.fromString).orUndefined,
      "overlap" -> overlap.map(js.Any.fromBoolean).orUndefined,
      "value"   -> value.map(js.Any.fromBoolean).orUndefined
    )
  }
}
