package com.sludg.vuetify.components

import com.sludg.scalajs.DynamicHelper
import com.sludg.vue.{EventBindings, ScopedSlots, VueProps}

import scala.scalajs.js

import scala.scalajs.js.|

/**
  * @author dpoliakas
  *         Date: 26/11/2018
  *         Time: 12:45
  *
  *
  */
trait VImage {

  import com.sludg.vue.RenderHelpers._

  val vImage = namedTag[VImageProps, EventBindings, ScopedSlots]("v-img")

}

object VImage extends VImage

trait VImageProps extends VueProps {
  val alt: js.UndefOr[String]                  = js.undefined
  val aspectRatio: js.UndefOr[String | Int]    = js.undefined
  val contain: js.UndefOr[Boolean]             = js.undefined
  val gradient: js.UndefOr[String]             = js.undefined
  val height: js.UndefOr[Int | String]         = js.undefined
  val `lazy-src`: js.UndefOr[String]           = js.undefined
  val `max-height`: js.UndefOr[Int | String]   = js.undefined
  val `max-width`: js.UndefOr[Int | String]    = js.undefined
  val `min-height`: js.UndefOr[Int | String]   = js.undefined
  val `min-width`: js.UndefOr[Int | String]    = js.undefined
  val position: js.UndefOr[String]             = js.undefined
  val sizes: js.UndefOr[String]                = js.undefined
  val src: js.UndefOr[String | js.Object]      = js.undefined
  val srcset: js.UndefOr[String]               = js.undefined
  val transition: js.UndefOr[Boolean | String] = js.undefined
  val width: js.UndefOr[Int | String]          = js.undefined
}

object VImageProps {

  import scala.scalajs.js.JSConverters._

  def apply(
      src: Either[String, js.Object],
      alt: Option[String] = None,
      aspectRatio: Option[Either[String, Int]] = None,
      contain: Option[Boolean] = None,
      gradient: Option[String] = None,
      height: Option[Either[Int, String]] = None,
      lazySrc: Option[String] = None,
      maxHeight: Option[Either[Int, String]] = None,
      maxWidth: Option[Either[Int, String]] = None,
      minHeight: Option[Either[Int, String]] = None,
      minWidth: Option[Either[Int, String]] = None,
      position: Option[String] = None,
      sizes: Option[String] = None,
      srcset: Option[String] = None,
      transition: Option[Either[Boolean, String]] = None,
      width: Option[Either[Int, String]] = None
  ): VImageProps = {
    DynamicHelper.buildViaDynamic(
      "src"         -> js.defined(src.fold(js.Any.fromString, identity)),
      "alt"         -> alt.map(js.Any.fromString).orUndefined,
      "aspectRatio" -> aspectRatio.map(_.fold(js.Any.fromString, js.Any.fromInt)).orUndefined,
      "contain"     -> contain.map(js.Any.fromBoolean).orUndefined,
      "gradient"    -> gradient.map(js.Any.fromString).orUndefined,
      "height"      -> height.map(_.fold(js.Any.fromInt, js.Any.fromString)).orUndefined,
      "lazy-src"    -> lazySrc.map(js.Any.fromString).orUndefined,
      "max-height"  -> maxHeight.map(_.fold(js.Any.fromInt, js.Any.fromString)).orUndefined,
      "max-width"   -> maxWidth.map(_.fold(js.Any.fromInt, js.Any.fromString)).orUndefined,
      "min-height"  -> minHeight.map(_.fold(js.Any.fromInt, js.Any.fromString)).orUndefined,
      "min-width"   -> minWidth.map(_.fold(js.Any.fromInt, js.Any.fromString)).orUndefined,
      "position"    -> position.map(js.Any.fromString).orUndefined,
      "sizes"       -> sizes.map(js.Any.fromString).orUndefined,
      "srcset"      -> srcset.map(js.Any.fromString).orUndefined,
      "transition"  -> transition.map(_.fold(js.Any.fromBoolean, js.Any.fromString)).orUndefined,
      "width"       -> width.map(_.fold(js.Any.fromInt, js.Any.fromString)).orUndefined
    )
  }
}
