package com.sludg.vue.rendering

import com.sludg.scalajs.DynamicHelper
import com.sludg.vue.{EventBindings, ScopedSlots, TemplatingHelpers, VueProps}

import scala.scalajs.js

/**
  * @author dpoliakas
  *         Date: 22/11/2018
  *         Time: 18:50
  *
  *
  */
trait Transition {
  this: TemplatingHelpers =>
  val transition = namedTag[TransitionProps, EventBindings, ScopedSlots]("transition")
}

trait TransitionProps extends VueProps

object TransitionProps {

  import scala.scalajs.js.JSConverters._

  def apply(name: Option[String] = None): TransitionProps = {
    DynamicHelper.buildViaDynamic(
      "name" -> name.map(js.Any.fromString).orUndefined
    )
  }

}
