package com.sludg.vuetify.components

import com.sludg.vue.{EventBindings, ScopedSlots, VueProps}

import scala.scalajs.js

/**
  * @author dpoliakas
  *         Date: 23/11/2018
  *         Time: 17:26
  *
  *
  */
trait VToolbarSideIcon {

  import com.sludg.vue.RenderHelpers._

  val vToolbarSideIcon = namedTag[VueProps, EventBindings, ScopedSlots]("v-toolbar-side-icon")

}

object VToolbarSideIcon extends VToolbarSideIcon
