package com.sludg.vuetify.components

import com.sludg.scalajs.DynamicHelper
import com.sludg.vue.{EventBindings, ScopedSlots, VueProps}

import scala.scalajs.js
import scala.scalajs.js.|

trait VSwitch {

  import com.sludg.vue.RenderHelpers._

  val vSwitch = namedTag[VSwitchProps, EventBindings, ScopedSlots]("v-switch")
}

object VSwitch extends VSwitch

trait VSwitchProps extends VueProps {

  val `append-icon`: js.UndefOr[String]                         = js.undefined
  val `append-icon-cb`: js.UndefOr[js.Function]                 = js.undefined
  val `background-color`: js.UndefOr[String]                    = js.undefined
  val color: js.UndefOr[String]                                 = js.undefined
  val dark: js.UndefOr[Boolean]                                 = js.undefined
  val disabled: js.UndefOr[Boolean]                             = js.undefined
  val error: js.UndefOr[Boolean]                                = js.undefined
  val `error-count`: js.UndefOr[Int | String]                   = js.undefined
  val `error-messages`: js.UndefOr[String | js.Array[js.Any]]   = js.undefined
  val `false-value`: js.UndefOr[js.Any]                         = js.undefined
  val height: js.UndefOr[Int | String]                          = js.undefined
  val `hide-details`: js.UndefOr[Boolean]                       = js.undefined
  val hint: js.UndefOr[String]                                  = js.undefined
  val id: js.UndefOr[String]                                    = js.undefined
  val `input-value`: js.UndefOr[js.Any]                         = js.undefined
  val label: js.UndefOr[String]                                 = js.undefined
  val light: js.UndefOr[Boolean]                                = js.undefined
  val loading: js.UndefOr[Boolean]                              = js.undefined
  val messages: js.UndefOr[String | js.Array[js.Any]]           = js.undefined
  val multiple: js.UndefOr[Boolean]                             = js.undefined
  val `persistent-hint`: js.UndefOr[Boolean]                    = js.undefined
  val `prepend-icon`: js.UndefOr[String]                        = js.undefined
  val `prepend-icon-cb`: js.UndefOr[js.Function]                = js.undefined
  val readonly: js.UndefOr[Boolean]                             = js.undefined
  val ripple: js.UndefOr[Boolean | js.Object]                   = js.undefined
  val rules: js.UndefOr[js.Array[js.Any]]                       = js.undefined
  val success: js.UndefOr[Boolean]                              = js.undefined
  val `success-messages`: js.UndefOr[String | js.Array[js.Any]] = js.undefined
  val `true-value`: js.UndefOr[js.Any]                          = js.undefined
  val `validate-on-blur`: js.UndefOr[Boolean]                   = js.undefined
  val value: js.UndefOr[js.Any]                                 = js.undefined
  val `value-comparator`: js.UndefOr[js.Function]               = js.undefined

}

object VSwitchProps {

  import scala.scalajs.js.JSConverters._

  def apply(
      `append-icon`: Option[String] = None,
      `append-icon-cb`: Option[js.Function] = None,
      `background-color`: Option[String] = None,
      color: Option[String] = None,
      dark: Option[Boolean] = None,
      disabled: Option[Boolean] = None,
      error: Option[Boolean] = None,
      `error-count`: Option[Either[Int, String]] = None,
      `error-messages`: Option[Either[String, js.Array[js.Any]]] = None,
      `false-value`: Option[js.Any] = None,
      height: Option[Either[Int, String]] = None,
      `hide-details`: Option[Boolean] = None,
      hint: Option[String] = None,
      id: Option[String] = None,
      `input-value`: Option[js.Any] = None,
      label: Option[String] = None,
      light: Option[Boolean] = None,
      loading: Option[Boolean] = None,
      messages: Option[Either[String, js.Array[js.Any]]] = None,
      multiple: Option[Boolean] = None,
      `persistent-hint`: Option[Boolean] = None,
      `prepend-icon`: Option[String] = None,
      `prepend-icon-cb`: Option[js.Function] = None,
      readonly: Option[Boolean] = None,
      ripple: Option[Either[Boolean, js.Object]] = None,
      rules: Option[js.Array[js.Any]] = None,
      success: Option[Boolean] = None,
      `success-messages`: Option[Either[String, js.Array[js.Any]]] = None,
      `true-value`: Option[js.Any] = None,
      `validate-on-blur`: Option[Boolean] = None,
      value: Option[js.Any] = None,
      `value-comparator`: Option[js.Function] = None
  ): VSwitchProps = {

    DynamicHelper.buildViaDynamic(
      "append-icon"      -> `append-icon`.map(js.Any.fromString).orUndefined,
      "append-icon-cb"   -> `append-icon-cb`.orUndefined,
      "background-color" -> `background-color`.map(js.Any.fromString).orUndefined,
      "color"            -> color.map(js.Any.fromString).orUndefined,
      "dark"             -> dark.map(js.Any.fromBoolean).orUndefined,
      "disabled"         -> disabled.map(js.Any.fromBoolean).orUndefined,
      "error"            -> error.map(js.Any.fromBoolean).orUndefined,
      "error-count"      -> `error-count`.map(_.fold(js.Any.fromInt, js.Any.fromString)).orUndefined,
      "error-messages"   -> `error-messages`.map(_.fold(js.Any.fromString, identity)).orUndefined,
      "false-value"      -> `false-value`.orUndefined,
      "height"           -> height.map(_.fold(js.Any.fromInt, js.Any.fromString)).orUndefined,
      "hide-details"     -> `hide-details`.map(js.Any.fromBoolean).orUndefined,
      "hint"             -> hint.map(js.Any.fromString).orUndefined,
      "id"               -> id.map(js.Any.fromString).orUndefined,
      "input-value"      -> `input-value`.orUndefined,
      "label"            -> label.map(js.Any.fromString).orUndefined,
      "light"            -> light.map(js.Any.fromBoolean).orUndefined,
      "loading"          -> loading.map(js.Any.fromBoolean).orUndefined,
      "messages"         -> messages.map(_.fold(js.Any.fromString, identity)).orUndefined,
      "multiple"         -> multiple.map(js.Any.fromBoolean).orUndefined,
      "persistent-hint"  -> `persistent-hint`.map(js.Any.fromBoolean).orUndefined,
      "prepend-icon"     -> `prepend-icon`.map(js.Any.fromString).orUndefined,
      "prepend-icon-cb"  -> `prepend-icon-cb`.orUndefined,
      "readonly"         -> readonly.map(js.Any.fromBoolean).orUndefined,
      "ripple"           -> ripple.map(_.fold(js.Any.fromBoolean, identity)).orUndefined,
      "rules"            -> `rules`.orUndefined,
      "success"          -> success.map(js.Any.fromBoolean).orUndefined,
      "success-messages" -> `success-messages`.map(_.fold(js.Any.fromString, identity)).orUndefined,
      "true-value"       -> `true-value`.orUndefined,
      "validate-on-blur" -> `validate-on-blur`.map(js.Any.fromBoolean).orUndefined,
      "value"            -> value.orUndefined,
      "value-comparator" -> `value-comparator`.orUndefined
    )
  }
}
