package com.sludg.vuetify.components

import com.sludg.scalajs.DynamicHelper
import com.sludg.vue.VueProps

import scala.scalajs.js

/**
  * @author dpoliakas
  *         Date: 26/11/2018
  *         Time: 09:17
  *
  *         Many Vuetify components share the same props. This class exposes those props in a single place.
  *
  *         If you're implementing a component consequently your Prop declaration becomes a little simpler.
  *
  *         In fact, sometimes it might be as simple as:
  *
  *         {{{
  *         @ScalaJSDefined
  *         trait VToolbarProps extends VuetifyComponentProps
  *
  *         object VToolbarProps {
  *           def apply(app: Boolean = false): VToolbarProps = VuetifyComponentProps(app = app).asInstanceOf[VToolbarProps]
  *         }
  *         }}}
  *
  *         In more complex scenarios it might be something like
  *         {{{
  *         object VNavigationDrawer extends VNavigationDrawer
  *
  *         @ScalaJSDefined
  *         trait VNavigationDrawerProps extends VuetifyComponentProps {
  *           val p: js.UndefOr[String] = js.undefined
  *         }
  *
  *         object VNavigationDrawerProps{
  *           def apply(app: Boolean = true, p: String): VNavigationDrawerProps =
  *           VuetifyComponentProps(app = app)
  *             .withData("p" -> js.defined(p))
  *         }
  *         }}}
  */
trait VuetifyComponentProps extends VueProps {
  val app: js.UndefOr[Boolean] = js.undefined
}

object VuetifyComponentProps {
  def apply(app: Boolean = false): VuetifyComponentProps = {
    js.Dynamic
      .literal(
        "app" -> app
      )
      .asInstanceOf[VuetifyComponentProps]
  }

  /**
    * Allows one to easily add extra fields to a VuetifyComponentProps.
    *
    * You have to pass your fields as a mapping between field names and their (potential) values
    *
    * Any fields which are undefined will not be added to the object
    */
  implicit class EnhancableVueComponentProps(v: VuetifyComponentProps) {
    def withData[A <: js.Object](d: (String, js.UndefOr[js.Any])*): A = {
      val componentsAsDynamic = v.asInstanceOf[js.Dynamic]
      d.foreach(v => DynamicHelper.addToDynamicIfDefined(componentsAsDynamic, v))
      componentsAsDynamic.asInstanceOf[A]
    }
  }

}
