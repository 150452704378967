package com.sludg.vuetify.components

import com.sludg.scalajs.DynamicHelper
import com.sludg.vue.{EventBindings, ScopedSlots, VueProps}

import scala.scalajs.js

import scala.scalajs.js.|

/**
  * @author pbarman
  *         Date: 24/02/2020
  *         Time: 11:11
  *
  *
  */
trait VPagination {

  import com.sludg.vue.RenderHelpers._

  val vPagination = namedTag[VPaginationProps, EventBindings, ScopedSlots]("v-pagination")

}

object VPagination extends VPagination

trait VPaginationProps extends VueProps {

  val circle: js.UndefOr[Boolean]               = js.undefined
  val color: js.UndefOr[String]                 = js.undefined
  val dark: js.UndefOr[Boolean]                 = js.undefined
  val disabled: js.UndefOr[Boolean]             = js.undefined
  val length: js.UndefOr[Int]                   = js.undefined
  val light: js.UndefOr[Boolean]                = js.undefined
  val `next-icon`: js.UndefOr[String]           = js.undefined
  val `prev-icon`: js.UndefOr[String]           = js.undefined
  val `total-visible`: js.UndefOr[Int | String] = js.undefined
  val value: js.UndefOr[Int]                    = js.undefined

}

object VPaginationProps {

  import scala.scalajs.js.JSConverters._

  def apply(
      circle: Option[Boolean] = None,
      color: Option[String] = None,
      dark: Option[Boolean] = None,
      disabled: Option[Boolean] = None,
      length: Option[Int] = None,
      light: Option[Boolean] = None,
      `next-icon`: Option[String] = None,
      `prev-icon`: Option[String] = None,
      `total-visible`: Option[Either[Int, String]] = None,
      value: Option[Int] = None
  ): VPaginationProps = {

    DynamicHelper.buildViaDynamic(
      "circle"        -> circle.map(js.Any.fromBoolean).orUndefined,
      "color"         -> color.map(js.Any.fromString).orUndefined,
      "dark"          -> dark.map(js.Any.fromBoolean).orUndefined,
      "disabled"      -> disabled.map(js.Any.fromBoolean).orUndefined,
      "length"        -> length.map(js.Any.fromInt).orUndefined,
      "light"         -> light.map(js.Any.fromBoolean).orUndefined,
      "next-icon"     -> `next-icon`.map(js.Any.fromString).orUndefined,
      "prev-icon"     -> `prev-icon`.map(js.Any.fromString).orUndefined,
      "total-visible" -> `total-visible`.map(_.fold(js.Any.fromInt, js.Any.fromString)).orUndefined,
      "value"         -> value.map(js.Any.fromInt).orUndefined
    )
  }
}
