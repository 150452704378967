package com.sludg.vuetify.components

import com.sludg.scalajs.DynamicHelper
import com.sludg.vue.{EventBindings, ScopedSlots, VueProps}

import scala.scalajs.js

trait VMenu {

  import com.sludg.vue.RenderHelpers._

  val vMenu = namedTag[VMenuProps, EventBindings, ScopedSlots]("v-menu")

}

object VMenu extends VMenu

trait VMenuProps extends VueProps {
  val activator: js.UndefOr[js.Any]                 = js.undefined
  val `close-on-click`: js.UndefOr[Boolean]         = js.undefined
  val `close-on-content-click`: js.UndefOr[Boolean] = js.undefined
  val `offset-x`: js.UndefOr[Boolean]               = js.undefined
  val transition: js.UndefOr[Boolean]               = js.undefined
  val absolute: js.UndefOr[Boolean]                 = js.undefined
  val `allow-overflow`: js.UndefOr[Boolean]         = js.undefined
  val `full-width`: js.UndefOr[Boolean]             = js.undefined
  val `min-width`: js.UndefOr[Int]                  = js.undefined
  val `min-height`: js.UndefOr[Int]                 = js.undefined
  val value: js.UndefOr[js.Any]                     = js.undefined
  val `offset-y`: js.UndefOr[Boolean]               = js.undefined
  val `open-on-hover`: js.UndefOr[Boolean]          = js.undefined
  val `top`: js.UndefOr[Boolean]                    = js.undefined
  val right: js.UndefOr[Boolean]                    = js.undefined
  val `offset-overflow`: js.UndefOr[Boolean]        = js.undefined
  val light: js.UndefOr[Boolean]                    = js.undefined
  val left: js.UndefOr[Boolean]                     = js.undefined
  val `lazy`: js.UndefOr[Boolean]                   = js.undefined
  val `input-activator`: js.UndefOr[Boolean]        = js.undefined
  val fixed: js.UndefOr[Boolean]                    = js.undefined
  val disabled: js.UndefOr[Boolean]                 = js.undefined
  val `disable-keys`: js.UndefOr[Boolean]           = js.undefined
  val dark: js.UndefOr[Boolean]                     = js.undefined
  val bottom: js.UndefOr[Boolean]                   = js.undefined
  val auto: js.UndefOr[Boolean]                     = js.undefined
}

object VMenuProps {

  import scala.scalajs.js.JSConverters._

  def apply(
      activator: Option[js.Any] = None,
      closeOnClick: Option[Boolean] = None,
      closeOnContentClick: Option[Boolean] = None,
      offsetX: Option[Boolean] = None,
      `full-width`: Option[Boolean] = None,
      `min-width`: Option[Int] = None,
      `min-height`: Option[Int] = None,
      transition: Option[Boolean] = None,
      value: Option[js.Any] = None,
      `offset-y`: Option[Boolean] = None,
      `open-on-hover`: Option[Boolean] = None,
      `top`: Option[Boolean] = None,
      right: Option[Boolean] = None,
      `offset-overflow`: Option[Boolean] = None,
      light: Option[Boolean] = None,
      left: Option[Boolean] = None,
      `lazy`: Option[Boolean] = None,
      `input-activator`: Option[Boolean] = None,
      fixed: Option[Boolean] = None,
      disabled: Option[Boolean] = None,
      `disable-keys`: Option[Boolean] = None,
      dark: Option[Boolean] = None,
      bottom: Option[Boolean] = None,
      auto: Option[Boolean] = None
  ): VMenuProps = {

    DynamicHelper.buildViaDynamic(
      "activator"              -> activator.orUndefined,
      "close-on-click"         -> closeOnClick.map(js.Any.fromBoolean).orUndefined,
      "close-on-content-click" -> closeOnContentClick.map(js.Any.fromBoolean).orUndefined,
      "offset-x"               -> offsetX.map(js.Any.fromBoolean).orUndefined,
      "full-width"             -> `full-width`.map(js.Any.fromBoolean).orUndefined,
      "min-width"              -> `min-width`.map(js.Any.fromInt).orUndefined,
      "min-height"             -> `min-height`.map(js.Any.fromInt).orUndefined,
      "transition"             -> transition.map(js.Any.fromBoolean).orUndefined,
      "value"                  -> value.orUndefined,
      "offset-y"               -> `offset-y`.map(js.Any.fromBoolean).orUndefined,
      "open-on-hover"          -> `open-on-hover`.map(js.Any.fromBoolean).orUndefined,
      "top"                    -> `top`.map(js.Any.fromBoolean).orUndefined,
      "right"                  -> right.map(js.Any.fromBoolean).orUndefined,
      "offset-overflow"        -> `offset-overflow`.map(js.Any.fromBoolean).orUndefined,
      "light"                  -> light.map(js.Any.fromBoolean).orUndefined,
      "left"                   -> left.map(js.Any.fromBoolean).orUndefined,
      "lazy"                   -> `lazy`.map(js.Any.fromBoolean).orUndefined,
      "input-activator"        -> `input-activator`.map(js.Any.fromBoolean).orUndefined,
      "fixed"                  -> fixed.map(js.Any.fromBoolean).orUndefined,
      "disabled"               -> disabled.map(js.Any.fromBoolean).orUndefined,
      "disable-keys"           -> `disable-keys`.map(js.Any.fromBoolean).orUndefined,
      "dark"                   -> dark.map(js.Any.fromBoolean).orUndefined,
      "bottom"                 -> bottom.map(js.Any.fromBoolean).orUndefined,
      "auto"                   -> auto.map(js.Any.fromBoolean).orUndefined
    )
  }
}
