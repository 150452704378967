package com.sludg.vuetify.components.transitions

import com.sludg.vue.{EventBindings, ScopedSlots}

trait VExpandTransition {

  import com.sludg.vue.RenderHelpers._

  val vExpandTransition =
    namedTag[VTransitionProps, EventBindings, ScopedSlots]("v-expand-transition")

}
