package com.sludg.vuetify.components

import com.sludg.vue.{EventBindings, ScopedSlots, VueProps}

trait VStepperHeader {

  import com.sludg.vue.RenderHelpers._

  val vStepperHeader = namedTag[VueProps, EventBindings, ScopedSlots]("v-stepper-header")

}

object VStepperHeader extends VStepperHeader
