package com.sludg.vuetify.components

import com.sludg.scalajs.DynamicHelper
import com.sludg.vue.{EventBindings, ScopedSlots, VueProps}

import scala.scalajs.js

trait VCardTitle {

  import com.sludg.vue.RenderHelpers._
  val vCardTitle = namedTag[VCardTitleProps, EventBindings, ScopedSlots]("v-card-title")

}

trait VCardTitleProps extends VueProps {
  val `primary-title`: js.UndefOr[Boolean] = js.undefined
}

object VCardTitleProps {
  import scala.scalajs.js.JSConverters._
  def apply(`primary-title`: Option[Boolean]): VCardTitleProps = {
    DynamicHelper.buildViaDynamic(
      "primary-title" -> `primary-title`.map(js.Any.fromBoolean).orUndefined
    )
  }
}
