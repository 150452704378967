package com.sludg

import com.sludg.auth0.SludgToken
import com.sludg.client.Client
import com.sludg.model.Models.{UserPortalConfig, userPortalConfigFormat}
import play.api.libs.json.Format
import com.sludg.services.ApiCalls
import com.sludg.vuetify._
import fr.hmil.roshttp.HttpRequest
import org.log4s._
import com.sludg.models.Config.{
  AlmostAuth0Config,
  ApiConfig,
  AppLink,
  Config,
  ScheduledReportConfig,
  VueColors
}
import play.api.libs.json.{JsObject, JsString, Json}

import scala.util.{Failure, Success}

object Entry extends App {

  private[this] val logger = getLogger

  import com.sludg.json.Deserializer._
  import com.sludg.model.Models.userPortalConfigFormat

  import monix.execution.Scheduler.Implicits.global

  val originUrl = org.scalajs.dom.window.location.origin

  def getConfig = HttpRequest(s"$originUrl/config").send().map { r =>
    Json.parse(r.body).validate[UserPortalConfig].asOpt
  }

  def initLogging(level: Option[LogLevel]): Unit = {
    import org.log4s.log4sjs.Log4sConfig._
    level match {
      case Some(logLevel) => setLoggerThreshold("", logLevel)
      case _ => setLoggerThreshold("", OffThreshold)
    }
  }

  (for (configOpt <- getConfig) yield configOpt match {
    case Some(config) =>
//       Setup log level
      initLogging(config.logLevel)
      (for {
        maybeSecurity <- Security.initialise(
          Auth0Config(
            config.auth0.domain,
            config.auth0.clientID,
            config.auth0.audience,
            originUrl.get
          )
        )
      } yield {
        maybeSecurity match {
          case Left(Security.NotLoggedIn(redirectToLogin)) =>
            logger.info("User not logged in. Redirecting.")
            redirectToLogin.run()
          case Right(sec) =>
            logger.info("Security session initialised. Bootings...")
            sec.checkSession().onComplete {
              case Success(Some(tokenJs)) =>
                Client.initialise(
                  sec,
                  config,
                  config.otherApps,
                  new ApiCalls(config.apiConfig),
                  "main"
                )(new SludgToken(tokenJs.accessToken))
              case Failure(error) =>
                logger.debug("Failed to refresh the token")
                error.printStackTrace()
                logger.debug("Logging out.")
                sec.logout()
              case other =>
                logger.debug("Could not refresh the token: " + other)
                logger.debug("Logging out.")
                sec.logout()
            }

        }
      }).onComplete {
        case Success(_) =>
          logger.debug("Running application")
        case Failure(e) =>
          logger.error("failed to start the application")
          e.printStackTrace()
          logger.debug(e.getMessage)
      }

    case None => logger.error("Config not supplied or could not be parsed")

  }).onComplete {
    case Success(_) => logger.debug("running")
    case Failure(e) =>
      logger.error("failed to start the application")
      e.printStackTrace()
      println(e.getMessage)
  }

}
