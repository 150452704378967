package com.sludg.helpers

import com.sludg.vue
import com.sludg.vue.{ScopedSlots, Slots, VueProps, VueRouter}

import scala.scalajs.js

object App {
//  private[this] val logger = getLogger

  import com.sludg.vue.RenderHelpers._

  def props(elId: String, router: VueRouter) = {
    vue.VueInstanceProperties[VueProps, Slots, ScopedSlots](
      el = s"#$elId",
      router = js.defined(router)
    )(
      templateOrRender = Right((component, renderer) => {
        routerView.render(renderer)
      })
    )
  }
}
