package com.sludg.vuetify.components

import com.sludg.scalajs.DynamicHelper
import com.sludg.vue.{EventBindings, ScopedSlots, VueProps}

import scala.scalajs.js
import scala.scalajs.js.|

trait VForm {

  import com.sludg.vue.RenderHelpers._

  val vForm = namedTag[VueProps, EventBindings, ScopedSlots]("v-form")
}
