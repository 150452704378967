package com.sludg.vue.rendering

import com.sludg.vue.TemplatingHelpers
import com.sludg.vue.VueProps
import com.sludg.vue.EventBindings
import com.sludg.vue.ScopedSlots

trait Audio { this: TemplatingHelpers =>
  val audio = namedTag[VueProps, EventBindings, ScopedSlots]("audio")
}
