package com.sludg.vuetify.components

import com.sludg.scalajs.DynamicHelper
import com.sludg.vue._

import scala.scalajs.js
import scala.scalajs.js.|

/**
  * @author dpoliakas
  *         Date: 22/11/2018
  *         Time: 17:20
  *
  *
  */
trait VDataTable {

  import com.sludg.vue.RenderHelpers._

  /**
    * @tparam A the type of items rendered in the table
    * @tparam H the type of header items used by the table. Should be either:
    *   - VDataTableHeader - if you are using vuetify's standard headers
    *   - Your own custom type - if you are overriding the `headers` scoped slot
    */
  def vDataTable[A, H] =
    namedTag[VDataTableProps[A, H], EventBindings, VDataTableScopedSlots[A, H]]("v-data-table")

}

object VDataTable extends VDataTable

trait VDataTableProps[A, B] extends VueProps {
  val dense: js.UndefOr[Boolean]                             = js.undefined
  val dark: js.UndefOr[Boolean]                              = js.undefined
  val `disable-initial-sort`: js.UndefOr[Boolean]            = js.undefined
  val expand: js.UndefOr[Boolean]                            = js.undefined
  val `header-key`: js.UndefOr[String]                       = js.undefined
  val `header-text`: js.UndefOr[String]                      = js.undefined
  val `headers-length`: js.UndefOr[Int]                      = js.undefined
  val `hide-actors`: js.UndefOr[Boolean]                     = js.undefined
  val `hide-headers`: js.UndefOr[Boolean]                    = js.undefined
  val `item-key`: js.UndefOr[String]                         = js.undefined
  val light: js.UndefOr[Boolean]                             = js.undefined
  val headers: js.UndefOr[List[B]]                           = js.undefined
  val items: js.UndefOr[List[A]]                             = js.undefined
  val `hide-actions`: js.UndefOr[Boolean]                    = js.undefined
  val `next-icon`: js.UndefOr[String]                        = js.undefined
  val `no-data-text`: js.UndefOr[String]                     = js.undefined
  val `prev-icon`: js.UndefOr[String]                        = js.undefined
  val pagination: js.UndefOr[js.Object]                      = js.undefined
  val `rows-per-page`: js.UndefOr[js.Array[Int | js.Object]] = js.undefined
  val search: js.UndefOr[String]                             = js.undefined
  val value: js.UndefOr[js.Array[js.Object]]                 = js.undefined
}

object VDataTableProps {

  import scala.scalajs.js.JSConverters._

  /**
    * In cases where you do not override the header scoped slot
    * you most likely want H to be VDataTableHeader type,
    * which is going to make the standard behaviors to kick in.
    */
  def apply[A, H](
      dense: Option[Boolean] = None,
      dark: Option[Boolean] = None,
      `disable-initial-sort`: Option[Boolean] = None,
      expand: Option[Boolean] = None,
      `header-key`: Option[String] = None,
      `header-text`: Option[String] = None,
      `header-length`: Option[Int] = None,
      `hide-actors`: Option[Boolean] = None,
      `hide-headers`: Option[Boolean] = None,
      `item-key`: Option[String] = None,
      light: Option[Boolean] = None,
      headers: Option[List[H]] = None,
      items: Option[List[A]] = None,
      `hide-actions`: Option[Boolean] = None,
      `next-icon`: Option[String] = None,
      `no-data-text`: Option[String] = None,
      `prev-icon`: Option[String] = None,
      pagination: Option[js.Object] = None,
      `rows-per-page`: Option[List[Either[Int, RowsPerPageItem]]] = None,
      search: Option[String] = None,
      value: Option[List[A]] = None
  ): VDataTableProps[A, H] = {

    DynamicHelper.buildViaDynamic(
      "dense"                -> dense.map(js.Any.fromBoolean).orUndefined,
      "dark"                 -> dark.map(js.Any.fromBoolean).orUndefined,
      "disable-initial-sort" -> `disable-initial-sort`.map(js.Any.fromBoolean).orUndefined,
      "expand"               -> dark.map(js.Any.fromBoolean).orUndefined,
      "header-key"           -> `header-key`.map(js.Any.fromString).orUndefined,
      "header-text"          -> `header-text`.map(js.Any.fromString).orUndefined,
      "hide-actors"          -> `hide-actors`.map(js.Any.fromBoolean).orUndefined,
      "hide-headers"         -> `hide-headers`.map(js.Any.fromBoolean).orUndefined,
      "item-key"             -> `item-key`.map(js.Any.fromString).orUndefined,
      "light"                -> light.map(js.Any.fromBoolean).orUndefined,
      "headers"              -> headers.map(_.toJSArray).orUndefined,
      "items"                -> items.map(_.toJSArray).orUndefined,
      "hide-actions"         -> `hide-actions`.map(js.Any.fromBoolean).orUndefined,
      "next-icon"            -> `next-icon`.map(js.Any.fromString).orUndefined,
      "no-data-text"         -> `no-data-text`.map(js.Any.fromString).orUndefined,
      "prev-icon"            -> `prev-icon`.map(js.Any.fromString).orUndefined,
      "pagination"           -> pagination.orUndefined,
      "rows-per-page" -> `rows-per-page`
        .map(_.toJSArray.map(_.fold(js.Any.fromInt, identity)))
        .orUndefined,
      "search" -> search.map(js.Any.fromString).orUndefined,
      "value"  -> value.map(_.toJSArray).orUndefined
    )
  }
}

class RowsPerPageItem(val text: String, val value: Int) extends js.Object

class VDataTableScopedSlots[A, H](
    val items: js.UndefOr[js.Function1[VDataTableItemSlotData[A], VNode]] = js.undefined,
    val headers: js.UndefOr[js.Function1[VDataTableHeadersSlotData[H], VNode]] = js.undefined
) extends ScopedSlots

trait VDataTableHeadersSlotData[H] extends js.Object {
  val headers: js.Array[H]
}

trait VDataTableItemSlotData[A] extends js.Object {
  val item: A
  val index: Int
  val selected: Boolean
  val expanded: Boolean
}

class VDataTableHeader(
    val text: js.UndefOr[String] = js.undefined,
    val value: js.UndefOr[String] = js.undefined,
    val align: js.UndefOr[String] = js.undefined,
    val sortable: js.UndefOr[Boolean] = js.undefined,
    val width: js.UndefOr[String] = js.undefined
) extends js.Object
