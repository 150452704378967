package com.sludg.vue

import org.scalajs.dom
import org.scalajs.dom.raw.Element

import scala.scalajs.js
import scala.scalajs.js.|
import com.sludg.FieldExtractor

/**
  * @author dpoliakas
  *         Date: 21/11/2018
  *         Time: 17:23
  *
  *         === Props ===
  *
  *         When it comes to declaring props I would recommend doing so via empty-paren defs as such:
  *         {{{
  *         class SomeComponentProps extends js.Object {
  *           def someValue: js.UndefOr[String] = js.undefined
  *         }
  *
  *         object SomeComponentProps {
  *           def apply(someValue: js.UndefOr[String]): SomeComponentProps = {
  *             js.Dynamic.literal(
  *               "someValue" -> someValue
  *             ).asInstanceOf[SomeComponentProps]
  *           }
  *         }
  *         }}}
  *
  *         While this may seem counter-intuitive, it has to be done so in order for it to work. This is due to the fact that
  *         vue converts the properties passed in as props into values obtainable via getters. Meanwhile, scala compiles
  *         empty paren method calls into getter calls, and val accesses into property reads. The conjunction of this fact
  *         causes scalajs to try and read the prop, which is defined as a getter by vue, as a property, rather than as a
  *         getter. And that causes one to get undefined errors.
  *
  *
  * @tparam P the type of the props of this component
  * @tparam S the type of the slots available on this component
  * @tparam SS the type of the scoped slots available on this component
  */
@js.native
trait VueComponent[P <: VueProps, S <: Slots, SS <: ScopedSlots] extends js.Object {

  import VueInstanceProperties._

  val data: js.UndefOr[js.Function0[Any]]                              = js.undefined
  val template: js.UndefOr[String]                                     = js.undefined
  val methods: js.UndefOr[_ <: js.Object]                              = js.undefined
  val computed: js.UndefOr[_ <: js.Object]                             = js.undefined
  val props: js.UndefOr[P]                                             = js.undefined
  val created: js.UndefOr[js.ThisFunction0[VueComponent[P, S, SS], _]] = js.undefined
  val render: js.UndefOr[js.ThisFunction1[VueComponent[P, S, SS], CreateElement, VNode]] =
    js.undefined
  val components: js.UndefOr[js.Object]                                = js.undefined
  val watch: js.UndefOr[js.Object]                                     = js.undefined
  val updated: js.UndefOr[js.ThisFunction0[VueComponent[P, S, SS], _]] = js.undefined

  val $el: Element       = js.native
  val $slots: S          = js.native
  val $scopedSlots: SS   = js.native
  val $router: VueRouter = js.native
  val $route: js.Object  = js.native
  val $root: Vue         = js.native

  def $emit(eventName: String, args: Any): js.Object = js.native

  val $event = js.native
}

object VueComponent {
  def builder
      : VueComponentBuilder[js.Object, js.Object, js.Object, VueProps, Slots, ScopedSlots, Refs] =
    new VueComponentBuilder[js.Object, js.Object, js.Object, VueProps, Slots, ScopedSlots, Refs]
}

/**
  * We use the builder to effectively set sensible defaults to the generics specified on the class.
  *
  * Unfortunately we cannot rely on type inference for this. When using a type bound of {{{_ <: js.Object}}}, if not
  * explicitly specified the compiler would infer {{{Nothing}}}. This seems to occasionally cause problems.
  *
  * In order to bypass the above problems we use this builder as sort of a "sensible defaults" kind of a thing
  *
  */
class VueComponentBuilder[
    D <: js.Object,
    M <: js.Object,
    C <: js.Object,
    P <: VueProps,
    S <: Slots,
    SS <: ScopedSlots,
    R <: Refs
](
    data: js.UndefOr[js.Function0[D]] = js.undefined,
    methods: js.UndefOr[M] = js.undefined,
    computed: js.UndefOr[C] = js.undefined,
    props: js.UndefOr[P | js.Array[String]] = js.undefined
) {

  import VueInstanceProperties._
  import scala.scalajs.js.JSConverters._

  def build(
      watch: js.UndefOr[js.Object] = js.undefined,
      components: js.UndefOr[js.Object] = js.undefined,
      created: ComponentCallback[D, M, C, P, S, SS, R] = (js.undefined: js.UndefOr[js.ThisFunction0[
        VueComponent[_ <: VueProps, _ <: S, _ <: SS] with D with M with C with P with WithRefs[
          _ <: R
        ],
        Unit
      ]]),
      updated: ComponentCallback[D, M, C, P, S, SS, R] = (js.undefined: js.UndefOr[js.ThisFunction0[
        VueComponent[_ <: VueProps, _ <: S, _ <: SS] with D with M with C with P with WithRefs[
          _ <: R
        ],
        Unit
      ]]),
      mounted: ComponentCallback[D, M, C, P, S, SS, R] = (js.undefined: js.UndefOr[js.ThisFunction0[
        VueComponent[_ <: VueProps, _ <: S, _ <: SS] with D with M with C with P with WithRefs[
          _ <: R
        ],
        Unit
      ]]),
      destroyed: ComponentCallback[D, M, C, P, S, SS, R] =
        (js.undefined: js.UndefOr[js.ThisFunction0[
          VueComponent[_ <: VueProps, _ <: S, _ <: SS] with D with M with C with P with WithRefs[
            _ <: R
          ],
          Unit
        ]]),
      templateOrRender: Either[Template, RenderFunction[D, M, C, P, S, SS, R]] = Left(
        js.undefined: js.UndefOr[String]
      )
  ): VueComponent[_ <: P, _ <: S, _ <: SS] = {
    List[(String, js.UndefOr[js.Any])](
      "data"       -> data,
      "template"   -> templateOrRender.left.toOption.getOrElse(js.undefined).map(js.Any.fromString),
      "props"      -> props,
      "watch"      -> watch,
      "methods"    -> methods,
      "computed"   -> computed,
      "created"    -> created,
      "updated"    -> updated,
      "mounted"    -> mounted,
      "destroyed"  -> destroyed,
      "render"     -> templateOrRender.toOption.orUndefined,
      "components" -> components
    ).foldRight(js.Dynamic.literal()) {
        case ((key, prop), obj) =>
          if (prop.isDefined) obj.updateDynamic(key)(prop.asInstanceOf[js.Any])
          obj
      }
      .asInstanceOf[VueComponent[P, S, SS]]
  }

  def withSlots[NS <: Slots]: VueComponentBuilder[D, M, C, P, NS, SS, R] =
    new VueComponentBuilder[D, M, C, P, NS, SS, R](
      data = data,
      methods = methods,
      computed = computed,
      props = props
    )

  def withScopedSlots[NS <: ScopedSlots]: VueComponentBuilder[D, M, C, P, S, NS, R] =
    new VueComponentBuilder[D, M, C, P, S, NS, R](
      data = data,
      methods = methods,
      computed = computed,
      props = props
    )

  def withRefs[NR <: Refs]: VueComponentBuilder[D, M, C, P, S, SS, NR] =
    new VueComponentBuilder[D, M, C, P, S, SS, NR](
      data = data,
      methods = methods,
      computed = computed,
      props = props
    )

  def withData[ND <: js.Object](newData: => ND): VueComponentBuilder[ND, M, C, P, S, SS, R] =
    new VueComponentBuilder[ND, M, C, P, S, SS, R](
      data = js.defined((() => newData): js.Function0[ND]),
      methods = methods,
      computed = computed,
      props = props
    )

  def withMethods[NM <: js.Object](newMethods: NM): VueComponentBuilder[D, NM, C, P, S, SS, R] =
    new VueComponentBuilder[D, NM, C, P, S, SS, R](
      data = data,
      methods = js.defined(newMethods),
      computed = computed,
      props = props
    )

  def withComputed[NC <: js.Object](newComputed: NC): VueComponentBuilder[D, M, NC, P, S, SS, R] =
    new VueComponentBuilder[D, M, NC, P, S, SS, R](
      data = data,
      methods = methods,
      computed = js.defined(newComputed),
      props = props
    )

  def withProps[NP <: VueProps](newProps: NP): VueComponentBuilder[D, M, C, NP, S, SS, R] =
    new VueComponentBuilder[D, M, C, NP, S, SS, R](
      data = data,
      methods = methods,
      computed = computed,
      props = js.defined(newProps)
    )

  /**
    * Declare the props of this component to be of type NP.
    *
    * This method uses a FieldExtractor to include all
    * the vals of the type into the props.
    *
    * When props are declared via this method, you will be able to use
    * all the vals on the prop type NP in your other vue component methods,
    * e.g. your render function.
    *
    * Registering props this way does not require an instance of NP and therefore
    * might be preferable to [[withProps]] sometimes.
    *
    * It uses the prop declaration via a js.Array[String]. See vue
    * prop declaration docs for more info.
    *
    * NOTE: Currently there appears to be a problem with implicit
    * resolution when using this method. Normally, when an implicit
    * of type `F[A]` is declared, the companion objects of `F` and `A`
    * are searched. However, at the moment this seems to be broken for this implicit.
    * FieldExtrator companion does define an implicit FieldExtrator for any type,
    * but it does not seem to be detected unless the
    * field extractor type is visible in the current scope, e.g.
    * import com.sludg.FieldExtractor
    */
  def withPropsAs[NP <: VueProps](
      implicit f: FieldExtractor[NP]
  ): VueComponentBuilder[D, M, C, NP, S, SS, R] =
    new VueComponentBuilder[D, M, C, NP, S, SS, R](
      data = data,
      methods = methods,
      computed = computed,
      props = js.defined(f.fields.toJSArray)
    )

}
@js.native
trait Slots extends js.Object {
  val default: js.Array[VNode]
}

trait ScopedSlots extends js.Object

/**
  * VueProps is really just a tag on js.Object that indicates that a certain js.Object subtype is used as a props object
  * for a vue component.
  *
  * This is to prevent certain funky situations associated with using a supertype as generic as js.Object is. E.g. if your
  * component leaves it's props blank (i.e. as a js.Object), and you try to pass in a RenderOptions instance (which extends
  * js.Object), that RenderOptions instance might get mistaken as a props instance because implicits.
  *
  */
trait VueProps extends js.Object

/**
  * Extend this trait to define refs for a component.
  *
  * Members of this class should only be either Element from scalajs-dom or [[VueComponent]], as per Vue rules
  *
  * You still have to assign the refs via the [[RenderOptions.ref]] value. The value of the ref render option should
  * be equal to the field on the extension of this trait.
  */
trait Refs extends js.Object

@js.native
sealed trait WithRefs[R <: Refs] extends js.Object {
  val $refs: R = js.native
}
