package com.sludg

import java.util.Base64

import com.sludg.auth0._
import org.scalajs.dom
import org.scalajs.dom.ext.{SessionStorage, Storage}
import org.scalajs.dom.raw.Window

import scala.concurrent.{ExecutionContext, Future, Promise}
import scala.scalajs.js
import scala.scalajs.js.{Date, JSON}

/**
  * @author dpoliakas
  *         Date: 13/11/2018
  *         Time: 14:43
  *
  *         This can be used for Logging in/out of Auth0, getting the token, refreshing the token, etc.
  *
  *         One thing to note is that this does not verify the validity of the JWT, but it does use the values
  *         from the JWT. So any meddling with the storage of the JWT will cause this class to misbehave.
  *         It trusts that any backend for which this token is used is resilient against such meddling.
  *
  */
sealed class Security(
    tokenInternal: Security.Token,
    expiration: Security.Expiration,
    auth0Config: Auth0Config,
    webAuth: WebAuth,
    sessionStorage: Storage
) {

  private implicit val sessionStorageImpl = sessionStorage
  Security.setSessionToken(tokenInternal, expiration)

  var token = tokenInternal

  def logout(federated: Boolean = true) = {
    Security.clearSession
    webAuth.logout(
      LogoutProps(
        returnTo = Some(auth0Config.redirectUri),
        clientID = Some(auth0Config.clientID),
        federated =
          Some(s"https://${auth0Config.domain}/v2/logout${if (federated) "?federated" else ""}")
      )
    )
  }

  //Refreshes the token using auth0 checkSession
  def checkSession(): Future[Option[ResponseToken]] = {
    val promeece = Promise[Option[ResponseToken]]()
    webAuth.checkSession(
      js.defined(
        CheckSessionProps(
          clientID = Some(auth0Config.clientID),
          responseType = Some("token"),
          None,
          None,
          None,
          audience = Some(auth0Config.audience),
          timeout = None
        )
      ),
      cb = (error, jsTokenObject) => {
        if (jsTokenObject == null || jsTokenObject.isEmpty) {
          promeece.success(None)
        } else {
          val accessToken = jsTokenObject.get.accessToken
          val tokenExp    = Security.getExpirationInMillis(accessToken)
          Security.setSessionToken(accessToken, tokenExp)
          token = accessToken
          promeece.success(jsTokenObject.toOption)
        }
      }
    )
    promeece.future
  }
}

case class Auth0Config(domain: String, clientID: String, audience: String, redirectUri: String)

object Security {

  type Token      = String
  type Expiration = Long

  sealed trait Error

  /**
    * An error returned when the user is not logged in.
    * In addition to the error an action that redirects the user to login is returned
    *
    * @param redirectToLogin
    */
  case class NotLoggedIn(redirectToLogin: Runnable) extends Error

  @js.native
  trait JWTBody extends js.Object {
    val exp: Int = js.native
  }

  /**
    * This will return either an error, if security could not be initialised,
    * or a Security object
    *
    * @return
    */
  def initialise(
      auth0Config: Auth0Config,
      windowHash: Option[String] = None,
      consumeAccessTokenHash: Runnable = () => pushWindowStateRemovingHash(org.scalajs.dom.window),
      sessionStorage: Storage = SessionStorage
  )(implicit ec: ExecutionContext): Future[Either[Error, Security]] = {
    println("Very secure website process initiation protocol: INITIATED")

    implicit val sessionStorageImpl: Storage = sessionStorage
    implicit val webAuth: WebAuth = new WebAuth(
      WebAuthProps(
        domain = auth0Config.domain,
        clientID = auth0Config.clientID,
        audience = Some(auth0Config.audience),
        responseType = Some("token"),
        redirectUri = Some(auth0Config.redirectUri)
      )
    )

    getToken(windowHash, consumeAccessTokenHash)
      .map(_.toRight(NotLoggedIn(() => webAuth.authorize(None))))
      .map(_.map {
        case (token, expiration) =>
          new Security(token, expiration, auth0Config, webAuth, sessionStorage)
      })

  }

  /**
    * This method removes the hash section from the current window URL and pushes window state to the url without the
    * hash
    */
  def pushWindowStateRemovingHash(window: Window): Unit = {
    val p = window.location.pathname
    window.history.pushState("", "", p)
  }

  /**
    * Finds the token to use.
    * If the token is expired, returns none.
    *
    * @param consumeAccessTokenHash this may be run to consume the access token hash if the access token hash was found
    *                               and parsed
    */
  def getToken(windowHash: Option[String], consumeAccessTokenHash: Runnable)(
      implicit webAuth: WebAuth,
      storage: Storage,
      ec: ExecutionContext
  ): Future[Option[(Token, Expiration)]] = {

    def notExpired: ((_, Expiration)) => Boolean = checkNotExpired.compose(_._2)

    sessionToken
      .map(a => {
        println("Found an existing token")
        a
      })
      .filter(notExpired) match {
      case a: Some[(Token, Expiration)] =>
        println("Existing token verified and non-expired")
        Future.successful(a)
      case None =>
        println("No valid existing token found. Trying to obtain one.")
        parseHash(windowHash, consumeAccessTokenHash).map(_.map(extractInfo).filter(notExpired))
    }
  }

  def checkNotExpired: Expiration => Boolean = isExpired _ andThen (!_)

  def isExpired(l: Long) = {
    val currentTime = new Date().getTime()
    val expired     = l < currentTime
    println(s"Current time is: ${new Date().toISOString()} ($currentTime)")
    println(s"Expiration time is: ${new Date(l.toDouble).toISOString()} ($l)")
    println(s"Is expired: $expired")
    expired
  }

  def getExpirationInMillis(token: String): Long = {
    val e = JSON
      .parse(new String(Base64.getDecoder.decode(token.split("\\.")(1))))
      .asInstanceOf[JWTBody]
      .exp
      .toLong * 1000
    println(s"Expiration of token: $e")
    println(s"As time: ${new Date(e.toDouble).toISOString()}")
    e
  }

  /**
    * Gets the token from the session.
    */
  def sessionToken(implicit sessionStorage: Storage): Option[(Token, Expiration)] = {
    for {
      token      <- sessionStorage("token")
      expiration <- sessionStorage("expires_at")
    } yield token -> expiration.toLong
  }

  def setSessionToken(token: Token, expiration: Expiration)(implicit sessionStorage: Storage) = {
    sessionStorage.update("token", token)
    sessionStorage.update("expires_at", expiration.toString)
  }

  def clearSession(implicit sessionStorage: Storage) = {
    sessionStorage.remove("token")
    sessionStorage.remove("expires_at")
  }

  /**
    * Parses the URL to look for the access token.
    * The access token can be found in the URL when auth0 redirects the user after successful authentication.
    * If the access token hash is found consumeAccessTokenHash will be run with the expectation that the window hash
    * will be removed.
    *
    * @param consumeAccessTokenHash this will be run if the access token hash is found on the URL (or the windowHash
    *                               property if it is specified)
    * @param windowHash             if this is some value, then it will be parsed instead of the URL by auth0
    * @param webAuth                the webAuth instance with the client details, etc.
    * @return a future the value of which is either Auth0Result if the hash was detected or none otherwise
    */
  def parseHash(windowHash: Option[String], consumeAccessTokenHash: Runnable)(
      implicit webAuth: WebAuth
  ): Future[Option[ResponseToken]] = {
    val tokenPromise = Promise[Option[ResponseToken]]()
    webAuth.parseHash(
      HashProps(windowHash),
      (error, token) => {
        if (error == null && token == null) {
          tokenPromise.success(None)
        } else if (token.isDefined) {
          consumeAccessTokenHash.run()
          tokenPromise.success(token.toOption)
        } else {
          val (errorKey, errorValue) =
            error.map(e => e.error -> e.errorDescription).getOrElse("Unknown" -> "Unknown")
          println(s"""The token failed to be obtained
             |Error: $errorKey
             |Error description: $errorValue""".stripMargin)
          tokenPromise.success(None)
        }
      }
    )
    tokenPromise.future
  }

  def extractInfo(auth0Result: ResponseToken): (Token, Expiration) = {
    auth0Result.accessToken -> (new Date().getTime() + (auth0Result.expiresIn * 1000)).toLong
  }

}
