package com.sludg.vuetify.components

import com.sludg.scalajs.DynamicHelper
import com.sludg.vue.{EventBindings, ScopedSlots, VueProps}

import scala.scalajs.js

import scala.scalajs.js.|

/**
  * @author Samir :3
  *         Date: 1/03/2019
  *         Time: 10:50
  *
  *
  */
trait VExpansionPanelContent {

  import com.sludg.vue.RenderHelpers._

  val vExpansionPanelContent =
    namedTag[VExpansionPanelContentProps, EventBindings, ScopedSlots]("v-expansion-panel-content")
}

trait VExpansionPanelContentProps extends VueProps {

  val disabled: js.UndefOr[Boolean]           = js.undefined
  val `expand-icon`: js.UndefOr[String]       = js.undefined
  val `hide-actions`: js.UndefOr[Boolean]     = js.undefined
  val `lazy`: js.UndefOr[Boolean]             = js.undefined
  val readonly: js.UndefOr[Boolean]           = js.undefined
  val ripple: js.UndefOr[Boolean | js.Object] = js.undefined
  val value: js.UndefOr[js.Any]               = js.undefined
}

object VExpansionPanelContentProps {

  import scala.scalajs.js.JSConverters._

  def apply(
      disabled: Option[Boolean] = None,
      `expand-icon`: Option[String] = None,
      `hide-actions`: Option[Boolean] = None,
      `lazy`: Option[Boolean] = None,
      readonly: Option[Boolean] = None,
      ripple: Option[Either[Boolean, js.Object]] = None,
      value: Option[js.Any] = None
  ): VExpansionPanelContentProps = {

    DynamicHelper.buildViaDynamic(
      "disabled"     -> disabled.map(js.Any.fromBoolean).orUndefined,
      "expand-icon"  -> `expand-icon`.map(js.Any.fromString).orUndefined,
      "hide-actions" -> `hide-actions`.map(js.Any.fromBoolean).orUndefined,
      "lazy"         -> `lazy`.map(js.Any.fromBoolean).orUndefined,
      "readonly"     -> readonly.map(js.Any.fromBoolean).orUndefined,
      "ripple"       -> ripple.map(_.fold(js.Any.fromBoolean, identity)).orUndefined,
      "value"        -> value.orUndefined
    )
  }
}
