package com.sludg.vuetify.components.grid

import com.sludg.vue.{EventBindings, ScopedSlots}

trait VLayout {

  import com.sludg.vue.RenderHelpers._

  val vLayout = namedTag[VGridProps, EventBindings, ScopedSlots]("v-layout")

}

object VLayout extends VLayout
