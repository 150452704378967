package com.sludg.vue.rendering

import com.sludg.vue.{EventBindings, ScopedSlots, TemplatingHelpers, VueProps}

/**
  * @author dpoliakas
  *         Date: 22/11/2018
  *         Time: 18:50
  *
  *
  */
trait Headings {
  this: TemplatingHelpers =>
  val h1 = namedTag[VueProps, EventBindings, ScopedSlots]("h1")
  val h2 = namedTag[VueProps, EventBindings, ScopedSlots]("h2")
  val h3 = namedTag[VueProps, EventBindings, ScopedSlots]("h3")
  val h4 = namedTag[VueProps, EventBindings, ScopedSlots]("h4")
  val h5 = namedTag[VueProps, EventBindings, ScopedSlots]("h5")
}
