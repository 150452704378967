package com.sludg.vuetify.components.grid

import com.sludg.scalajs.DynamicHelper
import com.sludg.vue.VueProps

import scala.scalajs.js

/**
  * @author dpoliakas
  *         Date: 2019-02-20
  *         Time: 16:55
  *
  *         Both VContainer and VLayout have the same props. This is the class for that.
  *
  */
trait VGridProps extends VueProps {
  val `align-baseline`: js.UndefOr[Boolean]
  val `align-center`: js.UndefOr[Boolean]
  val `align-content-center`: js.UndefOr[Boolean]
  val `align-content-end`: js.UndefOr[Boolean]
  val `align-content-space-around`: js.UndefOr[Boolean]
  val `align-content-space-between`: js.UndefOr[Boolean]
  val `align-content-start`: js.UndefOr[Boolean]
  val `align-start`: js.UndefOr[Boolean]
  val `align-end`: js.UndefOr[Boolean]

  val `justify-center`: js.UndefOr[Boolean]        = js.undefined
  val `justify-end`: js.UndefOr[Boolean]           = js.undefined
  val `justify-space-around`: js.UndefOr[Boolean]  = js.undefined
  val `justify-space-between`: js.UndefOr[Boolean] = js.undefined
  val `justify-start`: js.UndefOr[Boolean]         = js.undefined

  val fluid: js.UndefOr[Boolean] = js.undefined
  val tag: js.UndefOr[String]    = js.undefined
  val id: js.UndefOr[String]     = js.undefined

  val `grid-list-xs`: js.UndefOr[Boolean] = js.undefined
  val `grid-list-sm`: js.UndefOr[Boolean] = js.undefined
  val `grid-list-md`: js.UndefOr[Boolean] = js.undefined
  val `grid-list-lg`: js.UndefOr[Boolean] = js.undefined
  val `grid-list-xl`: js.UndefOr[Boolean] = js.undefined

  val `d-flex`: js.UndefOr[Boolean]        = js.undefined
  val `d-inline-flex`: js.UndefOr[Boolean] = js.undefined
  val `d-block`: js.UndefOr[Boolean]       = js.undefined
  // TODO the above list of `d-{type}`s is probably not exhaustive

  val reverse: js.UndefOr[Boolean] = js.undefined
  val row: js.UndefOr[Boolean]     = js.undefined
  val wrap: js.UndefOr[Boolean]    = js.undefined

}

object VGridProps {

  import scala.scalajs.js.JSConverters._
  import VGridPropValues._

  def apply(
      fluid: Boolean = false,
      alignment: Option[Alignment] = None,
      justification: Option[Justification] = None,
      tag: Option[String] = None,
      gridList: Option[GridList] = None,
      id: Option[String] = None,
      displayType: Option[DisplayType] = None,
      reverse: Option[Boolean] = None,
      row: Option[Boolean] = None,
      wrap: Option[Boolean] = None
  ): VGridProps = {

    def maybeAdd(props: js.Dynamic)(prop: Option[StringableProp]) =
      prop.foreach(v => props.updateDynamic(v.stringName)(true))

    val props = js.Dynamic.literal(
      "fluid" -> fluid
    )

    val madd = maybeAdd(props) _

    madd(alignment)
    madd(justification)
    madd(gridList)
    madd(displayType)

    DynamicHelper.addToDynamicIfDefined(
      props,
      "id"      -> id.map(js.Any.fromString).orUndefined,
      "tag"     -> tag.map(js.Any.fromString).orUndefined,
      "reverse" -> reverse.map(js.Any.fromBoolean).orUndefined,
      "row"     -> row.map(js.Any.fromBoolean).orUndefined,
      "wrap"    -> wrap.map(js.Any.fromBoolean).orUndefined
    )

    props.asInstanceOf[VGridProps]
  }

}

trait VGridPropValues {

  import VGridPropValues._

  case object `d-flex` extends DisplayType {
    override val stringName: String = "d-flex"
  }

  case object `d-inline-flex` extends DisplayType {
    override val stringName: String = "d-inline-flex"
  }

  case object `d-block` extends DisplayType {
    override val stringName: String = "d-block"
  }

  case object `grid-list-xs` extends GridList {
    override val stringName: String = "grid-list-xs"
  }

  case object `grid-list-sm` extends GridList {
    override val stringName: String = "grid-list-sm"
  }

  case object `grid-list-md` extends GridList {
    override val stringName: String = "grid-list-md"
  }

  case object `grid-list-lg` extends GridList {
    override val stringName: String = "grid-list-lg"
  }

  case object `grid-list-xl` extends GridList {
    override val stringName: String = "grid-list-xl"
  }

  case object `justify-center` extends Justification {
    override val stringName: String = "justify-center"
  }

  case object `justify-end` extends Justification {
    override val stringName: String = "justify-end"
  }

  case object `justify-space-around` extends Justification {
    override val stringName: String = "justify-space-around"
  }

  case object `justify-space-between` extends Justification {
    override val stringName: String = "justify-space-between"
  }

  case object `justify-start` extends Justification {
    override val stringName: String = "justify-start"
  }

  case object `align-baseline` extends Alignment {
    override val stringName: String = "align-baseline"
  }

  case object `align-center` extends Alignment {
    override val stringName: String = "align-center"
  }

  case object `align-content-center` extends Alignment {
    override val stringName: String = "align-content-center"
  }

  case object `align-content-end` extends Alignment {
    override val stringName: String = "align-content-end"
  }

  case object `align-content-space-around` extends Alignment {
    override val stringName: String = "align-content-space-around"
  }

  case object `align-content-space-between` extends Alignment {
    override val stringName: String = "align-content-space-between"
  }

  case object `align-content-start` extends Alignment {
    override val stringName: String = "align-content-start"
  }

  case object `align-start` extends Alignment {
    override val stringName: String = "align-start"
  }

  case object `align-end` extends Alignment {
    override val stringName: String = "align-end"
  }

}

object VGridPropValues extends VGridPropValues {

  trait StringableProp {
    val stringName: String
  }

  sealed trait DisplayType   extends StringableProp
  sealed trait GridList      extends StringableProp
  sealed trait Justification extends StringableProp
  sealed trait Alignment     extends StringableProp

}
