package com.sludg.client.pages.components.roles

import com.sludg.FieldExtractor
import cats.data.EitherT
import cats.implicits._
import com.sludg.Security
import com.sludg.auth0.SludgToken
import com.sludg.client.pages.components.roles.RolesTable.{RoleTableType, TableItems}
import com.sludg.helpers.LoadingFuture
import com.sludg.models.Config.AppLink
import com.sludg.scalajs.DynamicHelper
import com.sludg.services.ApiCalls
import com.sludg.util.json.RoleJsonDeserializers._
import com.sludg.util.models.RoleModels.Role
import com.sludg.util.models.RoleModels.Role.{AnalyticsAdmin, DashboardAdmin}
import com.sludg.vue.RenderHelpers._
import com.sludg.vue.VueInstanceProperties.CreateElement
import com.sludg.vue.{EventBindings, RenderHelpers, _}
import com.sludg.vuetify.VuetifyComponents.{vCard, vCardTitle, vCheckBox, vDivider, vSpacer, vTextField}
import com.sludg.vuetify.components._
import monix.execution.Scheduler.Implicits.global
import org.log4s.{Logger, getLogger}
import play.api.libs.json.{JsValue => _, Reads => _, _}
import com.sludg.vue.RenderHelpers._

import scala.collection.immutable.List
import scala.scalajs.js
import scala.scalajs.js.JSConverters._

object RolesTable {
  type RoleTableType = VueComponent[_ <: RolesTableProps, _ <: Slots, _ <: ScopedSlots] with RolesTableData with js.Object with RolesTableProps
  val logger: Logger = getLogger

  def RolesTableRenderer(registrationName: String): RenderHelpers.NodeRenderer[RolesTableProps, EventBindings, ScopedSlots] = namedTag[RolesTableProps, EventBindings, ScopedSlots](registrationName)

  def rolesTableComponent(apiCalls: ApiCalls, security: Security, loader: Vue, otherApps: List[AppLink])(
      implicit token: SludgToken
  ): VueComponent[_ <: RolesTableProps, _ <: Slots, _ <: ScopedSlots] = {
    VueComponent.builder
      .withData(new RolesTableData())
      .withPropsAs[RolesTableProps]
      .build(
        created = js.defined(c => {
          c.a = Some(apiCalls)
          c.l = Some(loader)
          c.t = Some(token)
          reloadData(c.tenantId, apiCalls, loader, token, c)
        }),
        watch = new EventsWatcher(),
        templateOrRender = Right((r, renderer) => loadGui(r.tenantId, apiCalls, loader, token, renderer, r).render(renderer))
      )
  }

  class TableItems(
      val subscriberID: Option[Int] = None,
      val extension: Option[String] = None,
      val firstName: Option[String] = None,
      val lastName: Option[String] = None,
      val dashboards: Option[Boolean] = None,
      val analytics: Option[Boolean] = None,
      val userType: Option[String] = None
  ) extends js.Object

  def dataTable(renderer: CreateElement, loader: Vue, apiCalls: ApiCalls, token: SludgToken, tenantId: Int, r: RoleTableType): RenderFunction[VNode] = {
    vCard(
      RenderOptions(style = Some(js.Dynamic.literal("margin-top" -> "25px"))),
      div(
        List(
          vCardTitle(
            RenderOptions(`class` = List(Left("title"))),
            "User privileges",
            vSpacer,
            vTextField(
              RenderOptions(
                style = Some(js.Dynamic.literal("margin-top" -> "-5px")),
                props = Some(
                  VTextFieldProps(
                    label = Some("Search..."),
                    `single-line` = Some(true),
                    `append-icon` = Some("search")
                  )
                ),
                on = Some(EventBindings(input = js.defined(e => r.tableItemsFilter = if (js.isUndefined(e)) "" else e.toString)))
              )
            )
          ),
          vDivider,
          VDataTable.vDataTable(
            RenderOptions[VDataTableProps[TableItems, VDataTableHeader], EventBindings, VDataTableScopedSlots[TableItems, VDataTableHeader]](
              style = Some(js.Dynamic.literal("height" -> "100%")),
              props = Some(
                VDataTableProps[TableItems, VDataTableHeader](
                  `disable-initial-sort` = Some(true),
                  items = Some(r.tableItems),
                  light = Some(false),
                  search = Some(r.tableItemsFilter),
                  `rows-per-page` = Some(List(Left(25))),
                  dense = Some(true),
                  headers = Some(
                    List(
                      new VDataTableHeader(sortable = true, value = "extension", text = "Extension"),
                      new VDataTableHeader(sortable = true, value = "firstName", text = "First Name"),
                      new VDataTableHeader(sortable = true, value = "lastName", text = "Last Name"),
                      new VDataTableHeader(sortable = true, value = "dashboards", text = "Dashboards"),
                      new VDataTableHeader(sortable = true, value = "analytics", text = "Analytics"),
                      new VDataTableHeader(sortable = true, value = "userType", text = "User Type")
                    )
                  )
                )
              ),
              scopedSlots = Some(
                new VDataTableScopedSlots[TableItems, VDataTableHeader](
                  js.defined(i => {
                    def isDash(granted: Option[Boolean]): Option[String] = if(granted.getOrElse(i.item.dashboards.getOrElse(false))) { Some("Dashboard_Admin") } else { None }
                    def isAnal(granted: Option[Boolean]): Option[String] = if(granted.getOrElse(i.item.analytics.getOrElse(false))) { Some("Analytics_Admin") } else { None }
                    def getAppAccess(dash: Option[Boolean] = None, anal: Option[Boolean] = None): List[String] = List(isDash(dash), isAnal(anal)).flatten

                    tr(
                      td(i.item.extension.getOrElse("-").toString),
                      td(i.item.firstName.getOrElse("-").toString),
                      td(i.item.lastName.getOrElse("-").toString),
                      td(
                        vCheckBox(
                          RenderOptions(
                            style = Some(js.Dynamic.literal("position" -> "relative", "bottom" -> "-12px")),
                            props = Some(VCheckBoxProps(
                              disabled = Some(i.item.userType.contains("Administrator")),
                              `input-value` = Some((i.item.userType.contains("Administrator") || i.item.dashboards.getOrElse(false)).asInstanceOf[js.Any]))),
                            on = Some(EventBindings(change = js.defined(e => {

                              val bool = e.asInstanceOf[Boolean]
                              i.item.subscriberID match {
                                case None => logger.debug("No subscriber id found.")
                                case Some(id) =>
                                  LoadingFuture.withLoading(loader, apiCalls.updateUserRoles(id, tenantId, getAppAccess(dash = Some(bool)))(implicitly, token))
                              }
                            })))
                          )
                        )
                      ),
                      td(
                        vCheckBox(
                          RenderOptions(
                            style = Some(js.Dynamic.literal("position" -> "relative", "bottom" -> "-12px")),
                            props = Some(VCheckBoxProps(
                              disabled = Some(i.item.userType.contains("Administrator")),
                              `input-value` = {Some((i.item.userType.contains("Administrator") || i.item.analytics.getOrElse(false)).asInstanceOf[js.Any])
                              })),
                            on = Some(EventBindings(change = js.defined(e => {

                              val bool = e.asInstanceOf[Boolean]
                              i.item.subscriberID match {
                                case None => logger.debug("No subscriber id found.")
                                case Some(id) =>
                                  LoadingFuture.withLoading(loader, apiCalls.updateUserRoles(id, tenantId, getAppAccess(anal = Some(bool)))(implicitly, token))
                              }
                            })))
                          )
                        )
                      ),
                      td(i.item.userType.getOrElse("-").toString)
                    ).render(renderer)
                  })
                  //Displaying the dashboards icon beside the header title "dashboards" broke the sorting

//                  headers = js.defined(props => {
//                    tr(
//                      props.headers.map(header => {
//                        th(
//                          RenderOptions(
//                            props = Some(js.Dynamic.literal("key" -> header.text.get.asInstanceOf[js.Any]).asInstanceOf[VueProps]),
//                            style = Some(js.Dynamic.literal("white-space" -> "normal")),
//                            `class` = List(Left("column"), Left("sortable")) ::: List(Left("active"))),
//                          vLayout(
//
//                            vFlex(vIcon("arrow_upward")),
//                            vFlex(
//                              vLayout(
//                                (if (header.text.getOrElse("").contains("Dashboards")) vIcon("dashboard") else vIcon()),
//                                p(header.text.getOrElse("").toString())
//                              )
//                            )
//                          )
//                        )
//                      })
//                    ).render(renderer)
//                  })
                )
              )
            )
          )
        )
      )
    )
  }

  def loadGui(tid: Option[Int], apiCalls: ApiCalls, loader: Vue, token: SludgToken, renderer: CreateElement, r: RoleTableType): RenderHelpers.NodeRenderer[VueProps, EventBindings, ScopedSlots] = {
    div(
      tid match {
        case Some(tid) => div(dataTable(renderer, loader, apiCalls, token, tid, r))
        case None => {
          logger.info("No tenant selected to load GUI.")
          div()
        }
      }
    )
  }

  /**
    *
    * @param tid                                    Tenant id for reloading the data
    * @param apiCalls                               Reference to instance of Api calls
    * @param loader                                 Reference to instance of loader for the loading bar
    * @param token                                  Token used to api calls
    * @param c                                      r
    */
  def reloadData(tid: Option[Int], apiCalls: ApiCalls, loader: Vue, token: SludgToken, c: RoleTableType): Unit = {
    tid match {
      case Some(tid) =>
        LoadingFuture.withLoading(
          loader,
          (for {
            subscribers <- EitherT(apiCalls.getSubscribers(tid)(implicitly, token))
            callStats   <- EitherT(apiCalls.getUserRolesOnTenant(tid)(implicitly, token))
          } yield {

            val rolesMap: Map[Int, List[Role]] = callStats.map(a => a.subscriber.subscriberId -> a.roles).toMap

            c.tableItems = subscribers
              .map(a =>
                new TableItems(
                  Some(a.subscriberId),
                  Some(a.extension),
                  a.firstName,
                  Some(a.lastName),
                  Some(rolesMap.getOrElse(a.subscriberId, List()).contains(DashboardAdmin)),
                  Some(rolesMap.getOrElse(a.subscriberId, List()).contains(AnalyticsAdmin)),
                  Some((if (a.admin) "Administrator" else "Standard")),
                )
              )
              .sortBy(_.extension.getOrElse(""))
          }).value
        )
      case None => logger.info("No tenant selected.")
    }
  }

}

class EventsWatcher() extends js.Object {

  /**
    * @param newTenant                                      The new tenant value
    * @param oldValue                                       The old tenant value
    *
    * Watches the tenant selector change. If it changes, it updates the data on the table.
    *
    */
  def tenantId(newTenant: Option[Int], oldValue: Option[Int]): Unit = {
    val r = this.asInstanceOf[RoleTableType]
    if (newTenant != oldValue) {

      (r.a, r.l, r.t) match {
        case (Some(a), Some(l), Some(token)) => {
          r.tableItems = Nil
          RolesTable.reloadData(newTenant, a, l, token, r)
        }
        case _ => println("Data missing for reload.")
      }
    }
  }
}

class RolesTableData extends js.Object {
  var tableItems: List[TableItems] = Nil
  var tableItemsFilter: String     = ""

  var a: Option[ApiCalls]   = None
  var l: Option[Vue]        = None
  var t: Option[SludgToken] = None
}

class RolesTableProps(val tenantId: Option[Int]) extends VueProps
