package com.sludg.helpers

import cats.data.EitherT
import com.sludg.models.Config.{AppLink, DevState}
import com.sludg.models.Models
import com.sludg.models.Models.{AccessForbidden, SubscriberWithAdminStatus}
import com.sludg.services.ApiCalls
import com.sludg.util.models.SilhouetteModels.Tenant
import com.sludg.vue.RenderHelpers.{div, namedTag, nothing, _}
import com.sludg.vue.{
  EventBindings,
  Refs,
  RenderHelpers,
  RenderOptions,
  ScopedSlots,
  Slots,
  VNode,
  VueComponent,
  VueProps,
  _
}
import com.sludg.vuetify.VuetifyComponents._
import com.sludg.vuetify.components._
import monix.execution.Scheduler
import org.scalajs.dom.{Event, document}
import org.scalajs.dom.html.Input
import org.scalajs.dom.raw.Node
import cats.implicits._
import com.sludg.auth0.SludgToken
import com.sludg.components.Snackbar.SnackBarMessage
import com.sludg.components.VTSLApp.VTSLAppType
import com.sludg.models.Config.DevState.{Alpha, Beta, Prod, Unavailable}
import com.sludg.vuetify.components.grid.{VFlexProps, VGridProps}
import scala.scalajs.js.UndefOr
import scala.collection.immutable.List
import scala.scalajs.js
import com.sludg.util.models.SilhouetteModels.Subscriber
import scala.scalajs.js.JSConverters._
import scala.scalajs.js.timers.SetTimeoutHandle

object AppSetup {

  def toolbar(
      v: VTSLAppType,
      guideLink: Option[String],
      showLogo: Boolean = false,
      movePowerButtonToExtension: Boolean = false,
      drag: Boolean = false,
      logOutMethod: Event => Unit
  ): RenderHelpers.NodeRenderer[VToolbarProps, EventBindings, ScopedSlots] = {
    vToolbar(
      RenderOptions(
        style = Some(js.Dynamic.literal("-webkit-app-region" -> (if (drag) "drag" else "no-drag"))),
        props = Some(VToolbarProps(app = true)),
        ref = Some("toolbar")
      ),
      tenantCheck(
        v.$scopedSlots.toolbarLeft,
        v.selectedTenant,
        vToolbarSideIcon(click(_ => toggleDrawer(v)))
      ),
      if (showLogo) addLogo() else nothing,
      addToolbarTitle(v),
      tenantCheck(v.$scopedSlots.toolbar, v.selectedTenant, vSpacer, v => vLayout(v)),
      addGuideLink(guideLink),
      if (!movePowerButtonToExtension) addPowerButton(logOutMethod) else nothing,
      addToolbarExtension(v.$scopedSlots, v, logOutMethod, movePowerButtonToExtension)
    )
  }

  private def addToolbarExtension(
      s: VtslAppScopedSlots,
      v: VTSLAppType,
      logOutMethod: Event => Unit,
      movePowerButtonToExtension: Boolean
  ) = {
    s.toolbarExtension.toOption match {
      case Some(f) => {
        vLayout(
          RenderOptions(
            style = Some(js.Dynamic.literal("-webkit-app-region" -> "no-drag")),
            slot = Some("extension")
          ),
          vFlex(f(v.selectedTenant.toOption)),
          if (movePowerButtonToExtension)
            vFlex(
              vSpacer(),
              vButton(
                vIcon("power_settings_new"),
                RenderOptions(
                  style = Some(
                    js.Dynamic.literal(
                      "-webkit-app-region" -> "no-drag",
                      "float"              -> "right"
                    )
                  ),
                  props = Some(VButtonProps(icon = Some(true))),
                  on = Some(click(logOutMethod))
                )
              )
            )
          else div()
        )
      }
      case None => div()
    }
  }

  private def tenantCheck(
      scopedData: UndefOr[js.Function1[Option[Tenant], VNode]],
      tenant: js.UndefOr[Tenant],
      fallback: => RenderFunction[VNode],
      wrapper: VNode => RenderFunction[VNode] = v => div(v)
  ): RenderFunction[VNode] = {
    scopedData.map(a => wrapper(a.apply(tenant.toOption))).toOption.getOrElse(fallback)
  }

  private def addToolbarTitle(
      props: VtslAppProps
  ): RenderHelpers.NodeRenderer[VueProps, EventBindings, ScopedSlots] = {
    props.title.toOption match {
      case Some(title) => {
        div(
          vToolbarTitle(
            title,
            RenderOptions(
              style = Some(
                js.Dynamic.literal(
                  "margin-right" -> "0.5em",
                  "margin-left"  -> "0"
                )
              )
            )
          )
        )
      }
      case _ => div()
    }
  }

  private def addLogo() = {
    vImage(
      RenderOptions(
        props = Some(
          VImageProps(
            src = Left("/images/VTSL_cloud_medium.png"),
            maxWidth = Some(Left(38)),
            minWidth = Some(Left(38))
          )
        ),
        style = Some(js.Dynamic.literal("width" -> "38px", "margin-right" -> "0.5em"))
      )
    )
  }

  private def addPowerButton(
      logOutMethod: Event => Unit
  ): RenderHelpers.NodeRenderer[VButtonProps, EventBindings, ScopedSlots] = {
    vButton(
      vIcon("power_settings_new"),
      RenderOptions(
        style = Some(
          js.Dynamic.literal(
            "-webkit-app-region" -> "no-drag"
          )
        ),
        props = Some(VButtonProps(icon = Some(true))),
        on = Some(click(logOutMethod))
      )
    )
  }

  private def addGuideLink(
      guideLink: Option[String]
  ): RenderHelpers.NodeRenderer[VueProps, EventBindings, ScopedSlots] = {
    guideLink match {
      case Some(g) =>
        div(
          vButton(
            RenderOptions(
              props = Some(
                VButtonProps(
                  icon = Some(true),
                  href = Some(Left(g)),
                  target = Some("_blank")
                )
              )
            ),
            vIcon("help_outline")
          )
        )
      case None => div()
    }
  }

  def progressBar(
      data: VtslAppData
  ): RenderHelpers.NodeRenderer[VProgressLinearProps, EventBindings, ScopedSlots] = {
    vProgressLinear(
      RenderOptions(
        props = Some(
          VProgressLinearProps(
            indeterminate = Some(true),
            active = Some(data.loading > 0)
          )
        ),
        style = Some(
          js.Dynamic.literal(
            "margin-top" -> s"${data.toolbarHeightOnInit}px",
            "position"   -> "fixed",
            "z-index"    -> "1"
          )
        )
      )
    )
  }

  def navDrawer(v: VTSLAppType, otherApps: List[AppLink], apiCalls: ApiCalls, loader: Vue)(
      implicit scheduler: Scheduler
  ): RenderHelpers.NodeRenderer[VNavigationDrawerProps, EventBindings, ScopedSlots] = {
    vNavigationDrawer(
      vLayout(
        RenderOptions(`class` = List(Left("column"), Left("fill-height"))),
        v.$scopedSlots.drawer.foldToNode(d => d(v.selectedTenant.toOption), nothing),
        vSpacer,
        vList(
          vSubheader("Other VTSL Apps"),
          otherApps.map(app => {
            vListTile(
              RenderOptions(
                props = Some(
                  VListTileProps(
                    href = Some(Left(s"https://${app.domain.getOrElse("")}")),
                    disabled = Some(app.domain.isEmpty)
                  )
                )
              ),
              vListTileAction(vIcon(app.icon)),
              vListTileContent(
                vListTileTitle(app.title, app.devState match {
                  case Some(a) if a == Alpha => renderStateChip(a, "pink accent-3")
                  case Some(b) if b == Beta  => renderStateChip(b, "yellow")
                  case _                     => nothing
                }),
                vListTileSubTitle(app.subtitle)
              )
            )
          }),
          v.token match {
            case Some(token) =>
              towsListTiles(
                v,
                v.towsGroupOpen,
                v.towsGroupOpen = _,
                v.token.fold[List[SubscriberWithAdminStatus]](Nil)(_.subscribers),
                apiCalls,
                loader
              )(implicitly, token)
            case None => nothing
          }
        )
      )
    )(
      RenderOptions(
        props = Some(
          VNavigationDrawerProps(
            value = v.drawer.toOption,
            temporary = Some(v.temporaryDrawer)
          )
        ),
        on = Some(
          EventBindings(
            input = js.defined(_ => toggleDrawer(v)),
            transitionEnd = js.defined(_ => {
              v.openingDrawer = false
            })
          )
        )
      )
    )
  }

  def footer(
      name: String,
      version: Option[String]
  ): RenderHelpers.NodeRenderer[VFooterProps, EventBindings, ScopedSlots] = {
    vFooter(
      vSpacer,
      version match {
        case Some(value) => div(s" VTSL LTD. ${name}${version.map(v => s" v$v").getOrElse("")}")
        case None        => div()
      },
      RenderOptions(
        `class` = List(Left("pa-3")),
        props = Some(VFooterProps(app = true))
      )
    )
  }

  def renderStateChip(
      state: DevState,
      color: String
  ): RenderHelpers.NodeRenderer[VChipProps, EventBindings, ScopedSlots] = {
    vChip(
      RenderOptions(
        style = Some(js.Dynamic.literal("height" -> "20px")),
        props =
          Some(VChipProps(disabled = Some(true), textColor = Some("black"), color = Some(color)))
      ),
      s"$state"
    )
  }

  def linkComponent(
      data: VtslAppData,
      subscriber: Models.SubscriberWithAdminStatus,
      apiCalls: ApiCalls,
      loaderBus: Vue
  )(
      implicit scheduler: Scheduler,
      token: SludgToken
  ): RenderHelpers.NodeRenderer[VListTileProps, EventBindings, ScopedSlots] = {
    towsLinkItem(
      subscriber.uuid.map(uuid => _ => makeHTMLForm(data, subscriber, apiCalls, loaderBus)),
      s"Settings: ${subscriber.ext}",
      if (subscriber.uuid.isEmpty) "Link again to access" else "Edit phone or company settings"
    )
  }

  def towsLinkItem(clickBehavior: Option[Event => Unit], title: String, subTitle: String) = {
    vListTile(
      RenderOptions(
        props = Some(
          VListTileProps(
            disabled = Some(clickBehavior.isEmpty)
          )
        ),
        on = clickBehavior.map(click => EventBindings(click = js.defined(click)))
      ),
      vListTileAction(vIcon("dialpad")),
      vListTileContent(
        vListTileTitle(title),
        vListTileSubTitle(subTitle)
      )
    )
  }

  private def towsListTiles(
      data: VtslAppData,
      groupOpen: Boolean,
      setGroupOpen: Boolean => Unit,
      users: List[SubscriberWithAdminStatus],
      apiCalls: ApiCalls,
      loaderBus: Vue
  )(
      implicit scheduler: Scheduler,
      token: SludgToken
  ): NodeRenderer[_ <: VueProps, _ <: js.Object, _ <: ScopedSlots] = {
    if (users.isEmpty) {
      towsLinkItem(None, "Settings", "Link to access")

    } else {
      if (users.size == 1) {
        linkComponent(data, users.head, apiCalls, loaderBus)
      } else {
        vListGroup(
          RenderOptions(
            props = Some(
              VListGroupProps(
                value = Some(groupOpen)
              )
            ),
            on =
              Some(EventBindings(input = js.defined(setGroupOpen.compose(_.asInstanceOf[Boolean]))))
          ),
          vListTile(
            RenderOptions(
              slot = Some("activator")
            ),
            vListTileAction(vIcon("dialpad")),
            vListTileContent(
              vListTileTitle("Settings")
            )
          ),
          users.map(u => linkComponent(data, u, apiCalls, loaderBus))
        )
      }
    }

  }

  private def makeHTMLForm(
      data: VtslAppData,
      subscriber: SubscriberWithAdminStatus,
      apiCalls: ApiCalls,
      loadingBus: Vue
  )(implicit ec: Scheduler, token: SludgToken) = {
    LoadingFuture
      .withLoading(
        loadingBus,
        (for {
          userInfo <- EitherT(apiCalls.getUserInfo())
          tenant   <- EitherT(apiCalls.getTenant(subscriber.tid.toInt))
        } yield (userInfo, tenant)).value
      )
      .map { result =>
        def makeformValue(nodeBody: Node, inputElem: Input, name: String, value: String): Unit = {
          inputElem.name = name
          inputElem.`type` = "hidden"
          inputElem.setAttribute("value", value)
          nodeBody.appendChild(inputElem)
        }

        result.fold(
          accessForbidden =>
            loadingBus.$emit("error", "Permission was denied. Please contact support."), {
            case (userInfoForAll, tenantMaybe) =>
              val data = for {
                uuid     <- subscriber.uuid
                userInfo <- userInfoForAll.find(_._2 == uuid)
                tenant   <- tenantMaybe
              } yield {
                val (towsUrl, _, pin) = userInfo
                val tenantUrl         = tenant.uri

                val url =
                  s"https://$towsUrl/webadmin/en/user/jsp/ProcessLogin.jsp?tenant=$tenantUrl"
                var formNode = document.createElement("form")
                formNode.id = "towsForm"
                formNode.setAttribute("method", "POST")
                formNode.setAttribute("action", url)

                def createElement = document.createElement("input").asInstanceOf[Input]

                var smb = createElement

                makeformValue(formNode, createElement, "UserID", subscriber.ext)
                makeformValue(formNode, createElement, "Password", pin)
                makeformValue(formNode, createElement, "tenantWebName", tenantUrl)

                smb.`type` = "submit"
                formNode.appendChild(smb)
                document.body.appendChild(formNode)
                smb.click()

                document.body.removeChild(formNode)
              }

              if (data.isEmpty) {
                loadingBus.$emit(
                  "error",
                  "Your user configuration seems to be corrupt. Please contact support."
                )
              }
          }
        )
      }
  }

  trait AppRefs extends Refs { val toolbar: VueComponent[_, _, _] }

  class VtslAppProps(
      val title: js.UndefOr[String] = js.undefined,
      val spacedToolbar: Boolean = true,
      val buttonName: js.UndefOr[String] = js.undefined,
      val displayTenantSelector: Boolean = true,
      val vContentIsFluid: Boolean = false, //removes padding around around the v-container component
      val temporaryDrawer: Boolean = false,
      val token: Option[SludgToken] = None
  ) extends VueProps

  trait VtslAppScopedSlots extends ScopedSlots {
    val toolbar: js.UndefOr[js.Function1[Option[Tenant], VNode]]                = js.undefined
    val toolbarExtension: js.UndefOr[js.Function1[Option[Tenant], VNode]]       = js.undefined
    val default: js.UndefOr[js.Function1[Option[Tenant], VNode]]                = js.undefined
    val selectedSubscriber: js.UndefOr[js.Function1[Option[Subscriber], VNode]] = js.undefined
    val drawer: js.UndefOr[js.Function1[Option[Tenant], VNode]]                 = js.undefined

    val toolbarLeft: js.UndefOr[js.Function1[Option[Tenant], VNode]] = js.undefined

  }

  class VtslAppData extends js.Object with com.sludg.components.SnackbarData {

    var snackBarQueue: List[SnackBarMessage]                  = Nil
    var currentSnackBarMessageTimer: Option[SetTimeoutHandle] = None
    var drawer: js.UndefOr[Boolean]                           = js.undefined
    var openingDrawer: js.UndefOr[Boolean]                    = js.undefined
    var selectedTenant: js.UndefOr[Tenant]                    = js.undefined
    var selectedSubscriber: js.UndefOr[Subscriber]            = js.undefined

    /**
      * This is a counter for how many asynchronous requests are occurring at a given time.
      * It is used to determine whether the loading bar should be displayed.
      */
    var loading: Int             = 0
    var toolbarHeightOnInit: Int = 0
    var towsGroupOpen: Boolean   = false

  }

  def toggleDrawer(data: VtslAppData) = {
    if (!data.openingDrawer.getOrElse(false)) {
      data.drawer = !data.drawer.getOrElse(false)
      data.openingDrawer = true
    }
  }

  class VtslAppMethods extends js.Object {
    def setSelectedTenant(tenant: Event): Unit =
      this.asInstanceOf[VtslAppData].selectedTenant =
        if (js.isUndefined(tenant)) js.undefined else js.defined(tenant.asInstanceOf[Tenant])
  }

  class VTSLAppWatcher() extends js.Object {
    def snackBarQueue(value: List[SnackBarMessage]) = {
      val c = this.asInstanceOf[VTSLAppType]
      if (c.snackBarQueue.nonEmpty && c.currentSnackBarMessageTimer.isEmpty) {
        c.snackBarQueue.headOption foreach (
            msg =>
              c.currentSnackBarMessageTimer = Some(js.timers.setTimeout(6000) {
                c.currentSnackBarMessageTimer.foreach(t => {
                  js.timers.clearTimeout(t)
                  c.currentSnackBarMessageTimer = None
                })
                c.snackBarQueue = if (c.snackBarQueue.nonEmpty) c.snackBarQueue.tail else Nil
              })
          )
      }
    }
  }
}
