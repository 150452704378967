package com.sludg.models

import scala.scalajs.js.annotation.JSExportAll

object Models {
  @JSExportAll
  case class JWTSubscribers(subscribers: List[SubscriberWithAdminStatus])

  case class Report(id: Int, tenantId: Int, name: String)

  case class AccessForbidden(message: String)

  case class SubscriberWithAdminStatus(
      sid: Int,
      tid: String,
      ext: String,
      uuid: Option[String],
      admin: Boolean
  )
}
