package com.sludg.services

import java.nio.ByteBuffer

import com.sludg.models.Models.AccessForbidden
import fr.hmil.roshttp.body.BulkBodyPart
import fr.hmil.roshttp.exceptions.HttpException
import fr.hmil.roshttp.response.SimpleHttpResponse
import monix.execution.Scheduler
import play.api.libs.json.{JsError, JsSuccess, JsValue, Json, Reads, Writes}

import scala.concurrent.Future
import scala.reflect.ClassTag

object Helpers {
  class JSONBody private (value: JsValue) extends BulkBodyPart {
    override def contentType: String = s"application/json; charset=utf-8"

    override def contentData: ByteBuffer = ByteBuffer.wrap(value.toString.getBytes("utf-8"))
  }

  object JSONBody {
    def apply[A](value: A)(implicit writer: Writes[A]) = new JSONBody(Json.toJson(value))
  }

  def extractResponse(
      httpRequest: Future[Either[AccessForbidden, SimpleHttpResponse]]
  )(implicit scheduler: Scheduler): Future[Either[AccessForbidden, SimpleHttpResponse]] = {
    httpRequest.recover {
      case HttpException(e: SimpleHttpResponse) => Right[AccessForbidden, SimpleHttpResponse](e)
    }
  }

  //Checking if it is successful
  def handleSuccess[T](
      f: SimpleHttpResponse => Future[Either[AccessForbidden, T]]
  ): PartialFunction[SimpleHttpResponse, Future[Either[AccessForbidden, T]]] = {
    handleStatus(200, f).orElse(handleStatus(201, f))
  }

  def noneOn404: PartialFunction[SimpleHttpResponse, Future[Either[AccessForbidden, None.type]]] =
    handleStatus(404, _ => Future.successful(Right(None)))

  def handleStatus[T](
      statusToHandle: Int,
      f: SimpleHttpResponse => Future[Either[AccessForbidden, T]]
  ): PartialFunction[SimpleHttpResponse, Future[Either[AccessForbidden, T]]] = {
    case x if x.statusCode == statusToHandle => f(x)
  }

  def valueResult[T](v: T): SimpleHttpResponse => Future[Either[AccessForbidden, T]] =
    _ => Future.successful(Right(v))

  def deserializeJson[T: ClassTag: Reads](s: JsValue): Future[Either[AccessForbidden, T]] = {
    s.validate[T] match {
      case JsSuccess(value, path) => Future.successful(Right(value))
      case JsError(errors)        => Future.failed(new Exception(errors.toString()))
    }
  }

  def deserialiseAsAndWrapBody[T: ClassTag: Reads](jsPath: Option[String] = None)(
      implicit scheduler: Scheduler
  ): SimpleHttpResponse => Future[Either[AccessForbidden, Option[T]]] =
    (deserialiseBodyAs[T] _).andThen(_.andThen(_.map(_.map(t => Some(t)))))(jsPath)

  def deserialiseBodyAs[T: ClassTag: Reads](jsPath: Option[String] = None)(
      implicit scheduler: Scheduler
  ): SimpleHttpResponse => Future[Either[AccessForbidden, T]] = response => {
    val parsedBody = Json.parse(response.body)
    deserializeJson(jsPath.map(parsedBody \ _).getOrElse(parsedBody).as[JsValue])
  }

}
