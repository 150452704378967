package com.sludg.client.pages.components.diverts

import com.sludg.FieldExtractor
import com.sludg.Security
import com.sludg.auth0.SludgToken
import com.sludg.helpers.LoadingFuture
import com.sludg.services.ApiCalls
import com.sludg.util.PresenterSyntax._
import com.sludg.util.SilhouettePresenters._
import com.sludg.util.Validators
import com.sludg.util.models.SilhouetteModels
import com.sludg.util.models.SilhouetteModels.{DIDNumber, EmergencyOverride, HourlyExtensions}
import com.sludg.vue.{EventBindings, RenderOptions, _}
import com.sludg.vuetify.VuetifyComponents._
import com.sludg.vuetify.components._
import monix.execution.Scheduler.Implicits.global
import org.log4s.getLogger
import org.scalajs.dom.Event

import scala.scalajs.js
import scala.scalajs.js.JSConverters._

/** The routing screen makes use of much of the same input located in the divert screen (housed in DDIUtil),
  * and is used to configure the hourly routing of calls for a particular number.
  * Tab management is handled by the DDIDisplayer, this is the component that is displayed under each tab.
  * The user must first select the destination type, and after they they can select an individual
  * location to route calls to during those hours.
  */

object RoutingInputScreen {

  import com.sludg.vue.RenderHelpers._

  private[this] val logger = getLogger

  def RoutingInputScreenRenderer(registrationName: String) =
    namedTag[RoutingInputScreenProps, RoutingInputScreenEvents, ScopedSlots]("RoutingInputScreen")

  def RoutingInputScreenComponent(apiCalls: ApiCalls, security: Security, apiCallBus: Vue)(implicit
      token: SludgToken
  ) = {
    VueComponent.builder
      .withData(new RoutingInputScreenData())
      .withPropsAs[RoutingInputScreenProps]
      .build(
        created = js.defined(c => {
          c.routingExtensionType = {
            val extension = hourTypeToExtension(c.didFromTable, c.hourComponentType)
            //Take the extension from the field until the names are decoupled from the extensions in our api :(
            val number: Option[String] = extension.map(_.split(" - ")(0))
            c.routingDestinationNumber = number
            val listToUseAndType
                : (List[SilhouetteModels.SilhouetteLocation], Option[OverrideInputType]) =
              (for {
                subs <- c.tenantSubscribers
                aas <- c.tenantAutoAttendants
                cgs <- c.tenantCallGroups
                num <- number.orUndefined
              } yield DDIUtil.findNumberFromSilhouette(subs, aas, cgs, num)).getOrElse(Nil, None)

            c.extensionInput = DDIUtil.silhouetteUserToExtensionInput(listToUseAndType._1)
            listToUseAndType._2
          }
        }),
        templateOrRender = Right((component, renderer) => {
          vContainer(
            vLayout(
              vFlex(
                p(
                  s"Select the destination type your calls should be routed to during ${component.hourComponentType.toString.toUpperCase} hours"
                ),
                DDIUtil.RenderFunctions.extensionTypeInputSelector(
                  VAutocompleteEventBindings(
                    input = js.defined(e => {
                      component.routingExtensionType = (e: Any) match {
                        case a: OverrideInputType => Some(a)
                        case _ => None
                      }
                      component.extensionInput = (e: Any) match {
                        case AutoAttendant =>
                          component.tenantAutoAttendants
                            .map(aa => DDIUtil.silhouetteUserToExtensionInput(aa))
                            .getOrElse(Nil)
                        case CallGroup =>
                          component.tenantCallGroups
                            .map(cg => DDIUtil.silhouetteUserToExtensionInput(cg))
                            .getOrElse(Nil)
                        case Extension =>
                          component.tenantSubscribers
                            .map(sub => DDIUtil.silhouetteUserToExtensionInput(sub))
                            .getOrElse(Nil)
                        case _ => Nil
                      }
                    })
                  ),
                  "Routing Destination",
                  disabledProp = false,
                  component.routingExtensionType,
                  removeExternalSelection = true
                )
              )
            ),
            vLayout(
              vFlex(
                p(
                  s"Select the specific ${component.routingExtensionType.map(route => DDIUtil.overrideTypeToText(route)).getOrElse("destination")} from numbers on your tenant"
                ),
                DDIUtil.RenderFunctions.numberInputSelector(
                  VAutocompleteEventBindings(
                    input = js.defined(e => {
                      component.routingDestinationNumber = if (js.isUndefined(e)) {
                        component.$emit("setExtension", "")
                        None
                      } else {
                        Option(e: Any).collect { case ext: ExtensionInput =>
                          component.$emit("setExtension", ext.value.getOrElse(""))
                          ext.value.getOrElse("")
                        }
                      }
                    })
                  ),
                  "Routing Number",
                  disabledProp = component.routingExtensionType.isEmpty,
                  component.routingExtensionType,
                  component.extensionInput,
                  component.routingDestinationNumber
                )
              )
            )
          ).render(renderer)
        })
      )
  }

  def hourTypeToExtension(did: DIDNumber, hour: HourlyType): Option[String] = {
    hour match {
      case Open => did.openHoursExtension
      case Closed => did.closedHoursExtension
      case Lunch => did.lunchHoursExtension
      case Special => did.specialHoursExtension
    }
  }

}

class RoutingInputScreenData extends js.Object {
  var routingExtensionType: Option[OverrideInputType] = None
  var routingDestinationNumber: Option[String] = None
  var extensionInput: List[ExtensionInput] = Nil

  var updateSuccessful = false
}

class RoutingInputScreenProps(
    val token: SludgToken,
    val tenant: SilhouetteModels.Tenant,
    val didFromTable: DIDNumber,
    val hourComponentType: HourlyType,
    val tenantSubscribers: js.UndefOr[List[SilhouetteModels.SilhouetteLocation]] = js.undefined,
    val tenantAutoAttendants: js.UndefOr[List[SilhouetteModels.SilhouetteLocation]] = js.undefined,
    val tenantCallGroups: js.UndefOr[List[SilhouetteModels.SilhouetteLocation]] = js.undefined
) extends VueProps

trait RoutingInputScreenEvents extends EventBindings {
  def routeUpdateSuccessful(e: Boolean): Unit
  def setExtension(s: String): Unit
}

object RoutingInputScreenEvents {
  def apply(
      bindings: EventBindings = EventBindings(),
      routeUpdateSuccessful: js.UndefOr[js.Function1[Boolean, Unit]] = js.undefined,
      setExtension: js.UndefOr[js.Function1[String, Unit]] = js.undefined
  ): RoutingInputScreenEvents = {
    bindings.asInstanceOf[js.Dynamic].updateDynamic("routeUpdateSuccessful")(routeUpdateSuccessful)
    bindings.asInstanceOf[js.Dynamic].updateDynamic("setExtension")(setExtension)
    val binding = bindings.asInstanceOf[RoutingInputScreenEvents]
    binding
  }
}

