package com.sludg.vuetify.components

import com.sludg.scalajs.DynamicHelper
import com.sludg.vue.{EventBindings, ScopedSlots, VueProps}

import scala.scalajs.js
import scala.scalajs.js.|

trait VSubheader {

  import com.sludg.vue.RenderHelpers._

  val vSubheader = namedTag[VSubheaderProps, EventBindings, ScopedSlots]("v-subheader")

}

trait VSubheaderProps extends VueProps {
  val dark: js.UndefOr[Boolean]  = js.undefined
  val inset: js.UndefOr[Boolean] = js.undefined
  val light: js.UndefOr[Boolean] = js.undefined
}

object VSubheaderProps {

  import scala.scalajs.js.JSConverters._

  def apply(
      dark: Option[Boolean] = None,
      inset: Option[Boolean] = None,
      light: Option[Boolean] = None
  ): VSubheaderProps = {

    DynamicHelper.buildViaDynamic(
      "dark"  -> dark.map(js.Any.fromBoolean).orUndefined,
      "inset" -> inset.map(js.Any.fromBoolean).orUndefined,
      "light" -> light.map(js.Any.fromBoolean).orUndefined
    )
  }
}
