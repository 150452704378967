package com.sludg.vuetify.components

import com.sludg.scalajs.DynamicHelper
import com.sludg.vue.{EventBindings, ScopedSlots, VueProps}

import scala.scalajs.js

trait VList {

  import com.sludg.vue.RenderHelpers._

  val vList = namedTag[VListProps, EventBindings, ScopedSlots]("v-list")

}

object VList extends VList

trait VListProps extends VueProps {

  val dark: js.UndefOr[Boolean]         = js.undefined
  val dense: js.UndefOr[Boolean]        = js.undefined
  val expand: js.UndefOr[Boolean]       = js.undefined
  val light: js.UndefOr[Boolean]        = js.undefined
  val subheader: js.UndefOr[Boolean]    = js.undefined
  val `three-line`: js.UndefOr[Boolean] = js.undefined
  val `two-line`: js.UndefOr[Boolean]   = js.undefined

}

object VListProps {

  import scala.scalajs.js.JSConverters._

  def apply(
      dark: Option[Boolean] = None,
      dense: Option[Boolean] = None,
      expand: Option[Boolean] = None,
      light: Option[Boolean] = None,
      subheader: Option[Boolean] = None,
      `three-line`: Option[Boolean] = None,
      `two-line`: Option[Boolean] = None
  ): VListProps = {
    DynamicHelper.buildViaDynamic(
      "dark"       -> dark.map(js.Any.fromBoolean).orUndefined,
      "dense"      -> dense.map(js.Any.fromBoolean).orUndefined,
      "expand"     -> expand.map(js.Any.fromBoolean).orUndefined,
      "light"      -> light.map(js.Any.fromBoolean).orUndefined,
      "subheader"  -> subheader.map(js.Any.fromBoolean).orUndefined,
      "three-line" -> `three-line`.map(js.Any.fromBoolean).orUndefined,
      "two-line"   -> `two-line`.map(js.Any.fromBoolean).orUndefined
    )
  }
}
