package com.sludg.vuetify.components

import com.sludg.vue.{EventBindings, ScopedSlots}

/**
  * @author dpoliakas
  *         Date: 26/11/2018
  *         Time: 11:02
  *
  *
  */
trait VFooter {

  import com.sludg.vue.RenderHelpers._

  val vFooter = namedTag[VFooterProps, EventBindings, ScopedSlots]("v-footer")

}

object VFooter extends VFooter

trait VFooterProps extends VuetifyComponentProps

object VFooterProps {
  def apply(app: Boolean = true): VFooterProps =
    VuetifyComponentProps(app = app).asInstanceOf[VFooterProps]
}
