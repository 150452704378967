package com.sludg.vue.rendering

import com.sludg.vue.{EventBindings, ScopedSlots, TemplatingHelpers, VueProps}

/**
  * @author dpoliakas
  *         Date: 22/11/2018
  *         Time: 18:50
  *
  *
  */
trait RouterView {
  this: TemplatingHelpers =>
  val routerView = namedTag[VueProps, EventBindings, ScopedSlots]("router-view")
}
