package com.sludg.vuetify.components.transitions

import com.sludg.vue.{EventBindings, ScopedSlots}

trait VScrollXTransition {

  import com.sludg.vue.RenderHelpers._

  val vScrollXTransition =
    namedTag[VTransitionProps, EventBindings, ScopedSlots]("v-scroll-x-transition")

}
