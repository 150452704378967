package com.sludg.auth0

import java.util.Base64

import com.sludg.json.Deserializer.subscriberWithAdminStatusReads
import com.sludg.models.Models.SubscriberWithAdminStatus
import play.api.libs.json.Json

import scala.scalajs.js
import scala.scalajs.js.{JSON, UndefOr}

/**
  * @author dpoliakas
  *         Date: 29/11/2018
  *         Time: 09:45
  *
  *         This exposes various properties of the token to be accessed easily
  *
  */
class SludgToken(val tokenString: String) {

  lazy val parsedToken = {
    val result = new String(Base64.getDecoder.decode(tokenString.split("\\.")(1)))
    JSON.parse(result).asInstanceOf[SludgJWT]
  }

  lazy val subscribers = {
    if (js.isUndefined(parsedToken.`http://vtsl.net/silhouette/subscribers`)) {
      Nil
    } else {
      Json
        .parse(JSON.stringify(parsedToken.`http://vtsl.net/silhouette/subscribers`))
        .validate[List[SubscriberWithAdminStatus]]
        .asOpt match {
        case Some(listOfSubscribers) => listOfSubscribers
        case None                    => Nil
      }
    }
  }

  lazy val tenants: List[Int] = subscribers.map(_.tid.toInt).distinct

  val adminStatus: UndefOr[Boolean] = parsedToken.`http://vtsl.net/silhouette/super_user`

  lazy val isSuperUser = if (js.isUndefined(adminStatus)) false else adminStatus.getOrElse(false)

}

@js.native
trait SludgJWT extends js.Object {
  val `http://vtsl.net/silhouette/subscribers`: js.UndefOr[js.Array[js.Object]]
  val `http://vtsl.net/silhouette/super_user`: js.UndefOr[Boolean]
}
