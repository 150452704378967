package com.sludg.vuetify.components

import com.sludg.scalajs.DynamicHelper
import com.sludg.vue.{EventBindings, ScopedSlots, VueProps}

import scala.scalajs.js
import scala.scalajs.js.|

trait VStepperContent {

  import com.sludg.vue.RenderHelpers._

  val vStepperContent =
    namedTag[VStepperContentProps, EventBindings, ScopedSlots]("v-stepper-content")
}

object VStepperContent extends VStepperContent

trait VStepperContentProps extends VueProps {
  val step: js.UndefOr[Int | String] = js.undefined
}

object VStepperContentProps {

  import scala.scalajs.js.JSConverters._

  def apply(
      step: Option[Either[Int, String]] = None
  ): VStepperContentProps = {

    DynamicHelper.buildViaDynamic(
      "step" -> step.map(_.fold(js.Any.fromInt, js.Any.fromString)).orUndefined
    )
  }
}
