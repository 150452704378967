package com.sludg.client

import com.sludg._
import com.sludg.models.Config.{AppLink, Config}
import com.sludg.services.ApiCalls
import com.sludg.vue._
import org.log4s.getLogger
import com.sludg.auth0.{ResponseToken, SludgToken}
import com.sludg.client.pages.{MainPage, RolesPage}
import com.sludg.client.pages.components.diverts.DivertsPage

import scala.scalajs.js

object Client {

  private[this] val logger = getLogger

  import com.sludg.helpers.Client.initialize

  def initialise(security: Security, config: Config, otherApps: List[AppLink], apiCalls: ApiCalls, appContainer: String)(implicit token: SludgToken) = {
    logger.debug("Client processes. We don't care about security >:)")
    val apiCallBus = new Vue(js.undefined)
    val mainPage = MainPage.initialComponent(apiCalls, security, apiCallBus, otherApps)
    val divertsPage = DivertsPage.DivertsPageComponent(apiCalls, security, apiCallBus, otherApps)
    val rolesPage = RolesPage.rolePageComponent(apiCalls, security, apiCallBus, otherApps)

    initialize(config.vuetify, appContainer, List(
      VueRoute("/", name = Some("User Portal"), component = Some(mainPage)),
      VueRoute("/call_routing", name = Some("Call Routing"), component = Some(divertsPage)),
      VueRoute("/user_roles", name = Some("User Roles"), component = Some(rolesPage)),
      VueRoute("*", redirect = Some("/"))
    ))

  }
}

