package com.sludg.vuetify.components

import com.sludg.scalajs.DynamicHelper
import com.sludg.vue.{EventBindings, ScopedSlots, VueProps}

import scala.scalajs.js

import scala.scalajs.js.|

/**
  * @author dpoliakas
  *         Date: 26/11/2018
  *         Time: 17:19
  *
  *
  */
trait VButton {

  import com.sludg.vue.RenderHelpers._

  val vButton = namedTag[VButtonProps, EventBindings, ScopedSlots]("v-btn")
}

trait VButtonProps extends VueProps {

  val absolute: js.UndefOr[Boolean]            = js.undefined
  val `active-class`: js.UndefOr[String]       = js.undefined
  val append: js.UndefOr[Boolean]              = js.undefined
  val block: js.UndefOr[Boolean]               = js.undefined
  val bottom: js.UndefOr[Boolean]              = js.undefined
  val color: js.UndefOr[String]                = js.undefined
  val dark: js.UndefOr[Boolean]                = js.undefined
  val depressed: js.UndefOr[Boolean]           = js.undefined
  val disabled: js.UndefOr[Boolean]            = js.undefined
  val exact: js.UndefOr[Boolean]               = js.undefined
  val `exact-active-class`: js.UndefOr[String] = js.undefined
  val fab: js.UndefOr[Boolean]                 = js.undefined
  val href: js.UndefOr[String | js.Object]     = js.undefined
  val icon: js.UndefOr[Boolean]                = js.undefined
  val `input-value`: js.UndefOr[js.Any]        = js.undefined
  val large: js.UndefOr[Boolean]               = js.undefined
  val left: js.UndefOr[Boolean]                = js.undefined
  val light: js.UndefOr[Boolean]               = js.undefined
  val loading: js.UndefOr[Boolean]             = js.undefined
  val nuxt: js.UndefOr[Boolean]                = js.undefined
  val outline: js.UndefOr[Boolean]             = js.undefined
  val replace: js.UndefOr[Boolean]             = js.undefined
  val right: js.UndefOr[Boolean]               = js.undefined
  val ripple: js.UndefOr[Boolean | js.Object]  = js.undefined
  val round: js.UndefOr[Boolean]               = js.undefined
  val small: js.UndefOr[Boolean]               = js.undefined
  val tag: js.UndefOr[String]                  = js.undefined
  val target: js.UndefOr[String]               = js.undefined
  val to: js.UndefOr[String | js.Object]       = js.undefined
  val top: js.UndefOr[Boolean]                 = js.undefined
  val `type`: js.UndefOr[String]               = js.undefined
  val value: js.UndefOr[js.Any]                = js.undefined

}

object VButtonProps {

  import scala.scalajs.js.JSConverters._

  def apply(
      absolute: Option[Boolean] = None,
      activeClass: Option[String] = None,
      append: Option[Boolean] = None,
      block: Option[Boolean] = None,
      bottom: Option[Boolean] = None,
      color: Option[String] = None,
      dark: Option[Boolean] = None,
      depressed: Option[Boolean] = None,
      disabled: Option[Boolean] = None,
      exact: Option[Boolean] = None,
      exactActiveClass: Option[String] = None,
      fab: Option[Boolean] = None,
      fixed: Option[Boolean] = None,
      flat: Option[Boolean] = None,
      href: Option[Either[String, js.Object]] = None,
      icon: Option[Boolean] = None,
      inputValue: Option[js.Any] = None,
      large: Option[Boolean] = None,
      left: Option[Boolean] = None,
      light: Option[Boolean] = None,
      loading: Option[Boolean] = None,
      nuxt: Option[Boolean] = None,
      outline: Option[Boolean] = None,
      replace: Option[Boolean] = None,
      right: Option[Boolean] = None,
      ripple: Option[Either[Boolean, js.Object]] = None,
      round: Option[Boolean] = None,
      small: Option[Boolean] = None,
      tag: Option[String] = None,
      target: Option[String] = None,
      to: Option[Either[String, js.Object]] = None,
      top: Option[Boolean] = None,
      `type`: Option[String] = None,
      value: Option[js.Any] = None
  ): VButtonProps = {

    DynamicHelper.buildViaDynamic(
      "absolute"           -> absolute.map(js.Any.fromBoolean).orUndefined,
      "active-class"       -> activeClass.map(js.Any.fromString).orUndefined,
      "append"             -> append.map(js.Any.fromBoolean).orUndefined,
      "block"              -> block.map(js.Any.fromBoolean).orUndefined,
      "bottom"             -> bottom.map(js.Any.fromBoolean).orUndefined,
      "color"              -> color.map(js.Any.fromString).orUndefined,
      "dark"               -> dark.map(js.Any.fromBoolean).orUndefined,
      "depressed"          -> depressed.map(js.Any.fromBoolean).orUndefined,
      "disabled"           -> disabled.map(js.Any.fromBoolean).orUndefined,
      "exact"              -> exact.map(js.Any.fromBoolean).orUndefined,
      "exact-active-class" -> exactActiveClass.map(js.Any.fromString).orUndefined,
      "fab"                -> fab.map(js.Any.fromBoolean).orUndefined,
      "fixed"              -> fixed.map(js.Any.fromBoolean).orUndefined,
      "flat"               -> flat.map(js.Any.fromBoolean).orUndefined,
      "href"               -> href.map(_.fold(js.Any.fromString, identity)).orUndefined,
      "icon"               -> icon.map(js.Any.fromBoolean).orUndefined,
      "input-value"        -> inputValue.orUndefined,
      "large"              -> large.map(js.Any.fromBoolean).orUndefined,
      "left"               -> left.map(js.Any.fromBoolean).orUndefined,
      "light"              -> light.map(js.Any.fromBoolean).orUndefined,
      "loading"            -> loading.map(js.Any.fromBoolean).orUndefined,
      "nuxt"               -> nuxt.map(js.Any.fromBoolean).orUndefined,
      "outline"            -> outline.map(js.Any.fromBoolean).orUndefined,
      "replace"            -> replace.map(js.Any.fromBoolean).orUndefined,
      "right"              -> right.map(js.Any.fromBoolean).orUndefined,
      "ripple"             -> ripple.map(_.fold(js.Any.fromBoolean, identity)).orUndefined,
      "round"              -> round.map(js.Any.fromBoolean).orUndefined,
      "small"              -> small.map(js.Any.fromBoolean).orUndefined,
      "tag"                -> tag.map(js.Any.fromString).orUndefined,
      "target"             -> target.map(js.Any.fromString).orUndefined,
      "to"                 -> to.orUndefined.map(_.fold(js.Any.fromString, identity)),
      "top"                -> top.map(js.Any.fromBoolean).orUndefined,
      "type"               -> `type`.map(js.Any.fromString).orUndefined,
      "value"              -> value.orUndefined
    )
  }
}
