package com.sludg.vuetify.components

import scala.scalajs.js.JSConverters._
import com.sludg.scalajs.DynamicHelper
import com.sludg.vue.{EventBindings, ScopedSlots, VueProps}
import scala.scalajs.js

trait VRadio {
  import com.sludg.vue.RenderHelpers._

  val vRadio = namedTag[VRadioProps, EventBindings, ScopedSlots]("v-radio")
}

trait VRadioProps extends VueProps {
  val value: js.UndefOr[js.Any]       = js.undefined
  val label: js.UndefOr[String]       = js.undefined
  val name: js.UndefOr[String]        = js.undefined
  val description: js.UndefOr[String] = js.undefined
  val disabled: js.UndefOr[Boolean]   = js.undefined
  val id: js.UndefOr[String]          = js.undefined

}

object VRadioProps {

  def apply(
      value: Option[js.Any] = None,
      label: Option[String] = None,
      name: Option[String] = None,
      description: Option[String] = None,
      disabled: Option[Boolean] = None,
      id: Option[String] = None
  ): VRadioProps = {
    DynamicHelper.buildViaDynamic(
      "value"       -> value.orUndefined,
      "label"       -> label.map(js.Any.fromString).orUndefined,
      "name"        -> name.map(js.Any.fromString).orUndefined,
      "description" -> description.map(js.Any.fromString).orUndefined,
      "disabled"    -> disabled.map(js.Any.fromBoolean).orUndefined,
      "id"          -> id.map(js.Any.fromString).orUndefined
    )
  }
}
