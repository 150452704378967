package com.sludg.vuetify.components

import com.sludg.vue.{EventBindings, ScopedSlots, VueProps}

trait VSpeedDial {

  import com.sludg.vue.RenderHelpers._

  val vSpeedDial = namedTag[VueProps, EventBindings, ScopedSlots]("v-speed-dial")

}

object VSpeedDial extends VSpeedDial
