package com.sludg.vuetify.components.transitions

import com.sludg.vue.{EventBindings, ScopedSlots}

trait VSlideYTransition {

  import com.sludg.vue.RenderHelpers._

  val vSlideYTransition =
    namedTag[VTransitionProps, EventBindings, ScopedSlots]("v-slide-y-transition")

}
