package com.sludg.vuetify.components

import com.sludg.scalajs.DynamicHelper
import com.sludg.vue.{EventBindings, ScopedSlots, VueProps}

import scala.scalajs.js
import scala.scalajs.js.|

trait VTab {

  import com.sludg.vue.RenderHelpers._

  val vTab = namedTag[VTabProps, EventBindings, ScopedSlots]("v-tab")

}

object VTab extends VTab

trait VTabProps extends VueProps {

  val dark: js.UndefOr[Boolean]            = js.undefined
  val light: js.UndefOr[Boolean]           = js.undefined
  val href: js.UndefOr[String | js.Object] = js.undefined
  val tag: js.UndefOr[Int | String]        = js.undefined
  val disabled: js.UndefOr[Boolean]        = js.undefined
  val ripple: js.UndefOr[Boolean]          = js.undefined

}

object VTabProps {

  import scala.scalajs.js.JSConverters._

  def apply(
      dark: Option[Boolean] = None,
      light: Option[Boolean] = None,
      href: Option[Either[String, js.Object]] = None,
      tag: Option[Either[String, js.Object]] = None,
      disabled: Option[Boolean] = None,
      ripple: Option[Boolean] = None
  ): VTabProps = {
    DynamicHelper.buildViaDynamic(
      "dark"     -> dark.map(js.Any.fromBoolean).orUndefined,
      "light"    -> light.map(js.Any.fromBoolean).orUndefined,
      "href"     -> href.orUndefined.map(_.fold(js.Any.fromString, identity)),
      "tag"      -> tag.orUndefined.map(_.fold(js.Any.fromString, identity)),
      "disabled" -> disabled.map(js.Any.fromBoolean).orUndefined,
      "ripple"   -> ripple.map(js.Any.fromBoolean).orUndefined
    )
  }
}
