package com.sludg.vuetify.components

import com.sludg.scalajs.DynamicHelper
import com.sludg.vue.{EventBindings, ScopedSlots, VueProps}

import scala.scalajs.js
import scala.scalajs.js.|

trait VStepper {

  import com.sludg.vue.RenderHelpers._

  val vStepper = namedTag[VStepperProps, EventBindings, ScopedSlots]("v-stepper")
}

object VStepper extends VStepper

trait VStepperProps extends VueProps {
  val `non-linear`: js.UndefOr[Boolean] = js.undefined
  val light: js.UndefOr[Boolean]        = js.undefined
  val dark: js.UndefOr[Boolean]         = js.undefined
  val `alt-labels`: js.UndefOr[Boolean] = js.undefined
  val value: js.UndefOr[Int | String]   = js.undefined
  val vertical: js.UndefOr[Boolean]     = js.undefined
}

object VStepperProps {

  import scala.scalajs.js.JSConverters._

  def apply(
      `non-linear`: Option[Boolean] = None,
      light: Option[Boolean] = None,
      dark: Option[Boolean] = None,
      `alt-labels`: Option[Boolean] = None,
      value: Option[Either[Int, String]] = None,
      vertical: Option[Boolean] = None
  ): VStepperProps = {

    DynamicHelper.buildViaDynamic(
      "non-linear" -> `non-linear`.map(js.Any.fromBoolean).orUndefined,
      "light"      -> light.map(js.Any.fromBoolean).orUndefined,
      "dark"       -> dark.map(js.Any.fromBoolean).orUndefined,
      "alt-labels" -> `alt-labels`.map(js.Any.fromBoolean).orUndefined,
      "value"      -> value.map(_.fold(js.Any.fromInt, js.Any.fromString)).orUndefined,
      "vertical"   -> vertical.map(js.Any.fromBoolean).orUndefined
    )
  }
}
