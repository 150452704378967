package com.sludg.vuetify.components

import com.sludg.scalajs.DynamicHelper
import com.sludg.vue.{EventBindings, ScopedSlots, VueProps}

import scala.scalajs.js
import scala.scalajs.js.|

trait VStepperStep {

  import com.sludg.vue.RenderHelpers._

  val vStepperStep = namedTag[VStepperStepProps, EventBindings, ScopedSlots]("v-stepper-step")

}

object VStepperStep extends VStepperStep

trait VStepperStepProps extends VueProps {
  val color: js.UndefOr[String]           = js.undefined
  val complete: js.UndefOr[Boolean]       = js.undefined
  val `complete-icon`: js.UndefOr[String] = js.undefined
  val `edit-icon`: js.UndefOr[String]     = js.undefined
  val editable: js.UndefOr[Boolean]       = js.undefined
  val `error-icon`: js.UndefOr[Boolean]   = js.undefined
  val step: js.UndefOr[Int | String]      = js.undefined
}

object VStepperStepProps {

  import scala.scalajs.js.JSConverters._

  def apply(
      color: Option[String] = None,
      complete: Option[Boolean] = None,
      `complete-icon`: Option[String] = None,
      `edit-icon`: Option[String] = None,
      editable: Option[Boolean] = None,
      `error-icon`: Option[String] = None,
      step: Option[Either[Int, String]] = None
  ): VStepperStepProps = {

    DynamicHelper.buildViaDynamic(
      "color"         -> color.map(js.Any.fromString).orUndefined,
      "complete"      -> complete.map(js.Any.fromBoolean).orUndefined,
      "complete-icon" -> `complete-icon`.map(js.Any.fromString).orUndefined,
      "edit-icon"     -> `edit-icon`.map(js.Any.fromString).orUndefined,
      "editable"      -> editable.map(js.Any.fromBoolean).orUndefined,
      "error-icon"    -> `error-icon`.map(js.Any.fromString).orUndefined,
      "step"          -> step.map(_.fold(js.Any.fromInt, js.Any.fromString)).orUndefined
    )
  }
}
