package com.sludg.client.pages.components.mainpage

import com.sludg.vue.RenderHelpers.{div, namedTag, p}
import com.sludg.FieldExtractor
import monix.execution.Scheduler
import org.log4s.{Logger, getLogger}
import com.sludg.vue.{EventBindings, RenderHelpers, RenderOptions, ScopedSlots, Vue, VueProps, _}
import com.sludg.vuetify.VuetifyComponents.{vAlert, vButton, vCard, vContainer, vFlex, vLayout, vTextField}
import com.sludg.vue.RenderHelpers.{div, p}
import com.sludg.vuetify.components.grid.{VFlexProps, VGridProps}
import com.sludg.vuetify.components.{VAlertProps, VButtonProps, VCardProps, VDataTable, VDataTableHeader, VDataTableProps, VDataTableScopedSlots, VDialogProps, VListProps, VTextFieldProps}
import com.sludg.FieldExtractor
import com.sludg.Security
import com.sludg.services.ApiCalls
import com.sludg.vue.RenderHelpers._
import com.sludg.vuetify.VuetifyComponents.{vButton, _}
import com.sludg.auth0.SludgToken
import com.sludg.models.Models.{AccessForbidden, SubscriberWithAdminStatus}
import cats.data.EitherT
import cats.implicits._
import com.sludg.client.pages.components.mainpage.UserPortal.SelectedMode.{ScreenOne, ScreenTwo}
import com.sludg.helpers.LoadingFuture
import com.sludg.util.Validators
import com.sludg.vue.VueInstanceProperties.CreateElement
import monix.execution.Scheduler.Implicits.global

import scala.collection.immutable.List
import scala.scalajs.js
import scala.concurrent.Future

object UserPortal {
  val centerClass                                             = List(Left("justify-center"))
  val justifyCenter: RenderOptions[Nothing, Nothing, Nothing] = RenderOptions(`class` = List(Left("justify-center")))

  val logger: Logger = getLogger

  type UserPortalComponent = VueComponent[_ <: LinkerProps, _ <: Slots, _ <: ScopedSlots] with LinkerData with js.Object with js.Object with LinkerProps with WithRefs[_ <: Refs]

  def userPortalRenderer(registrationName: String): RenderHelpers.NodeRenderer[LinkerProps, LinkerEvents, ScopedSlots] = namedTag[LinkerProps, LinkerEvents, ScopedSlots](registrationName)

  def userPortalComponent(apiCalls: ApiCalls, security: Security, loader: Vue): VueComponent[_ <: LinkerProps, _ <: Slots, _ <: ScopedSlots] = {
    VueComponent.builder
      .withData(new LinkerData())
      .withPropsAs[LinkerProps]
      .build(
        created = js.defined(l => {
          implicit val token = l.token.get
          l.apiCalls = Some(apiCalls)
          l.loader = Some(loader)
          loadData(apiCalls, l, token, loader, l, token.subscribers.headOption).map(_ => l.initialRenderingComplete = true)
        }),
        watch = new LinkerWatcher(),
        templateOrRender = Right((u, renderer) => div(justifyCenter, cardDisplay(apiCalls, security, loader, u, renderer), unlinkDialog(u, loader, apiCalls, security, u.token.get)).render(renderer))
      )
  }

  private def unlinkDialog(u: UserPortalComponent, loader: Vue, apiCalls: ApiCalls, security: Security, token: SludgToken) = {
    vDialog(
      RenderOptions(
        style = Some(js.Dynamic.literal("text-align" -> "center", "box-shadow" -> "0px")),
        props = Some(VDialogProps(value = Some(u.unlinkDialog), width = Some(Right(200)), scrollable = Some(false), `max-width` = Some(Right(550)))),
        on = Some(EventBindings(input = js.defined(e => u.unlinkDialog = e.asInstanceOf[Boolean])))
      ),
      vCard(
        vCardTitle("Unlink account", RenderOptions(`class` = List(Left("headline")))),
        vDivider,
        vFlex(
          p(s"Are you sure you want to unlink your VTSL Account?"),
          p(s"You will lose access to all VTSL applications."),
          RenderOptions(
            style = Some(js.Dynamic.literal("padding-left" -> "40px", "padding-right" -> "40px", "padding-top" -> "35px"))
          )
        ),
        vCardText(vList(VListProps(`two-line` = Some(true)))),
        vCardActions(
          vSpacer,
          vButton("Cancel", EventBindings(click = js.defined(_ => u.unlinkDialog = false))),
          vButton(
            "Confirm",
            RenderOptions(
              props = Some(VButtonProps(color = Some("red"))),
              on = Some(EventBindings(click = js.defined(_ => {
                u.unlinkDialog = false
                u.ddiVal = ""
                u.extVal = ""
                u.pinVal = ""

                LoadingFuture.withLoading(
                  loader,
                  apiCalls.unLink(u.subscriber.get.sid.toInt, u.subscriber.get.tid.toInt)(implicitly, u.token.get).map {
                    case Right(value) if value => {
                      u.screenMode = SelectedMode.LoadingPage

                      for {
                        tokenJs <- EitherT.right[AccessForbidden](security.checkSession())
                        refreshedToken: SludgToken = new SludgToken(tokenJs.get.accessToken.toString)
                      } yield {
                        u.$emit("refreshedToken", refreshedToken)
                        UserPortal.loadData(apiCalls, u, token, loader, u, token.subscribers.headOption)
                      }
                    }
                    case _ => logger.debug("Access forbidden")
                  }
                )
              })))
            )
          )
        )
      )
    )
  }

  class LinkerWatcher extends js.Object {
    def token(newVal: js.UndefOr[SludgToken], oldVal: js.UndefOr[SludgToken]): Unit = {
      val data = this.asInstanceOf[UserPortalComponent]

      if (newVal.isDefined) loadData(data.apiCalls.get, data, newVal.get, data.loader.get, data, newVal.toOption.flatMap(_.subscribers.headOption))
    }
  }

  class LinkerProps(val token: js.UndefOr[SludgToken]) extends VueProps

  class LinkerData extends js.Object {
    //This decides which screen to display
    var screenMode: SelectedMode = SelectedMode.LoadingPage
    var unlinkDialog             = false

    var subscriber: Option[SubscriberWithAdminStatus] = None
    var linkData: Option[Link]                        = None

    var initialRenderingComplete = false //We wait for the GUI to be rendered before allowing css animations to be applied
    var userDetailsChecked       = false //We set this to true after we have loaded the token data, or your linking details
    var animateCard              = false //We set this to true when we animate the card from one page to another

    var alertVisible: Boolean = false

    var ddiVal, extVal, pinVal: String = ""

    var currentTenantId, currentSubId: Int = 0

    var apiCalls: Option[ApiCalls] = None
    var loader: Option[Vue]        = None
  }

  trait LinkerEvents extends EventBindings {
    def refreshedToken(t: SludgToken): Unit
  }

  object LinkerEvents {
    def apply(bindings: EventBindings = EventBindings(), refreshedToken: js.UndefOr[js.Function1[SludgToken, Unit]]): LinkerEvents = {
      bindings.asInstanceOf[js.Dynamic].updateDynamic("refreshedToken")(refreshedToken)
      val refreshedTokenBinding = bindings.asInstanceOf[LinkerEvents]
      refreshedTokenBinding
    }
  }

  /**
    * Loads user data and returns unit when loading is complete
    * @param apiCalls
    * @param data
    * @param tokenToUse
    * @param loader
    * @param l
    * @param subscriber
    * @return
    */
  def loadData(apiCalls: ApiCalls, data: LinkerData, tokenToUse: SludgToken, loader: Vue, l: UserPortalComponent, subscriber: Option[SubscriberWithAdminStatus]): Future[Unit] = {
    l.subscriber = subscriber

    data.subscriber match {
      case None => {
        l.screenMode = ScreenOne
        l.userDetailsChecked = true
        Future.successful(println("Not found."))
      }
      case Some(sub) => {
        l.screenMode = ScreenTwo
        LoadingFuture.withLoading(
          loader,
          apiCalls.getSubscriber(sub.tid.toInt, sub.sid)(token = tokenToUse, scheduler = implicitly[Scheduler]).map {
            case Right(Some(s)) => {
              l.linkData = Some(new Link(s.lastName, s.firstName.getOrElse("-"), s.email, s.extension, (if (sub.admin) "Administrator" else "Standard"), s.subscriberId.toString, s.tenantId.toString))
              l.userDetailsChecked = true
            }
            case _ => {
              l.linkData = None
              l.userDetailsChecked = true
            }
          }
        )
      }
    }
  }

  class Link(val lastName: String = "", val firstName: String = "", val email: String = "", val extension: String = "", val userStatus: String = "", val tid: String = "", val sid: String = "")

  def unlinkButton(u: UserPortalComponent, security: Security, apiCalls: ApiCalls, loader: Vue, token: SludgToken, subscriber: SubscriberWithAdminStatus) = {
    vButton(
      "Unlink", RenderOptions(on = Some(EventBindings(click = js.defined(_ => u.unlinkDialog = true))))
    )
  }

  def linkButton(u: UserPortalComponent, security: Security, apiCalls: ApiCalls, loadingEventBus: Vue): RenderHelpers.NodeRenderer[VButtonProps, EventBindings, ScopedSlots] = {
    vButton("Link")(
      RenderOptions(
        on = Some(
          EventBindings(
            click = js.defined(e => {
              implicit val token = u.token.get

              //Link, and refresh the token via checkSession if the link is successful
              LoadingFuture.withLoading(
                loadingEventBus,
                apiCalls.linkTelco(u.ddiVal, u.extVal, u.pinVal).map {
                  case Right(value) =>
                    if (value) {
                      u.alertVisible = false
                      u.screenMode = SelectedMode.LoadingPage
                      u.animateCard = true

                      for {
                        tokenJs <- EitherT.right[AccessForbidden](security.checkSession())
                        refreshedToken: SludgToken = new SludgToken(tokenJs.get.accessToken.toString)
                      } yield {
                        u.animateCard = false

                        u.currentTenantId = refreshedToken.subscribers.head.tid.toInt
                        u.currentSubId = refreshedToken.subscribers.head.sid.toInt
                        u.subscriber = refreshedToken.subscribers.headOption
                        u.$emit("refreshedToken", refreshedToken)
                      }
                    } else {
                      u.screenMode = SelectedMode.ScreenOne
                      u.alertVisible = true
                    }
                  case Left(value) => {
                    u.screenMode = SelectedMode.ScreenOne
                    u.alertVisible = true
                  }
                }
              )
            })
          )
        )
      )
    )
  }

  private def screenTwo(l: UserPortalComponent, renderer: CreateElement) = {
    div(
      RenderOptions(style = Some(js.Dynamic.literal("height" -> "100%", "width" -> "100%"))),
      animateCard(l),
      p("Your VTSL account details"),
      VDataTable.vDataTable(
        RenderOptions[VDataTableProps[TableItems, VDataTableHeader], EventBindings, VDataTableScopedSlots[TableItems, VDataTableHeader]](
          style = Some(js.Dynamic.literal("height" -> "100%", "width" -> "100%")),
          props = Some(
            VDataTableProps(
              items = Some(
                l.linkData match {
                  case Some(value) =>
                    List(
                      new TableItems("Name:", value.firstName + " " + value.lastName),
                      new TableItems("Email:", value.email),
                      new TableItems("Extension:", value.extension),
                      new TableItems("User type:", value.userStatus)
                    )
                  case None => Nil
                }
              ),
              `hide-actions` = Some(true),
              `hide-headers` = Some(true),
              dense = Some(true)
            )
          ),
          scopedSlots = Some(new VDataTableScopedSlots(items = js.defined(i => tr(td(i.item.a), td(i.item.b)).render(renderer))))
        )
      )
    )
  }

  /**
    * Animation used for when switching between screen one and screen two
    * @param l
    * @return
    */
  private def animateCard(l: UserPortalComponent) = {
    (if (l.animateCard) {
       RenderOptions(`class` = List(Left("initial")), style = Some(js.Dynamic.literal("height" -> "100%", "width" -> "100%")))
     } else {
       RenderOptions(`class` = List(Left("internal_animation")), style = Some(js.Dynamic.literal("height" -> "100%", "width" -> "100%")))
     })
  }

  private def screenOne(u: UserPortalComponent) = {
    div(
      animateCard(u),
      p("Enter your login credentials below to link your account with VTSL services."),
      vLayout(
        justifyCenter,
        vFlex(
          createTextField("DDI", hideInput = false, EventBindings(input = js.defined(e => u.ddiVal = e.toString))),
          createTextField("Extension", hideInput = false, EventBindings(input = js.defined(e => u.extVal = e.toString))),
          createTextField("PIN", hideInput = true, EventBindings(input = js.defined(e => u.pinVal = e.toString)))
        )
      )
    )
  }

  /**
    * The card used to display information
    * @param apiCalls
    * @param security
    * @param loader
    * @param l
    * @param r
    * @return
    */
  def cardDisplay(apiCalls: ApiCalls, security: Security, loader: Vue, l: UserPortalComponent, r: CreateElement): RenderHelpers.NodeRenderer[VueProps, EventBindings, ScopedSlots] = {
    div(
      vContainer(
        cardLoadAnimation(l),
        //Each individual layout needs the justify-center class here (believe me I tried)
        cardHeader(l),
        vLayout(
          justifyCenter,
          vCard(
            vContainer(
              RenderOptions(`class` = centerClass, style = Some(js.Dynamic.literal("width" -> "450px", "height" -> "365px"))),
              vLayout(
                RenderOptions(style = Some(js.Dynamic.literal("height" -> "280px")), `class` = List(Left("justify-end"))),
                l.screenMode match {
                  case SelectedMode.ScreenOne   => screenOne(l)
                  case SelectedMode.ScreenTwo   => screenTwo(l, r)
                  case SelectedMode.LoadingPage => div("")
                }
              ),
              linkButton(apiCalls, security, loader, l)
            ),
            Alert(l)
          )
        )
      )
    )
  }

  /**
    * Link buttons
    * @param apiCalls
    * @param security
    * @param loader
    * @param l
    * @return
    */
  private def linkButton(apiCalls: ApiCalls, security: Security, loader: Vue, l: UserPortalComponent): RenderHelpers.NodeRenderer[VGridProps, EventBindings, ScopedSlots] = {
    vLayout(
      RenderOptions(`class` = List(Left("justify-end"))),
      l.screenMode match {
        case SelectedMode.ScreenOne   => div(linkButton(l, security, apiCalls, loader))
        case SelectedMode.ScreenTwo   => div(unlinkButton(l, security, apiCalls, loader, l.token.get, l.subscriber.get))
        case SelectedMode.LoadingPage => div()
      }
    )
  }

  /**
    * This is the card header
    *
    * @param l
    * @return
    */
  private def cardHeader(l: UserPortalComponent): RenderHelpers.NodeRenderer[VGridProps, EventBindings, ScopedSlots] = {
    vLayout(
      justifyCenter,
      vCardTitle(
        headerLoadAnimation(l),
        if (l.subscriber.isDefined) "Link to VTSL services" else "User Information"
      )
    )
  }

  /**
    * This is the animation for the header which occures on load
    * @param l
    * @return
    */
  private def headerLoadAnimation(l: UserPortalComponent): RenderOptions[Nothing, Nothing, Nothing] = {
    if (l.userDetailsChecked && l.initialRenderingComplete) RenderOptions(`class` = List(Left("headline"), Left("title_after_load")))
    else RenderOptions(`class` = List(Left("headline"), Left("title_initial")))
  }

  /**
    * This is the animation which occurs on load, the css transitions from initial => after_load
    * After the initial details of the user is checked, the screen is selected for display, then after this screen
    * is rendered, the animation is applied.
    *
    * @param l
    * @return
    */
  private def cardLoadAnimation(l: UserPortalComponent): RenderOptions[Nothing, Nothing, Nothing] = {
    if (l.userDetailsChecked && l.initialRenderingComplete) RenderOptions(`class` = List(Left("after_load"))) else RenderOptions(`class` = List(Left("initial")))
  }

  /**
    * The error box which appears when you make a bad link
    * @param l
    * @return
    */
  private def Alert(l: UserPortalComponent): RenderHelpers.NodeRenderer[VAlertProps, EventBindings, ScopedSlots] = {
    vAlert("Invalid user credentials. Please check whether your DDI, extension and PIN are correct.")(
      RenderOptions(
        `class` = centerClass,
        style = Some(js.Dynamic.literal("width" -> "450px", "height" -> "80px")),
        props = Some(VAlertProps(`type` = Some("error"), value = Some(l.alertVisible), dismissible = Some(true))),
        on = Some(EventBindings(input = js.defined(e => l.alertVisible = false)))
      )
    )
  }

  /**
    * Generates a text feild for page one
    * @param label
    * @param hideInput
    * @param eventBindings
    * @return
    */
  def createTextField(label: String, hideInput: Boolean, eventBindings: EventBindings): RenderHelpers.NodeRenderer[VTextFieldProps, EventBindings, ScopedSlots] = {
    vTextField(
      RenderOptions(
        props = Some(VTextFieldProps(label = Some(label), rules = List(Validators.textNumberRules(label)), `type` = if (hideInput) Some("password") else None)),
        on = Some(eventBindings)
      )
    )
  }

  /**
    * Used for displaying details in the static list
    * @param a
    * @param b
    */
  class TableItems(val a: String, val b: String) extends js.Object

  sealed trait SelectedMode

  object SelectedMode {
    case object ScreenOne extends SelectedMode

    case object ScreenTwo extends SelectedMode

    case object LoadingPage extends SelectedMode
  }

}
