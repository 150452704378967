package com.sludg.vuetify.components

import com.sludg.scalajs.DynamicHelper
import com.sludg.vue.EventBindings.Trigger
import com.sludg.vue.{EventBindings, ScopedSlots, VNode, VueProps}
import org.scalajs.dom.Event
import org.scalajs.dom.raw.KeyboardEvent

import scala.reflect.ClassTag
import scala.scalajs.js
import scala.scalajs.js.|
import com.sludg.vue.RenderHelpers._

trait VAutocomplete {
  def vAutocomplete[A] =
    namedTag[VAutocompleteProps[A], VAutocompleteEventBindings, VAutocompleteScopedSlots[A]](
      "v-autocomplete"
    )
}

trait VAutocompleteEventBindings extends EventBindings {
  def search(e: Event): Unit
}

object VAutocompleteEventBindings {
  def applyEvent = js.undefined: js.UndefOr[js.Function1[Event, Unit]]
  def apply(
      click: Trigger[Event] = js.undefined: js.UndefOr[js.Function1[Event, Unit]],
      transitionEnd: Trigger[Event] = js.undefined: js.UndefOr[js.Function1[Event, Unit]],
      input: Trigger[Event] = js.undefined: js.UndefOr[js.Function1[Event, Unit]],
      change: Trigger[Event] = js.undefined: js.UndefOr[js.Function1[Event, Unit]],
      keyup: Trigger[KeyboardEvent] = js.undefined: js.UndefOr[js.Function1[KeyboardEvent, Unit]],
      keydown: Trigger[KeyboardEvent] = js.undefined: js.UndefOr[js.Function1[KeyboardEvent, Unit]],
      keypress: Trigger[KeyboardEvent] = js.undefined: js.UndefOr[js.Function1[KeyboardEvent, Unit]],
      mouseover: Trigger[Event] = js.undefined: js.UndefOr[js.Function1[Event, Unit]],
      mouseleave: Trigger[Event] = js.undefined: js.UndefOr[js.Function1[Event, Unit]],
      mouseenter: Trigger[Event] = js.undefined: js.UndefOr[js.Function1[Event, Unit]],
      mouseout: Trigger[Event] = js.undefined: js.UndefOr[js.Function1[Event, Unit]],
      mousemove: Trigger[Event] = js.undefined: js.UndefOr[js.Function1[Event, Unit]],
      onblur: Trigger[Event] = js.undefined: js.UndefOr[js.Function1[Event, Unit]],
      search: Trigger[Event] = js.undefined: js.UndefOr[js.Function1[Event, Unit]]
  ): VAutocompleteEventBindings = {
    DynamicHelper.buildViaDynamic(
      "click"              -> click,
      "input"              -> input,
      "change"             -> change,
      "transitionend"      -> transitionEnd,
      "keyup"              -> keyup,
      "keydown"            -> keydown,
      "keypress"           -> keypress,
      "mouseover"          -> mouseover,
      "mouseleave"         -> mouseleave,
      "mouseenter"         -> mouseenter,
      "mouseout"           -> mouseout,
      "mousemove"          -> mouseout,
      "onblur"             -> onblur,
      "update:searchInput" -> search
    )
  }
}

class VAutocompleteScopedSlots[A](
    val selection: js.UndefOr[js.Function1[VAutocompleteScopedSlotsSelectionData[A], VNode]] =
      js.undefined,
    val item: js.UndefOr[js.Function1[VAutocompleteScopedSlotsItemData[A], VNode]] = js.undefined
) extends ScopedSlots

trait VAutocompleteScopedSlotsSelectionData[A] extends js.Object {
  val item: A
  val index: Int
  val selected: Boolean
  val disabled: Boolean
}

trait VAutocompleteScopedSlotsItemData[A] extends js.Object {
  val item: A
  val title: String
}

object VAutocomplete extends VAutocomplete

trait VAutocompleteProps[A] extends VueProps {
  val `allow-overflow`: js.UndefOr[Boolean]                              = js.undefined
  val `auto-select-first`: js.UndefOr[Boolean]                           = js.undefined
  val autofocus: js.UndefOr[Boolean]                                     = js.undefined
  val box: js.UndefOr[Boolean]                                           = js.undefined
  val `cache-items`: js.UndefOr[Boolean]                                 = js.undefined
  val chips: js.UndefOr[Boolean]                                         = js.undefined
  val autocomplete: js.UndefOr[Boolean]                                  = js.undefined
  val clearable: js.UndefOr[Boolean]                                     = js.undefined
  val counter: js.UndefOr[Boolean]                                       = js.undefined
  val dark: js.UndefOr[Boolean]                                          = js.undefined
  val `deletable-chips`: js.UndefOr[Boolean]                             = js.undefined
  val dense: js.UndefOr[Boolean]                                         = js.undefined
  val disabled: js.UndefOr[Boolean]                                      = js.undefined
  val `dont-fill-mask-blanks`: js.UndefOr[Boolean]                       = js.undefined
  val error: js.UndefOr[Boolean]                                         = js.undefined
  val flat: js.UndefOr[Boolean]                                          = js.undefined
  val `full-width`: js.UndefOr[Boolean]                                  = js.undefined
  val `hide-details`: js.UndefOr[Boolean]                                = js.undefined
  val `hide-no-data`: js.UndefOr[Boolean]                                = js.undefined
  val `hide-selected`: js.UndefOr[Boolean]                               = js.undefined
  val light: js.UndefOr[Boolean]                                         = js.undefined
  val loading: js.UndefOr[Boolean]                                       = js.undefined
  val multiple: js.UndefOr[Boolean]                                      = js.undefined
  val `no-filter`: js.UndefOr[Boolean]                                   = js.undefined
  val `open-on-clear`: js.UndefOr[Boolean]                               = js.undefined
  val outline: js.UndefOr[Boolean]                                       = js.undefined
  val `persistent-hint`: js.UndefOr[Boolean]                             = js.undefined
  val placeholder: js.UndefOr[String]                                    = js.undefined
  val prefix: js.UndefOr[String]                                         = js.undefined
  val `readonly`: js.UndefOr[Boolean]                                    = js.undefined
  val `return-masked-value`: js.UndefOr[Boolean]                         = js.undefined
  val `return-object`: js.UndefOr[Boolean]                               = js.undefined
  val `reverse`: js.UndefOr[Boolean]                                     = js.undefined
  val `single-line`: js.UndefOr[Boolean]                                 = js.undefined
  val `small-chips`: js.UndefOr[Boolean]                                 = js.undefined
  val solo: js.UndefOr[Boolean]                                          = js.undefined
  val `solo-inverted`: js.UndefOr[Boolean]                               = js.undefined
  val success: js.UndefOr[Boolean]                                       = js.undefined
  val `validate-on-blur`: js.UndefOr[Boolean]                            = js.undefined
  val items: js.UndefOr[js.Array[String] | js.Array[js.Object]]          = js.undefined
  val `item-value`: js.UndefOr[String | js.Function1[js.Object, String]] = js.undefined
  val `item-text`: js.UndefOr[String | js.Function1[js.Object, String]]  = js.undefined
  val `no-data-text`: js.UndefOr[String]                                 = js.undefined
  val label: js.UndefOr[String]                                          = js.undefined
  val `success-messages`: js.UndefOr[String]                             = js.undefined
  val hint: js.UndefOr[String]                                           = js.undefined
  val `menu-props`: js.UndefOr[String | js.Array[String] | js.Object]    = js.undefined
  val `search-input`: js.UndefOr[String]                                 = js.undefined
}

object VAutocompleteProps {

  import scala.scalajs.js.JSConverters._

  def apply[A: ClassTag](
      `allow-overflow`: Option[Boolean] = None,
      `auto-select-first`: Option[Boolean] = None,
      autofocus: Option[Boolean] = None,
      box: Option[Boolean] = None,
      `cache-items`: Option[Boolean] = None,
      chips: Option[Boolean] = None,
      autocomplete: Option[Boolean] = None,
      clearable: Option[Boolean] = None,
      counter: Option[Boolean] = None,
      dark: Option[Boolean] = None,
      `deletable-chips`: Option[Boolean] = None,
      dense: Option[Boolean] = None,
      disabled: Option[Boolean] = None,
      `dont-fill-mask-blanks`: Option[Boolean] = None,
      error: Option[Boolean] = None,
      flat: Option[Boolean] = None,
      `full-width`: Option[Boolean] = None,
      `hide-details`: Option[Boolean] = None,
      `hide-no-data`: Option[Boolean] = None,
      `hide-selected`: Option[Boolean] = None,
      light: Option[Boolean] = None,
      loading: Option[Boolean] = None,
      multiple: Option[Boolean] = None,
      `no-filter`: Option[Boolean] = None,
      `open-on-clear`: Option[Boolean] = None,
      outline: Option[Boolean] = None,
      `persistent-hint`: Option[Boolean] = None,
      placeholder: Option[String] = None,
      prefix: Option[String] = None,
      `readonly`: Option[Boolean] = None,
      `return-masked-value`: Option[Boolean] = None,
      `return-object`: Option[Boolean] = None,
      `reverse`: Option[Boolean] = None,
      `single-line`: Option[Boolean] = None,
      `small-chips`: Option[Boolean] = None,
      solo: Option[Boolean] = None,
      `solo-inverted`: Option[Boolean] = None,
      success: Option[Boolean] = None,
      `validate-on-blur`: Option[Boolean] = None,
      items: Option[List[A]] = None,
      itemValue: Option[Either[String, A | List[A] => Any]] = None,
      itemText: Option[Either[String, A | List[A] => String]] = None,
      deletableChips: Option[Boolean] = None,
      soloInverted: Option[Boolean] = None,
      hideDetails: Option[Boolean] = None,
      noDataText: Option[String] = None,
      label: Option[String] = None,
      value: Option[Either[A, List[A]]] = None,
      `success-messages`: Option[String] = None,
      hint: Option[String] = None,
      `menu-props`: Option[VMenuProps] = None,
      searchInput: Option[String] = None
  ): VAutocompleteProps[A] = {
    val itemsJs = items.map(_.toJSArray).orUndefined

    //You must handle whether itemValue and itemText are single values or lists when implementing these props,
    val itemV: js.UndefOr[js.Any] = itemValue
      .map(
        _.fold(
          js.Any.fromString,
          f => {
            js.Any.fromFunction1(f.compose[A | js.Array[A]] { (v: Any) =>
              v match {
                case x: js.Array[_] => x.toList.collect { case a: A => a }
                case x: A           => x.asInstanceOf[A | List[A]]
              }
            })
          }
        )
      )
      .orUndefined
    val itemT: js.UndefOr[js.Any] = itemText
      .map(
        _.fold(
          js.Any.fromString,
          f => {
            js.Any.fromFunction1(f.compose[A | js.Array[A]] { (v: Any) =>
              v match {
                case x: js.Array[_] => x.toList.collect { case a: A => a }
                case x: A           => x.asInstanceOf[A | List[A]]
              }
            })
          }
        )
      )
      .orUndefined

    DynamicHelper.buildViaDynamic(
      "allow-overflow"        -> `allow-overflow`.map(js.Any.fromBoolean).orUndefined,
      "auto-select-first"     -> `auto-select-first`.map(js.Any.fromBoolean).orUndefined,
      "autofocus"             -> autofocus.map(js.Any.fromBoolean).orUndefined,
      "box"                   -> box.map(js.Any.fromBoolean).orUndefined,
      "cache-items"           -> `cache-items`.map(js.Any.fromBoolean).orUndefined,
      "chips"                 -> chips.map(js.Any.fromBoolean).orUndefined,
      "autocomplete"          -> autocomplete.map(js.Any.fromBoolean).orUndefined,
      "clearable"             -> clearable.map(js.Any.fromBoolean).orUndefined,
      "counter"               -> counter.map(js.Any.fromBoolean).orUndefined,
      "dark"                  -> dark.map(js.Any.fromBoolean).orUndefined,
      "deletable-chips"       -> `deletable-chips`.map(js.Any.fromBoolean).orUndefined,
      "dense"                 -> dense.map(js.Any.fromBoolean).orUndefined,
      "disabled"              -> disabled.map(js.Any.fromBoolean).orUndefined,
      "dont-fill-mask-blanks" -> `dont-fill-mask-blanks`.map(js.Any.fromBoolean).orUndefined,
      "error"                 -> error.map(js.Any.fromBoolean).orUndefined,
      "flat"                  -> flat.map(js.Any.fromBoolean).orUndefined,
      "full-width"            -> `full-width`.map(js.Any.fromBoolean).orUndefined,
      "hide-details"          -> `hide-details`.map(js.Any.fromBoolean).orUndefined,
      "hide-no-data"          -> `hide-no-data`.map(js.Any.fromBoolean).orUndefined,
      "hide-selected"         -> `hide-selected`.map(js.Any.fromBoolean).orUndefined,
      "light"                 -> light.map(js.Any.fromBoolean).orUndefined,
      "loading"               -> loading.map(js.Any.fromBoolean).orUndefined,
      "multiple"              -> multiple.map(js.Any.fromBoolean).orUndefined,
      "no-filter"             -> `no-filter`.map(js.Any.fromBoolean).orUndefined,
      "open-on-clear"         -> `open-on-clear`.map(js.Any.fromBoolean).orUndefined,
      "outline"               -> outline.map(js.Any.fromBoolean).orUndefined,
      "persistent-hint"       -> `persistent-hint`.map(js.Any.fromBoolean).orUndefined,
      "placeholder"           -> placeholder.map(js.Any.fromString).orUndefined,
      "prefix"                -> prefix.map(js.Any.fromString).orUndefined,
      "readonly"              -> readonly.map(js.Any.fromBoolean).orUndefined,
      "return-masked-value"   -> `return-masked-value`.map(js.Any.fromBoolean).orUndefined,
      "return-object"         -> `return-object`.map(js.Any.fromBoolean).orUndefined,
      "reverse"               -> reverse.map(js.Any.fromBoolean).orUndefined,
      "single-line"           -> `single-line`.map(js.Any.fromBoolean).orUndefined,
      "small-chips"           -> `small-chips`.map(js.Any.fromBoolean).orUndefined,
      "solo"                  -> solo.map(js.Any.fromBoolean).orUndefined,
      "solo-inverted"         -> `solo-inverted`.map(js.Any.fromBoolean).orUndefined,
      "success"               -> success.map(js.Any.fromBoolean).orUndefined,
      "validate-on-blur"      -> `validate-on-blur`.map(js.Any.fromBoolean).orUndefined,
      "items"                 -> itemsJs,
      "item-value"            -> itemV,
      "item-text"             -> itemT,
      "clearable"             -> clearable.map(js.Any.fromBoolean).orUndefined,
      "deletable-chips"       -> chips.map(js.Any.fromBoolean).orUndefined,
      "solo-inverted"         -> soloInverted.map(js.Any.fromBoolean).orUndefined,
      "hide-details"          -> hideDetails.map(js.Any.fromBoolean).orUndefined,
      "no-data-text"          -> noDataText.map(js.Any.fromString).orUndefined,
      "label"                 -> label.map(js.Any.fromString).orUndefined,
      "value" -> value
        .map({
          case Left(x)  => x.asInstanceOf[js.Any]
          case Right(x) => x.toJSArray
        })
        .orUndefined,
      "success-messages" -> `success-messages`.map(js.Any.fromString).orUndefined,
      "hint"             -> hint.map(js.Any.fromString).orUndefined,
      "menu-props"       -> `menu-props`.orUndefined,
      "hint"             -> hint.map(js.Any.fromString).orUndefined,
      "search-input"     -> searchInput.map(js.Any.fromString).orUndefined
    )
  }
}
