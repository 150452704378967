package com.sludg.client.pages.components.diverts

import com.sludg.FieldExtractor
import cats.kernel.Monoid
import com.sludg.util.Validators
import com.sludg.util.models.SilhouetteModels
import com.sludg.vue.RenderHelpers._
import com.sludg.vue.{
  EventBindings,
  RenderHelpers,
  RenderOptions,
  ScopedSlots,
  VNode,
  Vue,
  VueProps
}
import com.sludg.vuetify.VuetifyComponents._
import com.sludg.vuetify.components.{
  VAutocompleteProps,
  VButtonProps,
  VIconProps,
  VListProps,
  VTextFieldProps
}
import com.sludg.util.PresenterSyntax._
import com.sludg.util.SilhouettePresenters._
import cats.implicits._
import com.sludg.auth0.SludgToken
import com.sludg.services.ApiCalls
import com.sludg.util.models.SilhouetteModels.{
  DIDNumber,
  EmergencyOverride,
  HourlyExtensions,
  SilhouetteLocation,
  Subscriber
}

import scala.scalajs.js
import com.sludg.vuetify.components.VAutocompleteEventBindings

/** DDIUtil has one main purpose - housing shared functionality that is used across the did section of the User Portal.
  * It does this by housing renderfunctions for input that are used in multiple places, and general models and functions.
  */

object DDIUtil {

  object RenderFunctions {

    def cardTitleHeadline(titleText: String, did: DIDNumber, buttonEvent: EventBindings) = {
      List(
        vCardTitle(
          titleText,
          vSpacer,
          vIcon("clear", RenderOptions(on = Some(buttonEvent))),
          RenderOptions(
            style = Some(
              js.Dynamic.literal(
                "font-size" -> "20px",
                "padding-bottom" -> "8px"
              )
            ),
            `class` = List(Left("font-weight-medium"))
          )
        ),
        vCardTitle(
          did.phoneNumber,
          RenderOptions(
            style = Some(
              js.Dynamic.literal(
                "font-size" -> "16px",
                "padding-top" -> "0px",
                "padding-bottom" -> "8px"
              )
            )
          )
        ),
        vDivider
      )
    }

    def extensionTypeInputSelector(
        eventBindings: VAutocompleteEventBindings,
        label: String,
        disabledProp: Boolean,
        valueProp: Option[OverrideInputType],
        removeExternalSelection: Boolean
    ) = {
      vAutocomplete[OverrideInputType](
        RenderOptions(
          props = Some(
            VAutocompleteProps[OverrideInputType](
              disabled = Some(disabledProp),
              label = Some(label),
              items =
                if (removeExternalSelection)
                  Some(List(AutoAttendant, CallGroup, Extension)): Option[List[OverrideInputType]]
                else
                  Some(List(AutoAttendant, CallGroup, Extension, External)): Option[
                    List[OverrideInputType]
                  ],
              itemText = Some(Right(((g) => {
                (g: Any) match {
                  case lil: List[_] =>
                    lil.collect { case oit: OverrideInputType => overrideTypeToText(oit) }.mkString
                  case other: OverrideInputType => overrideTypeToText(other)
                }
              }))),
              `return-object` = Some(true),
              value = valueProp.map(x => Right(List(x)))
            )
          ),
          on = Some(
            eventBindings
          )
        )
      )
    }

    def numberInputSelector(
        autoEventBindings: VAutocompleteEventBindings,
        label: String,
        disabledProp: Boolean,
        routingExtensionType: Option[OverrideInputType],
        extensionInput: List[ExtensionInput],
        numberDestination: Option[String]
    ) = {
      //Many autocomplete fields can be either a single item or a list, so exhaustive matching is necessary here
      vAutocomplete[ExtensionInput](
        RenderOptions(
          props = Some(
            VAutocompleteProps[ExtensionInput](
              label = Some(routingExtensionType.map(overrideTypeToText).getOrElse(label)),
              clearable = Some(true),
              disabled = Some(disabledProp),
              items = Some(extensionInput),
              itemValue = Some(
                Right(x =>
                  (x: Any) match {
                    case first :: _ => first
                    case one => one
                  }
                )
              ),
              itemText = Some(
                Right(x =>
                  (x: Any) match {
                    case lil: List[_] =>
                      lil.collect { case oit: ExtensionInput =>
                        oit.extensionData.get.present
                      }.mkString
                    case ExtensionInput(_, _, Some(data)) => data.present
                    case other => other.toString
                  }
                )
              ),
              `return-object` = Some(true),
              value = Some(Right(extensionInput.collect {
                case ext if ext.text == numberDestination => ext
              }))
            )
          ),
          on = Some(
            autoEventBindings
          )
        )
      )
    }

    def textFieldNameInput(
        valueProp: String,
        disabledProp: Boolean,
        eventBindings: EventBindings
    ) = {
      vFlex(
        vTextField(
          RenderOptions(
            props = Some(
              VTextFieldProps(
                label = Some("Note"),
                disabled = Some(disabledProp),
                value = Some(valueProp),
                clearable = Some(true)
              )
            ),
            on = Some(
              eventBindings
            )
          )
        )
      )
    }

    def menuDropDown(
        component: DDIDisplayerData with DDIDisplayerProps,
        vueBus: Vue,
        apiCalls: ApiCalls,
        id: Int
    )(implicit token: SludgToken) = {
      vMenu(
        vButton(
          vIcon("more_vert"),
          RenderOptions(
            style = Some(
              js.Dynamic.literal(
                "margin" -> "0px",
                "margin-left" -> "2px"
              )
            ),
            slot = Some("activator"),
            props = Some(
              VButtonProps(
                fab = Some(true),
                flat = Some(true),
                left = Some(true)
//              right = Some(true)
              )
            ),
            on = Some(
              EventBindings(
                click = js.defined(e => {
                  component.showMenu = true
                  component.currentlySelectedDid =
                    DDIUtil.collectDidFromTable(component.didsOnTenant, id)
                  component.hourlyLocations = DDIUtil
                    .collectHourExtensionsFromDid(component.currentlySelectedDid)
                    .getOrElse(HourlyExtensions(None, None, None, None))
                })
              )
            )
          )
        ),
        vList(
          RenderOptions(
            props = Some(
              VListProps(
                `two-line` = Some(true)
              )
            )
          ),
          vListTile(
            vListTileContent("Routing"),
            vListTileAction(vIcon("sync_alt")),
            RenderOptions(
              on = Some(
                EventBindings(
                  click = js.defined(e => {
                    component.showMenu = false
                    component.snackbarValues = SnackbarData(false, Routing)
                    component.routingDialogVisible = true
                  })
                )
              )
            )
          ),
          vListTile(
            vListTileContent("Diverts"),
            vListTileAction(vIcon("call_made")),
            RenderOptions(
              on = Some(
                EventBindings(
                  click = js.defined(e => {
                    component.showMenu = false
                    component.snackbarValues = SnackbarData(false, Divert)
                    component.divertDialogVisible = true
                  })
                )
              )
            )
          )
        )
      )
    }

    def summariseHourlyExtensions(
        didHours: DIDHours
    ): List[RenderHelpers.NodeRenderer[VueProps, EventBindings, ScopedSlots]] = {
      //Turns extensions and hourly locations into a summarised sequence of p tags for each unique extension
      //e.g DID(closed = Some(333), lunch = Some(444), open = None, special = Some(333), becomes
      //p("333 - Closed, Special")
      //p("444 - Lunch")
      Monoid
        .combineAll(
          List(
            didHours.open.map(e => DDIUtil.toHours("Open", e)),
            didHours.closed.map(e => DDIUtil.toHours("Closed", e)),
            didHours.lunch.map(e => DDIUtil.toHours("Lunch", e)),
            didHours.special.map(e => DDIUtil.toHours("Special", e))
          ).flatten
        )
        .map { case (ext, hours) =>
          vListTileSubTitle(
            s"${hours.mkString(", ")}: ${ext}",
            RenderOptions(style = Some(js.Dynamic.literal("margin" -> "0px")))
          )
        }
        .toList
    }

    def whichIcon(did: DIDNumber) = {
      DDIUtil.didStatus(
        did,
        vIcon(
          RenderOptions(props = Some(VIconProps(color = Some("#adadad")))),
          "call_end"
        ), //Light grey
        vIcon("phone"),
        vIcon(RenderOptions(props = Some(VIconProps(color = Some("red")))), "phone_forwarded")
      )
    }

  }

  def isDivertEnablable(did: DIDNumber): Boolean =
    did.emergencyOverride.number.exists(_.trim.nonEmpty)

  def areHoursEmpty(did: DIDNumber): Boolean = {
    did.openHoursExtension.exists(_.trim.isEmpty) &&
    did.closedHoursExtension.exists(_.trim.isEmpty) &&
    did.specialHoursExtension.exists(_.trim.isEmpty) &&
    did.lunchHoursExtension.exists(_.trim.isEmpty)
  }

  /*
   * We typically need to set various fields based on the status of the did, but keeping a bunch of otherwise
   * unrelated information in one place didn't feel great. This method lets you specify the values you'd like
   * to set, and under what circumstances they'll be displayed.
   * Try to use as "high up" within a component as possible, so the check isn't performed multiple times.
   * */
  def didStatus[A](
      did: DIDNumber,
      noHoursOrOverrideSet: A,
      overrideInactive: A,
      overrideActive: A
  ): A = {
    if (!did.assigned) {
      //Neither hours are set, or is an override active - the number is not used
      noHoursOrOverrideSet
    } else if (!did.emergencyOverride.active) {
      overrideInactive
    } else {
      overrideActive
    }
  }

  def getExtensionSil(
      extension: String,
      tenantSubscribers: List[SilhouetteLocation],
      tenantAutoAttendants: List[SilhouetteLocation],
      tenantCallGroups: List[SilhouetteLocation]
  ): String = {
    //Checks any of the three silhouette locations for the given extension, and presents it if found
    extension match {
      case ext if tenantSubscribers.exists(_.extension == ext) =>
        tenantSubscribers.find(_.extension == ext).toList.present
      case ext if tenantAutoAttendants.exists(_.extension == ext) =>
        tenantAutoAttendants.find(_.extension == ext).toList.present
      case ext if tenantCallGroups.exists(_.extension == ext) =>
        tenantCallGroups.find(_.extension == ext).toList.present
      case ext => ext
    }
  }

  def overrideTypeToText(overrideInput: OverrideInputType): String = {
    overrideInput match {
      case AutoAttendant => "Auto Attendant"
      case CallGroup => "Call Group"
      case other => other.toString
    }
  }

  def collectDidFromTable(dids: Iterable[DIDNumber], id: Int): Option[DIDNumber] = {
    dids.collectFirst {
      case did if did.didId == id => did
    }
  }

  def collectHourExtensionsFromDid(didOpt: Option[DIDNumber]): Option[HourlyExtensions] = {
    //Extensions and name labels are coupled together (sometimes) via our api, this method takes only the extension.
    didOpt.map(did =>
      HourlyExtensions(
        did.closedHoursExtension.map(_.split(" - ")(0)),
        did.lunchHoursExtension.map(_.split(" - ")(0)),
        did.openHoursExtension.map(_.split(" - ")(0)),
        did.specialHoursExtension.map(_.split(" - ")(0))
      )
    )
  }

  def silhouetteUserToExtensionInput(
      list: List[SilhouetteModels.SilhouetteLocation]
  ): List[ExtensionInput] = {
    list.map(s => ExtensionInput(Some(s.extension), Some(s.extension), Some(s)))
  }

  def findNumberFromSilhouette(
      subscribers: List[SilhouetteModels.SilhouetteLocation],
      autoAttendants: List[SilhouetteModels.SilhouetteLocation],
      callGroups: List[SilhouetteModels.SilhouetteLocation],
      divertNumber: String
  ): (List[SilhouetteModels.SilhouetteLocation], Option[OverrideInputType]) = {
    if ((divertNumber.length < 1)) {
      (Nil, None)
    } else if (autoAttendants.exists(_.extension == divertNumber)) {
      (autoAttendants, Some(AutoAttendant))
    } else if (callGroups.exists(_.extension == divertNumber)) {
      (callGroups, Some(CallGroup))
    } else if (subscribers.exists(_.extension == divertNumber)) {
      (subscribers, Some(Extension))
    } else {
      (Nil, Some(External))
    }
  }

  def removeNineIfExternal(number: String): String = {
    if (number.length > 4 && number.startsWith("9")) number.tail else number
  }

  def booleanToText(bool: Boolean, trueText: String, falseText: String): String =
    if (bool) trueText else falseText

  def toHours(h: String, s: String): Map[String, List[String]] = Map(s -> List(h))

  trait DDITableScopedSlots extends ScopedSlots {
    val items: js.UndefOr[js.Function1[DDITableScopedSlotItems, VNode]]
  }

  trait DDITableScopedSlotItems extends js.Object {
    var item: DDITableItems
    val index: Int
    val selected: Boolean
  }

  trait DDITableItems extends js.Object {
    //Do not display, ties the did to its place within the table
    var didId: js.UndefOr[String] = js.undefined
    var phoneNumber: js.UndefOr[String] = js.undefined
    var assigned: js.UndefOr[String] = js.undefined
    var incomingName: js.UndefOr[String] = js.undefined
    var closedHoursExtension: js.UndefOr[String] = js.undefined
    var lunchHoursExtension: js.UndefOr[String] = js.undefined
    var openHoursExtension: js.UndefOr[String] = js.undefined
    var specialHoursExtension: js.UndefOr[String] = js.undefined
    var emergencyOverride: js.UndefOr[String] = js.undefined
    var emergencyOverrideNumber: js.UndefOr[String] = js.undefined
    var emergencyOverrideName: js.UndefOr[String] = js.undefined
    var hours: js.UndefOr[DIDHours] = js.undefined
    var divertSummary: js.UndefOr[String] = js.undefined
  }

  object DDITableItems {

    import scala.scalajs.js.JSConverters._

    def apply(
        didId: Option[String] = None,
        phoneNumber: Option[String] = None,
        assigned: Option[String] = None,
        incomingName: Option[String] = None,
        closedHoursExtension: Option[String] = None,
        lunchHoursExtension: Option[String] = None,
        openHoursExtension: Option[String] = None,
        specialHoursExtension: Option[String] = None,
        emergencyOverride: Option[String] = None,
        emergencyOverrideNumber: Option[String] = None,
        emergencyOverrideName: Option[String] = None,
        hours: Option[String] = None,
        divertSummary: Option[String] = None
    ): DDITableItems = {
      js.Dynamic
        .literal(
          "didId" -> didId.map(js.Any.fromString).orUndefined,
          "phoneNumber" -> phoneNumber.map(js.Any.fromString).orUndefined,
          "assigned" -> assigned.map(js.Any.fromString).orUndefined,
          "incomingName" -> incomingName.map(js.Any.fromString).orUndefined,
          "closedHoursExtension" -> closedHoursExtension.map(js.Any.fromString).orUndefined,
          "lunchHoursExtension" -> lunchHoursExtension.map(js.Any.fromString).orUndefined,
          "openHoursExtension" -> openHoursExtension.map(js.Any.fromString).orUndefined,
          "specialHoursExtension" -> specialHoursExtension.map(js.Any.fromString).orUndefined,
          "emergencyOverride" -> emergencyOverride.map(js.Any.fromString).orUndefined,
          "emergencyOverrideNumber" -> emergencyOverrideNumber.map(js.Any.fromString).orUndefined,
          "emergencyOverrideName" -> emergencyOverrideName.map(js.Any.fromString).orUndefined,
          "hours" -> hours.map(js.Any.fromString).orUndefined,
          "divertSummary" -> divertSummary.map(js.Any.fromString).orUndefined
        )
        .asInstanceOf[DDITableItems]
    }
  }

}

case class DIDHours(
    closed: Option[String],
    lunch: Option[String],
    open: Option[String],
    special: Option[String]
)

sealed trait OverrideInputType

case object AutoAttendant extends OverrideInputType
case object Extension extends OverrideInputType
case object External extends OverrideInputType
case object CallGroup extends OverrideInputType

//A type to store all extension information in one object for the autocomplete to reference
case class ExtensionInput(
    text: Option[String],
    value: Option[String],
    extensionData: Option[SilhouetteModels.SilhouetteLocation]
)

sealed trait HourlyType

case object Open extends HourlyType
case object Closed extends HourlyType
case object Lunch extends HourlyType
case object Special extends HourlyType

