package com.sludg.vuetify.components.grid

import com.sludg.vue.{EventBindings, ScopedSlots, VueProps}

import scala.scalajs.js

/**
  * @author dpoliakas
  *         Date: 22/11/2018
  *         Time: 17:20
  *
  *
  */
trait VFlex {

  import com.sludg.vue.RenderHelpers._

  val vFlex = namedTag[VFlexProps, EventBindings, ScopedSlots]("v-flex")

}

object VFlex extends VFlex

trait VFlexProps extends VueProps {

  val id: js.UndefOr[String]  = js.undefined
  val tag: js.UndefOr[String] = js.undefined

  val `align-self-baseline`: js.UndefOr[Boolean] = js.undefined
  val `align-self-center`: js.UndefOr[Boolean]   = js.undefined
  val `align-self-end`: js.UndefOr[Boolean]      = js.undefined
  val `align-self-start`: js.UndefOr[Boolean]    = js.undefined

  val grow: js.UndefOr[Boolean]   = js.undefined
  val shrink: js.UndefOr[Boolean] = js.undefined

  val `xs-1`: js.UndefOr[Boolean]         = js.undefined
  val `xs-2`: js.UndefOr[Boolean]         = js.undefined
  val `xs-3`: js.UndefOr[Boolean]         = js.undefined
  val `xs-4`: js.UndefOr[Boolean]         = js.undefined
  val `xs-5`: js.UndefOr[Boolean]         = js.undefined
  val `xs-6`: js.UndefOr[Boolean]         = js.undefined
  val `xs-7`: js.UndefOr[Boolean]         = js.undefined
  val `xs-8`: js.UndefOr[Boolean]         = js.undefined
  val `xs-9`: js.UndefOr[Boolean]         = js.undefined
  val `xs-10`: js.UndefOr[Boolean]        = js.undefined
  val `xs-11`: js.UndefOr[Boolean]        = js.undefined
  val `xs-12`: js.UndefOr[Boolean]        = js.undefined
  val `sm-1`: js.UndefOr[Boolean]         = js.undefined
  val `sm-2`: js.UndefOr[Boolean]         = js.undefined
  val `sm-3`: js.UndefOr[Boolean]         = js.undefined
  val `sm-4`: js.UndefOr[Boolean]         = js.undefined
  val `sm-5`: js.UndefOr[Boolean]         = js.undefined
  val `sm-6`: js.UndefOr[Boolean]         = js.undefined
  val `sm-7`: js.UndefOr[Boolean]         = js.undefined
  val `sm-8`: js.UndefOr[Boolean]         = js.undefined
  val `sm-9`: js.UndefOr[Boolean]         = js.undefined
  val `sm-10`: js.UndefOr[Boolean]        = js.undefined
  val `sm-11`: js.UndefOr[Boolean]        = js.undefined
  val `sm-12`: js.UndefOr[Boolean]        = js.undefined
  val `md-1`: js.UndefOr[Boolean]         = js.undefined
  val `md-2`: js.UndefOr[Boolean]         = js.undefined
  val `md-3`: js.UndefOr[Boolean]         = js.undefined
  val `md-4`: js.UndefOr[Boolean]         = js.undefined
  val `md-5`: js.UndefOr[Boolean]         = js.undefined
  val `md-6`: js.UndefOr[Boolean]         = js.undefined
  val `md-7`: js.UndefOr[Boolean]         = js.undefined
  val `md-8`: js.UndefOr[Boolean]         = js.undefined
  val `md-9`: js.UndefOr[Boolean]         = js.undefined
  val `md-10`: js.UndefOr[Boolean]        = js.undefined
  val `md-11`: js.UndefOr[Boolean]        = js.undefined
  val `md-12`: js.UndefOr[Boolean]        = js.undefined
  val `lg-1`: js.UndefOr[Boolean]         = js.undefined
  val `lg-2`: js.UndefOr[Boolean]         = js.undefined
  val `lg-3`: js.UndefOr[Boolean]         = js.undefined
  val `lg-4`: js.UndefOr[Boolean]         = js.undefined
  val `lg-5`: js.UndefOr[Boolean]         = js.undefined
  val `lg-6`: js.UndefOr[Boolean]         = js.undefined
  val `lg-7`: js.UndefOr[Boolean]         = js.undefined
  val `lg-8`: js.UndefOr[Boolean]         = js.undefined
  val `lg-9`: js.UndefOr[Boolean]         = js.undefined
  val `lg-10`: js.UndefOr[Boolean]        = js.undefined
  val `lg-11`: js.UndefOr[Boolean]        = js.undefined
  val `lg-12`: js.UndefOr[Boolean]        = js.undefined
  val `xl-1`: js.UndefOr[Boolean]         = js.undefined
  val `xl-2`: js.UndefOr[Boolean]         = js.undefined
  val `xl-3`: js.UndefOr[Boolean]         = js.undefined
  val `xl-4`: js.UndefOr[Boolean]         = js.undefined
  val `xl-5`: js.UndefOr[Boolean]         = js.undefined
  val `xl-6`: js.UndefOr[Boolean]         = js.undefined
  val `xl-7`: js.UndefOr[Boolean]         = js.undefined
  val `xl-8`: js.UndefOr[Boolean]         = js.undefined
  val `xl-9`: js.UndefOr[Boolean]         = js.undefined
  val `xl-10`: js.UndefOr[Boolean]        = js.undefined
  val `xl-11`: js.UndefOr[Boolean]        = js.undefined
  val `xl-12`: js.UndefOr[Boolean]        = js.undefined
  val `offset-xs-1`: js.UndefOr[Boolean]  = js.undefined
  val `offset-xs-2`: js.UndefOr[Boolean]  = js.undefined
  val `offset-xs-3`: js.UndefOr[Boolean]  = js.undefined
  val `offset-xs-4`: js.UndefOr[Boolean]  = js.undefined
  val `offset-xs-5`: js.UndefOr[Boolean]  = js.undefined
  val `offset-xs-6`: js.UndefOr[Boolean]  = js.undefined
  val `offset-xs-7`: js.UndefOr[Boolean]  = js.undefined
  val `offset-xs-8`: js.UndefOr[Boolean]  = js.undefined
  val `offset-xs-9`: js.UndefOr[Boolean]  = js.undefined
  val `offset-xs-10`: js.UndefOr[Boolean] = js.undefined
  val `offset-xs-11`: js.UndefOr[Boolean] = js.undefined
  val `offset-xs-12`: js.UndefOr[Boolean] = js.undefined
  val `offset-sm-1`: js.UndefOr[Boolean]  = js.undefined
  val `offset-sm-2`: js.UndefOr[Boolean]  = js.undefined
  val `offset-sm-3`: js.UndefOr[Boolean]  = js.undefined
  val `offset-sm-4`: js.UndefOr[Boolean]  = js.undefined
  val `offset-sm-5`: js.UndefOr[Boolean]  = js.undefined
  val `offset-sm-6`: js.UndefOr[Boolean]  = js.undefined
  val `offset-sm-7`: js.UndefOr[Boolean]  = js.undefined
  val `offset-sm-8`: js.UndefOr[Boolean]  = js.undefined
  val `offset-sm-9`: js.UndefOr[Boolean]  = js.undefined
  val `offset-sm-10`: js.UndefOr[Boolean] = js.undefined
  val `offset-sm-11`: js.UndefOr[Boolean] = js.undefined
  val `offset-sm-12`: js.UndefOr[Boolean] = js.undefined
  val `offset-md-1`: js.UndefOr[Boolean]  = js.undefined
  val `offset-md-2`: js.UndefOr[Boolean]  = js.undefined
  val `offset-md-3`: js.UndefOr[Boolean]  = js.undefined
  val `offset-md-4`: js.UndefOr[Boolean]  = js.undefined
  val `offset-md-5`: js.UndefOr[Boolean]  = js.undefined
  val `offset-md-6`: js.UndefOr[Boolean]  = js.undefined
  val `offset-md-7`: js.UndefOr[Boolean]  = js.undefined
  val `offset-md-8`: js.UndefOr[Boolean]  = js.undefined
  val `offset-md-9`: js.UndefOr[Boolean]  = js.undefined
  val `offset-md-10`: js.UndefOr[Boolean] = js.undefined
  val `offset-md-11`: js.UndefOr[Boolean] = js.undefined
  val `offset-md-12`: js.UndefOr[Boolean] = js.undefined
  val `offset-lg-1`: js.UndefOr[Boolean]  = js.undefined
  val `offset-lg-2`: js.UndefOr[Boolean]  = js.undefined
  val `offset-lg-3`: js.UndefOr[Boolean]  = js.undefined
  val `offset-lg-4`: js.UndefOr[Boolean]  = js.undefined
  val `offset-lg-5`: js.UndefOr[Boolean]  = js.undefined
  val `offset-lg-6`: js.UndefOr[Boolean]  = js.undefined
  val `offset-lg-7`: js.UndefOr[Boolean]  = js.undefined
  val `offset-lg-8`: js.UndefOr[Boolean]  = js.undefined
  val `offset-lg-9`: js.UndefOr[Boolean]  = js.undefined
  val `offset-lg-10`: js.UndefOr[Boolean] = js.undefined
  val `offset-lg-11`: js.UndefOr[Boolean] = js.undefined
  val `offset-lg-12`: js.UndefOr[Boolean] = js.undefined
  val `offset-xl-1`: js.UndefOr[Boolean]  = js.undefined
  val `offset-xl-2`: js.UndefOr[Boolean]  = js.undefined
  val `offset-xl-3`: js.UndefOr[Boolean]  = js.undefined
  val `offset-xl-4`: js.UndefOr[Boolean]  = js.undefined
  val `offset-xl-5`: js.UndefOr[Boolean]  = js.undefined
  val `offset-xl-6`: js.UndefOr[Boolean]  = js.undefined
  val `offset-xl-7`: js.UndefOr[Boolean]  = js.undefined
  val `offset-xl-8`: js.UndefOr[Boolean]  = js.undefined
  val `offset-xl-9`: js.UndefOr[Boolean]  = js.undefined
  val `offset-xl-10`: js.UndefOr[Boolean] = js.undefined
  val `offset-xl-11`: js.UndefOr[Boolean] = js.undefined
  val `offset-xl-12`: js.UndefOr[Boolean] = js.undefined
  val `order-xs-1`: js.UndefOr[Boolean]   = js.undefined
  val `order-xs-2`: js.UndefOr[Boolean]   = js.undefined
  val `order-xs-3`: js.UndefOr[Boolean]   = js.undefined
  val `order-xs-4`: js.UndefOr[Boolean]   = js.undefined
  val `order-xs-5`: js.UndefOr[Boolean]   = js.undefined
  val `order-xs-6`: js.UndefOr[Boolean]   = js.undefined
  val `order-xs-7`: js.UndefOr[Boolean]   = js.undefined
  val `order-xs-8`: js.UndefOr[Boolean]   = js.undefined
  val `order-xs-9`: js.UndefOr[Boolean]   = js.undefined
  val `order-xs-10`: js.UndefOr[Boolean]  = js.undefined
  val `order-xs-11`: js.UndefOr[Boolean]  = js.undefined
  val `order-xs-12`: js.UndefOr[Boolean]  = js.undefined
  val `order-sm-1`: js.UndefOr[Boolean]   = js.undefined
  val `order-sm-2`: js.UndefOr[Boolean]   = js.undefined
  val `order-sm-3`: js.UndefOr[Boolean]   = js.undefined
  val `order-sm-4`: js.UndefOr[Boolean]   = js.undefined
  val `order-sm-5`: js.UndefOr[Boolean]   = js.undefined
  val `order-sm-6`: js.UndefOr[Boolean]   = js.undefined
  val `order-sm-7`: js.UndefOr[Boolean]   = js.undefined
  val `order-sm-8`: js.UndefOr[Boolean]   = js.undefined
  val `order-sm-9`: js.UndefOr[Boolean]   = js.undefined
  val `order-sm-10`: js.UndefOr[Boolean]  = js.undefined
  val `order-sm-11`: js.UndefOr[Boolean]  = js.undefined
  val `order-sm-12`: js.UndefOr[Boolean]  = js.undefined
  val `order-md-1`: js.UndefOr[Boolean]   = js.undefined
  val `order-md-2`: js.UndefOr[Boolean]   = js.undefined
  val `order-md-3`: js.UndefOr[Boolean]   = js.undefined
  val `order-md-4`: js.UndefOr[Boolean]   = js.undefined
  val `order-md-5`: js.UndefOr[Boolean]   = js.undefined
  val `order-md-6`: js.UndefOr[Boolean]   = js.undefined
  val `order-md-7`: js.UndefOr[Boolean]   = js.undefined
  val `order-md-8`: js.UndefOr[Boolean]   = js.undefined
  val `order-md-9`: js.UndefOr[Boolean]   = js.undefined
  val `order-md-10`: js.UndefOr[Boolean]  = js.undefined
  val `order-md-11`: js.UndefOr[Boolean]  = js.undefined
  val `order-md-12`: js.UndefOr[Boolean]  = js.undefined
  val `order-lg-1`: js.UndefOr[Boolean]   = js.undefined
  val `order-lg-2`: js.UndefOr[Boolean]   = js.undefined
  val `order-lg-3`: js.UndefOr[Boolean]   = js.undefined
  val `order-lg-4`: js.UndefOr[Boolean]   = js.undefined
  val `order-lg-5`: js.UndefOr[Boolean]   = js.undefined
  val `order-lg-6`: js.UndefOr[Boolean]   = js.undefined
  val `order-lg-7`: js.UndefOr[Boolean]   = js.undefined
  val `order-lg-8`: js.UndefOr[Boolean]   = js.undefined
  val `order-lg-9`: js.UndefOr[Boolean]   = js.undefined
  val `order-lg-10`: js.UndefOr[Boolean]  = js.undefined
  val `order-lg-11`: js.UndefOr[Boolean]  = js.undefined
  val `order-lg-12`: js.UndefOr[Boolean]  = js.undefined
  val `order-xl-1`: js.UndefOr[Boolean]   = js.undefined
  val `order-xl-2`: js.UndefOr[Boolean]   = js.undefined
  val `order-xl-3`: js.UndefOr[Boolean]   = js.undefined
  val `order-xl-4`: js.UndefOr[Boolean]   = js.undefined
  val `order-xl-5`: js.UndefOr[Boolean]   = js.undefined
  val `order-xl-6`: js.UndefOr[Boolean]   = js.undefined
  val `order-xl-7`: js.UndefOr[Boolean]   = js.undefined
  val `order-xl-8`: js.UndefOr[Boolean]   = js.undefined
  val `order-xl-9`: js.UndefOr[Boolean]   = js.undefined
  val `order-xl-10`: js.UndefOr[Boolean]  = js.undefined
  val `order-xl-11`: js.UndefOr[Boolean]  = js.undefined
  val `order-xl-12`: js.UndefOr[Boolean]  = js.undefined

}

object VFlexProps {

  import VFlexPropValues._

  def apply(
      sizeSettings: List[VFlexSize] = Nil,
      alignment: Option[Alignment] = None,
      growth: Option[Growth] = None,
      id: Option[String] = None,
      tag: Option[String] = None
  ): VFlexProps = {

    val props = sizeSettings.foldRight(js.Dynamic.literal()) {
      case (size, props) =>
        props.updateDynamic(size)(true)
        props
    }

    growth match {
      case Some(Grow)   => props.updateDynamic("grow")(true)
      case Some(Shrink) => props.updateDynamic("shrink")(true)
      case _            =>
    }

    alignment.foreach(a => props.updateDynamic(a.stringName)(true))

    id.foreach(idd => props.updateDynamic("id")(idd))
    tag.foreach(tagg => props.updateDynamic("tag")(tagg))

    props.asInstanceOf[VFlexProps]

  }

}

trait VFlexPropValues {

  import VFlexPropValues._

  case object `align-self-baseline` extends Alignment {
    override val stringName = "align-self-baseline"
  }

  case object `align-self-center` extends Alignment {
    override val stringName = "align-self-center"
  }

  case object `align-self-end` extends Alignment {
    override val stringName = "align-self-end"
  }

  case object `align-self-start` extends Alignment {
    override val stringName = "align-self-start"
  }

  type VFlexSize = String

  val `xs1`: VFlexSize         = "xs1"
  val `xs2`: VFlexSize         = "xs2"
  val `xs3`: VFlexSize         = "xs3"
  val `xs4`: VFlexSize         = "xs4"
  val `xs5`: VFlexSize         = "xs5"
  val `xs6`: VFlexSize         = "xs6"
  val `xs7`: VFlexSize         = "xs7"
  val `xs8`: VFlexSize         = "xs8"
  val `xs9`: VFlexSize         = "xs9"
  val `xs10`: VFlexSize        = "xs10"
  val `xs11`: VFlexSize        = "xs11"
  val `xs12`: VFlexSize        = "xs12"
  val `sm1`: VFlexSize         = "sm1"
  val `sm2`: VFlexSize         = "sm2"
  val `sm3`: VFlexSize         = "sm3"
  val `sm4`: VFlexSize         = "sm4"
  val `sm5`: VFlexSize         = "sm5"
  val `sm6`: VFlexSize         = "sm6"
  val `sm7`: VFlexSize         = "sm7"
  val `sm8`: VFlexSize         = "sm8"
  val `sm9`: VFlexSize         = "sm9"
  val `sm10`: VFlexSize        = "sm10"
  val `sm11`: VFlexSize        = "sm11"
  val `sm12`: VFlexSize        = "sm12"
  val `md1`: VFlexSize         = "md1"
  val `md2`: VFlexSize         = "md2"
  val `md3`: VFlexSize         = "md3"
  val `md4`: VFlexSize         = "md4"
  val `md5`: VFlexSize         = "md5"
  val `md6`: VFlexSize         = "md6"
  val `md7`: VFlexSize         = "md7"
  val `md8`: VFlexSize         = "md8"
  val `md9`: VFlexSize         = "md9"
  val `md10`: VFlexSize        = "md10"
  val `md11`: VFlexSize        = "md11"
  val `md12`: VFlexSize        = "md12"
  val `lg1`: VFlexSize         = "lg1"
  val `lg2`: VFlexSize         = "lg2"
  val `lg3`: VFlexSize         = "lg3"
  val `lg4`: VFlexSize         = "lg4"
  val `lg5`: VFlexSize         = "lg5"
  val `lg6`: VFlexSize         = "lg6"
  val `lg7`: VFlexSize         = "lg7"
  val `lg8`: VFlexSize         = "lg8"
  val `lg9`: VFlexSize         = "lg9"
  val `lg10`: VFlexSize        = "lg10"
  val `lg11`: VFlexSize        = "lg11"
  val `lg12`: VFlexSize        = "lg12"
  val `xl1`: VFlexSize         = "xl1"
  val `xl2`: VFlexSize         = "xl2"
  val `xl3`: VFlexSize         = "xl3"
  val `xl4`: VFlexSize         = "xl4"
  val `xl5`: VFlexSize         = "xl5"
  val `xl6`: VFlexSize         = "xl6"
  val `xl7`: VFlexSize         = "xl7"
  val `xl8`: VFlexSize         = "xl8"
  val `xl9`: VFlexSize         = "xl9"
  val `xl10`: VFlexSize        = "xl10"
  val `xl11`: VFlexSize        = "xl11"
  val `xl12`: VFlexSize        = "xl12"
  val `offset-xs1`: VFlexSize  = "offset-xs1"
  val `offset-xs2`: VFlexSize  = "offset-xs2"
  val `offset-xs3`: VFlexSize  = "offset-xs3"
  val `offset-xs4`: VFlexSize  = "offset-xs4"
  val `offset-xs5`: VFlexSize  = "offset-xs5"
  val `offset-xs6`: VFlexSize  = "offset-xs6"
  val `offset-xs7`: VFlexSize  = "offset-xs7"
  val `offset-xs8`: VFlexSize  = "offset-xs8"
  val `offset-xs9`: VFlexSize  = "offset-xs9"
  val `offset-xs10`: VFlexSize = "offset-xs10"
  val `offset-xs11`: VFlexSize = "offset-xs11"
  val `offset-xs12`: VFlexSize = "offset-xs12"
  val `offset-sm1`: VFlexSize  = "offset-sm1"
  val `offset-sm2`: VFlexSize  = "offset-sm2"
  val `offset-sm3`: VFlexSize  = "offset-sm3"
  val `offset-sm4`: VFlexSize  = "offset-sm4"
  val `offset-sm5`: VFlexSize  = "offset-sm5"
  val `offset-sm6`: VFlexSize  = "offset-sm6"
  val `offset-sm7`: VFlexSize  = "offset-sm7"
  val `offset-sm8`: VFlexSize  = "offset-sm8"
  val `offset-sm9`: VFlexSize  = "offset-sm9"
  val `offset-sm10`: VFlexSize = "offset-sm10"
  val `offset-sm11`: VFlexSize = "offset-sm11"
  val `offset-sm12`: VFlexSize = "offset-sm12"
  val `offset-md1`: VFlexSize  = "offset-md1"
  val `offset-md2`: VFlexSize  = "offset-md2"
  val `offset-md3`: VFlexSize  = "offset-md3"
  val `offset-md4`: VFlexSize  = "offset-md4"
  val `offset-md5`: VFlexSize  = "offset-md5"
  val `offset-md6`: VFlexSize  = "offset-md6"
  val `offset-md7`: VFlexSize  = "offset-md7"
  val `offset-md8`: VFlexSize  = "offset-md8"
  val `offset-md9`: VFlexSize  = "offset-md9"
  val `offset-md10`: VFlexSize = "offset-md10"
  val `offset-md11`: VFlexSize = "offset-md11"
  val `offset-md12`: VFlexSize = "offset-md12"
  val `offset-lg1`: VFlexSize  = "offset-lg1"
  val `offset-lg2`: VFlexSize  = "offset-lg2"
  val `offset-lg3`: VFlexSize  = "offset-lg3"
  val `offset-lg4`: VFlexSize  = "offset-lg4"
  val `offset-lg5`: VFlexSize  = "offset-lg5"
  val `offset-lg6`: VFlexSize  = "offset-lg6"
  val `offset-lg7`: VFlexSize  = "offset-lg7"
  val `offset-lg8`: VFlexSize  = "offset-lg8"
  val `offset-lg9`: VFlexSize  = "offset-lg9"
  val `offset-lg10`: VFlexSize = "offset-lg10"
  val `offset-lg11`: VFlexSize = "offset-lg11"
  val `offset-lg12`: VFlexSize = "offset-lg12"
  val `offset-xl1`: VFlexSize  = "offset-xl1"
  val `offset-xl2`: VFlexSize  = "offset-xl2"
  val `offset-xl3`: VFlexSize  = "offset-xl3"
  val `offset-xl4`: VFlexSize  = "offset-xl4"
  val `offset-xl5`: VFlexSize  = "offset-xl5"
  val `offset-xl6`: VFlexSize  = "offset-xl6"
  val `offset-xl7`: VFlexSize  = "offset-xl7"
  val `offset-xl8`: VFlexSize  = "offset-xl8"
  val `offset-xl9`: VFlexSize  = "offset-xl9"
  val `offset-xl10`: VFlexSize = "offset-xl10"
  val `offset-xl11`: VFlexSize = "offset-xl11"
  val `offset-xl12`: VFlexSize = "offset-xl12"
  val `order-xs-1`: VFlexSize  = "order-xs-1"
  val `order-xs-2`: VFlexSize  = "order-xs-2"
  val `order-xs-3`: VFlexSize  = "order-xs-3"
  val `order-xs-4`: VFlexSize  = "order-xs-4"
  val `order-xs-5`: VFlexSize  = "order-xs-5"
  val `order-xs-6`: VFlexSize  = "order-xs-6"
  val `order-xs-7`: VFlexSize  = "order-xs-7"
  val `order-xs-8`: VFlexSize  = "order-xs-8"
  val `order-xs-9`: VFlexSize  = "order-xs-9"
  val `order-xs-10`: VFlexSize = "order-xs-10"
  val `order-xs-11`: VFlexSize = "order-xs-11"
  val `order-xs-12`: VFlexSize = "order-xs-12"
  val `order-sm-1`: VFlexSize  = "order-sm-1"
  val `order-sm-2`: VFlexSize  = "order-sm-2"
  val `order-sm-3`: VFlexSize  = "order-sm-3"
  val `order-sm-4`: VFlexSize  = "order-sm-4"
  val `order-sm-5`: VFlexSize  = "order-sm-5"
  val `order-sm-6`: VFlexSize  = "order-sm-6"
  val `order-sm-7`: VFlexSize  = "order-sm-7"
  val `order-sm-8`: VFlexSize  = "order-sm-8"
  val `order-sm-9`: VFlexSize  = "order-sm-9"
  val `order-sm-10`: VFlexSize = "order-sm-10"
  val `order-sm-11`: VFlexSize = "order-sm-11"
  val `order-sm-12`: VFlexSize = "order-sm-12"
  val `order-md-1`: VFlexSize  = "order-md-1"
  val `order-md-2`: VFlexSize  = "order-md-2"
  val `order-md-3`: VFlexSize  = "order-md-3"
  val `order-md-4`: VFlexSize  = "order-md-4"
  val `order-md-5`: VFlexSize  = "order-md-5"
  val `order-md-6`: VFlexSize  = "order-md-6"
  val `order-md-7`: VFlexSize  = "order-md-7"
  val `order-md-8`: VFlexSize  = "order-md-8"
  val `order-md-9`: VFlexSize  = "order-md-9"
  val `order-md-10`: VFlexSize = "order-md-10"
  val `order-md-11`: VFlexSize = "order-md-11"
  val `order-md-12`: VFlexSize = "order-md-12"
  val `order-lg-1`: VFlexSize  = "order-lg-1"
  val `order-lg-2`: VFlexSize  = "order-lg-2"
  val `order-lg-3`: VFlexSize  = "order-lg-3"
  val `order-lg-4`: VFlexSize  = "order-lg-4"
  val `order-lg-5`: VFlexSize  = "order-lg-5"
  val `order-lg-6`: VFlexSize  = "order-lg-6"
  val `order-lg-7`: VFlexSize  = "order-lg-7"
  val `order-lg-8`: VFlexSize  = "order-lg-8"
  val `order-lg-9`: VFlexSize  = "order-lg-9"
  val `order-lg-10`: VFlexSize = "order-lg-10"
  val `order-lg-11`: VFlexSize = "order-lg-11"
  val `order-lg-12`: VFlexSize = "order-lg-12"
  val `order-xl-1`: VFlexSize  = "order-xl-1"
  val `order-xl-2`: VFlexSize  = "order-xl-2"
  val `order-xl-3`: VFlexSize  = "order-xl-3"
  val `order-xl-4`: VFlexSize  = "order-xl-4"
  val `order-xl-5`: VFlexSize  = "order-xl-5"
  val `order-xl-6`: VFlexSize  = "order-xl-6"
  val `order-xl-7`: VFlexSize  = "order-xl-7"
  val `order-xl-8`: VFlexSize  = "order-xl-8"
  val `order-xl-9`: VFlexSize  = "order-xl-9"
  val `order-xl-10`: VFlexSize = "order-xl-10"
  val `order-xl-11`: VFlexSize = "order-xl-11"
  val `order-xl-12`: VFlexSize = "order-xl-12"

}

object VFlexPropValues extends VFlexPropValues {
  sealed trait Growth

  case object Grow   extends Growth
  case object Shrink extends Growth

  sealed trait Alignment {
    val stringName: String
  }

}
