package com.sludg.vuetify.components

import com.sludg.scalajs.DynamicHelper
import com.sludg.vue.{EventBindings, ScopedSlots, VueProps}

import scala.scalajs.js
import scala.scalajs.js.|

trait VTabs {

  import com.sludg.vue.RenderHelpers._

  val vTabs = namedTag[VTabsProps, EventBindings, ScopedSlots]("v-tabs")

}

object VTabs extends VTabs

trait VTabsProps extends VueProps {
  val color: js.UndefOr[String]             = js.undefined
  val height: js.UndefOr[Int | String]      = js.undefined
  val grow: js.UndefOr[Boolean]             = js.undefined
  val `icons-and-text`: js.UndefOr[Boolean] = js.undefined
  val `slider-color`: js.UndefOr[String]    = js.undefined
  val value: js.UndefOr[Int | String]       = js.undefined
  val dark: js.UndefOr[Boolean]             = js.undefined
  val `fixed-tabs`: js.UndefOr[Boolean]     = js.undefined
  val light: js.UndefOr[Boolean]            = js.undefined
  val max: js.UndefOr[Int | String]         = js.undefined
  val multiple: js.UndefOr[Boolean]         = js.undefined
  val `next-icon`: js.UndefOr[String]       = js.undefined
  val `prev-icon`: js.UndefOr[String]       = js.undefined

}

object VTabsProps {

  import scala.scalajs.js.JSConverters._

  def apply(
      color: Option[String] = None,
      height: Option[Either[Int, String]] = None,
      grow: Option[Boolean] = None,
      `icons-and-text`: Option[Boolean] = None,
      `slider-color`: Option[String] = None,
      `next-icon`: Option[String] = None,
      `prev-icon`: Option[String] = None,
      `show-arrows`: Option[Boolean] = None,
      value: Option[Either[Int, String]] = None,
      dark: Option[Boolean] = None,
      `fixed-tabs`: Option[Boolean] = None,
      light: Option[Boolean] = None,
      max: Option[Either[Int, String]] = None,
      multiple: Option[Boolean] = None
  ): VTabsProps = {
    DynamicHelper.buildViaDynamic(
      "color"          -> color.map(js.Any.fromString).orUndefined,
      "height"         -> height.map(_.fold(js.Any.fromInt, js.Any.fromString)).orUndefined,
      "grow"           -> grow.map(js.Any.fromBoolean).orUndefined,
      "icons-and-text" -> `icons-and-text`.map(js.Any.fromBoolean).orUndefined,
      "slider-color"   -> `slider-color`.map(js.Any.fromString).orUndefined,
      "next-icon"      -> `next-icon`.map(js.Any.fromString).orUndefined,
      "prev-icon"      -> `prev-icon`.map(js.Any.fromString).orUndefined,
      "show-arrows"    -> `show-arrows`.map(js.Any.fromBoolean).orUndefined,
      "value"          -> value.map(_.fold(js.Any.fromInt, js.Any.fromString)).orUndefined,
      "dark"           -> dark.map(js.Any.fromBoolean).orUndefined,
      "fixed-tabs"     -> `fixed-tabs`.map(js.Any.fromBoolean).orUndefined,
      "light"          -> light.map(js.Any.fromBoolean).orUndefined,
      "max"            -> max.map(_.fold(js.Any.fromInt, js.Any.fromString)).orUndefined,
      "multiple"       -> multiple.map(js.Any.fromBoolean).orUndefined
    )
  }
}
