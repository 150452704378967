package com.sludg.util



/**
  * Regex rules that can be used for field validation
 */

object Validators {

  def regexRuleMaker(regex: String, fieldName: String, errorText: String): Option[String] => Option[String] = {
    input =>
      input.flatMap { i =>
        if (i.matches(regex)) None else Some(s"The $fieldName field $errorText")
      }
  }

  def textNumberRules(fieldName: String): Option[String] => Option[String] = regexRuleMaker("^[0-9]*$", fieldName, "field must only contain numbers")
  def startsWithNineRules(fieldName: String): Option[String] => Option[String] = regexRuleMaker("^[^9].*", fieldName, "already includes a 9 in its input")
  def nonEmpty(fieldName: String): Option[String] => Option[String] = regexRuleMaker("(.|\\s)*\\S(.|\\s)*", fieldName, "must not be empty")

}
