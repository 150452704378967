package com.sludg.vuetify.components

import com.sludg.vue.{EventBindings, ScopedSlots}

import scala.scalajs.js

/**
  * @author dpoliakas
  *         Date: 22/11/2018
  *         Time: 17:20
  *
  *
  */
trait VToolbarTitle {

  import com.sludg.vue.RenderHelpers._

  val vToolbarTitle = namedTag[VToolbarProps, EventBindings, ScopedSlots]("v-toolbar-title")

}

object VToolbarTitle extends VToolbarTitle

trait VToolbarTitleProps extends js.Object {}
