package com.sludg.vue

import scala.scalajs.js
import scala.scalajs.js.annotation.JSImport
import scala.scalajs.js.|

/**
  * @author dpoliakas
  *         Date: 21/11/2018
  *         Time: 17:23
  *
  *
  */
@js.native
@JSImport("vue", JSImport.Default)
class Vue(vueInstance: js.UndefOr[VueInstanceProperties[_, _, _]]) extends js.Any {

  def $on(event: String | js.Array[String], callback: js.Function): Unit = js.native

  def $once(event: String, callback: js.Function): Unit = js.native

  def $off(event: String | js.Array[String], callback: js.Function): Unit = js.native

  def $emit(event: String, args: Any): Unit = js.native

  val $vuetify: js.Dynamic = js.native

}

@js.native
@JSImport("vue", JSImport.Namespace)
object Vue extends js.Object {
  def component(name: String, definition: js.Object): Unit = js.native

  def use(plugin: js.Any): js.Any = js.native

  def use(plugin: js.Any, args: js.Any*): js.Any = js.native
}

@js.native
trait VueInstanceProperties[P <: VueProps, S <: Slots, SS <: ScopedSlots]
    extends VueComponent[P, S, SS] {
  val el: js.UndefOr[String]        = js.undefined
  val router: js.UndefOr[VueRouter] = js.undefined
}

object VueInstanceProperties {

  import scala.scalajs.js.JSConverters._

  type Elem                                                    = String | js.Object | js.Function0[_]
  type Props[A <: VueProps, B <: js.Object, SS <: ScopedSlots] = js.UndefOr[RenderOptions[A, B, SS]]
  type Children                                                = js.UndefOr[String | js.Array[VNode]]

  type CreateElement = js.Function3[Elem, Props[_, _, _], Children, VNode]

  type Template = js.UndefOr[String]

  type RenderFunction[
      -D,
      -M <: js.Object,
      -C <: js.Object,
      -P <: VueProps,
      -S <: Slots,
      -SS <: ScopedSlots,
      -R <: Refs
  ] =
    js.ThisFunction1[VueComponent[_ <: P, _ <: S, _ <: SS] with D with M with C with P with WithRefs[
      _ <: R
    ], CreateElement, VNode]

  type ComponentCallback[
      -D,
      -M <: js.Object,
      -C <: js.Object,
      -P <: VueProps,
      -S <: Slots,
      -SS <: ScopedSlots,
      -R <: Refs
  ] =
    js.UndefOr[js.ThisFunction0[
      VueComponent[_ <: P, _ <: S, _ <: SS] with D with M with C with P with WithRefs[_ <: R],
      Unit
    ]]

  def apply[A <: js.Object, M <: js.Object, C <: js.Object](
      el: js.UndefOr[String] = js.undefined,
      router: js.UndefOr[VueRouter] = js.undefined,
      data: js.UndefOr[js.Function0[A]] = js.undefined,
      methods: js.UndefOr[M] = js.undefined,
      watch: js.UndefOr[js.Object] = js.undefined,
      computed: js.UndefOr[C] = js.undefined,
      components: js.UndefOr[js.Object] = js.undefined
  )(
      // created: js.UndefOr[js.ThisFunction0[VueComponent[_ <: VueProps, _ <: Slots, _ <: ScopedSlots] with A with M with C with VueProps with WithRefs[_ <: Refs], Unit]] = js.undefined,
      created: ComponentCallback[A, M, C, VueProps, Slots, ScopedSlots, Refs] =
        (js.undefined: js.UndefOr[js.ThisFunction0[VueComponent[
          _ <: VueProps,
          _ <: Slots,
          _ <: ScopedSlots
        ] with A with M with C with VueProps with WithRefs[_ <: Refs], Unit]]),
      updated: ComponentCallback[A, M, C, VueProps, Slots, ScopedSlots, Refs] =
        (js.undefined: js.UndefOr[js.ThisFunction0[VueComponent[
          _ <: VueProps,
          _ <: Slots,
          _ <: ScopedSlots
        ] with A with M with C with VueProps with WithRefs[_ <: Refs], Unit]]),
      templateOrRender: Either[
        Template,
        RenderFunction[A, M, C, VueProps, Slots, ScopedSlots, Refs]
      ] = Left(js.undefined: js.UndefOr[String])
  ): VueInstanceProperties[VueProps, Slots, ScopedSlots] = {
    List[(String, js.UndefOr[js.Any])](
      "data"       -> data,
      "template"   -> templateOrRender.left.toOption.getOrElse(js.undefined).map(js.Any.fromString),
      "methods"    -> methods,
      "watch"      -> watch,
      "computed"   -> computed,
      "created"    -> created,
      "updated"    -> updated,
      "render"     -> templateOrRender.toOption.orUndefined,
      "router"     -> router,
      "components" -> components,
      "el"         -> el.map(js.Any.fromString)
    ).foldRight(js.Dynamic.literal()) {
        case ((key, prop), obj) =>
          if (prop.isDefined) obj.updateDynamic(key)(prop.asInstanceOf[js.Any])
          obj
      }
      .asInstanceOf[VueInstanceProperties[VueProps, Slots, ScopedSlots]]
  }

}
