package com.sludg.vuetify.components

import com.sludg.vue.{EventBindings, ScopedSlots, VueProps}

import scala.scalajs.js

/**
  * @author dpoliakas
  *         Date: 26/11/2018
  *         Time: 12:43
  *
  *
  */
trait VSpacer {

  import com.sludg.vue.RenderHelpers._

  val vSpacer = namedTag[VueProps, EventBindings, ScopedSlots]("v-spacer")

}

object VSpacer extends VSpacer
