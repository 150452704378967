package com.sludg.components

import com.sludg.auth0.SludgToken
import com.sludg.helpers.AppSetup.{VtslAppMethods, _}
import com.sludg.models.Config.AppLink
import com.sludg.vue.{WithRefs, _}
import com.sludg.services.ApiCalls
import org.log4s.getLogger
import monix.execution.Scheduler.Implicits.global
import org.scalajs.dom.Event
import com.sludg.components.Snackbar.{
  DisplayMessageType,
  SnackBarMessage,
  snackBarEvent,
  snackBarRenderer
}

import scala.scalajs.js
import scala.scalajs.js.JSConverters._
import scala.scalajs.js.{JSON, UndefOr}
import com.sludg.vue.RenderHelpers._
import com.sludg.vuetify.VuetifyComponents._
import com.sludg.vuetify.components.grid._
import com.sludg._

object VTSLApp {

  private[this] val logger = getLogger

  type VTSLAppType = VueComponent[_ <: VtslAppProps, _ <: Slots, _ <: VtslAppScopedSlots]
    with VtslAppData
    with VtslAppMethods
    with js.Object
    with VtslAppProps
    with WithRefs[_ <: Refs]

  val tenantSelector = TenantSelector.tenantSelectorRenderer("TenantSelector")
  val appRenderer = (registrationName: String) =>
    namedTag[VueProps, EventBindings, VtslAppScopedSlots](registrationName)

  private def content(
      data: VtslAppData,
      scopedSlots: VtslAppScopedSlots,
      methods: VtslAppMethods,
      props: VtslAppProps,
      displayTenantSelector: Boolean,
      fluid: Boolean = false
  ): RenderFunction[VNode] = {
    vContent(
      props.token match {
        case Some(token) => {
          tenantSelector(
            RenderOptions(
              props = Some(new TenantSelectorProps(token, displayTenantSelector)),
              on = Some(
                new TenantSelectorEvents(
                  tenantSelected = e => {
                    data.selectedTenant = e.orUndefined
                    logger.debug("Tenant event received with tenant " + e)
                  }
                )
              )
            )
          )
        }
        case None => div()
      },
      vContainer(
        //NOTHING GOES HERE! ok
        scopedSlots.default.foldToNode(
          defaultContent => defaultContent(data.selectedTenant.toOption),
          nothing
        ),
        scopedSlots.selectedSubscriber.foldToNode(
          defaultContent => defaultContent(data.selectedSubscriber.toOption),
          nothing
        ),
        RenderOptions(
          props = Some(
            VGridProps(
              fluid = fluid
            )
          ),
          `class` = if (fluid) List(Left("pa-0 ma-0")) else Nil,
          style = Some(
            js.Dynamic.literal(
              "max-width" -> "100%",
              "width"     -> "100%",
              "height"    -> "100%"
            )
          )
        )
      )
    )
  }

  def createApp(
      name: String,
      api: ApiCalls,
      apiCallBus: Vue,
      otherApps: List[AppLink],
      versionOpt: Option[String],
      guideLink: Option[String] = None,
      logOutMethod: Event => Unit,
      showLogo: Boolean = false,
      powerButtonInExtension: Boolean = false,
      showFooter: Boolean = false,
      draggableToolbar: Boolean = false
  ) =
    VueComponent.builder
      .withData(new VtslAppData())
      .withRefs[AppRefs]
      .withScopedSlots[VtslAppScopedSlots]
      .withMethods(new VtslAppMethods)
      .withPropsAs[VtslAppProps]
      .build(
        created = js.defined(c => {
          apiCallBus.$on(
            snackBarEvent,
            (s => Snackbar.addSnackBarToQueue(s, c)): js.Function1[SnackBarMessage, Unit]
          )

          apiCallBus.$on("api-call:started", (() => c.loading = c.loading + 1): js.Function0[Unit])
          apiCallBus.$on("api-call:complete", (() => c.loading = c.loading - 1): js.Function0[Unit])
          apiCallBus.$on(
            "api-call:error",
            (e => {
              logger.debug("An error has occurred communicating with the API")
              e.printStackTrace()
              c.loading = c.loading - 1
              Snackbar.addSnackBarToQueue(
                SnackBarMessage(
                  "An error occurred trying to communicate with the server. If this issue persists please contact support",
                  DisplayMessageType.Error
                ),
                c
              )
            }): js.Function1[Throwable, Unit]
          )
          apiCallBus.$on(
            "error",
            (e => {
              logger.debug(s"An error has been reported: $e")
              Snackbar.addSnackBarToQueue(SnackBarMessage(e, DisplayMessageType.Error), c)
            }): js.Function1[String, Unit]
          )
        }),
        updated = js.defined(c => logger.debug("VTSL app updated")),
        watch = js.defined(new VTSLAppWatcher()),
        mounted = js.defined(c => c.toolbarHeightOnInit = c.$refs.toolbar.$el.clientHeight),
        components = js.Dynamic.literal(
          "TenantSelector" -> TenantSelector.tenantSelectorComponent(
            api,
            apiCallBus,
            otherApps.flatMap(_.userLinkDomain).headOption
          )
        ),
        templateOrRender = Right((v, renderer) =>
          vApp(
            navDrawer(v, otherApps, api, apiCallBus),
            toolbar(v, guideLink, showLogo, powerButtonInExtension, draggableToolbar, logOutMethod),
            progressBar(v),
            content(v, v.$scopedSlots, v, v, v.displayTenantSelector, v.vContentIsFluid),
            if (showFooter) footer(name, versionOpt) else nothing,
            snackBarRenderer(v.asInstanceOf[VtslAppData], (e) => {
              Snackbar.closeSnackBar(v)
            })
          ).render(renderer)
        )
      )
}
