package com.sludg.vuetify.components

import com.sludg.scalajs.DynamicHelper
import com.sludg.vue.{EventBindings, ScopedSlots, VueProps}

import scala.scalajs.js

/**
  * @author pbarman
  *         Date: 29/01/2019
  *         Time: 11:11
  *
  *
  */
trait VButtonToggle {

  import com.sludg.vue.RenderHelpers._

  val vButtonToggle = namedTag[VButtonToggleProps, EventBindings, ScopedSlots]("v-btn-toggle")

}

object VButtonToggle extends VButtonToggle

trait VButtonToggleProps extends VueProps {
  val multiple: js.UndefOr[String] = js.undefined

}

object VButtonToggleProps {
  import scala.scalajs.js.JSConverters._

  def apply(
      multiple: Option[Boolean] = None
  ): VButtonToggleProps = {

    DynamicHelper.buildViaDynamic(
      "multiple" -> multiple.map(js.Any.fromBoolean).orUndefined
    )
  }
}
