package com.sludg.vuetify.components

import com.sludg.scalajs.DynamicHelper
import com.sludg.vue.{EventBindings, ScopedSlots, VueProps}

import scala.scalajs.js
import scala.scalajs.js.|

trait VTabItem {

  import com.sludg.vue.RenderHelpers._

  val vTabItem = namedTag[VTabItemProps, EventBindings, ScopedSlots]("v-tab-item")

}

object VTabItem extends VTabItem

trait VTabItemProps extends VueProps {

  val id: js.UndefOr[String]    = js.undefined
  val value: js.UndefOr[js.Any] = js.undefined

}

object VTabItemProps {

  import scala.scalajs.js.JSConverters._

  def apply(
      id: Option[String] = None,
      value: Option[js.Any] = None
  ): VTabItemProps = {
    DynamicHelper.buildViaDynamic(
      "id"    -> id.map(js.Any.fromString).orUndefined,
      "value" -> value.orUndefined
    )
  }
}
