package com.sludg.client.pages.components.diverts

import cats.Monoid
import cats.data.EitherT
import com.sludg.Security
import com.sludg.auth0.SludgToken
import com.sludg.client.pages.components.diverts.DDIUtil.DDITableItems
import com.sludg.helpers.LoadingFuture
import com.sludg.models.Config.AppLink
import com.sludg.services.ApiCalls
import com.sludg.util.models.SilhouetteModels
import com.sludg.util.models.SilhouetteModels.{
  DIDNumber,
  EmergencyOverride,
  HourlyExtensions,
  Tenant
}
import com.sludg.vue.RenderHelpers._
import com.sludg.vue.VueInstanceProperties.CreateElement
import com.sludg.vue._
import com.sludg.vuetify.VuetifyComponents._
import com.sludg.vuetify.components._
import org.log4s.getLogger
import cats.implicits._
import com.sludg.models.Models
import scala.concurrent.Future
import scala.scalajs.js
import scala.scalajs.js.{ThisFunction0, UndefOr}
import scala.util.{Failure, Success}

/** This component's main uses are displaying tenant dids, and housing the divert and routing
  * input screens for user customisation.
  * The did information is converted to table data for display in this component, and when the
  * user selects an option from the table or mobile list the `currentlySelectedDid` is set.
  * This data property is what is sent to the input components, and where fields pertaining to
  * that particular did are accessed from.
  * The input components convert front end input into values suitable for api calls, that are
  * posted via our api (and in turn, into sil), using the ID of the `currentlySelectedDid`.
  *
  * Note: In the current implementation, a None posted to sil for diverts and routing is
  * equivalent to the value not being provided at all, so is left as is.
  * A Some("") can be used to clear a particular field.
  */

object DDIDisplayer {
  import monix.execution.Scheduler.Implicits.global

  private[this] val logger = getLogger

  def DDIDisplayerRenderer(registrationName: String) =
    namedTag[VueProps, EventBindings, ScopedSlots]("DDIDisplayer")

  val divertInputScreen = DivertInputScreen.DivertInputScreenRenderer("DivertInputScreen")
  val routingInputScreen = RoutingInputScreen.RoutingInputScreenRenderer("RoutingInputScreen")
  val mobileList = MobileList.MobileListRenderer("MobileList")

  def DDIDisplayerComponent(
      apiCalls: ApiCalls,
      security: Security,
      apiCallBus: Vue,
      otherApps: List[AppLink]
  )(implicit token: SludgToken) = {
    VueComponent.builder
      .withData(new DDIDisplayerData())
      .withProps(
        js.Array(
          "token",
          "tenant"
        ).asInstanceOf[DDIDisplayerProps]
      )
      .withComputed(DDIDisplayerComputed())
      .build(
        created = js.defined(c => {
          c.vueBus = Some(apiCallBus)
          c.apiCalls = Some(apiCalls)
          LoadingFuture
            .withLoading(apiCallBus, getTenantDidsApiCall(c.tenant, apiCalls, apiCallBus, c))
          LoadingFuture.withLoading(apiCallBus, getTenantSilhouetteUsers(c.tenant, apiCalls, c))

        }),
        watch = new DDIDisplayerWatcher,
        components = js.defined(
          js.Dynamic.literal(
            "DivertInputScreen" -> DivertInputScreen
              .DivertInputScreenComponent(apiCalls, security, apiCallBus),
            "RoutingInputScreen" -> RoutingInputScreen
              .RoutingInputScreenComponent(apiCalls, security, apiCallBus),
            "MobileList" -> MobileList.MobileListComponent(apiCalls, security, apiCallBus)
          )
        ),
        templateOrRender = Right((component, renderer) => {
          //The snackbar and dialogs "live" inside the renderfunction, but are conditionally displayed based on
          //the value of their associated booleans.
          //The viewport match determines whether the mobile list, or full datatable is rendered.
          div(
            vCard(
              vSnackbar(s"Number ${component.currentlySelectedDid match {
                case Some(did) => did.phoneNumber
                case None => ""
              }} - ${component.snackbarValues.configuration.text}")(
                RenderOptions(
                  props = Some(
                    VSnackbarProps(
                      color = Some(component.snackbarValues.configuration.colour),
                      value = Some(component.snackbarValues.visible),
                      timeout = Some(4000)
                    )
                  ),
                  on = Some(
                    EventBindings(
                      input = js.defined(_ =>
                        component.snackbarValues = SnackbarData(false, NotConfiguredError)
                      )
                    )
                  )
                )
              ),
              component.$root.$vuetify.breakpoint.name.toString match {
                case "sm" | "xs" =>
                  /* For now, only the number itself is searchable - the label reflects this. */
                  titleSearchBar(
                    component,
                    "Search Numbers...",
                    mobileViewComponents(apiCalls, apiCallBus, component)
                  )
                case _ =>
                  titleSearchBar(
                    component,
                    "Search...",
                    dataTableComponent(component, apiCallBus, apiCalls, renderer)
                  )
              }
            ),
            component.currentlySelectedDid.map(did =>
              List(
                routingScreens(apiCalls, apiCallBus, component, did),
                divertScreen(apiCalls, apiCallBus, component, did)
              )
            ) match {
              case Some(node) => node
              case None => nothing
            }
          ).render(renderer)
        })
      )
  }

  def titleSearchBar(
      component: DDIDisplayerData with DDIDisplayerProps,
      label: String,
      didView: RenderFunction[VNode]
  )(implicit token: SludgToken) = {
    List(
      vCardTitle(
        RenderOptions(`class` = List(Left("title"))),
        "Your Phone Numbers",
        vSpacer,
        vTextField(
          RenderOptions(
            style = Some(js.Dynamic.literal("margin-top" -> "-6px")),
            props = Some(
              VTextFieldProps(
                label = Some(label),
                `single-line` = Some(true),
                `hide-details` = Some(true),
                `append-icon` = Some("search")
              )
            ),
            on = Some(
              EventBindings(
                input = js.defined(e => {
                  component.tableSearchString =
                    if (js.isUndefined(e)) ""
                    else {
                      //Filter input resets the page to the first position, to prevent irregularities with filtering removing
                      // a page that someone is currently on.
                      component.page = 1
                      e.toString
                    }
                })
              )
            )
          )
        )
      ),
      vDivider,
      didView
    )
  }

  private def mobileViewComponents(
      apiCalls: ApiCalls,
      apiCallBus: Vue,
      component: VueComponent[_, _, _]
        with DDIDisplayerData
        with DDIDisplayerProps
        with DDIDisplayerComputed
  )(implicit sludgToken: SludgToken) = {
    //We use grouped to split the list into "chunks" for pagination. The length of the chunked list of lists is
    //used to determine information about the current page.
    //The length of the chunked list gives us the total number of pages for the pagination component, and
    //[component.page] is an int set to 1, and the value that controls paginator. Input events modify the page value.
    div(
      vList(
        component.didsOnTenantGrouped match {
          //We don't want to show pagination when there is enough to show on one page
          case Nil => div()
          case notNil =>
            notNil(component.page - 1).map { did =>
              div(
                mobileList(
                  RenderOptions(
                    props = Some(
                      new MobileListProps(
                        didFromTable = did,
                        tenantSubscribers = component.tenantSubscribers,
                        tenantAutoAttendants = component.tenantAutoAttendants,
                        tenantCallGroups = component.tenantCallGroups
                      )
                    ),
                    on = Some(
                      MobileListEvents(
                        //These events just update the currentDid from the mobile screen, and signify when the relevant
                        //dialogs should be opened
                        currentDid = js.defined(e => {
                          component.currentlySelectedDid = Some(e)
                        }),
                        openRouting = js.defined(e => {
                          component.snackbarValues = SnackbarData(false, Routing)
                          component.routingDialogVisible = true
                        }),
                        openDivert = js.defined(e => {
                          component.snackbarValues = SnackbarData(false, Divert)
                          component.divertDialogVisible = true
                        }),
                        toggleDivert = js.defined(e => {
                          if (
                            DDIUtil
                              .isDivertEnablable(did) && component.currentlySelectedDid.isDefined
                          ) {
                            DDIDisplayer.toggleOverride(component, apiCallBus, apiCalls, did.didId)
                          } else {
                            component.snackbarValues = SnackbarData(true, NotConfiguredError)
                          }
                        })
                      )
                    )
                  )
                ),
                vDivider
              )
            }
        },
        RenderOptions(
          //We use a key based on the current dids and page number
          //So that when either of those values change, a pristine list gets rendered
          key = Some(component.didsOnTenant.length.toString + component.page.toString),
          style = Some(
            js.Dynamic.literal(
              "padding" -> "0px"
            )
          ),
          props = Some(
            VListProps(
              `two-line` = Some(true)
            )
          )
        )
      ),
      if (component.didsOnTenantGrouped.length > 1) {
        vLayout(
          RenderOptions(`class` = List(Left("justify-center"))),
          vPagination(
            RenderOptions(
              props = Some(
                VPaginationProps(
                  length = Some(component.didsOnTenantGrouped.length),
                  value = Some(component.page)
                )
              ),
              on = Some(EventBindings(input = js.defined(e => {
                component.page = e.asInstanceOf[Int]
              })))
            )
          )
        )
      } else nothing
    )
  }

  private def dataTableComponent(
      component: DDIDisplayerData with DDIDisplayerProps,
      apiCallBus: Vue,
      apiCalls: ApiCalls,
      renderer: CreateElement
  )(implicit token: SludgToken) = {
    vDataTable(
      RenderOptions[VDataTableProps[
        DDITableItems,
        VDataTableHeader
      ], EventBindings, VDataTableScopedSlots[DDITableItems, VDataTableHeader]](
        style = Some(js.Dynamic.literal("table-layout" -> "fixed")),
        props = Some(
          VDataTableProps[DDITableItems, VDataTableHeader](
            headers = Some(
              List(
                new VDataTableHeader(
                  width = "12%",
                  sortable = (true),
                  text = ("Phone Number"),
                  value = ("phoneNumber")
                ),
                new VDataTableHeader(sortable = true, text = ("Assigned"), value = ("assigned")),
                new VDataTableHeader(
                  width = "10%",
                  sortable = (true),
                  text = ("Number Name"),
                  value = ("incomingName")
                ),
                new VDataTableHeader(
                  sortable = true,
                  text = ("Open"),
                  value = ("openHoursExtension")
                ),
                new VDataTableHeader(
                  sortable = true,
                  text = ("Closed"),
                  value = ("closedHoursExtension")
                ),
                new VDataTableHeader(
                  sortable = true,
                  text = ("Lunch"),
                  value = ("lunchHoursExtension")
                ),
                new VDataTableHeader(
                  sortable = true,
                  text = ("Special"),
                  value = ("specialHoursExtension")
                ),
                new VDataTableHeader(
                  width = "26%",
                  sortable = (true),
                  text = ("Divert Status"),
                  value = ("divertSummary")
                ),
                new VDataTableHeader(sortable = false, text = ("Settings"))
              )
            ),
            items = Some(component.didsOnTenant.map(convertToTableData)),
            light = Some(false),
            search = Some(component.tableSearchString)
          )
        ),
        scopedSlots = Some(
          new VDataTableScopedSlots[DDITableItems, VDataTableHeader](
            items = js.defined(props => {
              // A js.Dynamic props instance exists for each DDITableItem, and is converted from the api's DIDNumber with the
              // method `convertToTableData`.
              // If underlying data is changed, it should be reflected in `convertToTableData` so the natural table functionality
              // is supported with the changes.
              // ListTile titles apply nice formatting to make the text look pleasing
              // Additionally, we grey out the table row items if no routing is set or divert is enabled
              tr(
                RenderOptions(
                  style = DDIUtil.collectDidFromTable(
                    component.didsOnTenant,
                    props.item.didId.toString.toInt
                  ) match {
                    case Some(did: DIDNumber) =>
                      Some(
                        js.Dynamic.literal(
                          "color" -> DDIUtil
                            .didStatus[String](did, "grey !important", "black", "black")
                        )
                      )
                    case None => Some(js.Dynamic.literal("color" -> "black"))
                  }
                ),
                td(vListTileSubTitle(props.item.phoneNumber.toString)),
                td(props.item.assigned.toString),
                //This CSS is necessary whilst we're using vuetify 1.5 - as the table header prop (and css) doesn't work
                // and causes the header to stretch to the length of the namefield.
                td(
                  vListTileSubTitle(
                    props.item.incomingName.toString,
                    RenderOptions(`class` = List(Left("truncate-text")))
                  )
                ),
                td(
                  getExtensionsFromApiString(props.item.openHoursExtension.toString),
                  RenderOptions(style = Some(js.Dynamic.literal("white-space" -> "nowrap")))
                ),
                td(
                  getExtensionsFromApiString(props.item.closedHoursExtension.toString),
                  RenderOptions(style = Some(js.Dynamic.literal("white-space" -> "nowrap")))
                ),
                td(
                  getExtensionsFromApiString(props.item.lunchHoursExtension.toString),
                  RenderOptions(style = Some(js.Dynamic.literal("white-space" -> "nowrap")))
                ),
                td(
                  getExtensionsFromApiString(props.item.specialHoursExtension.toString),
                  RenderOptions(style = Some(js.Dynamic.literal("white-space" -> "nowrap")))
                ),
                td(
                  RenderOptions(style = Some(js.Dynamic.literal("padding-left" -> "10px"))),
                  divertSummaryAndSwitch(component, apiCallBus, apiCalls, props)
                ),
                td(
                  DDIUtil.RenderFunctions
                    .menuDropDown(component, apiCallBus, apiCalls, props.item.didId.toString.toInt),
                  RenderOptions(style = Some(js.Dynamic.literal("padding" -> "0px")))
                )
              ).render(renderer)
            })
          )
        )
      )
    )
  }

  def getExtensionsFromApiString(location: String): String = {
    //Extensions, names, and location type currently comes from the API in one string
    location match {
      case s if s.contains("User") => location.split(" - ")(0) + " - Ext"
      case s if s.contains("Auto Attendant") => location.split(" - ")(0) + " - AA"
      case s if s.contains("Call Group") => location.split(" - ")(0) + " - CG"
      case s => s
    }
  }

  private def divertSummaryAndSwitch(
      component: DDIDisplayerData with DDIDisplayerProps,
      apiCallBus: Vue,
      apiCalls: ApiCalls,
      props: VDataTableItemSlotData[com.sludg.client.pages.components.diverts.DDIUtil.DDITableItems]
  )(implicit token: SludgToken) = {
    /* This List Tile displays the current divert information from the prop items, and formats it
     * vListTileAction - Contains the switch, the value is determined by the status of the current DIDNumber
     * vListTileTitle - The divert status
     * vListTileSubTitle - The location, and name of the divert, or some default text.
     *                     First, an attempt to get the extension name is made, if this is not possible, the
     *                     9 prefix is removed from the number if it is external.
     *                     The name is optional, so display will differ if it is not present.
     * */
    vListTile(
      vListTileAction(
        vFlex(
          vSwitch(
            RenderOptions(
              props = Some(
                VSwitchProps(
                  color = Some("red"),
                  `input-value` =
                    Some(if (props.item.emergencyOverride.toString == "Active") true else false),
                  disabled = Some(
                    if (props.item.emergencyOverrideNumber.toString.length > 0) false else true
                  )
                )
              ),
              on = Some(
                EventBindings(
                  change = js.defined(e => {
                    toggleOverride(component, apiCallBus, apiCalls, props.item.didId.toString.toInt)
                  })
                )
              )
            )
          )
        )
      ),
      vListTileContent(
        RenderOptions(`class` = List(Left("truncate-text"))),
        vListTileTitle(
          props.item.emergencyOverride.toString,
          RenderOptions(style = Some(js.Dynamic.literal("margin" -> "0px")))
        ),
        if (!props.item.emergencyOverrideNumber.toString.isEmpty) {
          vListTileSubTitle(
            DDIUtil.getExtensionSil(
              DDIUtil.removeNineIfExternal(props.item.emergencyOverrideNumber.toString),
              component.tenantSubscribers,
              component.tenantAutoAttendants,
              component.tenantCallGroups
            ) +
              (if (props.item.emergencyOverrideName.toString.trim.nonEmpty)
                 " - " + props.item.emergencyOverrideName.toString
               else props.item.emergencyOverrideName.toString),
            RenderOptions(style = Some(js.Dynamic.literal("margin" -> "0px")))
          )
        } else {
          vListTileSubTitle(
            "Divert not set",
            RenderOptions(style = Some(js.Dynamic.literal("margin" -> "0px")))
          )
        }
      )
    )
  }

  private def divertScreen(
      apiCalls: ApiCalls,
      vueBus: Vue,
      component: DDIDisplayerData with DDIDisplayerProps,
      did: DIDNumber
  )(implicit sludgToken: SludgToken) = {
    vDialog(
      divertInputScreen(
        RenderOptions(
          key = Some(component.currentlySelectedDid.toString),
          props = Some(
            new DivertInputScreenProps(
              token = component.token,
              tenant = component.tenant,
              didFromTable = did,
              tenantAutoAttendants = component.tenantAutoAttendants,
              tenantCallGroups = component.tenantCallGroups,
              tenantSubscribers = component.tenantSubscribers
            )
          ),
          on = Some(
            DivertInputScreenEvents(
              updateSuccessful = js.defined(e => {
                if (e) {
                  component.divertDialogVisible = false
                  component.snackbarValues = SnackbarData(true, Divert)
                  getTenantDidsApiCall(component.tenant, apiCalls, vueBus, component)
                }
              }),
              closeEvent = js.defined(_ => {
                component.divertDialogVisible = false
              })
            )
          )
        )
      ),
      RenderOptions(
        props = Some(
          VDialogProps(
            value = Some(component.divertDialogVisible),
            `max-width` = Some(Right(500))
          )
        ),
        on = Some(
          EventBindings(
            input = js.defined(e => {
              component.divertDialogVisible = (e.asInstanceOf[Boolean])
            })
          )
        )
      )
    )
  }

  private def routingScreens(
      apiCalls: ApiCalls,
      vueBus: Vue,
      component: DDIDisplayerData with DDIDisplayerProps,
      did: DIDNumber
  )(implicit sludgToken: SludgToken) = {
    vDialog(
      vFlex(
        vCard(
          DDIUtil.RenderFunctions.cardTitleHeadline(
            "Time of day routing",
            did,
            EventBindings(
              click = js.defined(_ => {
                component.routingDialogVisible = false
              })
            )
          ),
          /* Routing input UI via multiple tabbed instances of `RoutingInputScreen`, created with different props.
           * Components emit their selection back, and are saved via an api call made from this dialog. */
          vTabs(
            vTab("Open " + component.hourlyLocations.openHoursExtension.getOrElse("")),
            vTab("Closed " + component.hourlyLocations.closedHoursExtension.getOrElse("")),
            vTab("Lunch " + component.hourlyLocations.lunchHoursExtension.getOrElse("")),
            vTab("Special " + component.hourlyLocations.specialHoursExtension.getOrElse("")),
            vTabItem(
              routingInputComponent(apiCalls, vueBus, component, did, Open)
            ),
            vTabItem(
              routingInputComponent(apiCalls, vueBus, component, did, Closed)
            ),
            vTabItem(
              routingInputComponent(apiCalls, vueBus, component, did, Lunch)
            ),
            vTabItem(
              routingInputComponent(apiCalls, vueBus, component, did, Special)
            ),
            RenderOptions(
              props = Some(
                VTabsProps(
                  grow = Some(true)
                )
              )
            )
          ),
          vButton(
            "Save",
            RenderOptions(
              on = Some(
                EventBindings(
                  click = js.defined(_ => {
                    LoadingFuture.withLoading(
                      vueBus,
                      apiCalls
                        .setDidHours(component.tenant.id, did.didId, component.hourlyLocations)
                        .map {
                          case Right(value) =>
                            if (value) {
                              getTenantDidsApiCall(component.tenant, apiCalls, vueBus, component)
                              component.routingDialogVisible = false
                              component.snackbarValues = SnackbarData(true, Routing)
                            } else {
                              logger.debug("Unable to set routing due to an error with the api. ")
                            }
                          case Left(error) =>
                            logger.debug("Route setting failed: " + error)
                        }
                    )
                  })
                )
              )
            )
          )
        )
      ),
      RenderOptions(
        props = Some(
          VDialogProps(
            value = Some(component.routingDialogVisible),
            `max-width` = Some(Right(600)),
            `lazy` = Some(true)
          )
        ),
        on = Some(
          EventBindings(
            input = js.defined(e => {
              component.routingDialogVisible = (e.asInstanceOf[Boolean])
            })
          )
        )
      )
    )
  }

  def routingInputComponent(
      apiCalls: ApiCalls,
      vueBus: Vue,
      component: DDIDisplayerData with DDIDisplayerProps,
      did: DIDNumber,
      tabType: HourlyType
  )(implicit token: SludgToken) = {
    routingInputScreen(
      RenderOptions(
        key = Some(component.currentlySelectedDid.toString),
        props = Some(
          new RoutingInputScreenProps(
            token = component.token,
            tenant = component.tenant,
            didFromTable = did,
            hourComponentType = tabType,
            tenantAutoAttendants = component.tenantAutoAttendants,
            tenantCallGroups = component.tenantCallGroups,
            tenantSubscribers = component.tenantSubscribers
          )
        ),
        on = Some(
          RoutingInputScreenEvents(
            routeUpdateSuccessful = js.defined(e => {
              if (e) getTenantDidsApiCall(component.tenant, apiCalls, vueBus, component)
            }),
            setExtension = js.defined(e => {
              component.hourlyLocations = tabType match {
                case Open => component.hourlyLocations.copy(openHoursExtension = Some(e))
                case Closed => component.hourlyLocations.copy(closedHoursExtension = Some(e))
                case Lunch => component.hourlyLocations.copy(lunchHoursExtension = Some(e))
                case Special => component.hourlyLocations.copy(specialHoursExtension = Some(e))
              }
            })
          )
        )
      )
    )
  }

  def convertToTableData(did: DIDNumber): DDITableItems = {
    //This is somewhat bloated, but that's because for text to be searchable from within a particular column
    //it must be included in the table data at this point. Putting text in the <td> tag from elsewhere in the table items
    //will not be searchable in the table even though it's in the same structure.
    //Sticking as much information as possible in here leaves formatting implementation to be customised afterwards.
    DDITableItems(
      Some(did.didId.toString),
      Some(did.phoneNumber),
      Some(DDIUtil.booleanToText(did.assigned, "In use", "Unused")),
      did.incomingName,
      did.closedHoursExtension,
      did.lunchHoursExtension,
      did.openHoursExtension,
      did.specialHoursExtension,
      Some(DDIUtil.booleanToText(did.emergencyOverride.active, "Active", "Inactive")),
      did.emergencyOverride.number,
      did.emergencyOverride.name,
      (for {
        closed <- did.closedHoursExtension
        lunch <- did.lunchHoursExtension
        open <- did.openHoursExtension
        special <- did.specialHoursExtension
      } yield (open + closed + lunch + special)),
      (for {
        number <- did.emergencyOverride.number
        name <- did.emergencyOverride.name
      } yield (DDIUtil.booleanToText(
        did.emergencyOverride.active,
        "Active",
        "Inactive Divert not set"
      ) + number + name))
    )
  }

  def getTenantDidsApiCall(
      tenantVal: Tenant,
      apiCalls: ApiCalls,
      vueBus: Vue,
      component: DDIDisplayerData
  )(implicit token: SludgToken): Future[Either[Models.AccessForbidden, List[DIDNumber]]] = {
    LoadingFuture.withLoading(
      vueBus,
      apiCalls.getAllTenantDIDs(tenantVal.id).transform {
        case a @ Success(Right(didNumber)) =>
          component.didsOnTenant = didNumber.sortBy(_.phoneNumber)
          a
        case e =>
          component.didsOnTenant = Nil
          logger.error(s"Fetching tenant DIDs failed: $e")
          e
      }
    )
  }

  def getTenantSilhouetteUsers(tenantVal: Tenant, apiCalls: ApiCalls, component: DDIDisplayerData)(
      implicit token: SludgToken
  ): Future[Either[Models.AccessForbidden, Unit]] = {
    (for {
      subs <- EitherT(apiCalls.getSubscribers(tenantVal.id))
      autoAttendants <- EitherT(apiCalls.getAutoAttendants(tenantVal.id))
      callGroups <- EitherT(apiCalls.getCallGroups(tenantVal.id))
    } yield {
      component.tenantSubscribers = subs.sortBy(_.extension)
      component.tenantAutoAttendants = autoAttendants.sortBy(_.extension)
      component.tenantCallGroups = callGroups.sortBy(_.extension)
    }).value
  }

  def toggleOverride(
      component: DDIDisplayerData with DDIDisplayerProps,
      vueBus: Vue,
      apiCalls: ApiCalls,
      didId: Int
  )(implicit token: SludgToken) = {
    component.snackbarValues = SnackbarData(false, NotConfiguredError)
    component.currentlySelectedDid = DDIUtil.collectDidFromTable(component.didsOnTenant, didId)
    val emergencyOverride =
      EmergencyOverride(!component.currentlySelectedDid.get.emergencyOverride.active, None, None)

    LoadingFuture.withLoading(
      vueBus,
      apiCalls.setEmergencyOverride(component.tenant.id, didId, emergencyOverride).map {
        case Right(value) =>
          if (value) {
            getTenantDidsApiCall(component.tenant, apiCalls, vueBus, component)
            component.snackbarValues = SnackbarData(true, Divert)
          } else {
            component.snackbarValues = SnackbarData(true, Divert)
          }
        case Left(error) => logger.debug("disableCall failed: " + error)
      }
    )
  }

}

class DDIDisplayerWatcher extends js.Object {

  def tenant(tenantVal: Tenant): Unit = {
    //On tenant change, refresh the lists of silhouette locations
    val data = this.asInstanceOf[DDIDisplayerData]
    val props = this.asInstanceOf[DDIDisplayerProps]
    (
      this.asInstanceOf[DDIDisplayerData].apiCalls,
      this.asInstanceOf[DDIDisplayerData].vueBus
    ) match {
      case (Some(api), Some(vue)) =>
        implicit val token: SludgToken = props.token
        data.page = 1
        DDIDisplayer.getTenantDidsApiCall(tenantVal, api, vue, data)
        DDIDisplayer.getTenantSilhouetteUsers(tenantVal, api, data)
      case _ => None
    }
  }
}

class DDIDisplayerData extends js.Object {
  var didsOnTenant: List[DIDNumber] = Nil
  var tableSearchString: String = ""
  var currentlySelectedDid: Option[DIDNumber] = None
  var hourlyLocations: SilhouetteModels.HourlyExtensions =
    SilhouetteModels.HourlyExtensions(None, None, None, None)

  var tenantSubscribers: List[SilhouetteModels.SilhouetteLocation] = Nil
  var tenantAutoAttendants: List[SilhouetteModels.SilhouetteLocation] = Nil
  var tenantCallGroups: List[SilhouetteModels.SilhouetteLocation] = Nil

  var showMenu: Boolean = false
  var divertDialogVisible: Boolean = false
  var routingDialogVisible: Boolean = false
  var apiCalls: Option[ApiCalls] = None
  var vueBus: Option[Vue] = None

  var snackbarValues: SnackbarData = SnackbarData(visible = false, NotConfiguredError)

  var page = 1
}

trait DDIDisplayerComputed extends js.Object {
  def didsOnTenantGrouped: List[List[DIDNumber]]
}

object DDIDisplayerComputed {
  //A computed value to "chunk" the list of dids for pagination is used, to prevent this value from being recalculated on render
  def apply(): DDIDisplayerComputed = js.Dynamic
    .literal(
      "didsOnTenantGrouped" -> ((
          data =>
            data.didsOnTenant
              .filter(_.phoneNumber.contains(data.tableSearchString))
              .grouped(8)
              .toList
      ): ThisFunction0[DDIDisplayerData, List[List[DIDNumber]]])
    )
    .asInstanceOf[DDIDisplayerComputed]
}

trait DDIDisplayerProps extends VueProps {
  var token: SludgToken
  var tenant: Tenant
}

object DDIDisplayerProps {
  def apply(
      token: SludgToken,
      tenant: Tenant
  ): DDIDisplayerProps = {
    js.Dynamic
      .literal(
        "token" -> token.asInstanceOf[js.Any],
        "tenant" -> tenant.asInstanceOf[js.Any]
      )
      .asInstanceOf[DDIDisplayerProps]
  }
}

/*
 * This trait and case objects define some static values to use in a snackbar for user feedback of the DDIDisplayer.
 * The [SnackbarData] defines the visibility, and the given configuration specifies which values will be shown
 * The [ConfiguredValues] data is appended onto the `currentlySelectedDid` information to inform the user what they
 * were modifying, and whether it was successful.
 */
trait ConfiguredValues {
  def text: String

  def colour: String
}

case object NotConfiguredError extends ConfiguredValues {
  def text: String = "Not set. A divert must be configured before being enabled."

  def colour: String = "error"
}

case object Routing extends ConfiguredValues {
  def text: String = "Routing successfully configured."

  def colour: String = "success"
}

case object Divert extends ConfiguredValues {
  def text: String = "Divert successfully configured."

  def colour: String = "success"
}

case class SnackbarData(visible: Boolean, configuration: ConfiguredValues)
