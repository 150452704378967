package com.sludg.components

import com.sludg.FieldExtractor
import com.sludg.auth0.SludgToken
import com.sludg.helpers.LoadingFuture
import com.sludg.services.ApiCalls
import com.sludg.util.models.SilhouetteModels.Tenant
import com.sludg.vue.{RenderHelpers, _}
import com.sludg.vuetify.VuetifyComponents.vAutocomplete
import com.sludg.vuetify.components.{VAutocompleteEventBindings, VAutocompleteProps, VButtonProps}
import org.log4s.getLogger
import org.scalajs.dom.Event

import scala.scalajs.js
import com.sludg.vuetify.VuetifyComponents._
import com.sludg.vue.RenderHelpers._

object TenantSelector {
  import monix.execution.Scheduler.Implicits.global
  private[components] val logger = getLogger

  type TenantSelectorType = VueComponent[_ <: TenantSelectorProps, _ <: Slots, _ <: ScopedSlots]
    with TenantSelectorData
    with js.Object
    with TenantSelectorProps
    with WithRefs[_ <: Refs]

  def tenantSelectorRenderer(
      registrationName: String
  ): RenderHelpers.NodeRenderer[TenantSelectorProps, TenantSelectorEvents, ScopedSlots] =
    namedTag[TenantSelectorProps, TenantSelectorEvents, ScopedSlots](registrationName)

  def tenantSelectorComponent(
      apiCalls: ApiCalls,
      loadingEventBus: Vue,
      userLinkDomain: Option[String] = None
  ): VueComponent[_ <: TenantSelectorProps, _ <: Slots, _ <: ScopedSlots] = {
    VueComponent.builder
      .withPropsAs[TenantSelectorProps]
      .withData(new TenantSelectorData())
      .build(
        created = js.defined(t =>
          loadData(
            apiCalls,
            t.token.isSuperUser,
            loadingEventBus,
            t.token.subscribers.headOption.map(_.tid.toInt),
            t.token,
            t
          )
        ),
        updated = js.defined(_ => logger.debug("Tenant selector updated")),
        templateOrRender = Right((t, renderer) =>
          (if (t.token.isSuperUser && t.visible) {
             div(
               vContainer(
                 vAutocomplete[Tenant](
                   RenderOptions(
                     props = Some(
                       VAutocompleteProps[Tenant](
                         items = Some(t.tenantList),
                         placeholder = Some("Select Tenant"),
                         clearable = Some(true),
                         value = t.currentlySelectedTenant.map(a => Left(a)),
                         itemText = Some(
                           Right(input =>
                             (input: Any) match {
                               case a: List[_] =>
                                 a.collect { case tenant: Tenant => tenant.name }.mkString(", ")
                               case a: Tenant => a.name
                             }
                           )
                         ),
                         itemValue = Some(Right(a => a))
                       )
                     ),
                     on = Some(
                       VAutocompleteEventBindings(input = js.defined(e =>
                         if (js.isUndefined(e)) {
                           logger.debug("TARGET TENANT IS NULL")
                         } else {
                           t.$emit(
                             "tenantSelected",
                             if (js.isUndefined(e)) None
                             else Some(e.asInstanceOf[Tenant])
                           )
                           t.currentlySelectedTenant = Some(e.asInstanceOf[Tenant])
                         }
                       )
                       )
                     )
                   )
                 )
               )
             )
           } else if (t.token.subscribers.isEmpty && t.visible) {
             div(
               vContainer(
                 vCardText(
                   "Couldn't find a linked account",
                   RenderOptions(`class` = List(Left("headline")))
                 ),
                 p(
                   RenderOptions(style = Some(js.Dynamic.literal("padding-left" -> "16px"))),
                   "Access to VTSL applications first requires you to link your account through our User Portal."
                 ),
                 vLayout(
                   vButton(
                     "Click here",
                     RenderOptions(
                       props = Some(
                         VButtonProps(
                           small = Some(true),
                           bottom = Some(true),
                           flat = Some(true),
                           color = Some("primary"),
                           href = Some(Left(s"https://${userLinkDomain.getOrElse("")}")),
                           target = Some("_blank")
                         )
                       )
                     )
                   ),
                   p(
                     RenderOptions(style =
                       Some(js.Dynamic.literal("margin-top" -> "-6px", "margin-left" -> "-8px"))
                     ),
                     "to link via User Portal (you can also access it in the sidebar)."
                   )
                 )
               )
             )
           } else {
             div()
           }).render(renderer)
        )
      )
  }

  def loadData(
      apiCalls: ApiCalls,
      superUser: Boolean,
      loadingBus: Vue,
      tenantId: Option[Int],
      token: SludgToken,
      t: TenantSelectorType
  ): Unit = {
    TenantSelector.logger.debug("Loading tenants.")

    if (superUser) {

      LoadingFuture.withLoading(
        loadingBus,
        apiCalls
          .getAllTenants()(implicitly, token)
          .map({
            case Right(result) => {
              t.tenantList = result

              tenantId match {
                case None => TenantSelector.logger.debug("No tenant id found.")
                case Some(tid) =>
                  t.currentlySelectedTenant = t.tenantList collectFirst {
                    case i if (i.id == tid) => i
                  }
                  t.currentlySelectedTenant match {
                    case Some(tenant) => t.$emit("tenantSelected", Some(tenant))
                    case None         => logger.debug("Your linked tenant was not found.")
                  }

              }
            }
            case Left(_) => TenantSelector.logger.debug("No access.");
          })
      )
    } else {
      tenantId match {
        case None => TenantSelector.logger.debug("No tenant id found.")
        case Some(tid) =>
          LoadingFuture.withLoading(
            loadingBus,
            apiCalls.getTenant(tid)(implicitly, token).map {
              case Right(Some(x)) =>
                t.currentlySelectedTenant = Some(x)
                t.$emit("tenantSelected", Some(x))
              case _ => TenantSelector.logger.debug("No access.")
            }
          )
      }
    }
  }
}

class TenantSelectorData extends js.Object {
  var tenantList: List[Tenant]                = Nil
  var currentlySelectedTenant: Option[Tenant] = None
}

class TenantSelectorEvents(val tenantSelected: js.Function1[Option[Tenant], Unit]) extends js.Object

class TenantSelectorProps(val token: SludgToken, val visible: Boolean = true) extends VueProps
