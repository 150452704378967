package com.sludg.vuetify.components.transitions

import com.sludg.vue.{EventBindings, ScopedSlots}

trait VSlideXReverseTransition {

  import com.sludg.vue.RenderHelpers._

  val vSlideXReverseTransition =
    namedTag[VTransitionProps, EventBindings, ScopedSlots]("v-slide-x-reverse-transition")

}
