package com.sludg.vuetify.components.gridSystem

import com.sludg.scalajs.DynamicHelper
import com.sludg.vue.{EventBindings, ScopedSlots, VueProps}

import scala.scalajs.js

trait VueGridItem {
  import com.sludg.vue.RenderHelpers._
  val vueGridItem = namedTag[VueGridItemProps, EventBindings, ScopedSlots]("grid-item")
}

trait VueGridItemProps extends VueProps {
  val isResizable: js.UndefOr[Boolean]     = js.undefined
  val isDraggable: js.UndefOr[Boolean]     = js.undefined
  val minW: js.UndefOr[Int]                = js.undefined
  val minH: js.UndefOr[Int]                = js.undefined
  val x: js.UndefOr[Int]                   = js.undefined
  val y: js.UndefOr[Int]                   = js.undefined
  val i: js.UndefOr[Int]                   = js.undefined
  val h: js.UndefOr[Int]                   = js.undefined
  val w: js.UndefOr[Int]                   = js.undefined
  val maxW: js.UndefOr[Int]                = js.undefined
  val maxH: js.UndefOr[Int]                = js.undefined
  val static: js.UndefOr[Boolean]          = js.undefined
  val dragIgnoreFrom: js.UndefOr[String]   = js.undefined
  val dragAllowFrom: js.UndefOr[String]    = js.undefined
  val resizeIgnoreFrom: js.UndefOr[String] = js.undefined
}

object VueGridItemProps {

  import scala.scalajs.js.JSConverters._

  def apply(
      isResizable: Option[Boolean] = None,
      isDraggable: Option[Boolean] = None,
      minW: Option[Int] = None,
      minH: Option[Int] = None,
      x: Option[Int] = None,
      y: Option[Int] = None,
      i: Option[Int] = None,
      h: Option[Int] = None,
      w: Option[Int] = None,
      maxW: Option[Int] = None,
      maxH: Option[Int] = None,
      static: Option[Boolean] = None,
      dragIgnoreFrom: Option[String] = None,
      dragAllowFrom: Option[String] = None,
      resizeIgnoreFrom: Option[String] = None
  ): VueGridItemProps = {
    DynamicHelper.buildViaDynamic(
      "isResizable"      -> isResizable.map(js.Any.fromBoolean).orUndefined,
      "isDraggable"      -> isDraggable.map(js.Any.fromBoolean).orUndefined,
      "minW"             -> minW.map(js.Any.fromInt).orUndefined,
      "minH"             -> minH.map(js.Any.fromInt).orUndefined,
      "x"                -> x.map(js.Any.fromInt).orUndefined,
      "y"                -> y.map(js.Any.fromInt).orUndefined,
      "i"                -> i.map(js.Any.fromInt).orUndefined,
      "h"                -> h.map(js.Any.fromInt).orUndefined,
      "w"                -> w.map(js.Any.fromInt).orUndefined,
      "maxW"             -> maxW.map(js.Any.fromInt).orUndefined,
      "maxH"             -> maxH.map(js.Any.fromInt).orUndefined,
      "static"           -> static.map(js.Any.fromBoolean).orUndefined,
      "dragIgnoreFrom"   -> dragIgnoreFrom.map(js.Any.fromString).orUndefined,
      "dragAllowFrom"    -> dragAllowFrom.map(js.Any.fromString).orUndefined,
      "resizeIgnoreFrom" -> resizeIgnoreFrom.map(js.Any.fromString).orUndefined
    )
  }
}
