package com.sludg.services

import java.nio.ByteBuffer

import com.sludg.auth0.SludgToken
import com.sludg.models.Config.ApiConfig
import com.sludg.models.Models.AccessForbidden
import com.sludg.util.models.CallModels.{CDR, CallStats}
import com.sludg.util.models.{GroupingModels, ReportModels}
import com.sludg.util.models.ReportModels._
import fr.hmil.roshttp.Method._
import play.api.libs.json.{JsValue => _, Reads => _, _}
import com.sludg.auth0.SludgToken
import com.sludg.models.Config.ApiConfig
import com.sludg.models.Models.AccessForbidden
import Helpers.{JSONBody, handleStatus, handleSuccess, _}
import com.sludg.util.json.CallJsonDeserializers
import com.sludg.util.json.CallJsonDeserializers._
import com.sludg.util.json.FiltersJsonDeserializers._
import com.sludg.util.json.GroupingJsonDeserializers._
import com.sludg.util.json.ReportJsonDeserializers._
import com.sludg.util.json.SilhouetteDeserializers._
import com.sludg.util.models.CallModels.CDR
import com.sludg.util.models.DashboardComponentModels.{
  CallGroupComponentData,
  CallGroupMember,
  DashboardComponent
}
import com.sludg.util.models.SilhouetteModels.{
  AutoAttendant,
  CallGroup,
  CallGroupMemberData,
  DIDNumber,
  EmergencyOverride,
  HourlyExtensions,
  Subscriber,
  Tenant,
  UserPhone
}
import com.sludg.util.models.{GroupingModels, ReportModels}
import fr.hmil.roshttp.body.{BodyPart, BulkBodyPart, URLEncodedBody}
import fr.hmil.roshttp.exceptions.HttpException
import fr.hmil.roshttp.response.{SimpleHttpResponse, StreamHttpResponse}
import fr.hmil.roshttp.{HttpRequest, Method, Protocol}
import monix.execution.Scheduler
import org.scalajs.dom.Event
import org.scalajs.dom.raw.XMLHttpRequest
import play.api.libs.json.{Json, _}
import com.sludg.util.json.EventsDeserializer._

import scala.concurrent.{ExecutionContext, Future, Promise}
import scala.language.postfixOps
import com.sludg.util.models.DashboardModels._
import com.sludg.util.models.GroupingModels._
import com.sludg.util.json.DashboardJsonDeserializers._
import com.sludg.util.json.DashboardComponentDeserializers._
import com.sludg.util.json.CallRecordingDeserializers._
import com.sludg.util.models.CallGroupCounterModels.CallGroupCallStats
import com.sludg.util.models.Events.UserPresenceState
import com.sludg.util.json.SubscriberPreferenceDeserialzers._
import org.log4s.getLogger
import com.sludg.util.json.EventsDeserializer.callGroupStatsWrite
import com.sludg.util.json.EventsDeserializer.callGroupStatsRead
import com.sludg.util.json.SilhouetteDeserializers._
import scala.reflect.ClassTag
import com.sludg.util.models.CallRecordingInfo
import org.scalajs.dom.raw.Blob
import cats.data.EitherT
import cats.instances.future._
import com.sludg.util.models.RoleModels.{AssignedRoles, Role}
import com.sludg.util.models.SubscriberPreferenceModels.WatchedUserPresence
import com.sludg.util.json.DashboardJsonDeserializers._
import com.sludg.util.json.RoleJsonDeserializers._

class ApiCalls(config: ApiConfig) {
  val configuratedProtocol: Protocol = Protocol.fromString(config.protocol)

  def getCallRecordingInfo(tenantId: Int)(
      implicit scheduler: Scheduler,
      token: SludgToken
  ): Future[Either[AccessForbidden, CallRecordingInfo]] = {
    callProtectedApi(s"/v1/tenant/$tenantId/recording/", GET)(
      handleSuccess(deserialiseBodyAs[CallRecordingInfo](Some("info")))
    )
  }

  def toggleCallRecording(
      tenantId: Int,
      newStatus: Boolean
  )(implicit scheduler: Scheduler, token: SludgToken): Future[Either[AccessForbidden, Unit]] = {
    callProtectedApi(
      s"/v1/tenant/$tenantId/recording/",
      PUT,
      body = Some(JSONBody(Json.obj("newStatus" -> newStatus)))
    )(
      handleSuccess(valueResult(()))
    )
  }

  def canToggleCallRecording(
      tenantId: Int
  )(implicit scheduler: Scheduler, token: SludgToken): Future[Boolean] = {
    getAuthorizedMethods(s"/v1/tenant/$tenantId/recording", token.tokenString)
      .map(_.contains(Method.PUT))
  }

  def getUserPhones(tenantId: Int, extension: Int)(
      implicit scheduler: Scheduler,
      token: SludgToken
  ): Future[Either[AccessForbidden, List[UserPhone]]] = {
    callProtectedApi(s"/v1/tenant/$tenantId/extension/$extension/user_phone", GET, body = None)(
      handleSuccess(deserialiseBodyAs[List[UserPhone]]())
        .orElse(handleStatus(500, valueResult(Nil)))
    )
  }

  def geDefaultUserPhone(tid: Int, ext: Int, token: SludgToken)(
      implicit scheduler: Scheduler,
      ec: ExecutionContext
  ): Future[Either[AccessForbidden, Option[UserPhone]]] =
    getUserPhones(tid, ext)(implicitly, token).map(_.map(_.find(_.defaultC2CPhone)))

  def setClickToDialDevice(tenantId: Int, extension: Int, deviceId: Int)(
      implicit scheduler: Scheduler,
      token: SludgToken
  ): Future[Either[AccessForbidden, Boolean]] = {
    callProtectedApi(s"/v1/tenant/$tenantId/extension/$extension/user_phone/$deviceId", POST, None)(
      handleSuccess(valueResult(true)).orElse(handleStatus(500, valueResult(false)))
    )
  }

  def deleteCallGroupCallStats(tenantId: Int, callGroupId: Int)(
      implicit scheduler: Scheduler,
      token: SludgToken
  ): Future[Either[AccessForbidden, Option[CallGroupCallStats]]] = {
    doRequestAndWrapResultWithFallback[CallGroupCallStats](
      s"/v1/tenant/$tenantId/call_groups/${callGroupId}/stats",
      DELETE
    )
  }

  def getCallGroupCallStats(tenantId: Int, callGroupId: Int)(
      implicit scheduler: Scheduler,
      token: SludgToken
  ): Future[Either[AccessForbidden, Option[CallGroupCallStats]]] = {
    doRequestAndWrapResultWithFallback[CallGroupCallStats](
      s"/v1/tenant/$tenantId/call_groups/${callGroupId}/stats"
    )
  }

  def updateCallGroupMember(tenantId: Int, callGroupId: Int, extension: Int, loggedIn: Boolean)(
      implicit scheduler: Scheduler,
      token: SludgToken
  ): Future[Either[AccessForbidden, Boolean]] = {
    callProtectedApi(
      s"/v1/tenant/$tenantId/call_groups/$callGroupId/members/$extension",
      PUT,
      body = Some(JSONBody(Json.toJson(loggedIn)))
    )(
      handleSuccess(valueResult(true)).orElse(handleStatus(400, valueResult(false)))
    )(scheduler = scheduler, token = token)
  }

  def getCallGroupMembers(tenantId: Int, callGroupId: Int)(
      implicit scheduler: Scheduler,
      token: SludgToken
  ): Future[Either[AccessForbidden, Option[CallGroupComponentData]]] =
    doRequestAndWrapResultWithFallback[CallGroupComponentData](
      s"/v1/tenant/$tenantId/call_groups/$callGroupId"
    )

  def getUserRolesOnTenant(tenantId: Int)(
      implicit scheduler: Scheduler,
      token: SludgToken
  ): Future[Either[AccessForbidden, List[AssignedRoles]]] = {
    callProtectedApi(
      s"/v1/tenant/$tenantId/roles/",
      GET,
      body = None
    )(handleSuccess(deserialiseBodyAs[List[AssignedRoles]]()))
  }

  def getUserRoles(tenantId: Int, subscriberId: Int)(
      implicit scheduler: Scheduler,
      token: SludgToken
  ): Future[Either[AccessForbidden, Option[AssignedRoles]]] =
    doRequestAndWrapResultWithFallback[AssignedRoles](
      s"/v1/tenant/$tenantId/subscribers/${subscriberId}/role/"
    )

  def updateUserRoles(subscriberId: Int, tenantId: Int, roles: List[String])(
      implicit scheduler: Scheduler,
      token: SludgToken
  ): Future[Either[AccessForbidden, Boolean]] = {
    callProtectedApi(
      s"/v1/tenant/$tenantId/subscribers/$subscriberId/role/",
      PUT,
      body = Some(JSONBody(Json.toJson(roles)))
    )(
      handleSuccess(valueResult(true)).orElse(handleStatus(400, valueResult(false)))
    )
  }

  def getIncomingCallStats(tenantId: Int, filters: List[Filter])(
      implicit scheduler: Scheduler,
      token: SludgToken
  ): Future[Either[AccessForbidden, Option[CallStats]]] = {
    doPostAndWrapResultWithFallback[CallStats](
      s"/v1/tenant/$tenantId/calls/incoming_stats",
      Json.toJson(filters)
    )
  }

  def getOutgoingCallStats(tenantId: Int, filters: List[Filter])(
      implicit scheduler: Scheduler,
      token: SludgToken
  ): Future[Either[AccessForbidden, Option[CallStats]]] = {
    doPostAndWrapResultWithFallback[CallStats](
      s"/v1/tenant/$tenantId/calls/outgoing_stats",
      Json.toJson(filters)
    )
  }

  def getDashboard(tenantId: Int, subscriberId: Int, dashboardId: Int)(
      implicit scheduler: Scheduler,
      token: SludgToken
  ): Future[Either[AccessForbidden, Option[DashboardData]]] = {
    doRequestAndWrapResultWithFallback[DashboardData](
      s"/v1/tenant/${tenantId}/subscribers/${subscriberId}/dashboards/${dashboardId}"
    )
  }

  def callStats(tenantId: Int, uri: String)(
      implicit scheduler: Scheduler,
      token: SludgToken
  ): Future[Either[AccessForbidden, Option[CallStats]]] = {
    doRequestAndWrapResultWithFallback[CallStats](s"/v1/tenant/$tenantId/calls/$uri")
  }

  def getUserPresence(tenantId: Int, extension: String)(
      implicit scheduler: Scheduler,
      token: SludgToken
  ): Future[Either[AccessForbidden, Option[UserPresenceState]]] = {
    import com.sludg.util.models.Events.{UserPresenceState}

    doRequestAndWrapResultWithFallback[UserPresenceState](
      s"/v1/tenant/$tenantId/extension/$extension/user_presence"
    )
  }

  type WrappedHandle[T] =
    PartialFunction[SimpleHttpResponse, Future[Either[AccessForbidden, Option[T]]]]

  def doPostAndWrapResultWithFallback[T: ClassTag: Reads](
      url: String,
      jsonBody: JsValue,
      wrappedHandler: WrappedHandle[T] => WrappedHandle[T] = (defaultCase: WrappedHandle[T]) =>
        defaultCase.orElse(handleStatus(404, valueResult(None)))
  )(
      implicit scheduler: Scheduler,
      token: SludgToken
  ): Future[Either[AccessForbidden, Option[T]]] = {
    callProtectedApi(url, POST, body = Some(JSONBody(jsonBody)))(
      wrappedHandler(handleSuccess(deserialiseAsAndWrapBody[T]()))
    )
  }

  def doRequestAndWrapResultWithFallback[T: ClassTag: Reads](url: String, method: Method = GET)(
      implicit scheduler: Scheduler,
      token: SludgToken
  ): Future[Either[AccessForbidden, Option[T]]] = {
    callProtectedApi(url, method, body = None)(
      handleSuccess(deserialiseAsAndWrapBody[T]()).orElse(handleStatus(404, valueResult(None)))
    )
  }

  def getUserAcessibleDashboards(tenantId: Int, subscriberId: Int)(
      implicit scheduler: Scheduler,
      token: SludgToken
  ): Future[Either[AccessForbidden, List[Dashboard]]] = {
    import com.sludg.util.json.DashboardJsonDeserializers._
    callProtectedApi(
      s"/v1/tenant/${tenantId}/subscribers/${subscriberId}/dashboards/user_accessible",
      GET,
      body = None
    )(
      handleSuccess(deserialiseBodyAs[List[Dashboard]]())
        .orElse(handleStatus(404, valueResult(Nil)))
    )
  }

  def getSubscriberDashboards(tenantId: Int, subscriberId: Int)(
      implicit scheduler: Scheduler,
      token: SludgToken
  ): Future[Either[AccessForbidden, List[Dashboard]]] = {
    import com.sludg.util.json.DashboardJsonDeserializers._
    callProtectedApi(
      s"/v1/tenant/${tenantId}/subscribers/${subscriberId}/dashboards/",
      GET,
      body = None
    )(
      handleSuccess(deserialiseBodyAs[List[Dashboard]]())
        .orElse(handleStatus(404, valueResult(Nil)))
    )
  }

  def getTenantDashboards(tenantId: Int)(
      implicit scheduler: Scheduler,
      token: SludgToken
  ): Future[Either[AccessForbidden, List[Dashboard]]] = {
    import com.sludg.util.json.DashboardJsonDeserializers._
    callProtectedApi(
      s"/v1/tenant/${tenantId}/dashboards/",
      GET,
      body = None
    )(
      handleSuccess(deserialiseBodyAs[List[Dashboard]]())
        .orElse(handleStatus(404, valueResult(Nil)))
    )
  }

  def deleteDashboard(tenantId: Int, subscriberId: Int, dashboardId: Int)(
      implicit scheduler: Scheduler,
      token: SludgToken
  ): Future[Either[AccessForbidden, Boolean]] = {
    callProtectedApi(
      s"/v1/tenant/${tenantId}/subscribers/${subscriberId}/dashboards/${dashboardId}",
      DELETE,
      body = None
    )(
      handleSuccess(valueResult(true)).orElse(handleStatus(404, valueResult(false)))
    )
  }

  def updateDashboard(
      tenantId: Int,
      subscriberId: Int,
      dashboardId: Int,
      dashboardComponent: List[DashboardComponent],
      name: String,
      shared: Boolean,
      favourited: Boolean
  )(implicit scheduler: Scheduler, token: SludgToken): Future[Either[AccessForbidden, Boolean]] = {
    import com.sludg.util.json.DashboardJsonDeserializers._
    val jsonBody = Json.toJson(DashboardData(dashboardComponent, name, shared, favourited))

    callProtectedApi(
      s"/v1/tenant/$tenantId/subscribers/$subscriberId/dashboards/$dashboardId/",
      PUT,
      body = Some(JSONBody(jsonBody))
    )(
      handleSuccess(valueResult(true))
        .orElse(handleStatus(400, valueResult(false)))
    )
  }

  def getInfo(tenantId: Int, filters: List[Filter])(
      implicit scheduler: Scheduler,
      token: SludgToken
  ): Future[Either[AccessForbidden, Option[List[ExtensionStats]]]] = {
    doPostAndWrapResultWithFallback[List[ExtensionStats]](
      s"/v1/tenant/$tenantId/calls/report/user_stats",
      Json.toJson(filters)
    )
  }

  def createDashboard(tenantId: Int, subscriberId: Int, dashboardData: DashboardData)(
      implicit scheduler: Scheduler,
      token: SludgToken
  ): Future[Either[AccessForbidden, Option[Int]]] = {
    import com.sludg.util.json.DashboardJsonDeserializers._
    doPostAndWrapResultWithFallback[Int](
      s"/v1/tenant/$tenantId/subscribers/$subscriberId/dashboards/",
      Json.toJson(dashboardData),
      _.orElse(handleStatus(404, valueResult(None))).orElse(handleStatus(400, valueResult(None)))
    )
  }

  //Tenant
  def getAllTenants()(
      implicit scheduler: Scheduler,
      token: SludgToken
  ): Future[Either[AccessForbidden, List[Tenant]]] = {
    callProtectedApi(
      "/v1/tenant/"
    )(
      handleSuccess(deserialiseBodyAs[List[Tenant]](Some("listOfTenants")))
        .orElse(handleStatus(404, valueResult(Nil)))
    )
  }

  def getTenant(tenantId: Int)(
      implicit scheduler: Scheduler,
      token: SludgToken
  ): Future[Either[AccessForbidden, Option[Tenant]]] = {
    callProtectedApi(
      s"/v1/tenant/$tenantId"
    )(
      handleSuccess(deserialiseAsAndWrapBody[Tenant](Some("tenant")))
        .orElse(handleStatus(404, valueResult(None)))
    )
  }

  def getSubscribers(tenantId: Int)(
      implicit scheduler: Scheduler,
      token: SludgToken
  ): Future[Either[AccessForbidden, List[Subscriber]]] = {
    callProtectedApi(s"/v1/tenant/$tenantId/subscribers", GET, body = None)(
      handleSuccess(deserialiseBodyAs[List[Subscriber]](Some("subscribers"))).orElse {
        case x if x.statusCode == 404 => Future.successful(Right(Nil))
      }
    )
  }

  def getSubscriber(tenantId: Int, subscriberId: Int)(
      implicit scheduler: Scheduler,
      token: SludgToken
  ): Future[Either[AccessForbidden, Option[Subscriber]]] = {
    callProtectedApi(s"/v1/tenant/$tenantId/subscribers/$subscriberId", GET, body = None)(
      handleSuccess(deserialiseAsAndWrapBody[Subscriber](Some("subscriber")))
        .orElse(handleStatus(404, valueResult(None)))
    )
  }

  def getAutoAttendants(tenantId: Int)(
      implicit scheduler: Scheduler,
      token: SludgToken
  ): Future[Either[AccessForbidden, List[AutoAttendant]]] = {
    callProtectedApi(s"/v1/tenant/$tenantId/auto_attendants", GET, body = None)(
      handleSuccess(deserialiseBodyAs[List[AutoAttendant]]()).orElse {
        case x if x.statusCode == 404 => Future.successful(Right(Nil))
      }
    )
  }

  def getCallGroups(tenantId: Int)(
      implicit scheduler: Scheduler,
      token: SludgToken
  ): Future[Either[AccessForbidden, List[CallGroup]]] = {
    callProtectedApi(s"/v1/tenant/$tenantId/call_groups", GET, body = None)(
      handleSuccess(deserialiseBodyAs[List[CallGroup]]()).orElse {
        case x if x.statusCode == 404 => Future.successful(Right(Nil))
      }
    )
  }

  def getWatchedUserPresence(tenantId: Int, subscriberId: Int)(
      implicit scheduler: Scheduler,
      token: SludgToken
  ): Future[Either[AccessForbidden, Option[WatchedUserPresence]]] = {
    callProtectedApi(
      s"/v1/tenant/$tenantId/subscribers/$subscriberId/preferences",
      GET,
      body = None
    )(
      handleSuccess(deserialiseAsAndWrapBody[WatchedUserPresence]())
        .orElse(handleStatus(404, valueResult(None)))
    )
  }

  def upsertWatchedUserPresence(tenantId: Int, subscriberId: Int, watchedExtensions: List[String])(
      implicit scheduler: Scheduler,
      token: SludgToken
  ): Future[Either[AccessForbidden, Boolean]] = {
    val json = Json.toJson(watchedExtensions)
    callProtectedApi(
      s"/v1/tenant/$tenantId/subscribers/$subscriberId/preferences",
      PUT,
      body = Some(JSONBody(json))
    )(
      handleSuccess(valueResult(true)).orElse {
        case x if x.statusCode == 400 => Future.successful(Right((false)))
        case x if x.statusCode == 404 => Future.successful(Right((false)))
      }
    )
  }

  //Diverts
  def getSingleTenantDID(tenantId: Int, did: Int)(
      implicit scheduler: Scheduler,
      token: SludgToken
  ): Future[Either[AccessForbidden, Option[DIDNumber]]] = {
    callProtectedApi(s"/v1/tenant/$tenantId/diverts/ddi/$did", GET, body = None)(
      handleSuccess(deserialiseAsAndWrapBody[DIDNumber](None))
        .orElse(handleStatus(404, valueResult(None)))
    )
  }

  def getAllTenantDIDs(tenantId: Int)(
      implicit scheduler: Scheduler,
      token: SludgToken
  ): Future[Either[AccessForbidden, List[DIDNumber]]] = {
    callProtectedApi(s"/v1/tenant/$tenantId/diverts/ddi", GET, body = None)(
      handleSuccess(deserialiseBodyAs[List[DIDNumber]]()).orElse {
        case x if x.statusCode == 404 => Future.successful(Right(Nil))
      }
    )
  }

  def setDidHours(tenantId: Int, did: Int, hours: HourlyExtensions)(
      implicit scheduler: Scheduler,
      token: SludgToken
  ): Future[Either[AccessForbidden, Boolean]] = {
    val json = Json.toJson(hours)
    callProtectedApi(s"/v1/tenant/$tenantId/diverts/ddi/$did", PUT, body = Some(JSONBody(json)))(
      handleSuccess(valueResult(true)).orElse {
        case x if x.statusCode == 400 => Future.successful(Right((false)))
        case x if x.statusCode == 404 => Future.successful(Right((false)))
      }
    )
  }

  def setEmergencyOverride(tenantId: Int, did: Int, emergencyOverride: EmergencyOverride)(
      implicit scheduler: Scheduler,
      token: SludgToken
  ): Future[Either[AccessForbidden, Boolean]] = {
    val json = Json.toJson(emergencyOverride)
    callProtectedApi(
      s"/v1/tenant/$tenantId/diverts/ddi/$did/emergencyoverride",
      PUT,
      body = Some(JSONBody(json))
    )(
      handleSuccess(valueResult(true)).orElse {
        case x if x.statusCode == 400 => Future.successful(Right((false)))
        case x if x.statusCode == 404 => Future.successful(Right((false)))
      }
    )
  }

  def linkTelco(did: String, extension: String, pin: String)(
      implicit scheduler: Scheduler,
      token: SludgToken
  ): Future[Either[AccessForbidden, Boolean]] =
    telco(POST, URLEncodedBody("did" -> did, "extension" -> extension, "pin" -> pin))

  def unLink(
      sid: Int,
      tid: Int
  )(implicit scheduler: Scheduler, token: SludgToken): Future[Either[AccessForbidden, Boolean]] =
    telco(DELETE, URLEncodedBody("sid" -> sid.toString, "tid" -> tid.toString))

  private def telco(httpRequest: Method, URLEncodedBody: URLEncodedBody)(
      implicit scheduler: Scheduler,
      sludgToken: SludgToken
  ): Future[Either[AccessForbidden, Boolean]] = {
    callProtectedApi("/v1/user/link_telco", httpRequest, body = Some(URLEncodedBody))(
      handleSuccess(valueResult(true)).orElse({
        case x if x.statusCode != 200 => Future.successful(Right(false))
      })
    )
  }

  def getUserInfo()(
      implicit scheduler: Scheduler,
      token: SludgToken
  ): Future[Either[AccessForbidden, List[(String, String, String)]]] = {
    callProtectedApi(s"/v1/user/user_info", GET, body = None)(
      handleSuccess(deserialiseBodyAs[List[(String, String, String)]]()).orElse {
        case x if x.statusCode == 404 =>
          Future.successful(Left(AccessForbidden("We couldn't find any user info.")))
      }
    )
  }

  //Call reports
  def getAllCallReports(tenantId: Int)(
      implicit scheduler: Scheduler,
      token: SludgToken
  ): Future[Either[AccessForbidden, List[Report]]] = {
    callProtectedApi(s"/v1/tenant/$tenantId/call_reports/")(
      handleSuccess(deserialiseBodyAs[List[Report]]()) orElse handleStatus(404, valueResult(Nil))
    )
  }

  def getSubscriberCalls(tenantId: Int, reportId: Int)(
      implicit scheduler: Scheduler,
      token: SludgToken
  ): Future[Either[AccessForbidden, List[Report]]] = {
    callProtectedApi(s"/v1/tenant/$tenantId/call_reports/$reportId")(
      handleSuccess(deserialiseBodyAs[List[Report]]()) orElse handleStatus(404, valueResult(Nil))
    )
  }

  def getTenantCalls(tenantId: Int, reportId: Int)(
      implicit scheduler: Scheduler,
      token: SludgToken
  ): Future[Either[AccessForbidden, List[Report]]] = {
    callProtectedApi(s"/v1/tenant/$tenantId/call_reports/$reportId")(
      handleSuccess(deserialiseBodyAs[List[Report]]()) orElse handleStatus(404, valueResult(Nil))
    )
  }

  def createCallReport(tenantId: Int, reportName: String, filters: List[Filter])(
      implicit scheduler: Scheduler,
      token: SludgToken
  ): Future[Either[AccessForbidden, Int]] = {
    val jsonBody = Json.toJson(ReportModels.ReportCreationRequest(reportName, filters))

    callProtectedApi(s"/v1/tenant/$tenantId/call_reports/", POST, body = Some(JSONBody(jsonBody)))(
      {
        case x if x.statusCode == 201 =>
          val createdReportId = x.headers("Location").split("/").last.toInt
          createGroupings(
            tenantId,
            createdReportId
          ).flatMap(r =>
            if (r.contains(true))
              Future.successful(Right(createdReportId))
            else
              Future.failed(
                new Exception("Unable to create groupings for a report that was just created.")
              )
          )
      }
    )
  }

  def getCallReportWithFilters(tenantId: Int, reportId: Int)(
      implicit scheduler: Scheduler,
      token: SludgToken
  ): Future[Either[AccessForbidden, Option[ReportWithFilters]]] = {
    import com.sludg.util.json.FiltersJsonDeserializers._
    callProtectedApi(s"/v1/tenant/$tenantId/call_reports/$reportId/filters", GET, body = None)(
      handleSuccess(deserialiseAsAndWrapBody[ReportWithFilters]())
        .orElse(handleStatus(404, valueResult(None)))
    )
  }

  def updateCallReport(tenantId: Int, reportId: Int, filters: List[Filter])(
      implicit scheduler: Scheduler,
      token: SludgToken
  ): Future[Either[AccessForbidden, Boolean]] = {
    val jsonBody = Json.toJson(filters)
    callProtectedApi(
      s"/v1/tenant/$tenantId/call_reports/$reportId/filters",
      PUT,
      body = Some(JSONBody(jsonBody))
    )(
      handleSuccess(valueResult(true))
        .orElse(handleStatus(404, valueResult(false)))
    )
  }

  def deleteCallReport(
      tenantId: Int,
      reportId: Int
  )(implicit scheduler: Scheduler, token: SludgToken): Future[Either[AccessForbidden, Boolean]] = {
    callProtectedApi(s"/v1/tenant/$tenantId/call_reports/$reportId", DELETE, body = None)(
      handleSuccess(valueResult(true)) orElse handleStatus(400, valueResult(false))
    )
  }

  def getAllTenantSchedules(tenantId: Int)(
      implicit scheduler: Scheduler,
      token: SludgToken
  ): Future[Either[AccessForbidden, List[ReportSchedule]]] = {
    callProtectedApi(s"/v1/tenant/$tenantId/call_reports/scheduling", GET, None)(
      handleSuccess(deserialiseBodyAs[List[ReportSchedule]]()) orElse handleStatus(
        404,
        valueResult(Nil)
      )
    )
  }

  def createTenantSchedule(
      tenantId: Int,
      reportSchedule: ReportSchedule
  )(implicit scheduler: Scheduler, token: SludgToken): Future[Either[AccessForbidden, Boolean]] = {
    val jsonBody = Json.toJson(reportSchedule)
    callProtectedApi(
      s"/v1/tenant/$tenantId/call_reports/scheduling",
      POST,
      body = Some(JSONBody(jsonBody))
    )(
      handleSuccess(valueResult(true)) orElse handleStatus(404, valueResult(false))
    )
  }

  def deleteTenantSchedule(
      tenantId: Int,
      scheduleId: Int
  )(implicit scheduler: Scheduler, token: SludgToken): Future[Either[AccessForbidden, Boolean]] = {
    callProtectedApi(
      s"/v1/tenant/$tenantId/call_reports/scheduling/$scheduleId",
      DELETE,
      body = None
    )(
      handleSuccess(valueResult(true)) orElse handleStatus(404, valueResult(false))
    )
  }

  def updateTenantSchedule(tenantId: Int, scheduleId: Int, reportSchedule: ReportSchedule)(
      implicit scheduler: Scheduler,
      token: SludgToken
  ): Future[Either[AccessForbidden, Boolean]] = {
    val myBodyIsReady = Json.toJson(reportSchedule)
    callProtectedApi(
      s"/v1/tenant/$tenantId/call_reports/scheduling/$scheduleId",
      PUT,
      body = Some(JSONBody(myBodyIsReady))
    )(
      handleSuccess(valueResult(true)) orElse handleStatus(404, valueResult(false))
    )
  }

  def getTenantSchedule(tenantId: Int, scheduleId: Int)(
      implicit scheduler: Scheduler,
      token: SludgToken
  ): Future[Either[AccessForbidden, Option[ReportSchedule]]] = {
    callProtectedApi(s"/v1/tenant/$tenantId/call_reports/scheduling/$scheduleId", GET, None)(
      handleSuccess(deserialiseAsAndWrapBody[ReportSchedule]()) orElse handleStatus(
        404,
        valueResult(None)
      )
    )
  }

  def getCurrentGrouping(tenantId: Int, reportId: Int)(
      implicit scheduler: Scheduler,
      token: SludgToken
  ): Future[Either[AccessForbidden, List[Category[_]]]] = {
    callProtectedApi(
      s"/v1/tenant/$tenantId/calls/report/$reportId/grouping/default",
      GET,
      body = None
    )(
      handleSuccess(deserialiseBodyAs[List[Category[_]]]()) orElse handleStatus(
        404,
        valueResult(Nil: List[Category[_]])
      )
    )
  }

  def updateGroupings(tenantId: Int, reportId: Int, groupings: List[Category[_]])(
      implicit scheduler: Scheduler,
      token: SludgToken
  ): Future[Either[AccessForbidden, Boolean]] = {
    val json: play.api.libs.json.JsValue = Json.toJson(groupings)
    val jsonBody: JSONBody               = JSONBody(json)
    callProtectedApi(
      s"/v1/tenant/$tenantId/calls/report/$reportId/grouping/default",
      PUT,
      body = Some(jsonBody)
    )(
      handleSuccess(valueResult(true)) orElse handleStatus(404, valueResult(false))
    )
  }

  def createGroupings(
      tenantId: Int,
      reportId: Int
  )(implicit scheduler: Scheduler, token: SludgToken) = {
    callProtectedApi(s"/v1/tenant/$tenantId/calls/report/$reportId/grouping/default", POST)(
      handleSuccess(valueResult(true)) orElse handleStatus(404, valueResult(false))
    )
  }

  def getData(tenantId: Int, reportId: Int)(
      implicit scheduler: Scheduler,
      token: SludgToken
  ): Future[Either[AccessForbidden, Option[RootReportData]]] = {
    callProtectedApi(s"/v1/tenant/$tenantId/calls/report/$reportId/grouped", GET, body = None)(
      handleSuccess(deserialiseAsAndWrapBody[RootReportData]()).orElse(noneOn404)
    )
  }

  /**
    * Checks whether the user identified by the implicitly provided token is allowed to query
    * calls using [[ApiCalls.queryCalls]] method for the given tenant.
    *
    * @return true if queryCalls for the given tenant can be performed by the specified user,
    *  false if AccessForbidden would be returned by the attempts
    */
  def canQueryCalls(
      tenantId: Int
  )(implicit scheduler: Scheduler, token: SludgToken): Future[Boolean] = {
    getAuthorizedMethods(s"/v1/tenant/$tenantId/calls", token.tokenString)
      .map(_.contains(Method.POST))
  }

  def queryCalls(tenantId: Int, filters: List[Filter])(
      implicit scheduler: Scheduler,
      token: SludgToken
  ): Future[Either[AccessForbidden, List[CDR]]] = {
    val sendData = Json.prettyPrint(Json.toJson(filters))
    processXhrRequest[List[CDR]](
      tenantId,
      Method.POST,
      s"/v1/tenant/$tenantId/calls",
      token.tokenString,
      "text",
      sendData,
      (jsonToA[List[CDR]] _).andThen(_.getOrElse(Nil))
    )
  }

  def getCallRecording(tenantId: Int, cdrId: String)(
      implicit scheduler: Scheduler,
      token: SludgToken
  ): Future[Either[AccessForbidden, Option[org.scalajs.dom.Blob]]] = {
    val url = s"/v1/tenant/$tenantId/recording/cdr?cdrId=$cdrId"

    // This is a lot like processXhrRequest, which is of course duplicative
    // However, processXhrRequest automatically handles 200 and assumes everything else
    // is an error. However, in this case 404 may be reasonably returned.
    // Of course, ideally XHR request processing should be changed such that it does not
    // have this drawback, but since this class needs to be significantly reworked anyway
    // I am not going to do it at this time.
    //
    (for {
      req <- EitherT(validateXHRRequest(Method.GET, tenantId, url, token.tokenString, "blob"))
      result <- EitherT.right[AccessForbidden] {
        val p = Promise[Option[Blob]]()
        req.send()
        req.onreadystatechange = { (e: Event) =>
          if (!p.isCompleted) {
            if (req.status == 200 && req.readyState == 4) {
              p.success(Some(req.response.asInstanceOf[Blob]))
            } else if (req.status == 404) {
              p.success(None)
            } else if (req.status != 200 && req.status != 404) {
              p.failure(new Exception(s"Request failed with an unexpected result: ${req.status}"))
            }
          }
        }
        p.future
      }
    } yield result).value

  }

  //Used by the submit button to fetch data without modifying the database
  def queryCallReport(
      tenantId: Int,
      filtersAndGroupings: (List[Filter], List[GroupingModels.Category[_]])
  )(
      implicit scheduler: Scheduler,
      token: SludgToken
  ): Future[Either[AccessForbidden, Option[RootReportData]]] = {

    val sendData = Json.prettyPrint(Json.toJson(filtersAndGroupings))
    processXhrRequest(
      tenantId,
      Method.POST,
      s"/v1/tenant/$tenantId/calls/report",
      token.tokenString,
      "text",
      sendData,
      jsonToA[RootReportData]
    )
  }

  def getReportPdf(
      tenant: Int,
      filters: List[Filter],
      cats: List[Category[_]],
      columns: List[ReportColumn],
      callItemisation: Boolean,
      pdfName: Option[String],
      includePDF: Boolean,
      includeSpreadSheet: Boolean
  )(
      implicit scheduler: Scheduler,
      token: SludgToken
  ): Future[Either[AccessForbidden, org.scalajs.dom.Blob]] = {

    val sendData = Json
      .toJson(((filters, cats, columns, callItemisation, pdfName, includePDF, includeSpreadSheet)))
      .toString()
    processXhrRequest[org.scalajs.dom.Blob](
      tenant,
      Method.POST,
      s"/v1/tenant/$tenant/call_reports/generate",
      token.tokenString,
      "blob",
      sendData,
      blobToA[org.scalajs.dom.Blob]
    )
  }

  def getReportPdfFromReport(tenant: Int, reportId: Int, callItemisation: Boolean)(
      implicit scheduler: Scheduler,
      token: SludgToken
  ): Future[Either[AccessForbidden, org.scalajs.dom.Blob]] = {

    val sendData = callItemisation.toString
    processXhrRequest[org.scalajs.dom.Blob](
      tenant,
      Method.POST,
      s"/v1/tenant/$tenant/call_reports/$reportId/generate",
      token.tokenString,
      "blob",
      sendData,
      blobToA[org.scalajs.dom.Blob]
    )
  }

  def blobToA[A](xhr: XMLHttpRequest): A = {
    xhr.response.asInstanceOf[A]
  }

  def jsonToA[A](xhr: XMLHttpRequest)(implicit reads: Reads[A]): Option[A] = {
    Json.parse((xhr.response.asInstanceOf[String])).validate[A].asOpt
  }

  def processXhrRequest[A](
      tenantId: Int,
      method: Method,
      url: String,
      token: String,
      responseType: String,
      sendData: String,
      f: (XMLHttpRequest) => A
  )(implicit scheduler: Scheduler) = {
    val xhr: Future[Either[AccessForbidden, XMLHttpRequest]] =
      validateXHRRequest(method, tenantId, url, token, responseType)
    xhr.map {
      case Left(x) => Future.successful(Left(x))
      case Right(xhr) =>
        xhr.send(sendData)
        val p = Promise[Either[AccessForbidden, A]]()
        completeRequest(xhr, p, responseType, f)
    }.flatten
  }

  private def completeRequest[A](
      xhr: XMLHttpRequest,
      p: Promise[Either[AccessForbidden, A]],
      responseType: String,
      f: (XMLHttpRequest) => A
  )(implicit scheduler: Scheduler) = {
    Future {
      xhr.onreadystatechange = { (e: Event) =>
        if (xhr.status == 200 && xhr.readyState == 4) {
          p.success(Right(f(xhr)))
        } else if (xhr.status != 200) {
          p.success(Left(AccessForbidden("Forbidden")))
        }
      }
    }
    p.future
  }

  def getTableColumns(tenantId: Int, reportId: Int)(
      implicit scheduler: Scheduler,
      token: SludgToken
  ): Future[Either[AccessForbidden, List[ReportColumn]]] = {
    callProtectedApi(s"/v1/tenant/$tenantId/call_reports/$reportId/column_headers", GET, None)(
      handleSuccess(deserialiseBodyAs[List[ReportColumn]]())
    )
  }

  def updateTableColumns(tenantId: Int, reportId: Int, reportColumns: List[ReportColumn])(
      implicit scheduler: Scheduler,
      token: SludgToken
  ): Future[Either[AccessForbidden, Boolean]] = {
    val json: play.api.libs.json.JsValue = Json.toJson(reportColumns)
    val jsonBody: JSONBody               = JSONBody(json)
    callProtectedApi(
      s"/v1/tenant/$tenantId/call_reports/$reportId/column_headers",
      PUT,
      body = Some(jsonBody)
    )(
      handleSuccess(_ => Future.successful(Right(true)))
    )
  }

  def callProtectedApi[A](
      url: String,
      method: Method = GET,
      body: Option[BulkBodyPart] = None
  )(
      responseHandler: PartialFunction[SimpleHttpResponse, Future[Either[AccessForbidden, A]]]
  )(implicit scheduler: Scheduler, token: SludgToken) = {
    processResponse(
      extractResponse(sendRequest(method, url, token.tokenString, body)),
      responseHandler
    )
  }

  def genericRequestBuilder(
      requestMethod: Method,
      url: String,
      token: String,
      body: Option[BodyPart]
  ) = {
    val r = HttpRequest()
      .withMethod(requestMethod)
      .withProtocol(configuratedProtocol)
      .withHost(config.host)
      .withURL(url)
      .withHeaders(newHeaders = "Authorization" -> s"Bearer $token")

    body.map(b => r.withBody(b)).getOrElse(r)
  }

  def buildRequest(
      requestMethod: Method,
      url: String,
      token: String,
      body: Option[BodyPart]
  ): HttpRequest = {
    genericRequestBuilder(requestMethod, url, token, body)
  }

  def streamRequest(method: Method, url: String, token: String, body: Option[BodyPart])(
      implicit scheduler: Scheduler
  ): Future[Either[AccessForbidden, StreamHttpResponse]] = {
    validateRequest(method, url, token, body)(_.stream())
  }

  def sendRequest(method: Method, url: String, token: String, body: Option[BodyPart])(
      implicit scheduler: Scheduler
  ): Future[Either[AccessForbidden, SimpleHttpResponse]] = {
    validateRequest(method, url, token, body)(_.send())
  }

  def validateRequest[A](method: Method, url: String, token: String, body: Option[BodyPart])(
      f: HttpRequest => Future[A]
  )(implicit scheduler: Scheduler): Future[Either[AccessForbidden, A]] = {

    val result: Future[Either[AccessForbidden, A]] = getAuthorizedMethods(url, token).flatMap {
      case Nil =>
        Future.successful(
          Left(AccessForbidden(s"Access forbidden to this method $method, You are only allowed"))
        )

      case allowedMethods =>
        if (allowedMethods.contains(method)) {
          f(buildRequest(method, url, token, body)).map(x => Right(x))
        } else {
          Future.successful(
            Left(
              AccessForbidden(
                s"Access forbidden to this method $method, You are only allowed $allowedMethods"
              )
            )
          )
        }

    }
    result
  }

  def getAuthorizedMethods(url: String, token: String)(
      implicit scheduler: Scheduler
  ): Future[List[Method]] = {
    buildRequest(OPTIONS, url, token, body = None)
      .send()
      .map(result => {
        result.headers("Allow").split(",").map(methodName => Method.apply(methodName.trim)).toList
      })
      .recover {
        case HttpException(_) => Nil
      }
  }

  def processResponse[T](
      responseMaybe: Future[Either[AccessForbidden, SimpleHttpResponse]],
      responseParser: PartialFunction[SimpleHttpResponse, Future[Either[AccessForbidden, T]]]
  )(implicit scheduler: Scheduler): Future[Either[AccessForbidden, T]] = {

    val f: Future[Either[AccessForbidden, T]] = responseMaybe.flatMap { maybeResponse =>
      val t: Either[AccessForbidden, Future[Either[AccessForbidden, T]]] = maybeResponse.map {
        maybeResponse =>
          responseParser.orElse[SimpleHttpResponse, Future[Either[AccessForbidden, T]]] {
            case x
                if x.statusCode == 403 ||
                  x.statusCode == 401 =>
              Future.successful(
                Left[AccessForbidden, T](AccessForbidden("YOU CANNOT READ THIS >:("))
              )
            case x => Future.failed(HttpException.badStatus(x))
          }(maybeResponse)
      }
      val t2: Future[Either[AccessForbidden, T]] = t.fold(
        e => Future.successful(Left(e)),
        s => s
      )
      t2
    }
    f
  }

  val baseUrl: String = s"${config.protocol}://${config.host}"

  def validateXHRRequest(
      method: Method,
      tenantId: Int,
      url: String,
      token: String,
      responseType: String
  )(implicit scheduler: Scheduler): Future[Either[AccessForbidden, XMLHttpRequest]] = {

    def genericXHRCall(requestMethod: Method, tenantId: Int): XMLHttpRequest = {
      val xhr = new XMLHttpRequest()
      xhr.withCredentials = false
      xhr.open(requestMethod.toString(), baseUrl + url, async = true)
      xhr.setRequestHeader("Content-Type", "application/json")
      xhr.setRequestHeader("Authorization", "Bearer " + token)
      xhr.responseType = responseType
      xhr
    }

    val result: Future[Either[AccessForbidden, XMLHttpRequest]] =
      getAuthorizedMethods(url, token).map {
        case Nil =>
          println(s"Forbidden access: $method are Not allowed")
          Left(AccessForbidden(s"Access forbidden to this method $method, You are only allowed"))
        case allowedMethods =>
          if (allowedMethods.contains(method)) {
            Right(genericXHRCall(method, tenantId))
          } else {
            Left(
              AccessForbidden(
                s"Access forbidden to this method $method, You are only allowed $allowedMethods"
              )
            )
          }
      }
    result
  }

}
