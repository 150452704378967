package com.sludg.vuetify.components

import com.sludg.scalajs.DynamicHelper
import com.sludg.vue.{EventBindings, ScopedSlots, VueProps}

import scala.scalajs.js
import scala.scalajs.js.|

trait VListTile {

  import com.sludg.vue.RenderHelpers._

  val vListTile = namedTag[VListTileProps, EventBindings, ScopedSlots]("v-list-tile")

}

object VListTile extends VListTile

trait VListTileProps extends VueProps {

  val `active-class`: js.UndefOr[String]       = js.undefined
  val append: js.UndefOr[Boolean]              = js.undefined
  val avatar: js.UndefOr[Boolean]              = js.undefined
  val color: js.UndefOr[String]                = js.undefined
  val `dark`: js.UndefOr[Boolean]              = js.undefined
  val disabled: js.UndefOr[Boolean]            = js.undefined
  val exact: js.UndefOr[Boolean]               = js.undefined
  val `exact-active-class`: js.UndefOr[String] = js.undefined
  val href: js.UndefOr[String | js.Object]     = js.undefined
  val inactive: js.UndefOr[Boolean]            = js.undefined
  val `light`: js.UndefOr[Boolean]             = js.undefined
  val nuxt: js.UndefOr[Boolean]                = js.undefined
  val replace: js.UndefOr[Boolean]             = js.undefined
  val ripple: js.UndefOr[Boolean | js.Object]  = js.undefined
  val tag: js.UndefOr[String]                  = js.undefined
  val target: js.UndefOr[String]               = js.undefined
  val to: js.UndefOr[String | js.Object]       = js.undefined
  val value: js.UndefOr[js.Any]                = js.undefined

}

object VListTileProps {

  import scala.scalajs.js.JSConverters._

  def apply(
      `active-class`: Option[String] = None,
      append: Option[Boolean] = None,
      avatar: Option[Boolean] = None,
      color: Option[String] = None,
      `dark`: Option[Boolean] = None,
      disabled: Option[Boolean] = None,
      exact: Option[Boolean] = None,
      `exact-active-class`: Option[String] = None,
      href: Option[Either[String, js.Object]] = None,
      inactive: Option[Boolean] = None,
      `light`: Option[Boolean] = None,
      nuxt: Option[Boolean] = None,
      replace: Option[Boolean] = None,
      ripple: Option[Either[Boolean, js.Object]] = None,
      tag: Option[String] = None,
      target: Option[String] = None,
      to: Option[Either[String, js.Object]] = None,
      value: Option[js.Any] = None
  ): VListTileProps = {
    DynamicHelper.buildViaDynamic(
      "active-class"       -> `active-class`.map(js.Any.fromString).orUndefined,
      "append"             -> append.map(js.Any.fromBoolean).orUndefined,
      "avatar"             -> avatar.map(js.Any.fromBoolean).orUndefined,
      "color"              -> color.map(js.Any.fromString).orUndefined,
      "dark"               -> `dark`.map(js.Any.fromBoolean).orUndefined,
      "disabled"           -> disabled.map(js.Any.fromBoolean).orUndefined,
      "exact"              -> exact.map(js.Any.fromBoolean).orUndefined,
      "exact-active-class" -> `exact-active-class`.map(js.Any.fromString).orUndefined,
      "href"               -> href.map(_.fold(js.Any.fromString, identity)).orUndefined,
      "inactive"           -> inactive.map(js.Any.fromBoolean).orUndefined,
      "light"              -> `light`.map(js.Any.fromBoolean).orUndefined,
      "nuxt"               -> nuxt.map(js.Any.fromBoolean).orUndefined,
      "replace"            -> replace.map(js.Any.fromBoolean).orUndefined,
      "ripple"             -> ripple.map(_.fold(js.Any.fromBoolean, identity)).orUndefined,
      "tag"                -> tag.map(js.Any.fromString).orUndefined,
      "target"             -> target.map(js.Any.fromString).orUndefined,
      "to"                 -> to.map(_.fold(js.Any.fromString, identity)).orUndefined,
      "value"              -> value.orUndefined
    )

  }
}
