package com.sludg.vuetify.components

import com.sludg.scalajs.DynamicHelper
import com.sludg.vue.{EventBindings, ScopedSlots, VueProps}

import scala.scalajs.js

import scala.scalajs.js.|

/**
  * @author pbarman
  *         Date: 10/01/2019
  *         Time: 9:16
  *
  *
  */
trait VDatePicker {

  import com.sludg.vue.RenderHelpers._

  val vDatePicker = namedTag[VDatePickerProps, EventBindings, ScopedSlots]("v-date-picker")

}

object VDatePicker extends VDatePicker

trait VDatePickerProps extends VueProps {

  val `allowed-dates`: js.UndefOr[js.Function]                                       = js.undefined
  val color: js.UndefOr[String]                                                      = js.undefined
  val dark: js.UndefOr[Boolean]                                                      = js.undefined
  val `day-format`: js.UndefOr[js.Function]                                          = js.undefined
  val disabled: js.UndefOr[Boolean]                                                  = js.undefined
  val `event-color`: js.UndefOr[js.Array[js.Any] | js.Function | js.Object | String] = js.undefined
  val events: js.UndefOr[js.Array[js.Any] | js.Function | js.Object]                 = js.undefined
  val `first-day-of-week`: js.UndefOr[String | Int]                                  = js.undefined
  val `full-width`: js.UndefOr[Boolean]                                              = js.undefined
  val `header-color`: js.UndefOr[String]                                             = js.undefined
  val `header-date-format`: js.UndefOr[js.Function]                                  = js.undefined
  val landscape: js.UndefOr[Boolean]                                                 = js.undefined
  val light: js.UndefOr[Boolean]                                                     = js.undefined
  val locale: js.UndefOr[String]                                                     = js.undefined
  val max: js.UndefOr[String]                                                        = js.undefined
  val min: js.UndefOr[String]                                                        = js.undefined
  val `month-format`: js.UndefOr[js.Function]                                        = js.undefined
  val multiple: js.UndefOr[Boolean]                                                  = js.undefined
  val `next-icon`: js.UndefOr[String]                                                = js.undefined
  val `no-title`: js.UndefOr[Boolean]                                                = js.undefined
  val `picker-date`: js.UndefOr[String]                                              = js.undefined
  val `prev-icon`: js.UndefOr[String]                                                = js.undefined
  val reactive: js.UndefOr[Boolean]                                                  = js.undefined
  val readonly: js.UndefOr[Boolean]                                                  = js.undefined
  val scrollable: js.UndefOr[Boolean]                                                = js.undefined
  val `show-current`: js.UndefOr[Boolean | String]                                   = js.undefined
  val `show-week`: js.UndefOr[Boolean]                                               = js.undefined
  val `title-date-format`: js.UndefOr[js.Function]                                   = js.undefined
  val `type`: js.UndefOr[String]                                                     = js.undefined
  val value: js.UndefOr[js.Array[js.Any] | String]                                   = js.undefined
  val `weekday-format`: js.UndefOr[js.Function]                                      = js.undefined
  val width: js.UndefOr[Int | String]                                                = js.undefined
  val `year-format`: js.UndefOr[js.Function]                                         = js.undefined
  val `year-icon`: js.UndefOr[String]                                                = js.undefined

}

object VDatePickerProps {

  import scala.scalajs.js.JSConverters._

  def apply[A <: js.Any](
      `allowed-dates`: Option[js.Function] = None,
      color: Option[String] = None,
      dark: Option[Boolean] = None,
      `day-format`: Option[js.Function] = None,
      disabled: Option[Boolean] = None,
      `event-color`: Option[js.Array[js.Any] | js.Function | js.Object | String] = None,
      events: Option[js.Array[js.Any] | js.Function | js.Object] = None,
      `first-day-of-week`: Option[Either[String, Int]] = None,
      `full-width`: Option[Boolean] = None,
      `header-color`: Option[String] = None,
      `header-date-format`: Option[js.Function] = None,
      landscape: Option[Boolean] = None,
      light: Option[Boolean] = None,
      locale: Option[String] = None,
      max: Option[String] = None,
      min: Option[String] = None,
      `month-format`: Option[js.Function] = None,
      multiple: Option[Boolean] = None,
      `next-icon`: Option[String] = None,
      `no-title`: Option[Boolean] = None,
      `picker-date`: Option[String] = None,
      `prev-icon`: Option[String] = None,
      reactive: Option[Boolean] = None,
      readonly: Option[Boolean] = None,
      scrollable: Option[Boolean] = None,
      `show-current`: Option[Either[Boolean, String]] = None,
      `show-week`: Option[Boolean] = None,
      `title-date-format`: Option[js.Function] = None,
      `type`: Option[String] = None,
      value: Option[Either[js.Array[js.Any], String]] = None,
      `weekday-format`: Option[js.Function] = None,
      width: Option[Either[Int, String]] = None,
      `year-format`: Option[js.Function] = None,
      `year-icon`: Option[String] = None
  ): VDatePickerProps = {

    DynamicHelper.buildViaDynamic(
      "allowed-dates" -> `allowed-dates`.orUndefined,
      "color"         -> color.map(js.Any.fromString).orUndefined,
      "dark"          -> dark.map(js.Any.fromBoolean).orUndefined,
      "day-format"    -> `day-format`.orUndefined,
      "disabled"      -> disabled.map(js.Any.fromBoolean).orUndefined,
      "event-color"   -> `event-color`.map(_.asInstanceOf[js.Any]).orUndefined,
      "events"        -> events.orUndefined,
      "first-day-of-week" -> `first-day-of-week`
        .map(_.fold(js.Any.fromString, js.Any.fromInt))
        .orUndefined,
      "full-width"         -> `full-width`.map(js.Any.fromBoolean).orUndefined,
      "header-color"       -> `header-color`.map(js.Any.fromString).orUndefined,
      "header-date-format" -> `header-date-format`.orUndefined,
      "landscape"          -> landscape.map(js.Any.fromBoolean).orUndefined,
      "light"              -> light.map(js.Any.fromBoolean).orUndefined,
      "locale"             -> locale.map(js.Any.fromString).orUndefined,
      "max"                -> max.map(js.Any.fromString).orUndefined,
      "min"                -> min.map(js.Any.fromString).orUndefined,
      "month-format"       -> `month-format`.orUndefined,
      "multiple"           -> multiple.map(js.Any.fromBoolean).orUndefined,
      "next-icon"          -> `next-icon`.map(js.Any.fromString).orUndefined,
      "no-title"           -> `no-title`.map(js.Any.fromBoolean).orUndefined,
      "picker-date"        -> `picker-date`.map(js.Any.fromString).orUndefined,
      "prev-icon"          -> `prev-icon`.map(js.Any.fromString).orUndefined,
      "reactive"           -> reactive.map(js.Any.fromBoolean).orUndefined,
      "readonly"           -> readonly.map(js.Any.fromBoolean).orUndefined,
      "scrollable"         -> scrollable.map(js.Any.fromBoolean).orUndefined,
      "show-current" -> `show-current`
        .map(_.fold(js.Any.fromBoolean, js.Any.fromString))
        .orUndefined,
      "show-week"         -> `show-week`.map(js.Any.fromBoolean).orUndefined,
      "title-date-format" -> `title-date-format`.orUndefined,
      "type"              -> `type`.map(js.Any.fromString).orUndefined,
      "value"             -> value.map(_.fold(identity, js.Any.fromString)).orUndefined,
      "weekday-format"    -> `weekday-format`.orUndefined,
      "width"             -> width.map(_.fold(js.Any.fromInt, js.Any.fromString)).orUndefined,
      "year-format"       -> `year-format`.orUndefined,
      "year-icon"         -> `year-icon`.map(js.Any.fromString).orUndefined
    )
  }
}
