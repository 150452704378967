package com.sludg.vuetify.components

import com.sludg.vue.{EventBindings, ScopedSlots, VueProps}

import scala.scalajs.js

/**
  * @author dpoliakas
  *         Date: 22/11/2018
  *         Time: 17:20
  *
  *
  */
trait VContent {

  import com.sludg.vue.RenderHelpers._

  val vContent = namedTag[VContentProps, EventBindings, ScopedSlots]("v-content")

}

object VContent extends VContent

trait VContentProps extends VueProps {}
