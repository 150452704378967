package com.sludg.vue

import com.sludg.scalajs.DynamicHelper
import com.sludg.vue.rendering._
import org.scalajs.dom.Event
import org.scalajs.dom.raw.KeyboardEvent

import scala.scalajs.js
import scala.scalajs.js.|

import scala.language.implicitConversions

/**
  * @author dpoliakas
  *         Date: 21/11/2018
  *         Time: 17:23
  *
  *
  */
object RenderHelpers extends TemplatingHelpers with DefaultTags with PropHelpers {}

trait DefaultTags
    extends Anchor
    with Audio
    with Bold
    with Button
    with Br
    with Div
    with Headings
    with Paragraph
    with RouterView
    with Span
    with Small
    with Strong
    with Select
    // this is fully scoped so that it wouldn't be accidentally extending the standard scala Source type
    with com.sludg.vue.rendering.Source
    with Table
    with Transition
    with Th
    with Tr
    with Td
    with Template {
  this: TemplatingHelpers =>
}

trait TemplatingHelpers {

  import VueInstanceProperties._

  import scala.scalajs.js.JSConverters._

  trait RenderFunction[A] {
    def render(c: CreateElement): A
  }

  object RenderFunction {
    def arrayUnit(): RenderFunction[js.Array[VNode]] = _ => js.Array()
  }

  trait NodeModifier[A <: VueProps, B <: js.Object, SS <: ScopedSlots] {
    def modify(
        props: Props[A, B, SS],
        renderFunction: RenderFunction[js.Array[VNode]]
    ): (Props[A, B, SS], RenderFunction[js.Array[VNode]])
  }

  trait ChildAppender[A <: VueProps, B <: js.Object, SS <: ScopedSlots]
      extends NodeModifier[A, B, SS] {
    def modify(
        props: Props[A, B, SS],
        renderFunction: RenderFunction[js.Array[VNode]]
    ): (Props[A, B, SS], RenderFunction[js.Array[VNode]]) = {
      (props, appending(renderFunction))
    }

    private def appending(
        renderFunction: RenderFunction[js.Array[VNode]]
    ): RenderFunction[js.Array[VNode]] = c => {
      val others = renderFunction.render(c)
      others.push(appendables(c).toSeq: _*)
      others
    }

    def appendables(c: CreateElement): js.Array[VNode]
  }

  trait PropModifier[A <: VueProps, B <: js.Object, SS <: ScopedSlots]
      extends NodeModifier[A, B, SS] {
    def modify(
        props: Props[A, B, SS],
        renderFunction: RenderFunction[js.Array[VNode]]
    ): (Props[A, B, SS], RenderFunction[js.Array[VNode]]) = {
      (modifyProps(props), renderFunction)
    }

    def modifyProps(props: Props[A, B, SS]): Props[A, B, SS]
  }

  case class NodeRenderer[A <: VueProps, B <: js.Object, SS <: ScopedSlots](
      elem: Elem,
      props: Props[A, B, SS],
      children: js.UndefOr[RenderFunction[js.Array[VNode]]]
  ) extends RenderFunction[VNode] {
    def render(c: CreateElement): VNode = c(elem, props, children.map(_.render(c)))

    def withProps(p: Props[A, B, SS]): NodeRenderer[A, B, SS] = NodeRenderer(elem, p, children)

    /**
      * The ordering of children will get weird if you add children with multiple invocations of this method. Don't say I didn't warn you!
      *
      * @param thingsToAppend
      * @return
      */
    def apply(thingsToAppend: NodeModifier[A, B, SS]*) = {
      val (newProps, newChildren) =
        thingsToAppend.foldLeft((props, children.getOrElse(RenderFunction.arrayUnit()))) {
          case ((props, children), modifier) => modifier.modify(props, children)
        }
      NodeRenderer(elem, newProps, newChildren)
    }
  }

  def namedTag[A <: VueProps, B <: js.Object, SS <: ScopedSlots](
      s: String
  ): NodeRenderer[A, B, SS] =
    NodeRenderer[A, B, SS](s, js.undefined: js.UndefOr[RenderOptions[A, B, SS]], js.undefined)

  implicit def nestableSeq[A <: VueProps, B <: js.Object, SS <: ScopedSlots, C](
      l: Seq[C]
  )(implicit f: C => NodeModifier[A, B, SS]): NodeModifier[A, B, SS] =
    (props: Props[A, B, SS], renderFunction: RenderFunction[js.Array[VNode]]) =>
      l.map(f).foldLeft((props, renderFunction)) {
        case ((p, r), node) => node.modify(p, r)
      }

  implicit def nestableJsArray[A <: VueProps, B <: js.Object, SS <: ScopedSlots, C](
      l: js.Array[C]
  )(implicit f: C => NodeModifier[A, B, SS]): NodeModifier[A, B, SS] =
    (props: Props[A, B, SS], renderFunction: RenderFunction[js.Array[VNode]]) =>
      l.map(f).foldLeft((props, renderFunction)) {
        case ((p, r), node) => node.modify(p, r)
      }

  implicit class NestableRenderer[A <: VueProps, B <: js.Object, SS <: ScopedSlots](
      r: RenderFunction[VNode]
  ) extends ChildAppender[A, B, SS] {
    override def appendables(c: CreateElement): js.Array[VNode] = js.Array(r.render(c))
  }

  implicit class NestableString[A <: VueProps, B <: js.Object, SS <: ScopedSlots](s: String)
      extends ChildAppender[A, B, SS] {
    override def appendables(c: CreateElement): js.Array[VNode] = {
      js.Array(s.asInstanceOf[VNode]) // This is safe, vue can handle string VNodes
    }
  }

  implicit class ModifierWithFallback[X](n: js.UndefOr[X]) {
    def foldToNode[A <: VueProps, B <: js.Object, SS <: ScopedSlots](
        f: X => NodeModifier[A, B, SS],
        otherwise: NodeModifier[A, B, SS]
    ) =
      if (n.isDefined) {
        f(n.get)
      } else {
        otherwise
      }

  }

  implicit class NestableVNode[A <: VueProps, B <: js.Object, SS <: ScopedSlots](n: VNode)
      extends ChildAppender[A, B, SS] {
    override def appendables(c: CreateElement): js.Array[VNode] = js.Array(n)
  }

  implicit class NestableNode[A <: VueProps, B <: js.Object, SS <: ScopedSlots](
      n: NodeRenderer[_, _, _]
  ) extends ChildAppender[A, B, SS] {
    override def appendables(c: CreateElement): js.Array[VNode] = js.Array(n.render(c))
  }

  implicit class NestableArray[A <: VueProps, B <: js.Object, SS <: ScopedSlots](n: js.Array[VNode])
      extends ChildAppender[A, B, SS] {
    override def appendables(c: CreateElement): js.Array[VNode] = n
  }

  implicit class NestableSeq[A <: VueProps, B <: js.Object, SS <: ScopedSlots](n: Seq[VNode])
      extends ChildAppender[A, B, SS] {
    override def appendables(c: CreateElement): js.Array[VNode] = n.toJSArray
  }

  implicit class PropsOverride[A <: VueProps, B <: js.Object, SS <: ScopedSlots](
      p: RenderOptions[A, B, SS]
  ) extends PropModifier[A, B, SS] {
    override def modifyProps(props: Props[A, B, SS]): Props[A, B, SS] = p
  }

  implicit class EventAdder[A <: VueProps, B <: js.Object, SS <: ScopedSlots](e: B)
      extends PropModifier[A, B, SS] {
    override def modifyProps(props: Props[A, B, SS]): Props[A, B, SS] =
      props
        .map { ro =>
          ro.asInstanceOf[js.Dynamic].updateDynamic("on")(e).asInstanceOf[RenderOptions[A, B, SS]]
        }
        .orElse(js.defined(RenderOptions(on = Some(e))))
  }

  implicit class PropAdder[A <: VueProps, B <: js.Object, SS <: ScopedSlots](p: A)
      extends PropModifier[A, B, SS] {
    override def modifyProps(props: Props[A, B, SS]): Props[A, B, SS] =
      props
        .map { ro =>
          ro.asInstanceOf[js.Dynamic]
            .updateDynamic("props")(p)
            .asInstanceOf[RenderOptions[A, B, SS]]
        }
        .orElse(js.defined(RenderOptions(props = Some(p))))
  }

  def raw[A <: VueProps, B <: js.Object, SS <: ScopedSlots](r: RenderFunction[VNode]) =
    new ChildAppender[A, B, SS] {
      def appendables(c: CreateElement): js.Array[VNode] = js.Array(r.render(c))
    }

  implicit def text(text: String): RenderFunction[String] = _ => text

  def nothing[A <: VueProps, B <: js.Object, SS <: ScopedSlots]: NodeModifier[A, B, SS] =
    (props: Props[A, B, SS], renderFunction: RenderFunction[js.Array[VNode]]) =>
      (props, renderFunction)

}

object TemplatingHelpers extends TemplatingHelpers

/**
  * Methods in this trait help you with making Prop objects for your components
  */
trait PropHelpers {
  def click(click: Event => Unit): EventBindings = EventBindings(
    click = js.defined(click)
  )
}

object PropHelpers extends PropHelpers {}

trait RenderOptions[+A <: VueProps, +B <: js.Object, +SS <: ScopedSlots] extends js.Object {
  val on: js.UndefOr[B]              = js.undefined
  val props: js.UndefOr[A]           = js.undefined
  val domProps: js.UndefOr[DomProps] = js.undefined
  val attrs: js.UndefOr[HtmlAttrs]   = js.undefined
  val style: js.UndefOr[js.Object]   = js.undefined
  val slots: js.UndefOr[js.Object]   = js.undefined
  val scopedSlots: js.UndefOr[SS]    = js.undefined
  val slot: js.UndefOr[String]       = js.undefined
  val key: js.UndefOr[String]        = js.undefined
  val `class`: js.UndefOr[String | js.Object | js.Array[String | js.Object]]
  val ref: js.UndefOr[String] = js.undefined
}

class DomProps(val value: js.UndefOr[Any] = js.undefined)    extends js.Object
class HtmlAttrs(val href: js.UndefOr[String] = js.undefined) extends js.Object

object RenderOptions {

  import scala.scalajs.js.JSConverters._

  def apply[A <: VueProps, B <: js.Object, SS <: ScopedSlots](
      on: Option[B] = None,
      props: Option[A] = None,
      domProps: Option[DomProps] = None,
      attrs: Option[HtmlAttrs] = None,
      style: Option[js.Object] = None,
      slots: Option[js.Object] = None,
      slot: Option[String] = None,
      key: Option[String] = None,
      scopedSlots: Option[SS] = None,
      `class`: List[Either[String, js.Object]] = Nil,
      ref: Option[String] = None
  ): RenderOptions[A, B, SS] = {
    DynamicHelper.buildViaDynamic(
      "on"          -> on.orUndefined,
      "props"       -> props.orUndefined,
      "attrs"       -> attrs.orUndefined,
      "style"       -> style.orUndefined,
      "slots"       -> slots.orUndefined,
      "key"         -> key.map(js.Any.fromString).orUndefined,
      "slot"        -> slot.map(js.Any.fromString).orUndefined,
      "scopedSlots" -> scopedSlots.orUndefined,
      "class" -> {
        if (`class` == Nil) js.undefined
        else `class`.map(_.fold(js.Any.fromString, identity)).toJSArray
      },
      "ref" -> ref.map(js.Any.fromString).orUndefined
    )
  }

  def ofProps[A <: VueProps, B <: js.Object, SS <: ScopedSlots](props: A): RenderOptions[A, B, SS] =
    apply(props = Some(props))
}

trait EventBindings extends js.Object {
  def click(e: Event): Unit

  def input(e: Event): Unit

  def transitionend(e: Event): Unit

  def change(e: Event): Unit

  def keyup(e: KeyboardEvent): Unit

  def keydown(e: KeyboardEvent): Unit

  def onblur(e: Event): Unit
}

object EventBindings {
  type Trigger[T] = js.UndefOr[js.Function1[T, Unit]]

  def apply(
      click: Trigger[Event] = js.undefined: js.UndefOr[js.Function1[Event, Unit]],
      transitionEnd: Trigger[Event] = js.undefined: js.UndefOr[js.Function1[Event, Unit]],
      input: Trigger[Event] = js.undefined: js.UndefOr[js.Function1[Event, Unit]],
      change: Trigger[Event] = js.undefined: js.UndefOr[js.Function1[Event, Unit]],
      keyup: Trigger[KeyboardEvent] = js.undefined: js.UndefOr[js.Function1[KeyboardEvent, Unit]],
      keydown: Trigger[KeyboardEvent] = js.undefined: js.UndefOr[js.Function1[KeyboardEvent, Unit]],
      keypress: Trigger[KeyboardEvent] = js.undefined: js.UndefOr[js.Function1[KeyboardEvent, Unit]],
      mouseover: Trigger[Event] = js.undefined: js.UndefOr[js.Function1[Event, Unit]],
      mouseleave: Trigger[Event] = js.undefined: js.UndefOr[js.Function1[Event, Unit]],
      mouseenter: Trigger[Event] = js.undefined: js.UndefOr[js.Function1[Event, Unit]],
      mouseout: Trigger[Event] = js.undefined: js.UndefOr[js.Function1[Event, Unit]],
      mousemove: Trigger[Event] = js.undefined: js.UndefOr[js.Function1[Event, Unit]],
      onblur: Trigger[Event] = js.undefined: js.UndefOr[js.Function1[Event, Unit]]
  ): EventBindings = {
    DynamicHelper.buildViaDynamic(
      "click"         -> click,
      "input"         -> input,
      "change"        -> change,
      "transitionend" -> transitionEnd,
      "keyup"         -> keyup,
      "keydown"       -> keydown,
      "keypress"      -> keypress,
      "mouseover"     -> mouseover,
      "mouseleave"    -> mouseleave,
      "mouseenter"    -> mouseenter,
      "mouseout"      -> mouseout,
      "mousemove"     -> mouseout,
      "onblur"        -> onblur
    )
  }
}
