package com.sludg.vuetify.components.transitions

import com.sludg.scalajs.DynamicHelper
import com.sludg.vue.{EventBindings, ScopedSlots, VueProps}
import scala.scalajs.js
import scala.scalajs.js.|

trait VSlideXTransition {

  import com.sludg.vue.RenderHelpers._

  val vSlideXTransition =
    namedTag[VTransitionProps, EventBindings, ScopedSlots]("v-slide-x-transition")

}
